export function initLetterPagination(tableContainer) {
    const pageItem = $('.letter-pagination .page-item')
    const listOfLetters = '*abcdefghijklmnopqrstuvwxyz#'
    const nonLetterName = $('.non-letter').data('value')
    const allLettersName = $('.all-letters').data('value')
    let activeLetter = window.getUrlParams(url)['l'] || allLettersName
    let url = window.location.href

    // find the current letter container and make it active
    pageItem.find(`.page-link[data-value=${activeLetter}]`).parent().addClass('active')

    // disable prev/next button when necessary
    if (activeLetter == allLettersName) {
        $('.page-link.prev').parent().addClass('disabled')
    }
    if (activeLetter == nonLetterName) {
        $('.page-link.next').parent().addClass('disabled')
    }
    // event for clicking a letter in pagination
    $('.page-link.item').on('click', function() {
        tableContainer.addClass('is-requesting')
        url = window.updateUrlParameter(url, 'l', $(this).data('value'))
        window.location.href = url
    })
    // events for prev and next button
    $('.page-link.prev').on('click', function () {
        if (!$(this).parent().hasClass('disabled')){
            tableContainer.addClass('is-requesting')

            if (activeLetter == nonLetterName) {
                activeLetter = '#'
            }
            let currentIndex = listOfLetters.indexOf(activeLetter)
            let letterParameter = listOfLetters[currentIndex - 1]

            if (letterParameter == '*') {
                letterParameter = allLettersName
            }
            url = window.updateUrlParameter(url, 'l', letterParameter)
            window.location.href = url
        }
    })
    $('.page-link.next').on('click', function () {
        if (!$(this).parent().hasClass('disabled')){
            tableContainer.addClass('is-requesting')

            if (activeLetter == allLettersName) {
                activeLetter = '*'
            }
            let currentIndex = listOfLetters.indexOf(activeLetter)
            let letterParameter = listOfLetters[currentIndex + 1]

            if (letterParameter == '#') {
                letterParameter = nonLetterName
            }
            url = window.updateUrlParameter(url, 'l', letterParameter)
            window.location.href = url
        }
    })
}