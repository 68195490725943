$(document).ready(() => {
    if($('.web-multi-teaser-marketing-container').length > 0) {
        let webMultiTeaserContainer = $('.web-multi-teaser-marketing-container')
        
        // check if WebMultiTeaser was included in a snippet container - if yet, allow target groups select
        webMultiTeaserContainer.each(function() {
            if($(this).closest('.snippet-container').length > 0) {
                $(this).find('.editmode-popup .options-container.target-groups-select').addClass('visible')
            }
        })
        
        /**
         * Masonry layout init
         */
        const teasers = $('.web-multi-teaser-marketing-container .web-multi-teaser-marketing')
        teasers.each(function() {
            const teaser = $(this)

            teaser.packery({
                //columnWidth: '.teaser-single',
                itemSelector: '.teaser-single',
                gutter: 0,
                horizontalOrder: true,
                percentPosition: true
            })
        })

        // Open edit popup
        $('.web-multi-teaser-marketing-container .trigger-edit-popup').on('click', function() {
            const editModePopup = $(this).closest('.teaser-inner').find('.editmode-popup')

            editModePopup.addClass('open')
            $('html').css('overflow', 'hidden')
        })

        // Submit/save edit popup
        $('.web-multi-teaser-marketing-container .save-popup').on('click', function() {
            const { documentId } = $(this).data()
            const editModePopup = $(this).closest('.editmode-popup')

            editModePopup.removeClass('open')
            $('html').css('overflow', 'auto')

            const payload = {
                detail: {
                    document_id: documentId
                }
            }

            const myEvent = new CustomEvent('document_save_reload', payload)
            window.parent.dispatchEvent(myEvent)
        })

        // Close edit popup
        $('.web-multi-teaser-marketing-container .close-popup').on('click', function() {
            const editModePopup = $(this).closest('.editmode-popup')

            editModePopup.removeClass('open')
            $('html').css('overflow', 'auto')
        })

        // Video popup
        $('.web-multi-teaser-marketing-container .text-video-box .play-video').on('click', function() {
            const videoContent = $(this).find('.video-data').html()

            const wrapper = document.createElement('div')
            $(wrapper).addClass('video-wrapper')

            // Insert HTML
            wrapper.innerHTML = videoContent

            swal({
                content: wrapper,
                className: 'swal-video',
                buttons: {}
            }).then(() => {
                removeSwalOverlay()
            })
        })
        
        function removeSwalOverlay() {
            $('body > .swal-overlay').remove()
        }

        function styleMultiTeaserInEditMode() {
            let multiTeaserEditoModeBlock = $('.web-multi-teaser-marketing-container.edit-mode .pimcore_block_entry[data-real-name="web-multi-teaser-marketing-block"]')
            
            multiTeaserEditoModeBlock.each(function() {
                let teaserSingle = $(this).find('.teaser-single')
                let classList = teaserSingle.attr('class').split(/\s+/)
                let that = $(this)

                $.each(classList, function(index, className) {
                    if (className == 'width-1' || className == 'width-2' || className == 'width-3') {
                        that.addClass(className)
                    }
                })
            })
        }
        
        styleMultiTeaserInEditMode()

        let multiTeaserContactFormSubmit = $('.web-multi-teaser-marketing-container .contact-form button[type="submit"]')
        
        function validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(email)
        }
        
        multiTeaserContactFormSubmit.on('click touch', function(e) {
            let contactFormId = $(this).closest('.contact-form').attr('id')
            let multiTeaserContactFormRequiredField = $('.web-multi-teaser-marketing-container #' + contactFormId + ' .fields .field.required')
            
            let formValid = true

            multiTeaserContactFormRequiredField.each(function() {
                let inputValid = true
                
                if($(this).hasClass('input')) {
                    if($(this).find('input').attr('type') == 'email') {
                        inputValid = validateEmail($(this).find('input').val())
                    } else {
                        inputValid = $(this).find('input').val()
                    }
                } else if($(this).hasClass('checkbox')) {
                    inputValid = $(this).find('input').is(':checked')
                } else if($(this).hasClass('select')) {
                    inputValid = $(this).find('select').val()
                }
                
                if(!inputValid) {
                    formValid = false
                    
                    $(this).addClass('error')
                } else {
                    $(this).removeClass('error')
                }
            })
            
            if(!formValid) {
                e.preventDefault()
            }
        })
        
        let formParam = getUrlParameter('form')
        
        if(formParam) {
            let offsetFix = 166
            
            if($('.web-headline-bar-container.visible').length > 0) {
                offsetFix = 221
            }
            
            $('html, body').animate({ 
                scrollTop: $('.form-box .content[data-form-id="' + formParam + '"]').offset().top - offsetFix
            }, 1000)
        }
        
        function getUrlParameter(sParam) {
            var sPageURL = window.location.search.substring(1),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=')
        
                if (sParameterName[0] === sParam) {
                    return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1])
                }
            }

            return false
        };
        
        if($('.web-multi-teaser-marketing .teaser-single.type-newsletter-advanced-box').length > 0) {
            $('.teaser-single.type-newsletter-advanced-box select').select2({
                minimumResultsForSearch: -1
            })
        }
    }
})