
/**
* Translation prototype function on string
*/
String.prototype.trans = function (locale = appConfig.locale) {
   const key = this.toString()
   const { siteTranslations } = appConfig
   if (siteTranslations){
    if (siteTranslations.hasOwnProperty(key)) { 
        // If exists return translation if set
        return siteTranslations[key][locale] != ''
            ? siteTranslations[key][locale]
            : key
    } else {
        // Create missing key
        createTranslation(key)
        // Return it untranslated
        return key
    }
   } else {
       window.showErrorPopup()
   }
   
}
/**
* If translation key doesn't exist, make request for creation
* [Called from string prototype]
* @param {*} translationKey
*/
async function createTranslation(translationKey) {
    // Create propper payload
    // const payload = new FormData()
    // payload.append('translation_key', translationKey)
    let url = `/site/translation/create/${translationKey}`
    try {
        await axios.post(url)
        return
    } catch(err) {
        throw err
    }
 }
