$(document).ready(() => {
    if($('#user-profile-page').exists()) {
        let formContainer = $('#user-profile-page .user-profile__form')
        let removeAvatar = false
        let autoNumericInputs = []

        reinitPhoneFields() 
         /**
         * Init phone input Autonumeric and select2 country select 
         */
        function reinitPhoneFields() {
            const countrySelects = $('.country-select')
            const phoneSelector = '.phone-field'

            let ANOptions = {
                unformatOnSubmit: true,
                modifyValueOnWheel: false,
                emptyInputBehavior: 'null',
                currencySymbol: '(0)',
                currencySymbolPlacement: AutoNumeric.options.currencySymbolPlacement.prefix,
                decimalPlaces: 0,        
                digitGroupSeparator: '',
                minimumValue: 1,
            }
            const phoneAN = new AutoNumeric.multiple(phoneSelector, ANOptions)
            
            autoNumericInputs.push(phoneAN)
            
            // handle input clear since minimum value disables user input clear 
            $(phoneSelector).on('keyup', function(e){
                // check for backspace and delete keypress
                if (e.keyCode == 8 || e.keyCode == 46) {
                    let input = $(this)
                    let ANInstance = AutoNumeric.getAutoNumericElement(this)
                    // check if input is empty or displaying only currency symbol 
                    if (!input.val() || input.val() == '(0)' || input.val() == '(0'){
                       

                        ANInstance.update({minimumValue: 0})
                        ANInstance.clear()
                        ANInstance.update({minimumValue: 1})
                
                    }
                    
                }
            })
            countrySelects.each((index, select)=>{
                let $select = $(select)
                let selectOptions = {
                    dropdownParent: $select.closest('.form-group'),
                    minimumResultsForSearch: -1,
                    

                }
                $select.select2(selectOptions)
            })
        }

        //Logic for favorite button for sales office
        const changeFavoriteSalesOffice = async function () {
            console.log($(this).find('#star'))
            let favorite = $(this).data('favorite')
            let urlSetFavorite = $(this).data('urlSet')
            let urlRemoveFavorite = $(this).data('urlRemove')

            console.log(favorite)

            try {
                // await axios.get(url, window.axiosConfig)

                if (favorite) {
                    await axios.get(urlRemoveFavorite, window.axiosConfig)
                    
                    $(this).find('#star').html('star_border')
                    $(this).toggleClass('favorite-off favorite-on')
                } else {
                    await axios.get(urlSetFavorite, window.axiosConfig)

                    $('.star-icon').html('star_border')
                    $(this).find('#star').html('star')
                    $(this).toggleClass('favorite-off favorite-on')
                }
                location.reload()
            } catch (err) {
                window.showErrorPopup()
                throw err
            }
        }

        formContainer.on('click', '.favorite-button', changeFavoriteSalesOffice)

        // Make preview for avatar changes
        formContainer.on('change', '.profile-form #profile_avatar', function() {
            const profileImage = formContainer.find('#user-avatar-preview')
            if (this.files && this.files[0]) {
                // Create instance on file reader
                let reader = new FileReader()

                // After image is ready, use blob as preview
                reader.onload = function (e) {
                    if(e.target.result) {
                        removeAvatar = false
                        profileImage.attr('src', e.target.result)
                    }
                }

                // Just get link to blob when ready
                reader.readAsDataURL(this.files[0])
            }
        })

        formContainer.on('click', '.email-settings-toggler', async function(e) {
            // e.preventDefault()
            let input = $(this)
            const togglebutton = input.closest('.togglebutton')

            if (!togglebutton.hasClass('loading')) {
                togglebutton.addClass('loading')
                let {enablePath, disablePath} = togglebutton.data()
              
                // console.log('is it checked?', input.attr('checked') == 'checked');
                let inputValue = input.is(':checked') 
                let path = inputValue ? enablePath : disablePath
                await axios.post(path)
                .then(response => {
                    if (response.status == 200 && response.data.status != 'error'){
                        togglebutton.find('.success-icon').animate({ opacity: 1 }, 400, function() {
                            $(this).animate({opacity: 0}, 2000)  
                        })
                    } else {
                        input.prop('checked', !input.prop('checked'))
                        window.showErrorPopup()
                    }
                    togglebutton.removeClass('loading')

                })
                .catch( err => {
                    window.showErrorPopup()
                    togglebutton.removeClass('loading')

                })
            }
          
        }) 
        // Avatar removal action
        formContainer.on('click', '#remove-avatar', () => {
            const profileImage = formContainer.find('#user-avatar-preview')
            const defaultAvatarSrc = profileImage.data('defaultAvatar')
            profileImage.attr('src', defaultAvatarSrc)
            removeAvatar = true
        })

        // Form submit
        formContainer.on('click', '.profile-form #profile__submit', function(event) {
            event.preventDefault()

            if($(this).hasClass('is-requesting')) {
                return
            }

            const $form = $(this).closest('form')
            const requestURI = $form.attr('action')
            const validation = window.validateFormFields($form)
            if(validation && validation.isValid) {
                // Create "plain" formData
                const formData = new FormData()
                const formFields = $form.serializeArray()
                formFields.forEach(field => {
                    let value = field.value
                    const fieldName = field.name
    
                    // Fetch unformatted values
                    const input = $form.find(`[name="${field.name}"]`)
                    let ANInputs = [].concat.apply([], autoNumericInputs)
                    ANInputs.forEach(autonumericInput => {
                        const { domElement } = autonumericInput
                        // Find input which needs to be unformatted
                        if (input.is(domElement)) {
                            
                            value = autonumericInput.rawValue
                        }
                    })
                    // Create payload
                    formData.append(fieldName, value)
                })

                // Add "image removal" field if needed
                if (removeAvatar) {
                    formData.append('delete_image', true)
                } else {
                    // Image upload logic
                    const avatarInput = formContainer.find('#profile_avatar')
                    const avatarFieldName = avatarInput.attr('name')
                    const files = avatarInput[0].files

                    // There are some files in input
                    if (files.length > 0) {
                        formData.append(avatarFieldName, files[0])
                    }
                }

                submitProfileUpdate(requestURI, formData)
            }

        })
       
        /**
         * Make submit request via ajax call
         */
        async function submitProfileUpdate(requestURI, payload) {
            const submitButton = formContainer.find('.profile-form #profile__submit')

            // Show global loader popup adn disable body
            window.showLoaderPopup()

            submitButton.trigger('toggleState')

            await axios.post(requestURI, payload, window.axiosConfig)
            .then(response => {
                const { data } = response
                submitButton.trigger('toggleState')
                // Update form Content
                // const updatedContent = $(data).find('.account').html()
                // formContainer.find('.account').html(updatedContent)

                const updatedContent = $(data).find('.profile-form').html()
                formContainer.find('.profile-form').html(updatedContent)
                // Update header avatar
                const updatedHeaderContent = $(data).find('#page-header #logged-account-bar').html()
                $('#page-header #logged-account-bar').html(updatedHeaderContent)
              autoNumericInputs = []
              reinitPhoneFields()
              window.addToggleInput()
                // Close loader
                window.closeLoaderPopup()
            })
            .catch((err) => {
                window.closeLoaderPopup()
                window.showErrorPopup()

                submitButton.trigger('toggleState')
                console.error(err)
                throw err
            })                
        }
    }
})
