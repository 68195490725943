import { initAjaxFilter } from 'Select2/filter'

$(document).ready(() => {
    window.setCompanyToHome()
    /**
     * Select2 selects and logic for search
     */
    if(
        $('.js-search-autocomplete').length > 0
    ) {
        initSearchAC()
    }

    // Autocomplete component for search
    // Since we're updateing view via ajax, reinit is needed
    function initSearchAC() {
        const searchInput = $('.js-search-autocomplete')
        // GETselect
        searchInput.select2({
            width: '',
            multiple: false,
            allowClear: true,
            maximumSelectionSize: 1,
            minimumInputLength: 1,
            containerCssClass: 'search-match-container',
            dropdownCssClass: 'search-match-dropdown',
            placeholder: window.bag.match_search.placeholder,
            ajax: {
                transport: window.customTransportFunction,
                url: window.bag.match_search.api_route,
                dataType: 'json',
                cache: false,
                data: (params) => ({
                    term: $.trim(params.term)
                }),
                processResults: (data) => {
                    const results = data.map(item => ({
                        ...item,
                        id: item.customer_nr,
                    }))

                    return { results }
                },
                cache: true,
            },
            id: function (obj) {
                return obj.customer_nr; // use slug field for id
            },
            language: {
                inputTooShort: () => window.bag.match_search.add_more_text,
                noResults: () => window.bag.match_search.no_results,
            },
            escapeMarkup: (markup) => markup,
            templateResult: formatCompany,
            templateSelection: formatSelectedCompany,
        }).on('select2:select', function (e) {
            let uniqueId = $(this).data('unique_id')
            $(`#companyNumberResponse-${uniqueId}`).val(e.params.data.customer_nr)

            if (  formatNumber($(`#companyNumberCustomer-${uniqueId}`).val()) ==  formatNumber($(`#companyNumberResponse-${uniqueId}`).val()) ) {
                const message = window.bag.notification_translation.matching_numbers
                $(`#matching-container-status-${uniqueId}`).text(message).addClass('valid').removeClass('invalid')
                $(`#accept-button-${uniqueId}`).removeClass('disabled')
            } else {
                const message = window.bag.notification_translation.not_matching_numbers
                $(`#matching-container-status-${uniqueId}`).text(message).addClass('invalid').removeClass('valid')
                $(`#accept-button-${uniqueId}`).addClass('disabled')
            }
        }).on('select2:unselect', function(e) {
            e.preventDefault()
            let uniqueId = $(this).data('unique_id')
            $(`#matching-container-status-${uniqueId}`).text('').removeClass('valid invalid')
            $(`#accept-button-${uniqueId}`).addClass('disabled')
        })
       
        // Results formater
        function formatCompany (company, item) {
            if (company.loading) return `   <div class="loader-container">
                <img src="/static/img/assets/simple-loading-blue.svg" />
                </div>`
            const markup = `
                <span data-unique_id="${company.customer_nr}" data-customer_nr="${company.customer_nr}">
                    ${company.name} 
                </span>
            `
            return markup
        }

        function formatSelectedCompany (company, item) {
            if (!company.name) {
                return window.bag.match_search.placeholder
            }
            const markup = `
                <span class="">
                    ${company.name} - ${company.customer_nr}
                </span>
            `
            return markup
        }
        function formatNumber(companyNumber){
            let formattedNumber = parseInt(companyNumber).toString();
            let numberLength = formattedNumber.length
            if (numberLength<10){
                let zerosNum= 10 - numberLength;
                formattedNumber= formattedNumber.padStart(zerosNum, '0');
            }
            return formattedNumber
        }
    }

    // Autocomplete for search that used to be on header
    const headerSearch = $('body .header-search')
    let company = headerSearch.data('company') ? headerSearch.data('company') : ''
    const searchLoader = $('#search-loader').html()
    const searchQuery = headerSearch.data('value')
    const qualityLoader = $('#quality-control .loader')
    const QaList = $('#quality-control')
    
    if(
        headerSearch.length > 0 
    ) {
        // Onload init
        initHeaderSearch()
    
        function initHeaderSearch() {
            const headerSearch = $('body .header-search')
            let searchParams = headerSearch.data('params') ? headerSearch.data('params') : [] 
            // POSTselect
            headerSearch.select2({
                width: '',
                multiple: true,
                allowClear: false,
                minimumInputLength: 1,
                containerCssClass: 'header-search-select2',
                placeholder: headerSearch.data('message-placeholder'),
                dropdownParent: $('.search-bar'),
                ajax: {
                    transport: window.customTransportFunction,
                    method: 'POST',
                    url: headerSearch.data('message-api_route'),
                    dataType: 'json',
                    cache: false,

                    // params: ,
                    data: (params) => {
                        let routeData = {
                            term: $.trim(params.term),
                            ...searchParams,
                            page: params.page || 1
                        }

                        if (headerSearch.data('filter-options')) {
                            routeData.filterOptions = headerSearch.data('filter-options')
                        }

                        return routeData
                    },
                    processResults: (data, { term }) => {
                        if (Array.isArray(data)){
                        // handleSearchWithContractsList(term)
                            return {
                                results: data.map(item => ({
                                    ...item,
                                    id: item.name,

                                }))
                            }
                        } else {
                            // handle paginated results
                            return {
                                results: data.items.map(item => ({
                                    ...item,
                                    id: item.name,
                                    pagination: {
                                        more: data.pagination && data.pagination.has_next_page ? data.pagination.has_next_page: false
                                    }
                                }))
                            }
                        }
                    },
                    cache: true,
                },
                language: {
                    inputTooShort: () => headerSearch.data('message-add_more_text'),
                    noResults: () => headerSearch.data('message-no_results'),
                },
                escapeMarkup: (markup) => markup,
                templateResult: formatSearchData,
                templateSelection: formatSelectedSearch,
            }).on('select2:select', function(e) {
                qualityLoader.removeClass('hidden')
                QaList.addClass('is-requesting')
                $('.dashboard-company-table').addClass('is-requesting')
                $('#companies-list-container').addClass('is-requesting')
                $('#customer-users-list').addClass('is-requesting')
                let path = e.params.data.path
                if (!path){
                    let {filter_key, value} = e.params.data
                    let url = decodeURIComponent(window.location.href)
                    path =  window.updateUrlParameter(url, `filter[${filter_key}]`, value)
                }
                if ($('#contract-overview-page').exists()){
                    let url = decodeURIComponent(window.location.href)

                    const params = window.getUrlParams(path)
                    Object.keys(params).forEach(key => {
                        path = window.updateUrlParameter(url, key, params[key])
                        
                    })
                }
                if (company) {
                    path = window.updateUrlParameter(path, 'c', company)
                 }
                window.history.pushState(null, null, path)
                window.location.href = path
            }).on('select2:open', function () {
                setTimeout(() => {
                    $('#bag-user-dashboard .page-content:not(.highway-companies-page-content)').addClass('zIndexHack')
                })
            }).on('select2:close', function () {
                $('#bag-user-dashboard .page-content:not(.highway-companies-page-content)').removeClass('zIndexHack')
            })

            // Show icon after select2 init
            $('.search-bar .search-icon').removeClass('hidden')
        }
         //define global scoped function for header reinitialization
         window.reInitHeaderSearch = initHeaderSearch

        // pick up the value from url and display the searched query over the select2
 
        if (searchQuery){
            displaySearchQuery(searchQuery)
        }
        function displaySearchQuery(query){
            
            let urlParam = headerSearch.data('param')
            
            if (query!== ''){
               
                let customParam = urlParam == 'userId' ? 'term': urlParam;
                let route = window.updateUrlParameter(headerSearch.data('message-api_route'), customParam, query)
                axios({
                    method: 'GET',
                    cache: false,
                    url: route,
                })
                .then(function (data) {
                    let searchData = data.data.hasOwnProperty('items') ? data.data.items : data.data

                    let markup = `<div class="search-query">
                            <span>${searchData[0].name}</span>
                            <i class="material-icons">close </i>
                        </div>`
                    if (markup){
                        const searchInput = $('.header-search-select2 .select2-search__field')
                        searchInput.addClass('hidden')
                        searchInput.after(markup)
                        
                        let searchQueryElement = searchInput.siblings('.search-query')
                        searchQueryElement.on('click', function(){
                            qualityLoader.removeClass('hidden')
                            QaList.addClass('is-requesting')
                            $('#bag-users-list').addClass('is-requesting')
                            $('.dashboard-company-table').addClass('is-requesting')
                            searchQueryElement.remove()
                            searchInput.removeClass('hidden')
                            window.location.href = window.updateUrlParameter(window.location.href, urlParam, '')
                        })
                    }

                    // disable search if something has already been searched for
                    if (headerSearch.closest('.highway-search-navigation').exists()) {
                        headerSearch.prop('disabled', true)
                    }
                })
            }
        }

        function formatSearchData (data, item) {
            let markup = ''
            
            // Loading
            if (data.loading) {
                markup = searchLoader
            
            } else {
                // Option item
                let { name, path, header, value, filter_key } = data
                if (!path && value && filter_key  ) {
                    path = window.updateUrlParameter(window.location.href, `filter[${filter_key}]`, value)
                }
                if (header){
                    name = `${header}: ${name}`
                }
                // append company parameter if exists
                if (company) {
                    path = window.updateUrlParameter(path, 'c', company)
                }
                markup = `
                    <a class="search-result-link" href="${path}">
                        ${name}
                    </a>
                `
            }

            return markup
        }

        function formatSelectedSearch (data, item) {
            const { name } = data
            return name;
        }
    } 
    // triggers bag Benutzerkonsole company select, bag Usermanagement company select
    let dropdownParent = $('.highway .dashboard-company-filter')

    if ($('#bag-user-dashboard').exists()){
        dropdownParent = $('.dashboard-status-form')
    }

    //init highway view select on bagUsersList, bagInvitationsDashboard, bagCompaniesDashboard
    // customer users filter is handled inside customer-users.js
    $('.highway .company-filter-select').select2({
        dropdownParent: $('.highway'),
        minimumResultsForSearch: -1,
        dropdownCssClass: 'company-filter-select-dropdown'
    }).on('select2:select', () => {
        if ($('#invitations-dashboard').exists()) {
            $('#invitations-dashboard').addClass('is-requesting')
            location.reload()
        }
    }).on('select2:open', function () {
        setTimeout(() => {
            $('#bag-user-dashboard .page-content:not(.highway-companies-page-content)').addClass('zIndexHack')
        })
    }).on('select2:close', function () {
        $('#bag-user-dashboard .page-content:not(.highway-companies-page-content)').removeClass('zIndexHack')
    })
    dropdownParent.find('.select2-selection__arrow b').html('<i class="material-icons">expand_more</i>')

    if ($('.vkorg-filter-select').exists()) {
        const filter = $('.vkorg-filter-select')
        const filterParent = $('.highway')
        const dropdownClass = 'vkorg-filter-select-dropdown'
        let loaderContainer = $('#companies-list-container')
        if ($('#invitations-dashboard').exists()) {
            loaderContainer = $('#invitations-dashboard')
        }
        initAjaxFilter(filter, filterParent, dropdownClass, loaderContainer, searchLoader)

        filter.on('select2:open', function () {
            setTimeout(() => {
                $('#bag-user-dashboard .page-content:not(.highway-companies-page-content)').addClass('zIndexHack')
            })
        }).on('select2:close', function () {
            $('#bag-user-dashboard .page-content:not(.highway-companies-page-content)').removeClass('zIndexHack')
        })
    }

    const invitationsDashboard = $('#invitations-dashboard')

    invitationsDashboard.on('click', '.search-button', function(e) {
        e.preventDefault()
        const uniqueId = $(this).data('unique_id')
        handleStepChange(uniqueId, '.step-2')
    })

    invitationsDashboard.on('click', '.mini-button.complete', async function(e) {
        e.preventDefault()
        const uniqueId = $(this).data('unique_id')
        const companyId = $('.card-' + uniqueId + '.company-select').data('companynumber')

        if (!$(this).hasClass('disabled')) {
            let formData = new FormData
            formData.set('status_id', 5)
            formData.set('company_nr', companyId)

            axios(
                {
                method: 'post',
                url: $(this).data('url'),
                data: formData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
                })
                .then(function (response) {
                    //handle success
                    handleStepChange(uniqueId, '.step-3')
                })
                .catch(function (response) {
                    //handle error
                    window.showErrorPopup()
                }
            )
        }
    })

    invitationsDashboard.on('click', '.mini-button.cancel', async function(e) {
        e.preventDefault()
        const uniqueId = $(this).data('unique_id')
        handleStepChange(uniqueId, '.step-1')
    })

    invitationsDashboard.on('click', '.accept-button', function (e) {
        e.preventDefault()
        const uniqueId = $(this).data('unique_id')
        if (!$(this).hasClass('disabled')) {
            let formData = new FormData
            formData.set('status_id', 1)

            axios(
                {
                method: 'post',
                url: $(this).data('url'),
                data: formData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
                })
                .then(function (response) {
                    //handle success
                    getInvitationsHtml()
                })
                .catch(function (response) {
                    //handle error
                    window.showErrorPopup()
                    // console.log(response)
                }
            )
        }
    })

    invitationsDashboard.on('click', '.deny-button', function (e) {
        e.preventDefault()
        const uniqueId = $(this).data('unique_id')
        if (!$(this).hasClass('disabled')) {
            let formData = new FormData
            formData.set('status_id', 3)

            axios(
                {
                method: 'post',
                url: $(this).data('url'),
                data: formData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
                })
                .then(function (response) {
                    //handle success
                    // console.log(response)
                    getInvitationsHtml()
                })
                .catch(function (response) {
                    //handle error
                    window.showErrorPopup()
                    // console.log(response)
                }
            )
        }
    })

    const filterUsersInCompany = () => {
        let filterChoice = $("#customer-users-filter :selected").val()
        filterChoice == 'active'
        ? $('.company-user').each(function() {
            if($(this).find('.change-users-status').data('status_id') == '1') {
                $(this).addClass('hidden')
            } else {
                $(this).removeClass('hidden')
            }
        })
        : filterChoice == 'inactive'
        ? $('.company-user').each(function() {
            if($(this).find('.change-users-status').data('status_id') == '0') {
                $(this).addClass('hidden')
            } else {
                $(this).removeClass('hidden')
            }
        })
        : $('.company-user').each(function() {
            $(this).removeClass('hidden')
        })
    }
    // this seems to be out of use
    const filterCompanies = () => {
        let filterChoice = $("#dashboard-company-filter :selected").val()
        filterChoice == 'active'
        ? $('.card-dashboard__company').each(function() {
            if($(this).data('companyStatus') == 'inactive') {
                $(this).addClass('hidden')
            } else {
                $(this).removeClass('hidden')
            }
        })
        : filterChoice == 'inactive'
        ? $('.card-dashboard__company').each(function() {
            if($(this).data('companyStatus') == 'active') {
                $(this).addClass('hidden')
            } else {
                $(this).removeClass('hidden')
            }
        })
        : $('.card-dashboard__company').each(function() {
            $(this).removeClass('hidden')
        })
    }

    //Filter invitations by status
    let currentRoute = window.location.href
    window.bagRoutes.getUserDashboard = currentRoute
    const filterInvitationsByStatus = (status) => {
        // Update url
        currentRoute = window.updateUrlParameter(currentRoute, 'tab', 'invitations')
        currentRoute = window.updateUrlParameter(currentRoute, 'status', status)

        // Set to global object
        window.bagRoutes.getUserDashboard = currentRoute

        // Push to url feature
        // After page refresh, user will have everything all set
        if (typeof window.history.pushState != 'undefined') {
            window.history.pushState(null, null, currentRoute)
        }

        // Update view
        getInvitationsHtml()
    }

    // Filter companies or users
    $('#dashboard-company-filter').on('change', function () {
        let tab = $(this).data('tab')

        // Don't call it on dashboard list view
        if ($('#user-management-companies').exists()) {
            return
        }

        if(tab === 'companies') {
            // Not in use probably anymore
            filterCompanies()
        }

        if(tab === '' || tab === 'invitations') {
            let status = $(this).val()
            filterInvitationsByStatus(status)
        }
    })

    $('#customer-users-filter').on('change', function () {
        filterUsersInCompany()
    })

    invitationsDashboard.on('click', '.resend-invitation-button', function () {
        let url = $(this).data('url')
        resendInvitation(url)
    })

    invitationsDashboard.on('click', '.delete-invitation-button', function (e) {
        let url = $(this).data('url')
        deleteInvitation(url, e.target)
    })

    // Resend invitation
    async function resendInvitation(url) {
        try {
            // Make request
            window.showLoaderPopup()
            const response = await axios.get(url, window.axiosConfig)

            // Update view
            await getInvitationsHtml()

            window.closeLoaderPopup()

            const successPopup = $('#bag-user-dashboard .invite-company__success')
            successPopup.removeClass('hidden').addClass("animated fadeIn faster")
        } catch (err) {
            window.closeLoaderPopup()
            window.showErrorPopup()
            console.error(err)
            throw err
        }
    }

    // Popup closing logic
    const inviationsPage = $('#invitations-dashboard')
    inviationsPage.on('click', '.back-to-login, .popup-exit', () => {
        $('.success-popup').addClass("animated fadeOut faster")
        setTimeout(function(){
            $('.success-popup').addClass("hidden fadeOut").removeClass("animated fadeIn faster")
        }, 500)
    })

    // Delete invitation
    async function deleteInvitation (url, e) {
        try {
            window.showLoaderPopup()
            await axios.delete(url, window.axiosConfig)
            getInvitationsHtml()
            window.closeLoaderPopup()
            
            //different popups if you are deleting from status-green or status-red columns
            if ($(e).parentsUntil('.list', '.status-green').length == 1) {
                swal({
                    className: 'calloff-status-modal'.trans(),
                    title: 'company_deletion.confirmation_popup.title'.trans(),
                    text: 'company_deletion.confirmation_popup.text'.trans(),
                    icon: 'success',
                    buttons: {
                        submit: {
                            text: 'company_deletion.confirmation_popup.submit'.trans(),
                            closeModal: true,
                        }
                    }
                })
            } else {
                swal({
                    className: 'calloff-status-modal'.trans(),
                    title: 'company_complete_deletion.confirmation_popup.title'.trans(),
                    text: 'company_complete_deletion.confirmation_popup.text'.trans(),
                    icon: 'success',
                    buttons: {
                        submit: {
                            text: 'company_complete_deletion.confirmation_popup.submit'.trans(),
                            closeModal: true,
                        }
                    }
                })
            }
        } catch (err) {
            window.showErrorPopup()
            console.error(err)
            throw err
        }
    }

    // Get invitiations dashboard
    async function getInvitationsHtml() {
        try {
            const response = await axios.get(currentRoute, window.axiosConfig)
            const { data } = response
            const dashboardHtml = $(data).find('#invitations-dashboard').html()

            invitationsDashboard.html(dashboardHtml)
            initSearchAC()
        } catch (err) {
            window.showErrorPopup()
            console.error(err)
            throw err
        }
    }
})

const handleStepChange = (id, step) => {
    const card = $(`#card-${id}`)
    const oldStep = card.find('.card-content').not('.hidden-step')
    const newStep = card.find(step)

    oldStep.fadeOut(500)

    setTimeout(function(e) {
        oldStep.addClass('hidden-step')
        newStep.fadeIn(500).removeClass('hidden-step')
    }, 500)
}