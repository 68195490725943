<template>
  <div class="calloff-content form-container step-1" :class="loading ? 'is-requesting': ''">
    <p>{{'calloff.form.add-description'|trans}}</p>
    <div class="incoterm-selection">
        <div class="form-group label-floating">
            <label class="control-label">
                {{'calloff.form.label_choose_delivery'|trans}}
            </label>
            <div id="incoterm" data-name="incoterm" class="form-group incoterm-selection">
                <div v-for="(callOffType,index) in callOffTypes" :key="callOffType" class="inline-for">
                    <input type="radio" :id="`incoterm_${index}`" name="incoterm" required="required" :value="callOffType.name" v-model="callOffTypeSelected" @change="selectCallOffType">    
                    <label class="required" :for="`incoterm_${index}`">{{callOffType.name}}</label>    
                </div>
            </div> 
             <div class="delivery-mode">
                <div class="form-group label-floating">
                    <label class="control-label">
                        {{'calloff.form.label_choose_delivery_mode'|trans}}
                    </label>
                    <div id="delivery_mode" class="form-group">
                        <div v-for="(deliveryMode) in deliveryModes" :key="deliveryMode"  class="inline-for">
                            <input type="radio" :id="deliveryMode" :name="deliveryMode" :value="deliveryMode" v-model="deliveryModeSelected" @change="deliveryModeSelect">    
                            <label :for="deliveryMode">
                                {{deliveryMode}}
                            </label>    
                        </div>
                      
                    </div>    
                </div>
            </div>  
        </div>
    </div>

  </div>
</template>

<script>
export default {
    props: {
        callOffTypes: {
            type: Array
        },
        deliveryModes: {
            type: Array
        },
        axiosFlag: {
            type: Boolean
        },
     
        loading: {
            type: Boolean
        }
       
    },
    data() {
        return {
            callOffTypeSelected: this.callOffTypes[0].name,
            deliveryModeSelected: this.deliveryModes[0]

        }
    },
    methods: {
       selectCallOffType(){
           this.$emit('incotermSelection', this.callOffTypeSelected)
           
       },
       deliveryModeSelect(){
            this.$emit('deliveryModeSelection', this.deliveryModeSelected)
       }
    },
    watch: {
        deliveryModes: function(newVal) {

            this.deliveryModeSelected = newVal[0]
            this.deliveryModeSelect()

        }
    },
}
</script>

<style>
</style>