<template>
  <div class="link-container">
      <a v-if="status==0" @click="openConfirm">{{'disposition.calloff_details.send_reminder'|trans}}</a>
      <div v-if="status==1||status==4 || status==2 ||overview" class="calloff-details-controls">
            <a @click="openDeny" class="decline"> <i class="material-icons">close</i></a>
            <a v-if="status==1 || status===2" @click="openConfirm" class="confirm"> <i class="material-icons">done</i></a>
            
      </div>
  </div>
</template>

<script>
import EventBus from './../eventBus.js';
export default {
    data() {
        return {
           
           
        }
    },
    props: {
        status: {
            type: Number
        },
        id: {
            type: String
        },
        statusChangeUrl: {
            type: String
        },
        allUrl: {
            type: String
        },
        overview: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        openConfirm() {
                EventBus.$emit('toggleApprove', this.id);
                EventBus.$emit('hideCancel', this.id);
        },
        openDeny() {
            if (this.status === 1 || this.status === 2){
                this.emitCancellation()
            } else if (this.status == 4 || this.overview) {
                let url = this.statusChangeUrl
                let backUrl = this.allUrl
                  swal({
                        className: 'calloff-status-modal',
                        title:  this.$root.$options.filters.trans("disposition.calloff.cancel.heading"),
                        text:  this.$root.$options.filters.trans("disposition.calloff.cancel.content"),
                        buttons: [ this.$root.$options.filters.trans("company-invitation.cancel") , this.$root.$options.filters.trans("disposition.calloff.cancel.confirm") ]
                            

                        // icon: "alert",

                    }).then(function(value){
                        if (value){
                            axios.get(url)
                            .then(res=>{
                                if (res.status == 200){
                                   
                                    $('.disposition-table-container').addClass('is-requesting')
                                    location.reload()
                                }
                            })
                            .catch(err=> {
                                if (err.message){
                                    window.showErrorPopup(err.message)

                                } else {
                                    window.showErrorPopup()
                                }
                                throw err
                            })
                        }
                        // window.location.reload()
                    })
            }
        },
        emitCancellation() {
            EventBus.$emit('toggleCancel', this.id);
            EventBus.$emit('hideApprove', this.id);
        }
    },
    computed: {

    }
}
</script>

<style>

</style>