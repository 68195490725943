<template>
   <div class="calloff-popup-container" >
       <!-- data-id="calloff-single-__name__"-->
            <div class="calloff-popup">
            
                <!-- Single calloff -->
                <div class="popup-container">
                    <div class="calloff-header">
                        <h4>{{"calloff.highway_edit_form.add-title"|trans}}</h4>
                        <div class="callof-controls">
                            <a href="javascript:;" class="info-calloff-popup">
                            
                            <i class="material-icons">info</i>
                        </a>
                            <a href="javascript:;" class="close-calloff-popup" @click="$emit('close')">
                                <i class="material-icons">close</i>
                            </a>
                        
                        </div>
                    </div>
                    <div class="paginator">
                        <a class="paginator-page page-1" :class="{active: active==1}" @click="active=1" ref="step-1" href="javascript:;">
                            <span class="page-number">1</span>
                        </a>
                        <a class="paginator-page page-2" :class="{active: active==2, disabled: disableStep1}" @click="step2Action" ref="step-2" href="javascript:;">
                            <span class="page-number">2</span>
                        </a>
                    </div>
                
                    <loading-icon v-if="loading"/>
                    <step1 v-show="active==1"
                        v-if="axiosFlag"
                        :loading="loading"
                        :callOffTypes="callOffTypes"  
                        :axiosFlag="axiosFlag" 
                        :deliveryModes="deliveryModes" 
                        @incotermSelection="enableDeliveryMode"
                        @deliveryModeSelection="enableStep2" 
                    />
                    
                    <step2 v-show="active==2" ref="step-2-content"
                    :loading="loading"
                    :initialEditContent="editContent"
                    :editId="editId"
                    :key="`${callOffTypeSelected}-${deliveryModeSelected}`"
                    />

            
                </div>
                <div class="calloff-footer">
                    <button type="button" class="btn btn-simple cancel-button" @click="$emit('close')" :disabled="loading">
                        {{'register.not_yet'|trans}}
                    </button>
                    <button  v-if="active==1" type="button" class="btn btn-primary next-button" @click="step2Action" :disabled="disableStep1">
                        {{'calloff.form.next-step'|trans}}
                    </button>
                     <button  v-if="active==2" type="button" class="btn btn-primary next-button" @click="nextAction" :disabled="loading">
                        {{'calloff.form.submit'|trans}}
                    </button>
                </div>
        </div> 
    </div>
 

</template>

<script>
import EventBus from './../eventBus.js';
import step1 from './popup-step1.vue'
import step2 from './popup-step2.vue'
import loadingIcon from './loading-icon.vue'
export default {
    props: {
        calloffTypesUrl: {
            type: String
        },
        calloffEditUrl: {
            type: String
        },
       
    },
    mounted() {    
        const vueComponent = this
        EventBus.$on('loading', this.toggleLoading)  
        EventBus.$on('clickedBack', function(){
            let step1 = vueComponent.$refs['step-1']
            step1.click()
        })  
    },
    created() {

       axios.get(this.calloffTypesUrl)
       .then(res=>{
            this.axiosFlag = true
            this.availableCallOffTypes= res.data
            this.loading = false
            this.enableDeliveryMode(Object.keys(this.availableCallOffTypes)[0])
            this.editId  = this.callOffTypes.find(el=> el.name == this.callOffTypeSelected).id
            
       })
       .catch(e=>{
           window.showErrorPopup()
       })
    },
    data() {
        return {
            active: 1,
            loading: true,
            callOffTypeSelected: '',
            deliveryModeSelected: '',
            availableCallOffTypes: '',
            axiosFlag: false,
            editUrl: this.calloffEditUrl,
            editId: '',
            editContent: '',
            editUpdated: false,
            
        }
    },
    methods: {
        toggleLoading(state){
            this.loading = state
        },

        nextAction(){
            let step1 = this.$refs['step-1']
            let step2 = this.$refs['step-2']
            if (step2.classList.contains('active')){
                this.$refs['step-2-content'].submitPayload()
            } 
        },
        editRequest() {
            if (this.editUpdated) {
            let formData = new FormData()
            formData.append('call_off_type_id', this.editId)
            
            this.loading = true
            axios({ method: 'POST', 
                url: this.editUrl, 
                data: formData
            })
            .then(res=>{
                    this.loading = false
                    this.editContent= res.data
                    this.editUpdated = false
                   
                    
                })
                .catch(e=>{
                    window.showErrorPopup()
                })
            }
        },
        step2Action() {
            this.editRequest()
            this.active = 2

        },
        enableDeliveryMode(callOffTypeValue) {
            this.callOffTypeSelected = callOffTypeValue
        },
        enableStep2(deliveryModeValue){
            this.deliveryModeSelected = deliveryModeValue
            if (this.deliveryModeSelected != undefined){
                this.updateEditUrl()
            } 

        },
        updateEditUrl(){
            
            let calloffType = this.availableCallOffTypes[this.callOffTypeSelected].find(function(el){  
                if (el.delivery_mode == this.deliveryModeSelected){
                    return el
                }
            }, this)
            // this.editUrl = window.updateUrlParameter(this.editUrl, 'call_off_type_id', calloffType.id)
            this.editId = calloffType.id
            this.editUpdated = true
        }
    },
  
    computed: {
        callOffTypes: function(){
            if (this.availableCallOffTypes != undefined){
                let callOffKeys =  Object.keys(this.availableCallOffTypes)
                let uniqueCallOffTypes = callOffKeys.map((el, index) =>{
                    let element = {
                        'name': el, 
                        'id':  this.availableCallOffTypes[el][0]['id']
                    }
                    return element
                })
                return uniqueCallOffTypes
            }

        },
        deliveryModes: function(){
            if (this.callOffTypeSelected){
                let modes =this.availableCallOffTypes[this.callOffTypeSelected].map((el=>el.delivery_mode))
                return modes
            }
            return []
        },
        disableStep1: function() {
          
            if (this.loading){
                return true
            }
            return false;
        },
    },
    watch: {
        deliveryModes: function(newVal) {

            this.deliveryModeSelected = newVal[0]
            if (this.callOffTypeSelected &&  this.deliveryModeSelected){
                this.editUpdated = true; 
                this.updateEditUrl()            
            }


        }
    },
    components: {
       step1,
       step2,
       loadingIcon
    }

}
</script>

<style>

</style>