class FactoryDailyPlan {
    constructor($container = null, {
        apiRoute = ''
    }) {
        this.container = $container

        if(!this.container.exists()) {
            console.error('Please provide proper DOM element for FactoryDailyPlan')
            return
        }

        this.apiRoute = apiRoute
        this.callOffDays = null
        this.contractItemId = null

        this.container.addClass('empty')

        this.initializeEventListeners()
    }

    initializeEventListeners() {
        this.container.on('click', '.nav-button', this.handleFactoryNavButtonClick.bind(this))
        this.container.on('click', '.factory-button', this.handleFactoryButtonClick.bind(this))
    }

    setContractItemId(contractItemId = null) {
        this.contractItemId = contractItemId

        if(this.contractItemId && this.callOffDays) {
            this.fetchContent()
        } else {
            this.container.addClass('empty')
        }
    }

    setStartDate(startDate = null) {
        if(!startDate) {
            this.callOffDays = null
            this.container.addClass('empty')

            return
        }

        // Use moment for adding day & formatting
        const momentInstance = moment(startDate)

        const formattedStart = momentInstance.format('DD.MM.YYYY')
        const formattedEnd = momentInstance.add(1, 'days').format('DD.MM.YYYY')

        this.callOffDays = `${formattedStart},${formattedEnd}`

        if(this.contractItemId && this.callOffDays) {
            this.fetchContent()
        } else {
            this.container.addClass('empty')
        }
    }

    handleFactoryNavButtonClick(event) {
        const $button = $(event.currentTarget)
        const sideContent = $('.calloff-edit-side-content')

        this.container.find('.nav-button').removeClass('active')
        $button.addClass('active')

        if ($button.hasClass('today-button')) {
            sideContent.removeClass('tomorrow').addClass('today')
        } else {
            sideContent.addClass('tomorrow').removeClass('today')
        }
    }

    handleFactoryButtonClick(event) {
        const $button = $(event.currentTarget)
        const { path } = $button.data()

        window.open(path, '_blank')
    }

    async fetchContent() {
        try {
            // Set flag
            this.container.addClass('is-requesting')

            // Create route
            const queryString = window.createUrlParamsFromObject({
                contract_item_id: this.contractItemId,
                call_off_days: this.callOffDays,
            })

            const route = `${this.apiRoute}?${queryString}`

            // Make request
            const response = await axios.get(route, window.axiosConfig)
            const { data } = response

            // Set content & remove loader
            this.container.html(data)
            this.container.removeClass('empty').removeClass('is-requesting')

            // Set first date as selected one
            this.container.find('.calloff-edit-side-navigation button').first().click()

        } catch(err) {
            this.container.removeClass('is-requesting')
            window.showErrorPopup()
            throw err
        }
    }
}

window.highwayCOCData = window.highwayCOCData || {}
window.highwayCOCData.FactoryDailyPlanClass = FactoryDailyPlan

export default FactoryDailyPlan