const { default: tippy } = require("tippy.js")

$(document).ready(() => {
    if($('#calloff-creation.contact-step').exists()) {
        const stepFourContainer = $('.contact-step')
        const loadingContainer = $('.calloff-creation-brick-container')
        const contactPopup = $('.calloff-creation-contact-popup-container')
        const popupMainContainer = contactPopup.find('.popup-main-container')
        const popupLoadingContainer = contactPopup.find('.calloff-creation-contact-popup-content')

        let url = window.location.href


        // Logic for initializing table filters for either main table or popup table
        function initFilters(popupFilters = false) {
            let filter = $('.calloff-step-four-table .header-filter')

            if (popupFilters) {
                filter = $('.calloff-creation-contact-popup-table .header-filter')
            }

            filter.each(function() {
                const { filterName, placeholder, selection, filterIcon, noResults } = $(this).data()

                $(this).select2({
                    minimumResultsForSearch: -1,
                    dropdownParent: $(this).closest('.filter-heading'),
                    language: {
                        noResults: () => noResults
                    },
                    templateSelection: () => {
                        return `${placeholder.toString()} <i class="material-icons">${filterIcon}</i>`
                    },
                    templateResult: data => window.callOffCreation.styleResults(data, selection),
                    escapeMarkup: markup => markup
                }).on('select2:opening', function() {
                    $(this).closest('.responsive-table-wrapper').addClass('filter-opened')
                }).on('select2:select', async function(e) {

                    let filterText = e.params.data.text
                    let filterValue = e.params.data.id

                    let params = {
                        [filterName]: $(this).val()
                    }

                    // if its already selected remove it instead
                    if (selection == filterText.toString().trim() || selection == filterValue) {
                        params = {
                            [filterName]: ''
                        }
                    }

                    if (popupFilters) {
                        try {
                            popupLoadingContainer.addClass('is-requesting')
                            await updatePopup(params)
                        }
                        catch {
                            window.showErrorPopup()
                        }
                        popupLoadingContainer.removeClass('is-requesting')

                    } else {
                        try {
                            loadingContainer.addClass('is-requesting')
                            await updateView(params)
                        }
                        catch {
                            window.showErrorPopup()
                        }
                        loadingContainer.removeClass('is-requesting')
                    }
                }).on('select2:closing', function() {
                    $(this).closest('.responsive-table-wrapper').removeClass('filter-opened')
                })
            })
        }
        initFilters()


        // Logic for table sorters
        stepFourContainer.on('click', '.table-sort', async function() {
            const { sortOrder, sortKey } = $(this).data()

            let params = {
                'order': sortOrder,
                'order_by': sortKey
            }

            try {
                loadingContainer.addClass('is-requesting')
                await updateView(params)
            }
            catch {
                window.showErrorPopup()
            }

            loadingContainer.removeClass('is-requesting')
        })

        // Logic for initializing popup search without reload
        function initSearch(search, searchParent, searchLoadingIcon) {
            const popupTable = contactPopup.find('.calloff-creation-contact-popup-table')

            let { contractId, cartItemId } = popupTable.data()
            let searchTerm

            // get the data and rename it
            let {
                messageApi_route: searchUrl,
                messagePlaceholder: placeholder,
                messageAdd_more_text: addMoreText,
                messageNo_results: noResults
            } = search.data()

            search.select2({
                dropdownParent: searchParent,
                minimumResultsForSearch: -1,
                minimumInputLength: 1,
                multiple: true,
                placeholder: placeholder,
                ajax: {
                    url: () => searchUrl,
                    dataType: 'json',
                    data: params => {
                        // only used to store the search term
                        searchTerm = params.term
                        return params
                    },
                    processResults: data => {
                        return {
                            results: data.items.map(key => ({
                                    text: key.header + ': ' + key.name,
                                    id: key.value,
                                    value: key.filter_key
                            })),
                            pagination: {
                                more: data.pagination.has_next_page
                            }
                        }
                    }
                },
                language: {
                    inputTooShort: () => addMoreText,
                    noResults: () => noResults,
                    searching: () => searchLoadingIcon,
                    loadingMore: () => searchLoadingIcon
                },
                templateResult: data => styleResults(data),
                escapeMarkup: markup => markup
            }).on('select2:select', async function(e) {
                let data = e.params.data

                let params = {
                    [data.value]: data.id,
                    'term': searchTerm,
                    'contract_id': contractId,
                    'call_off_cart_item_id': cartItemId
                }
                try {
                    popupLoadingContainer.addClass('is-requesting')
                    await updatePopup(params)
                }
                catch {
                    window.showErrorPopup()
                }

                popupLoadingContainer.removeClass('is-requesting')
            })

            // show the search icon next to placeholder; icon defined in searchParams next to <select>
            $('.search-icon').removeClass('hidden')

            if($('.calloff-popup-contact input:checked').exists()) {
                popupMainContainer.find('.popup-button:not(.exit)').removeClass('button-disabled')
            } else {
                popupMainContainer.find('.popup-button:not(.exit)').addClass('button-disabled')
            }

            function styleResults(data) {
                return `<a class="search-result-link" href="#">
                            ${data.text}
                        </a>`
            }
        }


        // Logic for removing the active filter when clicked on in the searchbox
        popupMainContainer.on('click', '.active-filter', async function() {
            const filterKey = $(this).data('filterKey')

            let params = {
                [filterKey]: ''
            }
            try {
                popupLoadingContainer.addClass('is-requesting')
                await updatePopup(params)
            } catch {
                window.showErrorPopup()
            }

            popupLoadingContainer.removeClass('is-requesting')
        })


        // Logic for toggling the favorite status on main table
        stepFourContainer.on('click', '.calloff-favorite-toggle', async function() {
            try {
                const favoriteContainer = $(this).closest('.calloff-favorite')
                let path = $(this).data('url')

                favoriteContainer.toggleClass('favorite-active')
                loadingContainer.addClass('is-requesting')
                stepFourContainer.find('.step-next-button').addClass('disabled')
                let favoriteParam = favoriteContainer.hasClass('favorite-active') ? '1' : '0'

                path = window.updateUrlParameter(path, 'is_favourite', favoriteParam)
                await axios.get(path)
                await updateView()
            } catch {
                window.showErrorPopup()
            }

            loadingContainer.removeClass('is-requesting')
        })


        // Logic for removing a contact person
        stepFourContainer.on('click', '.remove-user', async function() {
            try {
                const thisRow = $(this).closest('tr')
                let path = $(this).data('path')
                stepFourContainer.find('.step-next-button').addClass('disabled')

                loadingContainer.addClass('is-requesting')

                await axios.get(path)

                stepFourContainer.find('.step-next-button').removeClass('disabled')

                // manually delete values so there is no need for updateView request
                thisRow.find('.contact-name, .contact-phone, .contact-email').html('—')
                thisRow.find('.calloff-favorite, .remove-user').html('')

                // update the steps navigation
                const response = await axios.get(url, window.axiosConfig)
                const { data } = response

                const stepsTopNavigation = $(data).find('.calloff-steps-container').html() || ''
                $('.calloff-steps-container').html(stepsTopNavigation)

                loadingContainer.removeClass('is-requesting')

                validateTableData()
            } catch {
                window.showErrorPopup()
            }
        })


        // Logic for opening the contact person popup
        stepFourContainer.on('click', '.user-icon', async function() {
            const url = $(this).data('path')

            contactPopup.removeClass('hidden fadeOut').addClass('animated fadeIn faster')
            popupLoadingContainer.addClass('is-requesting')

            try {
                const response = await axios.get(url, window.axiosConfig)
                const { data } = response
                popupMainContainer.html($(data))

                initFilters(true)
                initSearch(
                    $('.calloff-creation-popup-search'),
                    $('.search-container'),
                    $('#search-loader').html()
                )
                initFavoriteEvent()

                popupLoadingContainer.removeClass('is-requesting')

                if ($('.calloff-popup-contact input:checked').exists()) {
                    popupMainContainer.find('.popup-button').removeClass('button-disabled')
                }

            } catch {
                contactPopup.addClass('hidden fadeOut').removeClass('animated fadeIn faster')
                window.showErrorPopup()
            }
        })


        // Logic for closing the contact person popup
        stepFourContainer.on('click', '.exit-popup-icon, .popup-button.exit', function() {
            contactPopup.removeClass('animated fadeIn faster').addClass('hidden fadeOut')
            popupMainContainer.html('')
            popupLoadingContainer.addClass('is-requesting')
        })


        // Logic for invite-user popup button
        stepFourContainer.on('click', '.add-user-button', function() {
            const path = $(this).data('path')
            window.open(path)

            // contactPopup.removeClass('animated fadeIn faster').addClass('hidden fadeOut')
        })


        // Logic for enabling submit buttons when checking the radio button
        stepFourContainer.on('change', '.calloff-popup-contact input', function() {
            popupMainContainer.find('.popup-button').removeClass('button-disabled')
        })


        // Logic for removing a cart item
        if ($('.cart-action').exists()) {
            stepFourContainer.on('click', '.remove-from-cart svg', async function() {
                const tableRow = $(this).closest('tr')
                const path = $(this).closest('.cart-action').data('path')
                const additionalTableClass = 'calloff-step-four-table'
                const additionalTopOffset = 5

                window.callOffCreation.animateCalloffDeletion(tableRow, additionalTableClass, additionalTopOffset)
                loadingContainer.addClass('is-requesting')

                // if the cart will become empty then redirect
                try {
                    const response = await axios.get(path)
                    const { data } = response

                    if (data.redirect_url) {
                        window.location.href = data.redirect_url
                    } else {
                        loadingContainer.removeClass('is-requesting')
                        await updateView({}, false, true)
                    }
                } catch {
                    loadingContainer.removeClass('is-requesting')
                    window.showErrorPopup()
                }
            })
        }


        // Logic for applying popup changes to a single or all cart items
        stepFourContainer.on('click', '.calloff-popup-buttons-container .save-data, .calloff-popup-buttons-container .apply-data-to-all', async function() {
            const popupTable = contactPopup.find('.calloff-creation-contact-popup-table')
            const affectedRow = popupMainContainer.find('.calloff-popup-contact input:checked').closest('tr')
            const contactPerson = affectedRow.find('.calloff-popup-contact input:checked')

            let path = $('.calloff-popup-buttons-container').data('updateUrl')
            let { contractId, cartId, cartItemId } = popupTable.data()

            let contactPersonIds = []
            let favoritePersonsIds = []

            // collect all contact person ids and also favorite person ids
            popupTable.find('.calloff-popup-favorite input').each(function() {
                contactPersonIds.push($(this).data('value'))

                if ($(this).prop('checked')) {
                    favoritePersonsIds.push($(this).data('value'))
                }
            })

            path = window.updateUrlParameter(path, contactPerson.data('name'), contactPerson.val())
            path = window.updateUrlParameter(path, 'is_favourite', favoritePersonsIds.join())
            path = window.updateUrlParameter(path, 'contact_person_ids', contactPersonIds.join())
            path = window.updateUrlParameter(path, 'contract_id', contractId)
            path = window.updateUrlParameter(path, 'call_off_cart_id', cartId)

            if ($(this).hasClass('save-data')) {
                path = window.updateUrlParameter(path, 'call_off_cart_item_id', cartItemId)
            } else {
                path = window.updateUrlParameter(path, 'call_off_cart_item_id', 'all')
            }

            contactPopup.removeClass('animated fadeIn faster').addClass('hidden fadeOut')

            try {
                popupMainContainer.html('')
                loadingContainer.addClass('is-requesting')

                await axios.get(path)
                await updateView()
            } catch {
                window.showErrorPopup()
            }

            loadingContainer.removeClass('is-requesting')
        })


        // Logic for swapping material icon on favorite column of popup
        function initFavoriteEvent() {
            const popupTable = contactPopup.find('.calloff-creation-contact-popup-table')

            popupTable.on('change', '.calloff-popup-favorite input', function() {

                // swap material icon
                if ($(this).prop('checked')) {
                    $(this).next().html('<i id="star" class="material-icons material-icons--star-border">star</i>')
                } else {
                    $(this).next('label').html('<i id="star" class="material-icons material-icons--star-border">star_border</i>')
                }
            })
        }


        // Helper function for table data validation
        function validateTableData() {
            let validationGood = true

            stepFourContainer.find('tbody tr:not(.clone)').each(function() {
                const contactFavorite = $(this).find('.calloff-favorite').html()

                if (contactFavorite.toString().trim() == '') {
                    validationGood = false
                }
            })

            if ($('.step-next-button').data('itemsCount') == 0) {
                validationGood = false
            }

            if (validationGood) {
                stepFourContainer.find('.step-next-button').removeClass('disabled')
            } else {
                stepFourContainer.find('.step-next-button').addClass('disabled')
            }
        }
        validateTableData()


        // Logic for replacing page content without reload
        async function updateView(urlParams = {}, reload = false, pagination = false) {
            if (!urlParams.isEmpty) {
                Object.keys(urlParams).forEach(function(key) {
                    url = window.updateUrlParameter(url, key, urlParams[key])
                })
                if (!pagination) {
                    url = window.updateUrlParameter(url, 'page', '')
                }
                if (typeof window.history.pushState != 'undefined') {
                    window.history.pushState(null, null, url)
                }
                if (reload) {
                    window.location.href = url
                }
            }
            if (!reload) {
                try {
                    const response = await axios.get(url, window.axiosConfig)
                    const { data } = response

                    const calloffTable = $(data).find('.calloff-step-four-table').html() || ''
                    const stepsNavigation = $(data).find('.steps-navigation-container').html() || ''
                    const cartItemsCounter = $(data).find('.cart-items-number-contanier').html() || ''

                    $('.calloff-step-four-table:not(.clone)').html(calloffTable)
                    $('.steps-navigation-container').html(stepsNavigation)
                    $('.cart-items-number-contanier').html(cartItemsCounter)

                    initFilters()
                    initStepFourTooltips()
                    validateTableData()

                } catch {
                    window.showErrorPopup()
                }
            }
        }


        // Logic for replacing popup content without reload
        async function updatePopup(urlParams = {}) {
            let personListUrl = JSON.parse(window.currentPopupUrl)

            if (!urlParams.isEmpty) {
                Object.keys(urlParams).forEach(function(key) {
                    personListUrl = window.updateUrlParameter(personListUrl, key, urlParams[key])
                })
            }
            try {
                const response = await axios.get(personListUrl, window.axiosConfig)
                const { data } = response

                const popupTableData = $(data).find('.calloff-creation-contact-popup-table').html() || ''
                const searchContainer = $(data).find('.top-content-container').html() || ''

                $('.calloff-creation-contact-popup-table').html(popupTableData)
                $('.top-content-container').html(searchContainer)

                // initialize only popup filters
                initFilters(true)
                initSearch(
                    $('.calloff-creation-popup-search'),
                    $('.search-container'),
                    $('#search-loader').html()
                )

            } catch {
                window.showErrorPopup()
            }
        }

        // Logic for initializing tooltips
        function initStepFourTooltips() {
            $('.material-name .has-tippy').each( function() {
                const materialDescriptionTooltip = $(this).data('materialDescription')
                const materialDescriptionMoreTooltip = $(this).data('materialDescriptionMore')
                const numberTooltip = $(this).data('number')
                const epTooltip = $(this).data('ep')
                const avvCodeTooltip = $(this).data('avvCode')
                const avvTooltip = $(this).data('avv')

                tippy(this, {
                    content: materialDescriptionTooltip + (materialDescriptionMoreTooltip ? '<br>'+ materialDescriptionMoreTooltip : '')
                    + (numberTooltip ? '<br>'+epTooltip+numberTooltip : '')
                    + (avvCodeTooltip ? '<br>'+avvTooltip+avvCodeTooltip : ''),
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('.factory .has-tippy').each( function() {
                const factoryNameTooltip = $(this).data('factoryName')
                const factoryStreetTooltip = $(this).data('factoryStreet')
                const factoryStreetNumberTooltip = $(this).data('factoryStreetNumber')
                const factoryZipTooltip = $(this).data('factoryZip')
                const factoryCityTooltip = $(this).data('factoryCity')

                tippy(this, {
                    content: factoryNameTooltip + "<br>" + factoryStreetTooltip + " " + factoryStreetNumberTooltip + "<br>" +
                    factoryZipTooltip + " " + factoryCityTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            const removeItem = $('.remove-from-cart .has-tippy').data('tooltipTranslation')
            tippy('.remove-from-cart .has-tippy', {
                content: removeItem,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })

            const nightshiftTooltip = $('.nightshift-status').data('tooltipTranslation')
            tippy('.nightshift-status', {
                content: nightshiftTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
        }
        initStepFourTooltips()
    }
})