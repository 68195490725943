$(document).ready(() => {
    if ($('#highway-calloff-edit').exists()) {
        const callOffEditContainer = $('.calloff-edit-content-container')
        const exportContainer = $('.calloff-edit-export-container')
        const materialPopup = $('.highway-calloff-edit-material-popup-container .material-popup')
        const declinePopupContainer = $('.decline-calloff-popup-container')
        const sideContent = $('.calloff-edit-side-content')

        let url = window.location.href
        window.calloffEdit = {}


        // Logic for showing the red containers when the date/time values change
        callOffEditContainer.on('change', '.flatpickr-date-input', function() {
            const originalStartTime = `${$('.calloff-edit-date-start-input').data('originalValue')} ${$('.calloff-edit-time-start-input').data('originalValue')}`
            const originalEndTime = `${$('.calloff-edit-date-end-input').data('originalValue')} ${$('.calloff-edit-time-end-input').data('originalValue')}`

            const currentStartTime = `${$('.calloff-edit-date-start-input').val()} ${$('.calloff-edit-time-start-input').val()}`
            const currentEndTime = `${$('.calloff-edit-date-end-input').val()} ${$('.calloff-edit-time-end-input').val()}`

            if (currentStartTime != originalStartTime) {
                $('.date-time.start').addClass('value-changed')
                $('.date-time.start .old-value-container').text(originalStartTime)
                $('.date-time.start .old-value-container').addClass('has-value')

                // additionaly, only color the flatpickr whose value changed
                if ($('.calloff-edit-date-start-input').data('originalValue') != $('.calloff-edit-date-start-input').val()) {
                    $('.calloff-edit-date-start-input').addClass('date-changed')
                } else {
                    $('.calloff-edit-date-start-input').removeClass('date-changed')
                }

                if ($('.calloff-edit-time-start-input').data('originalValue') != $('.calloff-edit-time-start-input').val()) {
                    $('.calloff-edit-time-start-input').addClass('date-changed')
                } else {
                    $('.calloff-edit-time-start-input').removeClass('date-changed')
                }

            } else {
                $('.date-time.start').removeClass('value-changed')
                $('.date-time.start .old-value-container').removeClass('has-value')

                $('.calloff-edit-date-start-input').removeClass('date-changed')
                $('.calloff-edit-time-start-input').removeClass('date-changed')
            }

            if (currentEndTime != originalEndTime) {
                $('.date-time.end').addClass('value-changed')
                $('.date-time.end .old-value-container').text(originalEndTime)
                $('.date-time.end .old-value-container').addClass('has-value')

                // additionaly, only color the flatpickr whose value changed
                if ($('.calloff-edit-date-end-input').data('originalValue') != $('.calloff-edit-date-end-input').val()) {
                    $('.calloff-edit-date-end-input').addClass('date-changed')
                } else {
                    $('.calloff-edit-date-end-input').removeClass('date-changed')
                }

                if ($('.calloff-edit-time-end-input').data('originalValue') != $('.calloff-edit-time-end-input').val()) {
                    $('.calloff-edit-time-end-input').addClass('date-changed')
                } else {
                    $('.calloff-edit-time-end-input').removeClass('date-changed')
                }

            } else {
                $('.date-time.end').removeClass('value-changed')
                $('.date-time.end .old-value-container').removeClass('has-value')

                $('.calloff-edit-date-end-input').removeClass('date-changed')
                $('.calloff-edit-time-end-input').removeClass('date-changed')
            }
        })


        // Logic for opening the material popup
        callOffEditContainer.on('click', '.material-popup-opener', () => {
            $('.highway-calloff-edit-material-popup-container').removeClass('hidden')

            // hide scroll from body to avoid having 2 scroll bars
            $('html').css('overflow-y', 'hidden')
        })


        // Logic for adding a contract item to cart below
        materialPopup.on('click', '.add-to-cart svg', async function() {
            const tableRow = $(this).closest('tr')
            const cartTable = $('#selected-material-table')

            // clone the row that was clicked on
            const tableRowClone = tableRow.clone()

            // create the layout for the cloned row so it can take styles from its parents' classes
            let createdTable = $(`
                <table class="table contract-table material-popup-table">
                    <tbody>
                        ${tableRowClone.html()}
                    </tbody>
                </table>`)

            // forbid clicking while animation lasts
            materialPopup.css('pointer-events', 'none')

            // add styling to the created table and animate it
            createdTable.offset({
                top: tableRow[0].offsetTop + 10,
                left: tableRow[0].offsetLeft + 10
            })
            .css({
                'opacity': '0.85',
                'position': 'absolute',
                'width': '1390px'
            })
            .appendTo(materialPopup)
            .animate({
                top: cartTable[0].offsetTop,
                left: cartTable[0].offsetLeft
            }, 1000, function() {
                // after the animation is done, delete the clone
                createdTable.remove()

                // inject new data
                const cartTableRow = cartTable.find('tbody tr')
                const clonedRow = createdTable.find('tr').html()
                cartTableRow.html(clonedRow)

                // initialize tooltips in the edited row of cart table
                initCartTableTooltips()

                // allow clicking again
                materialPopup.css('pointer-events', 'auto')
            })
        })


        // Logic for submitting the material popup changes
        materialPopup.on('click', '.material-popup-submit', () => {
            const materialNumberInput = $('.material-popup-opener .material-number')
            const materialNameInput = $('.material-popup-opener .material-name')
            const factoryInput = $('.material-popup-opener .factory')
            const quantityInput = $('.calloff-edit-quantity-input')
            const epNumberRow = $('.calloff-edit-main-section .plant-ep_number-row')

            // get the old values from the inputs
            const materialNumber = materialNumberInput.data('originalValue').toString()
            const materialName = materialNameInput.data('originalValue').toString()
            const factory = factoryInput.data('originalValue').toString()

            const $materialNumberNew = $('#selected-material-table').find('.material-number-new')

            // get the new values from the item added to cart
            const materialNumberNew = $materialNumberNew.text().trim()
            const materialNameNew = $('#selected-material-table').find('.material-name-new').text().trim()
            const newWarningLimit = $materialNumberNew.data('warningLimit')
            let factoryNew = $('#selected-material-table').find('.factory-new').text().trim()
            factoryNew = factoryNew == '—' ? '' : factoryNew

            // Ep number
            const { eppNumber } = $materialNumberNew.data()

            if (epNumberRow.exists()) {
                const eppNumberValueContainer = epNumberRow.find('td.material-name')
                eppNumberValueContainer.text(eppNumber)

                if(eppNumber) {
                    epNumberRow.removeClass('hidden')
                } else {
                    epNumberRow.addClass('hidden')
                }
            }

            // change the input values
            materialNumberInput.val(materialNumberNew)
            materialNameInput.val(materialNameNew)
            factoryInput.val(factoryNew)

            quantityInput.data('warningLimit', newWarningLimit)

            // if the value is different, show the red container and init tooltips
            if (materialNumberNew != materialNumber) {
                materialNumberInput.addClass('value-changed')
                materialNumberInput.next().text(materialNumber)
                materialNumberInput.next().addClass('has-value')
            } else {
                materialNumberInput.removeClass('value-changed')
                materialNumberInput.next().removeClass('has-value')
            }

            if (materialNameNew != materialName) {
                materialNameInput.addClass('value-changed')
                materialNameInput.next().text(materialName)
                materialNameInput.next().addClass('has-value')
            } else {
                materialNameInput.removeClass('value-changed')
                materialNameInput.next().removeClass('has-value')
            }

            if (factoryNew != factory) {
                factoryInput.addClass('value-changed')

                if (factory) {
                    factoryInput.next().text(factory)
                } else {
                    factoryInput.next().text(factoryInput.next().data('tooltip'))
                }

                if (factoryNew) {
                    $('.factory-warning').addClass('hidden')
                } else {
                    $('.factory-warning').removeClass('hidden')
                }

                factoryInput.next().addClass('has-value')


            } else {
                factoryInput.removeClass('value-changed')
                factoryInput.next().removeClass('has-value')
            }

            if (newWarningLimit > 0 && quantityInput.data('valueToSend') > newWarningLimit) {
                $('.quantity-warning').removeClass('hidden')

            } else {
                $('.quantity-warning').addClass('hidden')
            }

            initOldValueTooltips()

            // close the popup and return the scroll functionality to body
            $('.highway-calloff-edit-material-popup-container').addClass('hidden')
            $('html').css('overflow-y', 'auto')
        })


        // Logic for exporting
        callOffEditContainer.on('click', '.export-button', async function() {
            const route = $(this).data('path')

            callOffEditContainer.addClass('is-requesting')

            try {
                const response = await axios.get(route)
                const { data } = response

                // show the newest data next to the button
                exportContainer.find('.name-data').text(data.data.last_export_by)
                exportContainer.find('.time-data').text(data.data.last_export_time)

                callOffEditContainer.removeClass('is-requesting')

            } catch {
                callOffEditContainer.removeClass('is-requesting')
                window.showErrorPopup()
            }
        })


        // Logic for the bottom back button
        callOffEditContainer.on('click', '.calloff-back-button', function() {
            callOffEditContainer.addClass('is-requesting')
            window.location.href = $(this).data('path')
        })


        // Logic for saving the calloff changes
        callOffEditContainer.on('click', '.save-button', function() {
            const factoryInput = $('.material-popup-opener .factory')
            const route = $(this).data('path')

            if (!factoryInput.val()) {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                factoryInput.addClass('empty-value')
            } else {
                submitCalloffData(route)
            }
        })


        // Logic for opening deny popup
        callOffEditContainer.on('click', '.deny-button', () => {
            declinePopupContainer.removeClass('hidden')
        })


        // Logic reminder note sending
        callOffEditContainer.on('click', '.reminder-button', async function() {
            const customerNote = $('.calloff-chat-message').val()
            const internalNote = $('.calloff-edit-data').find('.calloff-edit-note-single .internal-note').val()

            if(customerNote) {
                let path = ''

                if(internalNote) {
                    path = $(this).data('path') + '?customer_note=' + customerNote +'&internal_note=' + internalNote
                } else {
                    path = $(this).data('path') + '?customer_note=' + customerNote
                }

                try {
                    callOffEditContainer.addClass('is-requesting')

                    await axios.post(path)
                    await updateCalloffEditContainer()
                }
                catch {
                    window.showErrorPopup()

                }

                callOffEditContainer.removeClass('is-requesting')
            } else {
                $('.calloff-chat-message').addClass('error')
            }
        })


        // Logic for submitting the calloff changes
        callOffEditContainer.on('click', '.submit-button', function() {
            const factoryInput = $('.calloff-edit-table .factory')
            const dateInput = $('.calloff-edit-date-input')

            if (!factoryInput.val()) {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                factoryInput.addClass('empty-value')

            } else if (!dateInput.val()) {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                dateInput.addClass('date-changed')

            } else {
                const route = $('.submit-button').data('path')
                submitCalloffData(route, true)
            }
        })


        // Logic for closing deny popup
        $('.decline-calloff-popup').on('click', '.close-decline-calloff-popup', () => {
            declinePopupContainer.addClass('hidden')
        })


        // Logic for initializing select inside deny popup
        function initDeclineCalloffPopupSelect() {
            $('.calloff-declining-select').select2({
                minimumResultsForSearch: -1,
                dropdownCssClass: 'calloff-deny-reason-dropdown',
                placeholder: $('.calloff-declining-select').data('placeholder')
            })
        }
        initDeclineCalloffPopupSelect()


        // Logic for submitting deny popup data
        $('.decline-calloff-popup').on('click', '.decline-calloff-send-button', async function() {
            const route = $(this).data('path')
            let params = {}

            // get all of the values for sending
            declinePopupContainer.find('select, textarea').each(function() {
                params[$(this).data('name')] = $(this).val()
            })

            // convert all params to formData
            const payload = createPayload(params)

            try {
                callOffEditContainer.addClass('is-requesting')
                declinePopupContainer.addClass('hidden')

                await axios.post(route, payload)
                await updateCalloffEditContainer()

            } catch {
                window.showErrorPopup()
            }
        })


        // Logic for sidebar dropdowns
        callOffEditContainer.on('click', '.calloff-edit-side-dropdown-toggler', function() {
            const dropdownContent = $(this).closest('.calloff-edit-side-dropdown').find('.calloff-edit-side-dropdown-content')
            const togglerArrow = $(this).find('.toggler-arrow')

            $('.calloff-edit-side-content-container').find('.calloff-edit-side-dropdown-toggler.active').removeClass('active')
            $('.calloff-edit-side-content-container').find('.calloff-edit-side-dropdown-content:not(.hidden)').addClass('hidden')
            $('.calloff-edit-side-content-container').find('.toggler-arrow.rotate').removeClass('rotate')

            $(this).toggleClass('active')
            dropdownContent.toggleClass('hidden')
            togglerArrow.toggleClass('rotate')
        })

        // Logic for sidebar duplicate calloff
        callOffEditContainer.on('click', '.duplicate-calloff-button', async function() {
            const { duplicateUrl, title, text, buttonText, backButtonText } = $(this).data()

            swal({
                className: 'submit-calloff-changes-popup',
                title: title,
                text: text,
                buttons: {
                    cancel: {
                        text: backButtonText,
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                    confirm: {
                        text: buttonText,
                        value: true,
                        visible: true,
                        className: "confirm-calloff-duplicate",
                        closeModal: true,
                    },
                },
            }).then(async function(isConfirm) {
                if (isConfirm) {
                    $('.duplicate-calloff-button').addClass('is-requesting')

                    try {
                        const response = await axios.post(duplicateUrl)

                        const { data } = response

                        window.location.href = data.redirect_url + '?clone_date=1'
                    } catch {
                        window.showErrorPopup()
                    }
                }
            })
        })


        // Logic for changing date in the sidebar
        sideContent.on('click', '.nav-button', function() {
            sideContent.find('.nav-button').removeClass('active')
            $(this).addClass('active')

            if ($(this).hasClass('today-button')) {
                sideContent.removeClass('tomorrow').addClass('today')
            } else {
                sideContent.addClass('tomorrow').removeClass('today')
            }
        })


        // Logic for opening the baustellenmonitor factory screen from sidebar
        sideContent.on('click', '.factory-button', function() {
            window.open($(this).data('path'), '_blank')
        })


        // Logic for opening the history popup from sidebar
        sideContent.on('click', '.show-history-popup', () => {
            $('.highway-calloff-edit-history-popup-container').removeClass('hidden')

            // hide scroll from body to avoid having 2 scroll bars
            $('html').css('overflow-y', 'hidden')
        })


        // Logic for closing the history popup
        $('.calloff-history-popup-content').on('click', '.close-button', () => {
            $('.highway-calloff-edit-history-popup-container').addClass('hidden')
            $('html').css('overflow-y', 'auto')
        })


        // Logic for submitting the calloff data to a route
        async function submitCalloffData(route, showSuccessPopup = false) {
            let params = {}

            // get all of the selected values from dropdowns and text-areas
            callOffEditContainer.find('select, textarea').each(function() {
                params[$(this).data('name')] = $(this).val()
            })

            // get all of the selected values from inputs
            callOffEditContainer.find('input').each(function() {

                if ($(this).attr('type') == 'checkbox') {
                    params[$(this).data('name')] = $(this).prop('checked') == true ? '1' : '0'

                } else if ($(this).attr('type') == 'number') {
                    if ($(this).data('valueToSend') == $(this)[0].defaultValue) {
                        params[$(this).data('name')] = $(this)[0].defaultValue.replace(/[^0-9\.]/g, '')
                    } else {
                        params[$(this).data('name')] = $(this).data('valueToSend')
                    }

                } else if ($(this).hasClass('calloff-edit-date-input')) {
                    params[$(this).data('name')] = $(this).val() ? moment($(this).val(), 'DD.MM.YY').format('DD.MM.YYYY') : ''

                } else if ($(this).hasClass('calloff-edit-time-input')) {
                    params[$(this).data('name')] = $(this).val() ? $(this).val() : '00:00'

                } else {
                    params[$(this).data('name')] = $(this).val()
                }
            })

            // convert all params to formData
            const payload = createPayload(params)

            try {
                callOffEditContainer.addClass('is-requesting')
                await axios.post(route, payload)

                // Update sidebar sections
                if ($('.factory-data-dropdown').exists()) {
                    updateFactoryDailyPlanSidebar()
                }

                if ($('.calloff-edit-side-content').exists()) {
                    updateDailyPlanSidebar()
                }

                if ($('.calloff-history-dropdown').exists()) {
                    updateHistorySidebar()
                }

                if (showSuccessPopup) {
                    const { successTitle, successText, successButton, backButtonText, backPath } = $('.submit-button').data()

                    await updateCalloffEditContainer()

                    // Show popup with info
                    const popupContent = `
                        <div class="popup-content">
                            <div class="close-popup-button"></div>
                            <div class="popup-title">${successTitle}</div>
                            <div class="popup-text">${successText}</div>
                        </div>
                    `
                    const wrapper = document.createElement('div')
                    wrapper.innerHTML = popupContent


                    swal({
                        content: wrapper,
                        icon: 'success',
                        className: 'submit-calloff-changes-popup',
                        buttons: {
                            cancel: successButton,
                            submit: {
                                text: backButtonText,
                                value: true
                            }
                        }
                    }).then(value => {
                        if (value == true) {
                            window.location.href = backPath
                        }
                    })

                    $('.close-popup-button').on('click', function() {
                        swal.close()
                    })
                } else {
                    await updateCalloffEditContainer()
                }
            } catch {
                window.showErrorPopup()
            }

            callOffEditContainer.removeClass('is-requesting')
        }


        // Helper function for creating formData payload from post params
        function createPayload(params) {
            const keys = Object.keys(params)
            let data = new FormData()

            keys.map(key => {
                data.append(key, params[key])
            })

            return data
        }


        // Helper function for filling the central content of calloff edit
        async function updateCalloffEditContainer() {
            try {
                const response = await axios.get(url)
                const { data } = response

                const calloffEditContent = $(data).find('.calloff-edit-data').html() || ''
                const denyPopupContent = $(data).find('.decline-calloff-popup').html() || ''
                const currentTabName = $(data).find('.current-button').text().trim() || ''
                const chatSidebar = $(data).find('.calloff-edit-side-dropdown.chat-dropdown').html() || ''

                $('.calloff-edit-data').html(calloffEditContent)
                $('.decline-calloff-popup').html(denyPopupContent)
                $('.current-button').text(currentTabName)

                // also update chat sidebar
                $('.calloff-edit-side-dropdown.chat-dropdown').html(chatSidebar)

                window.calloffEditCommon.initCalloffEditSelects()
                window.calloffEditCommon.initCalloffEditDatepickers()
                window.calloffEditCommon.initCalloffEditTimepickers()
                initDeclineCalloffPopupSelect()
                initOldValueTooltips()

            } catch {
                window.showErrorPopup()
            }

            callOffEditContainer.removeClass('is-requesting')
        }


        // Helper function for filling the content of sidebar for factory daily plan
        async function updateFactoryDailyPlanSidebar() {
            const route = sideContent.data('factoryRoute')

            try {
                const response = await axios.get(route)
                const { data } = response

                const sidebarContent = $(data)[0].innerHTML || ''
                const factoryDailyContent = $('.calloff-edit-side-dropdown-content.factory-daily')
                factoryDailyContent.html(sidebarContent)

                $('.calloff-edit-side-navigation button:first-child').addClass('active')

            } catch {
                window.showErrorPopup()
            }

            $('.calloff-edit-side-dropdown-content.factory-daily').removeClass('is-requesting')
        }
        if ($('.factory-data-dropdown').exists()) {
            updateFactoryDailyPlanSidebar()
        }


        // Helper function for filling the content of sidebar for daily plan
        async function updateDailyPlanSidebar() {
            const route = sideContent.data('dailyRoute')

            try {
                const response = await axios.get(route)
                const { data } = response

                const sidebarContent = data

                if(!sidebarContent == '') {
                    $('.daily-plan-dropdown').removeClass('hidden')

                    const dailyContent = $('.calloff-edit-side-dropdown-content.daily-plan')
                    dailyContent.html(sidebarContent)

                    $('.calloff-edit-side-navigation button:first-child').addClass('active')
                    $('.calloff-edit-daily-plan-count').html(`(${$(data).find('.daily-plan-container').length})`)
                }
            } catch {
                window.showErrorPopup()
            }

            $('.calloff-edit-side-dropdown-content.daily-plan').removeClass('is-requesting')
        }
        if ($('.calloff-edit-side-content').exists()) {
            updateDailyPlanSidebar()
        }


        // Helper function for filling the sidebar content for history
        async function updateHistorySidebar() {
            const route = $('.calloff-history-dropdown').data('route')

            try {
                const response = await axios.get(route)
                const { data } = response

                const historySidebarContent = $(data).find('.calloff-history-sidebar-content').html()
                //const historySidebarCounter = $(data).find('.item-container').exists() ? $(data).find('.item-container').data('length') : ''
                const historySidebarCounter = $(data).find('.history-table-container').exists() ? $(data).find('.history-table-container').data('length') : ''
                const historyPopupContent = $(data).find('.calloff-history-popup-content').html()

                $('.calloff-history-content').html(historySidebarContent)
                $('.history-counter').text(historySidebarCounter)
                $('.calloff-history-popup-content').html(historyPopupContent)

            } catch {
                window.showErrorPopup()
            }
        }
        if ($('.calloff-history-dropdown').exists()) {
            updateHistorySidebar()
        }


        // Logic for initializing tooltips
        if($('.reminder-button').hasClass('reminder-sent')) {
            const reminderTooltip = $('.reminder-button').data('tooltipTranslation')
            tippy('.tooltip-container', {
                content: reminderTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
        }

        const factoryWarningTooltip = $('.factory-warning').data('tooltip')
        tippy('.factory-warning', {
            content: factoryWarningTooltip,
            placement: 'bottom',
            theme: 'cockpit-tippy'
        })

        function initOldValueTooltips() {
            const oldValueTippies = $('.old-value-container.conditional-tippy')
            oldValueTippies.each(function() {

                if ($(this)[0].scrollWidth > $(this)[0].clientWidth) {
                    $(this).css({'cursor': 'pointer', 'pointer-events': 'auto'})

                    tippy($(this)[0], {
                        content: $(this).text(),
                        placement: 'bottom',
                        theme: 'cockpit-tippy'
                    })
                }
            })
        }
        window.calloffEdit.initOldValueTooltips = initOldValueTooltips
        initOldValueTooltips()


        // tooltips inside cart table only
        function initCartTableTooltips() {
            if ($('#selected-material-table .material-name .has-tippy').exists()) {
                const { materialDescription, materialDescriptionMore, number, ep, avvCode, avv } = $('#selected-material-table .material-name .has-tippy').data()
                const tippyContentMaterial = `${materialDescription}
                                        ${materialDescriptionMore ? '<br>' + materialDescriptionMore : ''}
                                        ${number ? '<br>' + ep + ' ' + number : ''}
                                        ${avvCode ? '<br>' + avv + ' ' + avvCode : ''}
                                    `
                tippy($('#selected-material-table .material-name .has-tippy')[0], {
                    content: tippyContentMaterial,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            }

            if ($('#selected-material-table .factory .has-tippy').exists()) {
                const { factoryName, factoryStreet, factoryStreetNumber, factoryZip, factoryCity } = $('#selected-material-table .factory .has-tippy').data()
                const tippyContentFactory = `${factoryName}
                                                ${factoryStreet ? '<br>' +  factoryStreet + ' ' + factoryStreetNumber : ''}
                                                ${factoryCity ? '<br>' +  factoryZip + ' ' + factoryCity : ''}
                                            `
                tippy($('#selected-material-table .factory .has-tippy')[0], {
                    content: tippyContentFactory,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            }
        }
    }
})