$(document).ready(() => {
    if($('#contract-overview-page').exists()) {
        const calendarContainer = $('#calendar-container')
        const tableContainer = $('#calendar-table-container')

        let url = window.location.href


        // Logic for expanding a row in calendar, only one at the time can be expanded
        // calendarContainer.on('click', '.toggle-expand', function() {
        //     const tableMaterial = $(this).closest('.materials')
        //     tableMaterial.toggleClass('expanded')
        //     calendarContainer.find('.materials').not(tableMaterial).removeClass('expanded')
        // })


        // Logic for highlighting rows in the table when clicking on a calendar item
        // 1) single item
        calendarContainer.on('click', '.calloff-single', function() {
            const id = $(this).data('calloffId')
            const highlightedRow = tableContainer.find(`tr[data-calloff-id="${id}"]`)

            highlightedRow.addClass('highlight')
            setTimeout(() => {
                highlightedRow.removeClass('highlight')
            }, 1000 * 10)
        })
        // 2) stacked item
        calendarContainer.on('click', '.toggle-table-grouped.stacked', function() {
            const calloffs = $(this).find('.calloff-single')

            calloffs.each(function() {
                const id = $(this).data('calloffId')
                const highlightedRow = tableContainer.find(`tr[data-calloff-id="${id}"]`)

                highlightedRow.addClass('highlight')
                setTimeout(() => {
                    highlightedRow.removeClass('highlight')
                }, 1000 * 10)
            })
        })


        // Initialize calendar custom component
        function initCalendar() {
            if (calendarContainer.exists()) {
                const calendar = $('#calendar-container #select-month')
                const { minDate, maxDate, monthValues, currentMonth, currentYear, locale } = calendar.data()
                const prevArrow = $('.calendar-table-heading .prev-month')
                const nextArrow = $('.calendar-table-heading .next-month')
    
                let minDateFormatted = minDate.split('-')
                minDateFormatted = new Date(minDateFormatted[0], minDateFormatted[1])
    
                let maxDateFormatted = maxDate.split('-')
                maxDateFormatted = new Date(maxDateFormatted[0], maxDateFormatted[1])
    
                flatpickr(calendar, {
                    dateFormat: 'm-Y',
                    defaultDate: currentMonth + '-' + currentYear,
                    locale: locale,
                    disableMobile: true,
                    plugins: [
                        new monthSelectPlugin({
                            dateFormat: 'm-Y',
                            shorthand: false,
                            minDate: minDateFormatted,
                            maxDate: maxDateFormatted,
                            staticArrowPrev: prevArrow,
                            staticArrowNext: nextArrow,
                            monthsValues: monthValues,
                            onSelect: (fp) => {
                                const { currentYear, currentMonth } = fp
                                fp.close()
    
                                // months are indexed from 0
                                url = window.updateUrlParameter(url, 'y', currentYear)
                                url = window.updateUrlParameter(url, 'm', currentMonth + 1)

                                calendarContainer.addClass('is-requesting')
                                tableContainer.addClass('is-requesting')

                                window.location.href = url
                            }
                        })
                    ],
                })
            }
        }
        window.initCockpitCalendar = initCalendar
        initCalendar()
    }
})