<template>

    <div class="pagination-container">
        <ul id="vue-pagination" class="pagination">
            <li :class="pagination.current_page ==1 ?'page-item first disabled' : 'page-item first'">
                <a href="javascript:;" @click="redirect(1)" class="page-link">{{'pagination.first_page'|trans}}</a>
            </li>
            <li :class="pagination.current_page ==1 ?'page-item prev disabled' : 'page-item prev'"><a href="javascript:;"  @click="redirect(pagination.current_page -1 )" class="page-link">{{'pagination.previous_page'|trans}}</a></li>
        
            <li v-for="page in pages" :key="page.name" :class="page.isActive ? 'active page-item' : 'page-item' ">
                <a href="javascript:;" @click="redirect(page.name)" class="page-link">{{page.name}}</a>
            </li>
            <li :class="pagination.current_page ==pagination.total_pages ?'page-item next disabled' : 'page-item next'"><a href="javascript:;" @click="redirect(pagination.current_page +1 )"  class="page-link">{{'pagination.next_page'|trans }}</a></li>
            <li :class="pagination.current_page ==pagination.total_pages ?'page-item last disabled' : 'page-item last'">
                <a href="javascript:;" @click="redirect(pagination.total_pages )"  class="page-link">{{'pagination.last_page'|trans}}</a>
            </li>
        </ul>
    </div>

</template>

<script>
export default {
    name: 'pagination',
    props: {
        pagination: {
            type: Object,
            default: {}
        },
      
    
    },
    data() {
        return {

        }
    },
    methods: {
            redirect(page) {
                let url = window.updateUrlParameter(window.location.href, 'page', page )
                window.location.href= url
            }
        }, 
    computed: {
        startPage() {
            if (this.pagination.current_page == 1) {
                return 1
            } 
            if (this.pagination.current_page == this.pagination.total_pages) {
                if (this.pagination.total_pages - 9 > 0){
                    return this.pagination.total_pages - 9

                }
            } 
            
            return this.pagination.current_page - 1
             
        },
        pages() {
            let range = []
            let rangeEnd = Math.min(this.startPage + 10 - 1, this.pagination.total_pages)
            for (let i = this.startPage; i <= rangeEnd; i+= 1 ) {
                range.push({
                name: i,
                isActive: i === this.pagination.current_page
                });
            }

            return range;
        },
       
    }
}
</script>

<style>

</style>