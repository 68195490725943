// Dashboard navigation 
$(document).ready(() => {
    if($('#quality-control').exists() || $('#contract-overview-page').exists()) {
        $('.nav-pills-primary .nav-item').on('click', function(e){
            let tab = $(this)
            if (tab.hasClass('active') || tab.hasClass('yearly-preview-toggler') || tab.find('a.disabled').length ) {
                e.preventDefault()
            } else {
                let loader = $('.content-wrapper').find('.loader')
                if (loader.hasClass('hidden')){
                    loader.removeClass('hidden')
                }
                $('.contract-overview-container').addClass('is-requesting')
                $('#quality-control').addClass('is-requesting')
            }
        })

        $(document).on('click', '#calendar-table-container #expand-canceled-contracts', expandAllCanceledData)
        $(document).on('click', '#calendar-table-container #expand-changed-calloffs', expandAllCanceledData)
        $(document).on('click', '#calendar-table-container .corrected-delivery-note .toggle-item-changes', expandCanceledData)
        $(document).on('click', '#calendar-table-container .changed-calloff .toggle-item-changes', expandCanceledData)

        function expandCanceledData() {
            const $button = $(this)
            let correctedParent = $button.parents('.corrected-delivery-note')
            if (!correctedParent.length){
                correctedParent = $button.parents('.changed-calloff')
            }
            if (correctedParent.hasClass('canceled-hidden')){
                correctedParent.removeClass('canceled-hidden')
            } else {
                correctedParent.addClass('canceled-hidden')
            }
        }

        /**
         * Tiles/table view expander
         */
        function expandAllCanceledData () {
            const $button = $(this)
            let correctedCalloffs
            if ($button.attr('id') == "expand-canceled-contracts") {
                correctedCalloffs = $('.delivery-overview-table .corrected-delivery-note')
            } else {
                correctedCalloffs = $('.calendar-detail-table .changed-calloff')

            }
            let markup = ''

            if ($button.find('.toggler-down').exists()) {
                if(correctedCalloffs.length) {
                    const { hideText } = $button.data()
                    markup = `${hideText}<span class="toggler-up"></span>`
                    correctedCalloffs.removeClass('canceled-hidden')
                  
                
            }
            } else {
                if(correctedCalloffs.length) {
                    const { showText } = $button.data()
                    markup = `${showText}<span class="toggler-down"></span>`
                    correctedCalloffs.addClass('canceled-hidden')
                }
            }

            $button.html(markup)
        }

        /**
         * Select which delivery overview to show. The old one or with the carriers.
         */
        const deliverySelect = $('.contract-delivery-select')

        deliverySelect.select2({
            dropdownParent: $('.contract-overview-delivery-select'),
            minimumResultsForSearch: -1
        }).on('select2:select', function (e) {
            $('.contract-overview-container').addClass('is-requesting')
            const path = e.params.data.element.dataset.href
            window.location.href = path
        })
        // trigger this check before opening the select2 dropdown:
        deliverySelect.on('select2:opening', function(e) {
            const pathToDeliveryOverview = $('#delivery-select').find(':selected').data('href')
            if (!$('.yearly-preview-toggler-select').exists()) {
                e.preventDefault()
                let loader = $('.content-wrapper').find('.loader')
                if (loader.hasClass('hidden')){
                    loader.removeClass('hidden')
                }
                $('.contract-overview-container').addClass('is-requesting')
                $('#quality-control').addClass('is-requesting')
                window.location.href = pathToDeliveryOverview
            }
        })

        $('.contract-overview-delivery-select').find('.select2-selection__arrow b').html('<i class="material-icons">expand_more</i>')

        /**
         * Select between monthly and yearly overview.
         */
        const yearlySelect = $('.yearly-toggler-select')

        yearlySelect.select2({
            dropdownParent: $('.yearly-preview-toggler-select'),
            minimumResultsForSearch: -1
        }).on('select2:select', function (e) {
            const path = e.params.data.element.dataset.href

            if (path.indexOf('yearly') >= 0) {
                const popupHeading = e.params.data.element.dataset.popupHeading
                const popupContent = e.params.data.element.dataset.popupContent
                const popupCancel = e.params.data.element.dataset.popupCancel
                const popupSubmit = e.params.data.element.dataset.popupSubmit
                swal({
                    className: 'calloff-status-modal',
                    title: popupHeading,
                    text: popupContent,
                    buttons: [popupCancel,  popupSubmit]

                }).then(function(value){
                    if (value === true){
                        $('.contract-overview-container').addClass('is-requesting')
                        window.location.href = path
                    }
                    else {
                        yearlySelect.val("monthly").trigger('change')
                    }
                })
            }
            else {
                $('.contract-overview-container').addClass('is-requesting')
                window.location.href = path
            }
        })
        $('.yearly-preview-toggler-select').find('.select2-selection__arrow b').html('<i class="material-icons">expand_more</i>')

        const tableContainer = $('.calendar-table-container')
        tableContainer.removeClass('is-requesting')

        const searchLoader = $('#search-loader').html()
        let url = decodeURIComponent(window.location.href)
        initHeaderFilters()
        










        
        function initHeaderFilters() {
            const headerFilters = $('.delivery-overview-table .header-filter') 
            const filterIcon = headerFilters.data('filter-icon')
            if (headerFilters.length) {
                headerFilters.each(function(){
                    const filter = $(this)
                    const parentContainer = filter.parents('.filter-heading')
                    let { searchUrl, placeholder, name, subName, pathParams, selection} = filter.data()

                    let selectOptions = {
                        width: '',
                        allowClear: false,
                        minimumResultsForSearch: -1,
                        placeholder: placeholder,
                        dropdownParent: parentContainer,
                        escapeMarkup: markup => markup,
                        ajax: {
                            url: () => {
                                let params = window.getUrlParams(url)
                                let keys = Object.keys(params)
                                if (keys.length){
                                    keys.forEach(key => {
                                        if (params[key]!=='' && key !== `filter[${name}]`){
                                            searchUrl = window.updateUrlParameter(searchUrl, key, params[key])
                                        }
                                    })
                                }
                                return searchUrl
                            },
                            data: (params) => {
                                return {
                                    term: $.trim(params.value),
                                    page: params.page || 1,
                                }
                            },
                            dataType: 'json',
                            processResults: (data, { term }) => {
                                let property = name 
                                let keys = data.items[property] ? Object.keys(data.items[property]): []
                                return {
                                    results: Object.values(keys).map(key=> ({ 
                                            text: key,
                                            name: key,
                                            id: data.items[property][key],
                                            value: data.items[property][key]
                                    })),
                                    pagination: {
                                        more: data.pagination.has_next_page
                                    }
                                }
                            },
                        },
                        templateResult: (data, container) => templateOptions(data, container, selection),
                        templateSelection: (data, container) => templateOptions(data, container, selection)
                    }

                    filter.select2(selectOptions)
                    .on('select2:select', function(e) {
                        let exactTarget = $(e.params.originalEvent.originalEvent.target)
                        let searchKey = `filter[${name}]`
                        let searchValue = e.params.data.value
                        if (exactTarget.hasClass('table-filter-clear') || exactTarget.parent('.table-filter-clear').length){
                            url = window.updateUrlParameter(url, searchKey, '')
                        } else {
                            url = window.updateUrlParameter(url, searchKey, searchValue)
                        }
                        tableContainer.addClass('is-requesting')
                        window.location.href = url
                    })
                    parentContainer.find('.select2-selection__arrow').html('')
                })
            }
            /**
             * @description generate li - option html content and handle if an option is currently selected 
             * @param selection picked up from select's data-selection attribute
             * @param data, @param container select2 params that get passed from select2
             */
            function templateOptions(data, container, selection) {
                let markup = ''
                if (data.loading) {
                    markup = searchLoader
                } else {
                    let $option = $(data.element)
                    let text = $option.text()  
                    let icon = $option.data('icon')
                    if (icon){
                        text = `${text} <i class="material-icons">${icon}</i>`
                        return text;
                    }
                    if (data.id == ""){
                        text = `${data.text} <i class="material-icons">${filterIcon}</i>`
                        return text;
                    }

                    let { name, path, value } = data
                    markup = `
                        <a class="dropdown-item table-filter" href="${path ? path: 'javascript:;' }">
                            ${name} <span class="id-span"> ${name != value ? value : ''}</span>
                        </a>
                    `
                    if (selection != null && selection == value) {
                        markup = `
                        <a class="table-filter-clear"><i class="material-icons">close</i></a>
                        <a class="dropdown-item table-filter" href="${path ? path: 'javascript:;' }">
                            
                            ${name} <span class="id-span"> ${name != value ? value : ''}</span>
                        </a>
                    `
                    $(container).addClass('position-relative')
                    }
                }
                return markup
            }
      
            // event handler for clearing selected/filtered options above the table
            const activeFilter = $('.active-filters-container').find('.active-filter')
            if (activeFilter.exists()){
                activeFilter.on('click', function(){
                    let filterKey = $(this).find('.remove-active-filter').data('filter-key')
                    if (filterKey == 'delivery_date' ) {
                        url = window.updateUrlParameter(url, 'filter[delivery_from_date]', '')
                        url = window.updateUrlParameter(url, 'filter[delivery_to_date]', '')
                    } else {
                        url = window.updateUrlParameter(url, filterKey, '')
                    } 
                    activeFilter.remove()
                    tableContainer.addClass('is-requesting')
                    //getTableHtml(url)
                    window.location.href = url
                })
            }
        }

        function reinitPagination() {
            let $pagination = $('#pagination')

            if ($pagination.length == 0) {
                return
            }

            let {
                first,
                last,
                next,
                prev,
                startPage,
                totalPages,
                visiblePages,
            } = $pagination.data()

            if (totalPages == 0) {
                totalPages = 1
            }
            // Pagination Init
            $pagination.twbsPagination({
                first,
                last,
                next,
                prev,
                startPage,
                totalPages,
                visiblePages,
                initiateStartPageClick: false,
                onPageClick: async (event, page) => {
                    const params = {
                        ...window.getUrlParams(decodeURIComponent(window.location.href)),
                        'page': page
                    }
                    tableContainer.addClass('is-requesting')
                    if (!params.isEmpty) {
                        Object.keys(params).forEach(function (key) {
                            let value = params[key]
                            url = window.updateUrlParameter(url, key, value)
                        })
                        if (typeof window.history.pushState != 'undefined') {
                            window.history.pushState(null, null, url)
                        }
                        window.location.href = url
                    }
                }
            })
        }
        reinitPagination()
    }
})