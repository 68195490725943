const { default: tippy } = require("tippy.js")

$(document).ready(() => {
    /**
    * Close contract
    */
    if ($('#contract-header').exists()) {
        const header = $('#contract-header')
        const impersonation = $('.bag-back-link')
        // Create favorite button tooltips
        const favoriteOn = $('.favorite-button.favorite-on').data('defavorite')
        const favoriteOff = $('.favorite-button.favorite-off').data('description')

        const headerInfo = $('#contract-header .info-manage')
        const headerInfoContainer = $('#contract-header .heading-block')

        let tipOn = tippy('.favorite-on', {
            content: favoriteOn,
            maxWidth: 'max-content'
        })

        let tipOff = tippy('.favorite-off', {
            content: favoriteOff,
            maxWidth: 'max-content'
        })

        // Create tooltip for phone icon
        const phoneTooltip = $('.has-today-delivery').data('tooltip')
        tippy('.has-today-delivery', {
            content: phoneTooltip,
            maxWidth: 'max-content'
        })

        // Create tooltip for clock icon
        const clockTooltip = $('.has-today-calloff').data('tooltip')
        tippy('.has-today-calloff', {
            content: clockTooltip,
            maxWidth: 'max-content'
        })

        // Cancel action
        headerInfoContainer.on('click', '.submit-button .cancel', function (event) {
            event.stopPropagation()

            const buttonWrapper = $(this).closest('.submit-button')

            if (buttonWrapper.exists()) {
                buttonWrapper.removeClass('checked')
            }
        })

        // "Sind sie sicher" step call
        headerInfoContainer.on('click', '.submit-button', showVerification)

        // Status change Handlers
        headerInfoContainer.on('click', '.submit-button .submit', changeContractState)

        /**
         * Verification handler
         */
        function showVerification() {
            const $button = $(this)
            if ($button.hasClass('checked')) {
                return
            }

            $button.addClass('checked')
        }

        /**
         * Status handler
         */
        async function changeContractState() {
            const $this = $(this)

            const requestURI = $this.data('url')
            $this.trigger('toggleState')

            try {
                // Make request
                await axios.get(requestURI, window.axiosConfig)

                if ($(this).closest('#contract-header').exists()) {
                    location.reload()
                } else {
                    // Update view
                    const updateUri = window.location.href
                    const response = await axios.get(updateUri, window.axiosConfig)
                    const { data } = response

                    updateStatusButton(data)
                }
            } catch (err) {
                window.showErrorPopup()
                $this.parents('.submit-button').removeClass('is-requesting')

                throw err
            }
        }

        /**
         * Header update helper
         * @param {*} data
         */
        function updateStatusButton(data) {
            const responseData = $('<div />').append(data)

            const statusButtonSelector = '#contract-header .info-manage'
            const buttonResponse = responseData.find(statusButtonSelector).html()

            headerInfoContainer.html(buttonResponse)

            window.updateSubmitButtons()
        }

        /**
         * Remove checked classes
         */
        $(document).on('click', function (evt) {
            const clickedButton = $('.submit-button.checked')

            if (!clickedButton.is(evt.target) || !clickedButton.has(evt.target)) {
                if (!$(".submit-button").has(evt.target).length) {
                    clickedButton.removeClass('checked')
                }
            }
        })

        const changeFavoriteContract = async function () {
            let favorite = $(this).data('favorite')
            let url = $(this).data('url')
            const searchLoader = $(this).next('#search-loader')

            url = window.updateUrlParameter(url, 'status', !favorite)

            header.addClass('is-requesting')
            searchLoader.removeClass('hidden')

            try {
                await axios.get(url, window.axiosConfig)
                // await getContractListHtml()
                header.removeClass('is-requesting')
                searchLoader.addClass('hidden')

                if ($('#star').html() == 'star') {
                    // remove the existing tooltip
                    if(tipOff[0]) {
                        tipOff[0].destroy()
                    }
                    $('#star').html('star_border')
                    $(this).toggleClass('favorite-off favorite-on')
                    // reasign tooltip value so it can be toggled
                    tipOff = tippy('.favorite-off', {
                        content: $('.favorite-off').data('description')
                    })
                } else {
                    // remove the existing tooltip
                    if(tipOff[0]) {
                        tipOff[0].destroy()
                    }
                    $('#star').html('star')
                    $(this).toggleClass('favorite-off favorite-on')
                    // reasign tooltip value so it can be toggled
                    tipOn = tippy('.favorite-on', {
                        content: $('.favorite-on').data('defavorite')
                    })
                }
            } catch (err) {
                window.showErrorPopup()
                header.removeClass('is-requesting')
                searchLoader.addClass('hidden')
                throw err
            }
        }

        //header expanding
        if ($('.header-preview-toggler').exists()) {
            header.on('click', '.header-preview-toggler', function () {
                const headerWrapper = $(this).closest('.navigation-full-wrapper')
                const header = $(this).closest('.dashboard-navigation')
                headerWrapper.toggleClass('header-expanded')
                header.toggleClass('header-expanded')
                $('.header-toggler-text').toggleClass('hidden')
            })
        }

        header.on('click', '.favorite-button', changeFavoriteContract)
        //for baustelle header single:
        if ($('#disposition-contract-header').exists()) {
            $('#disposition-contract-header').on('click', '.favorite-button', changeFavoriteContract)
        }

        //make highway impersonation button fixed
        if ($('body').hasClass('highway') && impersonation.exists()) {
            $('.page-layout').css('margin-top', '58px')
        }

        //Contract header tooltips
        const contractHeaderTooltip = $('#contract-header-message').data('contractHeaderMessage')
        tippy('.submit-button', {
            theme: 'contract-header-message',
            content: contractHeaderTooltip,
            placement: 'bottom',
            appendTo: 'parent',
        })
    }

    //Accept new terms of use popup
    if($('#page-header').exists()) {
        const acceptedTermsOfUse = $('#page-header').data('acceptedTermsOfUse')
        const popupContent = $('#page-header').data('termsOfUsePage')
        const url = $('#page-header').data('path')

        if(!acceptedTermsOfUse) {
            const wrapper = document.createElement('div')

            wrapper.innerHTML = popupContent

            // Show warning popup
            swal({
                title: 'terms_of_use.agreement.title'.trans(),
                content: wrapper,
                buttons: {
                    submit: {
                        text: 'terms_of_use.agreement.confirm_button'.trans(),
                    },
                },
                className: 'terms-of-use-popup',
                closeOnEsc: false,
                closeOnClickOutside: false,
            }).then(async function(value){
                console.log(value)
                if(value === 'submit') {
                    try {
                        await axios.get(url, window.axiosConfig)
                    } catch (err) {
                        window.showErrorPopup()
                        throw err
                    }
                }
            })
        }
    }
})