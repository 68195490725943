export function initSearch(search, searchParent, loadingContainer, searchLoadingIcon, multiselect = false) {
    let url = window.location.href
    let searchTerm

    // get the data and rename it
    let { 
        messageApi_route: searchUrl,
        messagePlaceholder: placeholder,
        messageAdd_more_text: addMoreText,
        messageNo_results: noResults,
        paramsToIgnore
    } = search.data()

    search.select2({
        dropdownParent: searchParent,
        minimumResultsForSearch: -1,
        minimumInputLength: 1,
        multiple: true,
        placeholder: placeholder,
        ajax: {
            url: () => {
                let params = window.getUrlParams(url)
                let keys = Object.keys(params)

                // update url with all of the currently active parameters except for the current pagination params
                if (keys.length){
                    keys.forEach(key => {
                        if (key != 'page_size' && key != 'page' && !paramsToIgnore.includes(key)) {
                            if(multiselect) {
                                searchUrl = window.updateUrlParameterMulti(searchUrl, key, params[key])
                            } else {
                                searchUrl = window.updateUrlParameter(searchUrl, key, params[key])
                            }
                        }
                    })
                }
                return searchUrl
            },
            dataType: 'json',
            data: params => {
                // only used to store the search term
                searchTerm = params.term
                return params
            },
            processResults: data => {
                return {
                    results: data.items.map(key => ({
                            text: key.header + ': ' + key.name,
                            id: key.value,
                            value: key.filter_key
                    })),
                    pagination: {
                        more: data.pagination.has_next_page
                    }
                }
            }
        },
        language: {
            inputTooShort: () => addMoreText,
            noResults: () => noResults,
            searching: () => searchLoadingIcon,
            loadingMore: () => searchLoadingIcon
        },
        templateResult: data => styleResults(data),
        escapeMarkup: markup => markup
    }).on('select2:select', e => {
        let data = e.params.data
        loadingContainer.addClass('is-requesting')

        if(multiselect) {
            url = window.updateUrlParameterMulti(url, data.value, data.id)
        } else {
            url = window.updateUrlParameter(url, data.value, data.id)
            url = window.updateUrlParameter(url, 'term', searchTerm)
        }

        paramsToIgnore.forEach(key => {
            url = window.updateUrlParameter(url, key, '')
        })

        window.location.href = url
    })

    // show the search icon next to placeholder; icon defined in searchParams next to <select>
    $('.search-icon').removeClass('hidden')

    function styleResults(data) {
        return `<a class="search-result-link" href="#">
                    ${data.text}
                </a>`
    }
}