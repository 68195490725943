$(document).ready(()=> {
    if ($('#call-off-companies').exists()) {
    
        $(document).on('click','.disposition-table tr[data-href]', function() {
            const tableRow = $(this)
            if (tableRow.data('href')){
                window.location.href = tableRow.data('href')
            }
        })
        $(document).on('click','.disposition-table .details-link', function(e) {
            e.preventDefault()
            let link = $(this).attr('href')
            if (link) {
                link = decodeURIComponent(link)
                window.location.href = link
            }
           
        })
    }
})