$(document).ready(() => {
    if ($('.customize-table-container').exists()) {
        const tableContainer = $('.calendar-table-container')
        const table = $('.calendar-detail-table')
        const popup = $('.customize-table-popup-container')
        const headerCheckbox = $('.customize-table-popup-content').find('input[type=checkbox]')
        const saveBtn = popup.find('.customize-table-popup-save')
        const layoutBtn = popup.find('.change-overview-layout-btn')
        const resetBtn = popup.find('.customize-table-popup-reset')


        // Logic for opening/closing the customizable table popup
        tableContainer.on('click', '.edit-table-button', function() {
            popup.removeClass('closed')
        })
        $('.close-popup-btn').on('click', function() {
            $(this).closest(popup).addClass('closed')
        })
    

        // Logic for reseting table layout to default 
        resetBtn.on('click', async function() {
            const path = $(this).data('path')

            try {
                tableContainer.addClass('is-requesting')

                const response = await axios.get(path)
                const { data } = response.data

                const headers = data.headers
                const keys = Object.keys(headers)

                keys.forEach(key => {
                    if(headers[key].is_selected) {
                        table.find(`.${key}`).removeClass('hidden')
                    } else if(headers[key].is_hidden) {
                        table.find(`.${key}`).addClass('hidden')
                    }
                })
                location.reload()
            } catch {
                tableContainer.removeClass('is-requesting')
                window.showErrorPopup()
            }
        })


        // Logic for custom headers - checboxes for removing or adding headers and updating excel export url
        headerCheckbox.on('click', function() {
            const headerId = $(this).attr('id')

            // getting the selected headers from excel export link
            const excelExportUrl = $('.excel-export-container a').attr('href')
            const excelExportParams = new URLSearchParams(excelExportUrl)
            let selectedFieldsParam = excelExportParams.get('selected_fields') ? excelExportParams.get('selected_fields').split(',') : []

            if($(this).is(':checked') == false) {
                table.find(`.${headerId}`).addClass('hidden')
                selectedFieldsParam = selectedFieldsParam.filter(item => item != headerId)
            } else {
                table.find(`.${headerId}`).removeClass('hidden')
                selectedFieldsParam.push(headerId)
            }

            const updatedExcelExportUrl = window.updateUrlParameter(excelExportUrl, 'selected_fields', selectedFieldsParam.join())
            $('.excel-export-container a').attr('href', updatedExcelExportUrl)

            //Logic for changed calloff dropdown
            $('.calloff-changes').each(function() {
                let editedFields = $(this).find('.edited-field')
                let hideRow = true

                const dropdownCell = $(this).prev('.changed-calloff').find('.toggle-item-changes')
                const warningIcon = dropdownCell.find('.material-icons.error')

                editedFields.each(function() {
                    if (!$(this).hasClass('hidden')) {
                        hideRow = false
                        return false
                    }
                })

                if (hideRow) {
                    $(this).addClass('hidden-row')
                    dropdownCell.find('.cancel-dropdown').addClass('hidden')
                    warningIcon[0]._tippy.setContent(dropdownCell.data('dropdownTooltip'))

                } else {
                    $(this).removeClass('hidden-row')
                    dropdownCell.find('.cancel-dropdown').removeClass('hidden')
                    warningIcon[0]._tippy.setContent(dropdownCell.data('tooltip'))
                }
            })
        })


        // Logic for saving custom table to user
        saveBtn.on('click', async function() {
            const { path, layoutId} = $(this).data()
            
            const formData = new FormData()
            formData.append('selected_layout', layoutId)

            headerCheckbox.each(function() {
                if($(this).is(':checked')) {
                    formData.append('selected_fields[]', $(this).attr('id'))
                }
            })

            formData.append('selected_fields[]', 'calloff_delivery_status')
            formData.append('selected_fields[]', 'go_to_create_calloff')

            try {
                tableContainer.addClass('is-requesting')

                await axios.post(path, formData)

                $(this).closest(popup).addClass('closed')
            } catch {
                tableContainer.removeClass('is-requesting')
                window.showErrorPopup()
            }
            
            tableContainer.removeClass('is-requesting')
        })


        // Logic for changing overview layouts
        layoutBtn.on('click', async function() {
            tableContainer.addClass('is-requesting')
        })
    }
})