var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calloff-popup-container" }, [
    _c("div", { staticClass: "calloff-popup" }, [
      _c(
        "div",
        { staticClass: "popup-container" },
        [
          _c("div", { staticClass: "calloff-header" }, [
            _c("h4", [
              _vm._v(
                _vm._s(_vm._f("trans")("calloff.highway_edit_form.add-title"))
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "callof-controls" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "close-calloff-popup",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "paginator" }, [
            _c(
              "a",
              {
                ref: "step-1",
                staticClass: "paginator-page page-1",
                class: { active: _vm.active == 1 },
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    _vm.active = 1
                  }
                }
              },
              [_c("span", { staticClass: "page-number" }, [_vm._v("1")])]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                ref: "step-2",
                staticClass: "paginator-page page-2",
                class: { active: _vm.active == 2, disabled: _vm.disableStep1 },
                attrs: { href: "javascript:;" },
                on: { click: _vm.step2Action }
              },
              [_c("span", { staticClass: "page-number" }, [_vm._v("2")])]
            )
          ]),
          _vm._v(" "),
          _vm.loading ? _c("loading-icon") : _vm._e(),
          _vm._v(" "),
          _vm.axiosFlag
            ? _c("step1", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.active == 1,
                    expression: "active==1"
                  }
                ],
                attrs: {
                  loading: _vm.loading,
                  callOffTypes: _vm.callOffTypes,
                  axiosFlag: _vm.axiosFlag,
                  deliveryModes: _vm.deliveryModes
                },
                on: {
                  incotermSelection: _vm.enableDeliveryMode,
                  deliveryModeSelection: _vm.enableStep2
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("step2", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.active == 2,
                expression: "active==2"
              }
            ],
            key: _vm.callOffTypeSelected + "-" + _vm.deliveryModeSelected,
            ref: "step-2-content",
            attrs: {
              loading: _vm.loading,
              initialEditContent: _vm.editContent,
              editId: _vm.editId
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "calloff-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-simple cancel-button",
            attrs: { type: "button", disabled: _vm.loading },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [
            _vm._v(
              "\n                     " +
                _vm._s(_vm._f("trans")("register.not_yet")) +
                "\n                 "
            )
          ]
        ),
        _vm._v(" "),
        _vm.active == 1
          ? _c(
              "button",
              {
                staticClass: "btn btn-primary next-button",
                attrs: { type: "button", disabled: _vm.disableStep1 },
                on: { click: _vm.step2Action }
              },
              [
                _vm._v(
                  "\n                     " +
                    _vm._s(_vm._f("trans")("calloff.form.next-step")) +
                    "\n                 "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.active == 2
          ? _c(
              "button",
              {
                staticClass: "btn btn-primary next-button",
                attrs: { type: "button", disabled: _vm.loading },
                on: { click: _vm.nextAction }
              },
              [
                _vm._v(
                  "\n                     " +
                    _vm._s(_vm._f("trans")("calloff.form.submit")) +
                    "\n                 "
                )
              ]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "info-calloff-popup", attrs: { href: "javascript:;" } },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("info")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }