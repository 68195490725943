$(document).ready(() => {
    if($('#calloff-creation.summary-step').exists()) {
        const stepFiveContainer = $('.summary-step')
        const loadingContainer = $('.calloff-creation-brick-container')
        let url = window.location.href


        // Logic for initializing table filters
        function initFilters() {
            $('.header-filter').each(function() {
                const { filterName, placeholder, selection, filterIcon, noResults } = $(this).data()

                $(this).select2({
                    minimumResultsForSearch: -1,
                    dropdownParent: $(this).closest('.filter-heading'),
                    language: {
                        noResults: () => noResults
                    },
                    templateSelection: () => {
                        return `${placeholder.toString()} <i class="material-icons">${filterIcon}</i>`
                    },
                    templateResult: data => window.callOffCreation.styleResults(data, selection),
                    escapeMarkup: markup => markup
                }).on('select2:opening', function() {
                    $(this).closest('.responsive-table-wrapper').addClass('filter-opened')
                }).on('select2:select', async function(e) {

                    let filterText = e.params.data.text
                    let filterValue = e.params.data.id

                    let params = {
                        [filterName]: $(this).val()
                    }

                    // if its already selected remove it instead
                    if (selection == filterText.toString().trim() || selection == filterValue) {
                        params = {
                            [filterName]: ''
                        }
                    }
                    try {
                        loadingContainer.addClass('is-requesting')
                        await updateView(params)
                    }
                    catch {
                        window.showErrorPopup()
                    }
                    loadingContainer.removeClass('is-requesting')
                }).on('select2:closing', function() {
                    $(this).closest('.responsive-table-wrapper').removeClass('filter-opened')
                })
            })
        }
        initFilters()


        // Logic for table sorters
        stepFiveContainer.on('click', '.table-sort', async function() {
            const { sortOrder, sortKey } = $(this).data()

            let params = {
                'order': sortOrder,
                'order_by': sortKey
            }
            try {
                await updateView(params)
            }
            catch {
                window.showErrorPopup()
            }
        })


        // Logic for note popup related buttons
        if ($('.calloff-note').exists()) {

            // svg from the table
            stepFiveContainer.on('click', '.calloff-note svg', function() {
                $(this).closest('tr').find('.calloff-steps-notes-popup').removeClass('hidden')
            })

            // x button from the popup
            stepFiveContainer.on('click', '.notes-popup-close-btn', function() {
                $(this).closest('.calloff-steps-notes-popup').addClass('hidden')
            }) 
        }


        // Logic for removing a cart item
        if ($('.cart-action').exists()) {
            stepFiveContainer.on('click', '.remove-from-cart svg', async function() {
                const tableRow = $(this).closest('tr')
                const path = $(this).closest('.cart-action').data('path')
                const additionalTableClass = 'calloff-step-five-table'
                const additionalTopOffset = 5

                window.callOffCreation.animateCalloffDeletion(tableRow, additionalTableClass, additionalTopOffset)
                loadingContainer.addClass('is-requesting')

                // if the cart will become empty then redirect
                try {
                    const response = await axios.get(path)
                    const { data } = response

                    if (data.redirect_url) {
                        window.location.href = data.redirect_url
                    } else {
                        // await updateView()
                        loadingContainer.removeClass('is-requesting')
                    }
                } catch {
                    loadingContainer.removeClass('is-requesting')
                    window.showErrorPopup()
                }
            })
        }


        // Logic for the next-button below the table
        if ($('.navigation-button').exists()) {

            // open popup
            stepFiveContainer.on('click', '.step-end-button', async function() {
                let path = $(this).data('path')
                try {
                    loadingContainer.addClass('is-requesting')

                    await axios.get(path)

                    $(this).closest('.steps-navigation-container').find('.calloff-steps-done-popup').removeClass('hidden')
                } catch {
                    window.showErrorPopup()
                }

                loadingContainer.removeClass('is-requesting')
            })

            // stay on calloff creation or go to calloff overview page
            stepFiveContainer.on('click', '.stay-on-page-btn, .go-to-page-btn, .popup-close-btn', function() {
                let path = $(this).data('path')
                $('.steps-navigation-container').find('.calloff-steps-done-popup').addClass('hidden')
                loadingContainer.addClass('is-requesting')

                window.location.href = path
            })
        }


        // Helper function for validating the next button
        function validateNextButton() {
            if ($('.step-end-button').data('itemsCount') == 0) {
                $('.step-end-button').addClass('disabled')
            }
        }
        validateNextButton()


        // Logic for replacing page content without reload
        async function updateView(urlParams = {}, reload = false, pagination = false) {
            if (!urlParams.isEmpty) {
                Object.keys(urlParams).forEach(function(key) {
                    url = window.updateUrlParameter(url, key, urlParams[key])
                })
                if (!pagination) {
                    url = window.updateUrlParameter(url, 'page', '')
                }
                if (typeof window.history.pushState != 'undefined') {
                    window.history.pushState(null, null, url)
                }
                if (reload) {
                    window.location.href = url
                }
            }
            if (!reload) {
                try {
                    const response = await axios.get(url, window.axiosConfig)
                    const { data } = response
                    
                    const calloffTable = $(data).find('.calloff-step-five-table').html() || ''
                    const stepsNavigation = $(data).find('.steps-navigation-container').html() || ''
                    const cartItemsCounter = $(data).find('.cart-items-number-contanier').html() || ''

                    $('.calloff-step-five-table:not(.clone)').html(calloffTable)
                    $('.steps-navigation-container').html(stepsNavigation)
                    $('.cart-items-number-contanier').html(cartItemsCounter)

                    initFilters()
                    initStepFiveTooltips()
                    validateNextButton()

                } catch {
                    window.showErrorPopup()
                }
            }
        }

        // Logic for initializing tooltips
        function initStepFiveTooltips() {
            $('.material-name .has-tippy').each( function() {
                const materialDescriptionTooltip = $(this).data('materialDescription')
                const materialDescriptionMoreTooltip = $(this).data('materialDescriptionMore')
                const numberTooltip = $(this).data('number')
                const epTooltip = $(this).data('ep')
                const avvCodeTooltip = $(this).data('avvCode')
                const avvTooltip = $(this).data('avv')

                tippy(this, {
                    content: materialDescriptionTooltip + (materialDescriptionMoreTooltip ? '<br>'+ materialDescriptionMoreTooltip : '')
                    + (numberTooltip ? '<br>'+epTooltip+numberTooltip : '') 
                    + (avvCodeTooltip ? '<br>'+avvTooltip+avvCodeTooltip : ''),
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('.factory .has-tippy').each( function() {
                const factoryNameTooltip = $(this).data('factoryName')
                const factoryStreetTooltip = $(this).data('factoryStreet')
                const factoryStreetNumberTooltip = $(this).data('factoryStreetNumber')
                const factoryZipTooltip = $(this).data('factoryZip')
                const factoryCityTooltip = $(this).data('factoryCity')

                tippy(this, {
                    content: factoryNameTooltip + "<br>" + factoryStreetTooltip + " " + factoryStreetNumberTooltip + "<br>" +
                    factoryZipTooltip + " " + factoryCityTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('.contact-person .has-tippy').each( function() {
                const contactEmailTooltip = $(this).data('contactEmail')
                const contactEmailTitleTooltip = $(this).data('contactEmailTooltip')
                // Phone number hidden
                // const contactPhoneTooltip = $(this).data('contactPhone')

                tippy(this, {
                    content:  contactEmailTitleTooltip+ ': ' +contactEmailTooltip,
                    // + "<br>" + 'calloff_creation.tooltip.phone'-#TODO: add translation to twig when needed + ': ' + contactPhoneTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('.calloff-step-five-notes-btn .has-tippy').each(function() {
                const noteTooltip = $(this).data('note')
                const noteTitleTooltip = $(this).data('noteTooltip')

                tippy(this, {
                    content: noteTitleTooltip + '<br>' + noteTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            const removeItem = $('.remove-from-cart .has-tippy').data('tooltipTranslation')
            tippy('.remove-from-cart .has-tippy', {
                content: removeItem,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })

            const nightshiftTooltip = $('.nightshift-status').data('tooltipTranslation')
            tippy('.nightshift-status', {
                content: nightshiftTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
        }
        initStepFiveTooltips()
    }
})