/**
 * Create calloff page
 */
$(document).ready(() => {
    if($('#calloff-history-overview-page').exists()) {

        let selectedIDs = []
        const clickableRow = $('#calloff-history-overview-page tr.clickable')
        const duplicateButton = $('#calloff-history-overview-page #duplicate-selected')
        const selectionCheckbox = $('#calloff-history-overview-page tr.clickable .selection-checkbox')
        const activeFilter = $('.users-page-search').find('.active-filter')
        const headerSearch = $('.header-search')
        const tableContainer = $('#calloff-history-overview-page')
        const pagination = $('#pagination')
        const dateFilter = $('#calloff-history-overview-page .flatpickr-date-filter')
        const headerFilter = $('.material-select2-filter')
        let startValue, endValue
        // Pagination
        initPagination(pagination)
        
        // date filter calendar 
        if (dateFilter.exists()){
            let { filterValues, selectedFrom, selectedTo } = dateFilter.data()
            let keys = filterValues ? Object.keys(filterValues): []
            let firstDate, lastDate, lastDateArray, lastActiveYear, lastActiveMonth
            if (keys.length){
                firstDate = keys[0]
                lastDate = keys[keys.length-1]
                 lastDateArray = lastDate.split('.')
                 lastActiveYear = lastDateArray[2];
                 lastActiveMonth = lastDateArray[1]; 
            }
            
            if (selectedFrom && selectedTo ) {        
                startValue = selectedFrom.split('-').join('.')
                endValue = selectedTo.split('-').join('.')
            } 
            let dateFilterInstance =flatpickr('.flatpickr-date-filter', {
                dateFormat: 'd.m.Y',
                locale: window.shortLocale,
                minDate: firstDate ? firstDate : null ,
                maxDate: lastDate ? lastDate : null,
                appendTo: dateFilter[0],
                currentMonth: 5,
                mode:'range',
                defaultDate: startValue && endValue ? [startValue, endValue]: null,
                onReady: (selectedDates, dateString, instance) => {
                    // Append title to calendar
                    const { calendarContainer } = instance
                    $(calendarContainer).append(`<button class="date-filter-submit">${window.translationConfirm}</button>`)
                },
                onValueUpdate:  function(date, dateString, instance) {
                    if (date.length > 0){
                        let len = date.length
                        startValue = flatpickr.formatDate(date[0],'d-m-Y' )
                        endValue = flatpickr.formatDate(date[len -1],'d-m-Y' )
                    }
                },
                closeOnSelect: false
               
            })   
            if (lastActiveMonth && lastActiveYear) {
                dateFilterInstance.changeMonth(lastActiveMonth - 1, false)
                dateFilterInstance.changeYear(lastActiveYear)
            }
           
             //submit button on calendar
            $(document).on('click', '.date-filter-submit', function() {
                
                let url = window.updateUrlParameter(window.location.href, 'filter[delivery_from_date]', startValue )
                url = window.updateUrlParameter(url, 'filter[delivery_to_date]', endValue)
                
                window.history.pushState(null, null, url)
                
                dateFilterInstance.close()
                window.location.href = url
                tableContainer.addClass('is-requesting')

                
            })

        }
        if (headerFilter.exists()){
            const filterIcon = headerFilter.data('filter-icon')
            const parentContainer = headerFilter.parents('.filter-heading')
            const searchLoader = $('.contract-search-container #search-loader').html()

            let { searchUrl, placeholder, name, pathParams} = headerFilter.data()
            let keys =  Object.keys(pathParams)
            if (keys.length){
                keys.forEach(key => {
                    if (pathParams[key]!==''){
                        searchUrl = window.updateUrlParameter(searchUrl, key, pathParams[key])
                    }
                })
                
            }
            let selectOptions = {
                width: '',
                allowClear: false,
                minimumResultsForSearch: -1,
                placeholder: placeholder,
                dropdownParent: parentContainer,
                escapeMarkup: markup => markup,
                ajax: {
                    url: searchUrl,
                    data: (params) => {
                        return {
                            term: $.trim(params.value),
                            page: params.page || 1,
                        }
                    },
                    dataType: 'json',

                    processResults: (data, { term }) => {
                        // handleSearchWithContractsList(term)
                        if (!data.items.material_name){
                            return {
                                results: []
                            }
                        }
                        let IDs= Object.keys(data.items.material_name) 
                        let names = Object.values(data.items.material_name)
                        
                        return {
                            results: IDs.map((el,index)=> ({ 
                                    name: names[index],
                                    id: el, 
                                    filter_key: name,
                                    value: el,
                                    path: window.updateUrlParameter(window.location.href, `filter[${name}]`, el)

                            })),
                            pagination: {
                                more: data.pagination && data.pagination.has_next_page  ? data.pagination.has_next_page : false
                            }
                        }
                    },
                },
                templateResult: templateOptions,
                templateSelection: templateOptions,
            }
            headerFilter.select2(selectOptions)
            .on('select2:select', function(e) {
                let exactTarget = $(e.params.originalEvent.originalEvent.target)
                let searchKey = `filter[${name}]`
                let searchValue = e.params.data.value
                let url = window.location.href
                if (exactTarget.hasClass('table-filter-clear') || exactTarget.parent('.table-filter-clear').length){
                    url = window.updateUrlParameter(url, searchKey, '')
                   
                } else {
                    url = e.params.data.path

                }
                tableContainer.addClass('is-requesting')
                window.location.href = url
            })
            parentContainer.find('.select2-selection__arrow').html('')
            function templateOptions(data, container) {
               
                
                let markup = ''
                // Loading
                if (data.loading) {
                    markup = searchLoader
                } else {
                    
                    let $option = $(data.element)
                    let text = $option.text()  
                    let icon = $option.data('icon')
                    let {selection} =  $('.material-select2-filter').data();
                    
                    if (icon){
                        text = `${text} <i class="material-icons">${icon}</i>`
                        return text;
                    }
                    if (data.id == ""){
                        text = `${data.text} <i class="material-icons">${filterIcon}</i>`
                        return text;
                    }
                    // Option item
                    let { name, path, value } = data
                    // const params = window.getUrlParams(window.location.href)
                   
                    
                   
                    markup = `
                        <a class="dropdown-item table-filter" href="${path ? path: 'javascript:;' }">
                            ${name} 
                        </a>
                    `
                    if (selection != null && selection == value) {
                        markup = `
                        <a class="table-filter-clear"><i class="material-icons">close</i></a>
                        <a class="dropdown-item table-filter" href="${path ? path: 'javascript:;' }">
                            
                            ${name} 
                        </a>
                    `
                      
                    $(container).addClass('position-relative')
    
                    }
                }
                return markup
                
            }
        }
        
        tableContainer.on('click', '.table-filter', function() {
            const { filterValue, filterKey } = $(this).data()
            let url = decodeURIComponent(window.location.href)
       
            if(filterKey !== '' && filterValue !== '' ) {
                url  = window.updateUrlParameter(url, filterKey, filterValue.toString())
                tableContainer.addClass('is-requesting')
                // Make request
                window.location.href = url
            }
        })

        if (activeFilter.exists()){
            headerSearch.prop('disabled', true)
            activeFilter.on('click', function(){


                let filterKey = $(this).find('.remove-active-filter').data('filter-key')
       
                let url = window.location.href
                if (filterKey == 'delivery_date' ) {
                    url = window.updateUrlParameter(url, 'filter[delivery_from_date]', '')
                    url = window.updateUrlParameter(url, 'filter[delivery_to_date]', '')
                } else {
                    url = window.updateUrlParameter(url, filterKey, '')
                } 
                activeFilter.remove()
                tableContainer.addClass('is-requesting')
                window.location.href = url
              
            })
        }
        clickableRow.on('click', function() {
            const $this = $(this)
            const rowCheckbox = $this.find('.selection .selection-checkbox')

            if (rowCheckbox.is(':checked')) {
                $this.removeClass('selected')
                rowCheckbox.prop('checked', false).trigger('change')
            } else {
                $this.addClass('selected')
                rowCheckbox.prop('checked', true).trigger('change')
            }
        })

        selectionCheckbox.on('change', function() {
            const $this = $(this)
            const callOffId = $this.data('id')

            if (this.checked) {
                selectedIDs.push(callOffId)
            } else {
                const index = selectedIDs.indexOf(callOffId)
                if(index !== -1) {
                    selectedIDs.splice(index, 1)
                }
            }

            // Create csv (comma-separated) query param bay concating array
            const queryParam = selectedIDs.join(',')

            // Update uri
            let duplicateURI = duplicateButton.data('href')
            duplicateURI = window.updateUrlParameter(duplicateURI, 'duplicate', queryParam)

            duplicateButton.data('href', duplicateURI)

            // Set btn property
            if (selectedIDs.length > 0) {
                duplicateButton.prop('disabled', false)
            } else {
                duplicateButton.prop('disabled', true)
            }

            // Set selected count preview
            $('#calloff-history-overview-page #selected-count').text(selectedIDs.length)

        })

        // Since we trigger on row click, we dont' need this
        selectionCheckbox.on('click', (e) => { e.preventDefault() })

        duplicateButton.on('click', function() {
            const redirectURI = $(this).data('href')

            if(redirectURI && redirectURI !== '') {
                $(this).trigger('toggleState')
                window.location.href = redirectURI
            }
        })
        
         // Initialize pagination
        function initPagination($pagination) {
            if ($pagination.length == 0) {
                return
            }

            let {
                first,
                last,
                next,
                prev,
                startPage,
                totalPages,
                visiblePages,
            } = $pagination.data()

            if (totalPages == 0) {
                totalPages = 1
            }

            // Pagination Init
            pagination.twbsPagination({
                first,
                last,
                next,
                prev,
                startPage,
                totalPages,
                visiblePages,
                initiateStartPageClick: false,
                onPageClick: (event, page) => {
                    let uri = window.location.href
                    const requestURI = window.updateUrlParameter(uri, 'page', page)

                    tableContainer.addClass('is-requesting')
                    window.location.href = requestURI
                }
            })
        }
    }
})
