$(document).ready(() => {
    if($('#highway-calloff-dashboard').exists()) {
        const calloffDashboardContainer = $('#highway-calloff-dashboard')
        const filterContainer = $('.calloff-filter-container')
        const itemLoadingIcon = $('#search-loader').html()
        const dropdownArrow = $('.dropdown-arrow-svg').html()
        const searchContainer = $('.calloff-search-container')
        const liveUpdateSwitch = $('.live-update-switch .toggle')
        const tableContainer = $('.calloff-table-container')

        const paramsInSingleFormat = ['delivery_from_date', 'delivery_to_date', 'creation_from_date', 'creation_to_date']
        let url = window.location.href

        function getNewFilterUrl() {
            const currentNonStatusFilter = url.split("?")[1].split("&").filter(filter => !filter.startsWith("status"))
            const { protocol, host, pathname } = location
            const statusUrl = [...document.querySelectorAll('.calloff-top-container .calloff-filter-button.active')]
                .reduce((prev, cur) =>  `${prev}${prev !== "?" ? "&" : ""}status[]=${cur.getAttribute('data-filter-value')}`, "?")

            return location.href = `${protocol}//${host}${pathname}${statusUrl}${currentNonStatusFilter.map(filter => `&${filter}`).join("")}`
        }

        window.callOffEditOverview = {}

        // Logic for status filter buttons above the table
        $('.save-preset').attr('disabled', !($('.calloff-filter-button').hasClass('active') || $('.calloff-filter-button').hasClass('selected')))

        // set tooltip to new preset button if no statuses are chosen
        if($(".save-preset").is(":disabled")) {
            tippy('.save-preset-button-container', {
                content: $('.save-preset').data('tooltip'),
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
        }

        calloffDashboardContainer.on('click', '.calloff-filter-button', async function() {
            const { filterKey, filterValue } = $(this).data()

            calloffDashboardContainer.addClass('is-requesting')
            $(this).toggleClass('active')

            $('.calloff-filter-button').removeClass('selected')

            if(($('.calloff-filter-button').hasClass('active')) || ($('.calloff-filter-button').hasClass('selected'))) {
                $('.save-preset').attr('disabled', false)
            } else {
                $('.save-preset').attr('disabled', true)

                tippy('.save-preset-button-container', {
                    content: $('.save-preset').data('tooltip'),
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            }

            url = getNewFilterUrl()

            try {
                await updateView({}, false, true, true)
            } catch {
                window.showErrorPopup()
            }

            calloffDashboardContainer.removeClass('is-requesting')
        })

        // Remove all filters button
        calloffDashboardContainer.on('click', '.calloff-filter-delete-all', async function() {
            const originalUrl = $(this).data('originalUrl')

            calloffDashboardContainer.addClass('is-requesting')
            window.location.href = originalUrl
        })


        // Apply selected filters button
        /*   calloffDashboardContainer.on('click', '.calloff-filter-apply', async function() {
               const originalUrl = $(this).data('originalUrl')
               const filterValue = $(this).data('filterValue')

               const allFilters = $(this).closest('.calloff-filter-container').find('.calloff-filter-selector option:selected')
               const filterState = allFilters.map((i, el) => $(el).val()).toArray()
               const filterUrl = `${originalUrl}?status[]=${filterState.concat(filterValue).join('&status[]=')}`

               calloffDashboardContainer.addClass('is-requesting')
               window.location.href = filterUrl
           }) */

        /*

                // Apply selected filters button
        calloffDashboardContainer.on('click', '.calloff-filter-apply', async function() {
            const originalUrl = $(this).data('originalUrl')
            const filterValue = $(this).data('filterValue')

            const allFilters = $(this).closest('.calloff-filter-container').find('.calloff-filter-selector option:selected')
            const filterState = allFilters.map((i, el) => $(el).val()).toArray()
            let filterUrl = `${originalUrl}?`
            let filterParams = filterState.concat(filterValue).map(status => `status[]=${filterValue}`).join('&')
            filterUrl = `${filterUrl}${filterParams}`

            calloffDashboardContainer.addClass('is-requesting')
            window.location.href = filterUrl
        })

        // Apply selected filters button
        calloffDashboardContainer.on('click', '.calloff-filter-apply', async function() {
            const originalUrl = $(this).data('originalUrl')
            const filterValue = $(this).data('filterValue')

            const allFilters = $(this).closest('.calloff-filter-container').find('.calloff-filter-selector option:selected')
            const filterState = allFilters.map((i, el) => $(el).val()).toArray()
            let filterUrl = `${originalUrl}?`
            let filterParams = filterState.concat(filterValue).filter(status => status).map(status => `status[]=${encodeURIComponent(status)}`).join('&')
            filterUrl = `${filterUrl}${filterParams}`

            calloffDashboardContainer.addClass('is-requesting')
            window.location.href = filterUrl
        })

        **/




        // Apply selected filters button
        calloffDashboardContainer.on('click', '.calloff-filter-apply', async function() {
            const originalUrl = $(this).data('originalUrl')

            const allFilters = $(this).closest('.calloff-filter-container').find('.calloff-filter-selector option:selected')
            //const filterState = allFilters.map((i, el) => $(el).val()).toArray()
            const filterUrl = `${originalUrl}?status[]=0&status[]=1&status[]=2&status[]=3&status[]=4&status[]=5&status[]=20`

            calloffDashboardContainer.addClass('is-requesting')
            window.location.href = filterUrl
        })


        // Logic for clearing active filters above the table (x on active filter labels)
        calloffDashboardContainer.on('click', '.calloff-filter-label.active-filter', async function() {
            const filterKeys = $(this).find('.remove-active-filter').data('filter-keys')
            const filterKey = $(this).find('.remove-active-filter').data('filter-key')
            const filterValue = $(this).find('.remove-active-filter').data('filter-value')

            calloffDashboardContainer.addClass('is-requesting')

            $(this).remove()

            if (!!filterKey || !!filterKeys) {
                const params = filterKey ? {
                    [filterKey]: filterValue
                } : Object.fromEntries(filterKeys.map(x => [x, '']))

                try {
                    await updateView(params, false, true, !!filterKey)
                } catch {
                    window.showErrorPopup()
                }
            }

            calloffDashboardContainer.removeClass('is-requesting')
        })


        // Logic for save new preset popup
        calloffDashboardContainer.on('click', '.save-preset', function() {
            calloffDashboardContainer.find('.save-preset-popup-container').removeClass('hidden')
        })

        calloffDashboardContainer.on('click', '.close-preset-popup', function() {
            calloffDashboardContainer.find('.save-preset-popup-container').addClass('hidden')
        })

        calloffDashboardContainer.on('click', '.preset-cancel-button', function() {
            calloffDashboardContainer.find('.save-preset-popup-container').addClass('hidden')
        })


        // Logic for opening/closing the customizable table and preset popup
        calloffDashboardContainer.on('click', '.edit-table-button', function() {
            calloffDashboardContainer.find('.customize-table-popup-container').removeClass('closed')
        })
        calloffDashboardContainer.on('click', '.close-popup-btn', function() {
            $(this).closest('.customize-table-popup-container').addClass('closed')
        })

        calloffDashboardContainer.on('click', '.edit-preset-button', function() {
            calloffDashboardContainer.find('.customize-filters-popup-container').removeClass('closed')
        })
        calloffDashboardContainer.on('click', '.close-preset-popup-btn', function() {
            $(this).closest('.customize-filters-popup-container').addClass('closed')
        })


        // Logic for table sorters
        calloffDashboardContainer.on('click', '.table-sort', async function() {
            const { sortOrder, sortKey } = $(this).data()

            calloffDashboardContainer.addClass('is-requesting')

            let params = {
                'order': sortOrder,
                'order_by': sortKey
            }

            try {
                await updateView(params, false, true)
            }
            catch {
                window.showErrorPopup()
            }

            calloffDashboardContainer.removeClass('is-requesting')
        })


        // Logic for reminder message popup, bell icon in table
        calloffDashboardContainer.on('click', '.reminder', function() {
            if(!$(this).hasClass('disabled'))
            {
                $(this).closest('.deny-confirm-calloff').find('.reminder-popup-container').removeClass('hidden')
            }
        })

        calloffDashboardContainer.on('click', '.close-reminder-popup', function() {
            $(this).closest('.deny-confirm-calloff').find('.reminder-popup-container').addClass('hidden')
        })

        calloffDashboardContainer.on('click', '.reminder-cancel-button', function() {
            $(this).closest('.deny-confirm-calloff').find('.reminder-popup-container').addClass('hidden')
        })

        // Reminder note sending
        calloffDashboardContainer.on('click', '.reminder-send-button', async function() {
            const note = $(this).closest('.reminder-popup').find('.reminder-message').val()

            if(note) {
                const path = $(this).data('path') + '?customer_note=' + note

                try {
                    $(this).closest('.reminder-popup-container').addClass('hidden')
                    calloffDashboardContainer.addClass('is-requesting')

                    await axios.post(path)

                    await updateCalloffOverviewContainer(url)
                }
                catch {
                    window.showErrorPopup()

                }
                calloffDashboardContainer.removeClass('is-requesting')
            } else {
                $(this).closest('.reminder-popup').find('.reminder-message').addClass('error')
            }
        })

        // Remove red border from textarea when users types something
        calloffDashboardContainer.on('keyup', '.reminder-message', function(){
            if($(this).val().length > 0){
                $(this).removeClass('error');
            }
        })


        // Logic for declining calloff popup, x icon in table
        calloffDashboardContainer.on('click', '.deny', function() {
            $(this).closest('.deny-confirm-calloff').find('.decline-calloff-popup-container').removeClass('hidden')
        })

        calloffDashboardContainer.on('click', '.close-decline-calloff-popup', function() {
            $(this).closest('.deny-confirm-calloff').find('.decline-calloff-popup-container').addClass('hidden')
        })
        calloffDashboardContainer.on('click', '.decline-calloff-cancel-button', function() {
            $(this).closest('.deny-confirm-calloff').find('.decline-calloff-popup-container').addClass('hidden')
        })


        // Initialize filters above the table
        function initFilters() {
            const filter = $('.calloff-table-filter')

            filter.each(function() {
                const filter = $(this)
                const filterParent = filter.parent()
                initAjaxFilter(filter, filterParent, filterContainer, itemLoadingIcon, '', 'true')
            })
        }
        initFilters()


        // Logic for filter preset select
        function initPresetSelect() {
            $('#calloff-presets').select2({
                allowClear: true,
                dropdownParent: $('.caloff-preset-select'),
                containerCssClass: 'calloff-preset-select' ,
                dropdownCssClass: 'calloff-preset-dropdown',
                minimumResultsForSearch: -1,
                placeholder: $('#calloff-presets').data('placeholder'),
                language: {
                    noResults: () => $('#calloff-presets').data('noResults')
                },
            }).on('select2:clear', function() {
                url = $('#highway-calloff-dashboard').data('currentRoute')
                window.location.href = url
            }).on('select2:select', async function(e) {
                calloffDashboardContainer.addClass('is-requesting')

                const filterData = e.params.data
                const filterValue = filterData.id

                // creating an array from url string
                const urlArray = filterValue.split(/[?&]/).reduce(function(a,b,c){
                    var p=b.split("="), k=p[0], v=decodeURIComponent(p[1])
                    if(!p[1])return a
                    a[k]=a[k]||[]
                    a[k].push(v)
                    return a
                }, {})

                url = $('#highway-calloff-dashboard').data('currentRoute')

                $.each(urlArray, function(key, value) {
                    $.each(value, function(i, element) {
                        if(key == 'id') {
                            url = window.updateUrlParameter(url, key, element)
                        } else {
                            url = window.updateUrlParameterMulti(url, key.replace('[]', ''), element)
                        }
                    })
                })

                await updateCalloffOverviewContainer()

                if (typeof window.history.pushState != 'undefined') {
                    window.history.pushState(null, null, url)
                }

                // trigger preset select in popup
                window.callOffEdit.updatePresetPopup(filterData.element.dataset.id, filterValue)
                window.callOffEdit.updateFilterCounters()

                calloffDashboardContainer.removeClass('is-requesting')
            })
        }
        initPresetSelect()


        // Function for getting params key and value from string
        function getQueryStringParameters(url) {
            var urlParams = {},
                match,
                additional = /\+/g, // Regex for replacing additional symbol with a space
                search = /([^&=]+)=?([^&]*)/g,
                decode = function (s) {
                    return decodeURIComponent(s.replace(additional, " "))
                },
                query;
            if (url){
                if(url.split("?").length>0) {
                    query = url.split("?")[1]
                }
            } else {
                url = window.location.href
                query = window.location.search.substring(1)

            }

            while (match = search.exec(query)){
                urlParams[decode(match[1])] = decode(match[2])
            }
            return urlParams
        }


        // Logic for table layout select dropdown in the table header
        function initTableLayoutSelect() {
            $('#calloff-table-layout').select2({
                dropdownParent: $('.active-layout-select-container'),
                containerCssClass: 'calloff-table-layout-select' ,
                dropdownCssClass: 'calloff-table-layout-dropdown',
                minimumResultsForSearch: -1
            }).on("select2:open", function() {
                $(this).closest('#calendar-table-container').addClass('table-z-index-class')
            }).on("select2:close", function() {
                $(this).closest('#calendar-table-container').removeClass('table-z-index-class')
            }).on('select2:select', function(e) {
                calloffDashboardContainer.addClass('is-requesting')

                let filterValue = e.params.data.id
                window.location.href = filterValue
            })

            $('.calloff-table-layout-select').find('.select2-selection__arrow b').html(dropdownArrow)
        }
        initTableLayoutSelect()


        // Logic for submitting deny popup data
        calloffDashboardContainer.on('click', '.decline-calloff-send-button', async function() {
            const route = $(this).data('path')
            let params = {}

            // get all of the values for sending
            $(this).closest('.decline-calloff-popup').find('select, textarea').each(function() {
                params[$(this).data('name')] = $(this).val()
            })

            if(params.rejection_reason_note) {
                $(this).closest('.decline-calloff-popup-container').addClass('hidden')
                calloffDashboardContainer.addClass('is-requesting')

                // convert all params to formData
                const payload = createPayload(params)

                try {
                    await axios.post(route, payload)

                    await updateCalloffOverviewContainer(url)

                } catch {
                    window.showErrorPopup()
                }
                calloffDashboardContainer.removeClass('is-requesting')
            } else {
                $(this).closest('.decline-calloff-popup').find('.decline-calloff-message').addClass('error')
            }
        })

        // Remove red border from textarea when users types something
        calloffDashboardContainer.on('keyup', '.decline-calloff-message', function(){
            if($(this).val().length > 0){
                $(this).removeClass('error');
            }
        })


        // Helper function for creating formData payload from post params
        function createPayload(params) {
            const keys = Object.keys(params)
            let data = new FormData()

            keys.map(key => {
                data.append(key, params[key])
            })

            return data
        }


        // Logic for approving calloff cancelation popup
        // calloffDashboardContainer.on('click', '.cancel-calloff-send-button', async function() {
        //     const path = $(this).data('path')
        //     try {
        //         calloffDashboardContainer.addClass('is-requesting')

        //         await axios.post(path)
        //         await updateCalloffOverviewContainer(url)

        //         $(this).closest('.deny-confirm-calloff').addClass('hidden')
        //     } catch {
        //         window.showErrorPopup()
        //     }
        //     calloffDashboardContainer.removeClass('is-requesting')
        // })


        // Logic for rejection popup selects
        function initRejectionPopupSelect() {
            $('.calloff-rejection-select').each(function() {
                $(this).select2({
                    dropdownParent: $(this).closest('.decline-calloff-popup-select'),
                    containerCssClass: 'calloff-preset-select' ,
                    dropdownCssClass: 'calloff-preset-dropdown',
                    minimumResultsForSearch: -1
                }).on('select2:select', function(e) {
                })
            })
        }
        initRejectionPopupSelect()

        function initCancelationPopupSelect() {
            $('.calloff-cancelation-select').each(function() {
                $(this).select2({
                    dropdownParent: $(this).closest('.decline-calloff-popup-select'),
                    containerCssClass: 'calloff-preset-select' ,
                    dropdownCssClass: 'calloff-preset-dropdown',
                    minimumResultsForSearch: -1
                }).on('select2:select', function(e) {
                })
            })
        }
        initCancelationPopupSelect()


        // Logic for table header filters
        function initTableFilters() {
            if (tableContainer.exists()) {
                const tableFilters = $('.header-filter')

                tableFilters.each(function() {
                    const filter = $(this)
                    const filterParent = filter.parent()
                    initTableAjaxFilter(filter, filterParent, tableContainer, itemLoadingIcon, 'true')
                })
            }
        }
        initTableFilters()


// Logic for initializing pagination
        function initPagination() {
            const pagination = $('#number-pagination')
            if (pagination.exists()) {
                pagination.twbsPagination('destroy')

                let { first, last, next, prev, startPage, totalPages, visiblePages } = pagination.data()

                pagination.twbsPagination({
                    first,
                    last,
                    next,
                    prev,
                    startPage,
                    totalPages,
                    visiblePages,
                    initiateStartPageClick: false,
                    onPageClick: async (event, page) => {

                        calloffDashboardContainer.addClass('is-requesting')

                        url = window.updateUrlParameter(url, 'page', page)

                        if (typeof window.history.pushState != 'undefined') {
                            window.history.pushState(null, null, url)
                        }

                        await updateCalloffOverviewContainer2()

                        //document.querySelector(".calloff-filter-apply").setAttribute("hidden", "");
                        const button = document.querySelector(".calloff-filter-apply");

                        if (button.getAttribute("hidden") === "") {

                        } else if (button.hasAttribute("hidden", "")) {
                            button.setAttribute("hidden", "");
                        } else {
                            button.setAttribute("Test", "Test");
                        }

                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        })

                        calloffDashboardContainer.removeClass('is-requesting')
                        // document.querySelector(".calloff-filter-apply").setAttribute("hidden", "");
                    }
                })
            }
        }
        initPagination()

        // Logic for replacing page content without reload
        async function updateView(urlParams = {}, reload = false, pagination = false, multiselect = false) {
            if (!urlParams.isEmpty) {
                Object.keys(urlParams).forEach(function(key) {
                    url = window[multiselect && key != 'page' ? "updateUrlParameterMulti" : "updateUrlParameter"](url, key, urlParams[key])
                })
                if (!pagination) {
                    url = window.updateUrlParameter(url, 'page', '')
                }
                if (typeof window.history.pushState != 'undefined') {
                    window.history.pushState(null, null, url)
                }
                if (reload) {
                    window.location.href = url
                }
            }
            if (!reload) {
                try {
                    const response = await axios.get(url, window.axiosConfig)
                    const { data } = response

                    const topFiltersContainer = $(data).find('.calloff-top-container').html() || ''
                    const tableContainer = $(data).find('.calloff-table-container').html() || ''

                    $('.calloff-top-container').html(topFiltersContainer)
                    $('.calloff-table-container').html(tableContainer)

                    initFilters()
                    initPresetSelect()
                    initTableFilters()
                    initTableHeaderDatepickers()
                    initTableLayoutSelect()
                    initCallOffTooltips()
                    initPagination()
                    initRejectionPopupSelect()
                    initCancelationPopupSelect()

                } catch {
                    window.showErrorPopup()
                }
            }
        }

        // Helper function for reloading page
        async function updateCalloffOverviewContainer(refreshUrl = '') {
            try {
                let response = ''

                if(refreshUrl) {
                    response = await axios.get(refreshUrl)
                } else {
                    response = await axios.get(url)
                }
                const { data } = response

                const topFiltersContainer = $(data).find('.calloff-top-container').html() || ''
                const tableContainer = $(data).find('.calloff-table-container').html() || ''

                $('.calloff-top-container').html(topFiltersContainer)
                $('.calloff-table-container').html(tableContainer)

                initFilters()
                initPresetSelect()
                initTableFilters()
                initTableLayoutSelect()
                initCallOffTooltips()
                initPagination()
                initRejectionPopupSelect()
                initCancelationPopupSelect()

            } catch {
                window.showErrorPopup()
            }
        }





        async function updateCalloffOverviewContainer2(refreshUrl = '') {
            try {
                let response = ''

                if(refreshUrl) {
                    response = await axios.get(refreshUrl)
                } else {
                    response = await axios.get(url)
                }
                const { data } = response

                const tableContainer = $(data).find('.calloff-table-container').html() || ''

                $('.calloff-table-container').html(tableContainer)

                initPresetSelect()
                initTableFilters()
                initTableLayoutSelect()
                initCallOffTooltips()
                initPagination()
                initRejectionPopupSelect()
                initCancelationPopupSelect()

            } catch {
                window.showErrorPopup()
            }
        }







        window.callOffEditOverview.updateCalloffOverviewContainer = updateCalloffOverviewContainer


        // Logic for calloff overview table tooltips
        function initCallOffTooltips() {
            $('td.edited-calloff.has-tippy').each( function() {
                const tooltip = $(this).data('tooltip')

                tippy('td.edited-calloff.has-tippy .material-icons', {
                    content: tooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('td.customer_company_name .has-tippy').each( function() {
                const { nameTrans, name, numberTrans, number } = $(this).data()

                tippy(this, {
                    content: `
                                ${name ? nameTrans + ' ' + name : ''}
                                ${number ? '<br>' + numberTrans + ' ' + number : ''}
                            `,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('td.construction_site_name .has-tippy').each( function() {
                const { constructionSiteNumber, translation } = $(this).data()

                tippy(this, {
                    content: `${translation} ${constructionSiteNumber}`,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('td.material .has-tippy').each( function() {
                const { materialNumber, translation, materialEpNumber, materialEpNumberTrans } = $(this).data()

                tippy(this, {
                    content: `${translation} ${materialNumber} 
                                ${materialEpNumber ? '<br>' + materialEpNumberTrans + ' ' + materialEpNumber : ''}`,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('td.factory_name .has-tippy').each( function() {
                const factoryNameTooltip = $(this).data('factoryName')

                tippy(this, {
                    content: factoryNameTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('td.deny-confirm-calloff .reminder').each( function() {
                const tooltipTranslation = $(this).data('tooltipTranslation')

                tippy(this, {
                    content: tooltipTranslation,
                    placement: 'bottom',
                    theme: 'cockpit-tippy blue'
                })
            })

            $('td.deny-confirm-calloff .disabled').each( function() {
                const tooltipTranslation = $(this).data('tooltipTranslation')

                tippy(this, {
                    content: tooltipTranslation,
                    placement: 'bottom',
                    theme: 'cockpit-tippy blue'
                })
            })

            $('td.deny-confirm-calloff .deny').each( function() {
                const tooltipTranslation = $(this).data('tooltipTranslation')

                tippy(this, {
                    content: tooltipTranslation,
                    placement: 'bottom',
                    theme: 'cockpit-tippy red'
                })
            })

            $('td.deny-confirm-calloff .confirm').each( function() {
                const tooltipTranslation = $(this).data('tooltipTranslation')

                tippy(this, {
                    content: tooltipTranslation,
                    placement: 'bottom',
                    theme: 'cockpit-tippy blue'
                })
            })

            $('td.deny-confirm-calloff .see-details').each( function() {
                const tooltipTranslation = $(this).data('tooltipTranslation')

                tippy(this, {
                    content: tooltipTranslation,
                    placement: 'bottom',
                    theme: 'cockpit-tippy blue'
                })
            })

            $('td.deny-confirm-calloff .cancelation-note').each( function() {
                const tooltip = $(this).data('tooltip')

                tippy(this, {
                    content: tooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy yellow'
                })
            })

            $('td.deny-confirm-calloff .approve-calloff-cancellation').each( function() {
                const tooltip = $(this).data('tooltipTranslation')

                tippy(this, {
                    content: tooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy blue'
                })
            })

            $('td.creation_date .has-tippy').each(function() {
                const { time } = $(this).data()

                tippy(this, {
                    content: time,
                    placement: 'bottom',
                    theme: 'cockpit-tippy chat'
                })
            })

            $('td.created_by .has-tippy').each(function() {
                const { emailTrans, email, phoneTrans, phone } = $(this).data()

                tippy(this, {
                    content: `
                                ${email ? emailTrans + ' ' + email : ''}
                                ${phone ? '<br>' + phoneTrans + ' ' + phone : ''}
                            `,
                    placement: 'bottom',
                    theme: 'cockpit-tippy chat'
                })
            })

            $('td.reminder_messages .message').each( function() {
                const dateSentTooltip = $(this).data('dateSent')

                tippy(this, {
                    content: dateSentTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy chat'
                })
            })

            $('td.calloff-note .has-tippy').each( function() {
                const template = $(this).find('.tooltip-message').html()

                tippy(this, {
                    allowHTML: true,
                    content: template,
                    placement: 'bottom',
                    theme: 'calloff-messages-tippy'
                })
            })

            $('td .updated-by-field.has-tippy').each( function() {
                const emailTooltip = $(this).data('email')
                const numberTooltip = $(this).data('number')
                const mobileTooltip = $(this).data('mobileNumber')

                tippy(this, {
                    content: emailTooltip + '<br>' + numberTooltip + '<br>' + mobileTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })
        }
        initCallOffTooltips()


        // Logic for the search bar
        // Initialize search by sending 4 parameters:
        //  1) the item itself
        //  2) its parent
        //  3) container which will have 'is-requesting' class when loading
        //  4) html for the loading icon inside the item (not the main screen loader)
        function initSearch(search, searchParent, loadingContainer, searchLoadingIcon) {
            let searchTerm

            // get the data and rename it
            let {
                messageApi_route: searchUrl,
                messagePlaceholder: placeholder,
                messageAdd_more_text: addMoreText,
                messageNo_results: noResults
            } = search.data()

            search.select2({
                dropdownParent: searchParent,
                minimumResultsForSearch: -1,
                minimumInputLength: 1,
                multiple: true,
                placeholder: placeholder,
                ajax: {
                    url: () => {
                        let params = window.getUrlParams(url)
                        let keys = Object.keys(params)

                        // update url with all of the currently active parameters except for the current pagination params
                        if (keys.length){
                            keys.forEach(key => {
                                if (key != 'page_size' && key != 'page') {
                                    if (paramsInSingleFormat.includes(key)) {
                                        searchUrl = window.updateUrlParameter(searchUrl, key, params[key])
                                    } else {
                                        let ajaxUrlParams = Object.keys(window.getUrlParams(searchUrl))

                                        // since this url generator only appends params to old url when you search for 2nd
                                        // letter and after, only update url if there wasnt that param, otherwise
                                        // updateUrlParameterMulti function will simply remove it

                                        if (!ajaxUrlParams.includes(key)) {
                                            searchUrl = window.updateUrlParameterMulti(searchUrl, key, params[key])
                                        }
                                    }
                                }
                            })
                        }
                        return searchUrl
                    },
                    dataType: 'json',
                    data: params => {
                        // only used to store the search term
                        searchTerm = params.term
                        return params
                    },
                    processResults: data => {
                        return {
                            results: data.items.map(key => ({
                                text: `${key.header}: ${key.name}`, // Option preview
                                id: key.value,
                                name: key.name,
                                value: key.filter_key
                            })),
                            pagination: {
                                more: data.pagination.has_next_page
                            }
                        }
                    }
                },
                language: {
                    inputTooShort: () => addMoreText,
                    noResults: () => noResults,
                    searching: () => searchLoadingIcon,
                    loadingMore: () => searchLoadingIcon
                },
                templateResult: data => styleResults(data),
                escapeMarkup: markup => markup
            }).on('select2:select', async function(e) {
                const { value, name } = e.params.data

                calloffDashboardContainer.addClass('is-requesting')

                let params = {
                    [value]: name
                }

                try {
                    await updateView(params, false, true, true)
                }
                catch {
                    window.showErrorPopup()
                }

                $(this).val('').trigger('change')

                calloffDashboardContainer.removeClass('is-requesting')
            })

            // show the search icon next to placeholder; icon defined in searchParams next to <select>
            $('.search-icon').removeClass('hidden')

            function styleResults(data) {
                return `<a class="search-result-link" href="#">
                            ${data.text}
                        </a>`
            }
        }
        initSearch($('.calloff-search'), searchContainer, tableContainer, itemLoadingIcon, 'true')


        // Logic for live update switch
        if (liveUpdateSwitch.exists()) {

            // set the default state of switch to local storage, only the first time
            if (localStorage.getItem('calloffEditLiveSwitch') == null) {
                localStorage.setItem('calloffEditLiveSwitch', 'false')
            }

            // if the switch is active immediately start the update function
            if (localStorage.getItem('calloffEditLiveSwitch') == 'true') {
                liveUpdateSwitch.addClass('live-active')
                $('.change-live-status').prop('checked', true)
                toggleLiveUpdate()
            }

            // also when clicking the switch start the update function
            liveUpdateSwitch.on('click', async function() {
                liveUpdateSwitch.toggleClass('live-active')
                toggleLiveUpdate()
            })
        }


        // Logic for select filters outside of table
        function initAjaxFilter(
            filter,
            filterParent,
            loadingContainer,
            filterLoadingIcon,
            dropdownArrow = '<i class="material-icons">expand_more</i>',
        ) {

            let url = window.location.href
            let { filterUrl, filterName, selection, placeholder, noResults } = filter.data()

            let searchMinimum = 1

            filter.select2({
                dropdownParent: filterParent,
                minimumResultsForSearch: searchMinimum,
                ajax: {
                    // updates request url with all the current url params except for the current pagination params
                    url: () => {
                        let params = window.getUrlParams(url)
                        let keys = Object.keys(params)
                        if (keys.length){
                            keys.forEach(key => {
                                if (params[key] !== '' && key !== filterName && key != 'page_size' && key != 'page'){
                                    if (paramsInSingleFormat.includes(key)) {
                                        filterUrl = window.updateUrlParameter(filterUrl, key, params[key])
                                    } else {
                                        let ajaxUrlParams = Object.keys(window.getUrlParams(filterUrl))

                                        if (!ajaxUrlParams.includes(key)) {
                                            filterUrl = window.updateUrlParameterMulti(filterUrl, key, params[key])
                                        }
                                    }
                                }
                            })
                        }
                        return filterUrl
                    },
                    dataType: 'json',
                    processResults: data => {
                        // structure the response json to match select2 needs for filling dropdown
                        let keys = data.items[filterName] ? Object.keys(data.items[filterName]): []
                        let options = {
                            results: keys.map( key => ({
                                text: data.items[filterName][key],
                                id: key,
                                value: key
                            })),
                            pagination: {
                                more: data.pagination.has_next_page
                            }
                        }

                        return options
                    }
                },
                language: {
                    noResults: () => noResults,
                    searching: () => filterLoadingIcon,
                    loadingMore: () => filterLoadingIcon
                },
                templateSelection: function (data) {
                    return placeholder
                },
                templateResult: (data, container) => styleResults(data, container, selection),
                escapeMarkup: markup => markup

            }).on('select2:select', async function(e) {
                const { text } = e.params.data

                calloffDashboardContainer.addClass('is-requesting')

                const params = {
                    [filterName]: text
                }

                try {
                    await updateView(params, false, true, true)
                }
                catch {
                    window.showErrorPopup()
                }

                calloffDashboardContainer.removeClass('is-requesting')

            })

            // append an option that has the last saved state
            let preselectedOption = new Option(
                '',
                'preselected-option',
                true,
                true
            )
            filter.append(preselectedOption)

            // custom dropdown arrow
            filterParent.find('.select2-selection__arrow b').html(dropdownArrow)

            function styleResults(data, container, selection) {
                let markup = ''

                // Option item
                let { text, id } = data

                if (selection.includes(text.toString().trim()) || selection.includes(id)) {
                    markup = `
                                <p class="highlighted">${text}</p>
                            `
                } else {
                    markup = `${text}`
                }
                $(container).addClass('position-relative')

                return markup
            }
        }

        // Logic for select filters in table header
        function initTableAjaxFilter(filter, filterParent, loadingContainer, filterLoadingIcon) {
            let url = window.location.href
            let { filterUrl, filterName, selection, placeholder, filterIcon, noResults } = filter.data()

            filter.select2({
                dropdownParent: filterParent,
                minimumResultsForSearch: -1,
                placeholder: placeholder.toString(),
                ajax: {
                    // updates request url with all the current url params except for the current pagination params
                    url: () => {
                        let params = window.getUrlParams(url)
                        let keys = Object.keys(params)

                        if (keys.length){
                            keys.forEach(key => {
                                if (params[key] !== '' && key !== filterName && key != 'page_size' && key != 'page'){
                                    if (paramsInSingleFormat.includes(key)) {
                                        filterUrl = window.updateUrlParameter(filterUrl, key, params[key])
                                    } else {
                                        let ajaxUrlParams = Object.keys(window.getUrlParams(filterUrl))

                                        if (!ajaxUrlParams.includes(key)) {
                                            filterUrl = window.updateUrlParameterMulti(filterUrl, key, params[key])
                                        }
                                    }
                                }
                            })
                        }
                        return `${filterUrl}&statusGroup=${$('#highway-calloff-dashboard').data('statusGroup')}`
                    },
                    dataType: 'json',
                    processResults: data => {
                        // structure the response json to match select2 needs for filling dropdown
                        let keys = data.items[filterName] ? Object.keys(data.items[filterName]): []
                        return {
                            results: keys.map( key => ({
                                text: data.items[filterName][key],
                                id: key,
                                value: key
                            })),
                            pagination: {
                                more: data.pagination.has_next_page
                            }
                        }
                    }
                },
                language: {
                    noResults: () => noResults,
                    searching: () => filterLoadingIcon,
                    loadingMore: () => filterLoadingIcon
                },
                templateSelection: () => styleSelection(placeholder, filterIcon),
                templateResult: (data, container) => styleResults(data, container, selection),
                escapeMarkup: markup => markup

            }).on('select2:select', async function(e) {
                calloffDashboardContainer.addClass('is-requesting')
                const { text } = e.params.data

                let params = {
                    [filterName]: text
                }

                try {
                    await updateView(params, false, true, true)
                }
                catch {
                    window.showErrorPopup()
                }

                calloffDashboardContainer.removeClass('is-requesting')
            })

            // remove the default dropdown arrow
            filterParent.find('.select2-selection__arrow').html('')

            // custom class for hiding the search-box in dropdown during the ajax request
            filterParent.addClass('no-search-box')

            function styleSelection (placeholder, filterIcon) {
                return `${placeholder} <i class="material-icons">${filterIcon}</i>`
            }

            function styleResults(data, container, selection) {
                let markup = ''

                // Option item
                let { text, id } = data

                // if its selected add the "x" icon for removal
                // in the case that filter doesnt append to URL its text, we have additional check for id
                if (selection.includes(text.toString().trim()) || selection.includes(id)) {
                    markup = `
                                    <a class="table-filter-clear"><i class="material-icons">close</i></a>
                                    <a class="dropdown-item table-filter" href="#">
                                        ${text}
                                    </a>
                                `
                } else {
                    markup = `
                                    <a class="dropdown-item table-filter" href="#">
                                        ${text}
                                    </a>
                                `
                }

                $(container).addClass('position-relative')

                return markup
            }
        }


        // Logic for datepickers in table headers
        function initTableHeaderDatepickers() {
            const tableDatepickers = $('.table-header-datepicker')

            tableDatepickers.each(function() {
                const datepicker = $(this)
                let { locale, submitTranslation, type, selectedStartDate, selectedEndDate } = datepicker.data()
                let startDate, endDate

                flatpickr(datepicker, {
                    dateFormat: 'd.m.Y',
                    locale: locale,
                    mode: 'range',
                    defaultDate: [selectedStartDate, selectedEndDate],
                    closeOnSelect: false,
                    disableMobile: true,

                    onReady: (selectedDates, dateString, instance) => {
                        // append submit button to datepicker
                        let { calendarContainer } = instance
                        $(calendarContainer).append(`<button class="date-submit">
                                                        ${submitTranslation}
                                                    </button>`)

                        // add class to the dropdown
                        $(calendarContainer).addClass(`table-header-datepicker-dropdown ${type}`)
                    },
                    onValueUpdate: date => {
                        if (date.length) {
                            startDate = flatpickr.formatDate(date[0], 'd.m.Y')
                            endDate = flatpickr.formatDate(date[date.length - 1], 'd.m.Y')
                        }
                    }
                })
            })

            // logic for date submit button
            $('.date-submit').on('click', async function() {
                const delivery = $(this).closest('.table-header-datepicker-dropdown').hasClass('delivery')
                let params = ''

                // check if we are submitting delivery or creation date
                const datepicker = delivery ? $('.datepicker-delivery_date') : $('.datepicker-creation_date')

                datepicker[0]._flatpickr.close()
                calloffDashboardContainer.addClass('is-requesting')

                const dates = datepicker[0]._flatpickr.selectedDates

                // by default when nothing is selected set the dates to today
                let startDate = moment().format('DD.MM.YYYY')
                let endDate = moment().format('DD.MM.YYYY')

                // if something is selected take those values instead
                if (dates.length != 0) {
                    startDate = flatpickr.formatDate(dates[0], 'd.m.Y')
                    endDate = flatpickr.formatDate(dates[dates.length - 1], 'd.m.Y')
                }

                params = {
                    [delivery ? 'delivery_from_date' : 'creation_from_date']: startDate,
                    [delivery ? 'delivery_to_date' : 'creation_to_date']: endDate
                }

                try {
                    await updateView(params, false)
                } catch {
                    window.showErrorPopup()
                }

                calloffDashboardContainer.removeClass('is-requesting')
            })
        }
        initTableHeaderDatepickers()


        // Helper function for updating the data on screen for live update switch
        function toggleLiveUpdate() {
            if (liveUpdateSwitch.hasClass('live-active')) {
                const refreshTime = $('.change-live-status').data('interval')
                localStorage.setItem('calloffEditLiveSwitch', 'true')

                setInterval(async function() {
                    calloffDashboardContainer.addClass('is-requesting')

                    await updateCalloffOverviewContainer()

                    calloffDashboardContainer.removeClass('is-requesting')
                }, refreshTime * 1000)

            } else {
                localStorage.setItem('calloffEditLiveSwitch', 'false')

                calloffDashboardContainer.addClass('is-requesting')
                location.reload()
            }
        }
    }
})