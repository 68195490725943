$(function() {
    if($('.snippet-container.editmode').length > 0) {
        let options = $('.snippet-container div[data-real-name="survey-questions"] .pimcore_block_entry')
        let optionInputs = $('.snippet-container div[data-real-name="survey-questions"] .pimcore_block_entry .input-radio')
        
        optionInputs.on('click', function(e) {
            e.preventDefault()

            let key = $(this).closest('.pimcore_block_entry').attr('key')
            
            toggleOption(key)
        })
        
        function toggleOption(key) {
            let thisOption = $('.snippet-container div[data-real-name="survey-questions"] .pimcore_block_entry[key="' + key + '"]')
            let thisInput = thisOption.find('.input-radio')

            if(thisInput.hasClass('checked')) {
                thisOption.removeClass('open')
                thisInput.removeClass('checked')
            } else {
                thisOption.addClass('open')
                thisInput.addClass('checked')
            }
        }
        
        function initEditmode() {
            options.addClass('open')
        }
        
        initEditmode()
    }
})