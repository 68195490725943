/**
 * Axios configuration
 * Can be used as parameter for axios adapter
 */
window.axiosConfig = {
    // If timeout exceeds 30s (30,000ms)..
    timeout: 30 * 1000,
    headers: { 'Cache-Control': 'no-cache' }

}

/**
 * Set default header since backend needs this flag to check for ajax request type
 */
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
/**
 * Main axios response inteceptor
 */

// Session error flag
let sessionError = false
window.axios.interceptors.response.use(function (response) {
    sessionError = false
    // check for backend permissions check
    if (response.status == 200 && response.data.redirect == true){
        window.location.href = response.data.path
        return Promise.reject(error)
    }
    return response
}, function (error) {
    //check for backend login check
    if (error.response && error.response.status && error.response.status === 403) {
        // Show sessionWarnign popup
        
        let message = (error.response && error.response.unpublished == true) ? window.userUnpublished.message : window.sessionWarningPopup.message
        let buttonText = (error.response && error.response.unpublished == true) ? window.userUnpublished.buttonText : window.sessionWarningPopup.buttonText
        swal({
            icon: 'warning',
            dangerMode: true,
            className: 'session-warning-popup',
            text: message,
            button:{
                text:  buttonText,
                value: 'redirect',
            }
        }).then(function(){
           
            let url = error.response.data.login_page_url ? error.response.data.login_page_url : error.response.data.redirect_url
            window.location.href = url   
                  
        })

        sessionError = true
        throw error
       

    } else {
        // Set flag so error handler can show error popup if needed
        sessionError = false
        return Promise.reject(error)
    }
})

/**
 * Helper which shows sweetalert's popup
 * Custom message & button text can be used
 */
window.showErrorPopup = (customMessage = null, customButtonTxt = null, reload = false) => {
    const {
        message,
        buttonText,
    } = window.errorPopup

    if (sessionError) return
   
    swal({
        icon: 'error',
        dangerMode: true,
        text: customMessage || message,
        button: customButtonTxt || buttonText,
    }).then(()=>{
        if (reload){
            location.reload()
        }
    })
}

// custom transport function used in select2 with ajax requests  
// handling errors upon user session timeout or user getting unpublished inside admin
window.customTransportFunction = function(params, success, failure ) {
    var $request = $.ajax(params);

    $request.then(success);
    $request.fail(()=>handleError(failure))
    function handleError(failure) {
        
        if ($request.status && $request.status == 403) {
            
        let message = ($request.responseJSON && $request.responseJSON.unpublished == true )? window.userUnpublished.message : window.sessionWarningPopup.message
        let buttonText = ($request.responseJSON && $request.responseJSON.unpublished == true) ? window.userUnpublished.buttonText : window.sessionWarningPopup.buttonText
      
        swal({
            icon: 'warning',
            dangerMode: true,
            className: 'session-warning-popup',
            text: message,
            button:{
                text:  buttonText,
                value: 'redirect',
            }
        }).then(function(){
        
            let url = $request.responseJSON.login_page_url ? $request.responseJSON.login_page_url : $request.responseJSON.redirect_url
            window.location.href = url   
            failure()        
        })

        
        } else {
            failure()
        }
       
    }
    return $request;
}