$(document).ready(() => {
    // handle logic with besitzer hinzufugen button currently present on highway/unternehmen and highway/company/full
    if ($('.owner-inviter').exists() ){

        
        // Show invite-owner popup
        //$('.owner-inviter').on('click', handleOwnerInvite)
        $(document).on('click', '.owner-inviter', handleOwnerInvite)

        // Close invite-owner popup
        const inviteOwnerPopup = $('.add-owner-popup')

      
        // Form validation
        const companyUsersPage = $('#company-users-table')
        // @TODO change use on company full, check companyUsersPage 
        function validateForm() {
            const $form = $(this).closest('form')
            
            const validation = window.validateFormFields($form)
            if(validation && validation.isValid) {
                $('#company-users-table #invite_existing_user__submit').attr('disabled', false)
            } else {
                $('#company-users-table #invite_existing_user__submit').attr('disabled', true)
            }
        }
        companyUsersPage.on('change keyup', '.form-group input, #invite_existing_user_user', validateForm)

        // Ajax request for inviting owner
        async function handleOwnerInvite () {
            try {
                // Make request & set inner HTML
                
                const requestURI = $(this).data('url')
                const response = await axios.get(requestURI, window.axiosConfig)
                const { data } = response
                const popupContent = $(data)

                inviteOwnerPopup.find('.invite-owner-content').html(popupContent)
                inviteOwnerPopup.removeClass('hidden fadeOut').addClass("animated fadeIn faster")
                
                $('#bag-user-change-role').on('click', submitInvitation)
            } catch (error) {
                window.showErrorPopup()
                console.error(error)
                throw error
            }
        }
        async function submitInvitation(e){
            e.preventDefault()
            let submitButton = $(this)
            const $form = $(this).closest('form')
            const requestURI = $form.attr('action')
            const validation = window.validateFormFields($form)
            if(validation && validation.isValid) {
                const formData = new FormData($form[0])
                // const payload = $form[0]
                
                inviteOwnerPopup.addClass('hidden fadeOut').removeClass("animated fadeIn faster")
                window.showLoaderPopup()
                // formSubmitBtn.trigger('toggleState')

                await axios.post(requestURI, formData, window.axiosConfig)
                    .then((res)=> {
                        window.closeLoaderPopup()
                        
                        swal({
                            className: 'calloff-status-modal', 
                            title: submitButton.data('title'),
                            text:  submitButton.data('content'),
                            buttons:  {
                                submit: {
                                    text: submitButton.data('submit'),
                                    closeModal: true,
                                }
                            }
            
                        })
                      
                       
                    }).catch(err => {
                        // inviteOwnerPopup.addClass('hidden fadeOut').removeClass("animated fadeIn faster")
                        window.closeLoaderPopup()
                        window.showErrorPopup(err.response.data.message)
                        // formSubmitBtn.trigger('toggleState')
                        throw err
                    })
            
            } 
        }
       
    }
})