
/**
 * Create calloff page
 */
$(document).ready(() => {
    if($('#create-calloff-page').exists()) {
        // Simple flag for select options
        window.updateInputsFloatingLabels()
        let onLoadFlag = true
        let submitFromHistoryFlag = false
        //store popup values to set them when edit is canceled
        let formValues = {};
        let todayDate = flatpickr.formatDate(new Date(), 'd.m.Y')
        let currentTime = flatpickr.formatDate(new Date(), 'H:i')
        // Will be used as storage for all dynamically created autonumeric instances
        let autoNumericInputs = []
        const tableBody = $('#calloff-creation-container')
        // page submit/cancel elements
        const blockButton =  $('.button-block')
        const cancelButton = $('.button-block .calloff-cancel')
        let noDataText= cancelButton.data('notapplicable')

        // For preselecting default vechile types
        let defaultVehicleTypes = {}
        let defaultMaterialCategories = {}
        //pick up translations for continuous display in the table
        const continuous = $('.calloff-creation-table .continuous').data('continuous')
        const uncontinuous = $('.calloff-creation-table .continuous').data('uncontinuous')
        //pick up pen-menu for table row from hidden field
        const penMenu = $('.calloff-creation-table .pen-menu').html()
        $('.pen-menu .dropdown').detach()  
        // Do it in try-catch block, since parsing can go soo wrong..
        try {
            if(window.defaultVehicleTypes) {
                defaultVehicleTypes = JSON.parse(window.defaultVehicleTypes)
            }
            if(window.defaultMaterialCategories) {
                defaultMaterialCategories = JSON.parse(window.defaultMaterialCategories)
            }
        } catch(err) {
            defaultVehicleTypes = {}
            defaultMaterialCategories = {}
        }
        // Formatted quantity inputs
        // Digit separators & decimal characters options for localization
        let localizedOptions
        if(window.shortLocale && window.shortLocale == 'de') {
            localizedOptions = {
                digitGroupSeparator: '.',
                decimalCharacter: ',',
            }
        } else {
            localizedOptions = {
                digitGroupSeparator: ',',
                decimalCharacter: '.',
            }
        }

        const quantityInputOptions = {
            ...localizedOptions,
            decimalPlaces: 0,
            unformatOnSubmit: true,
            modifyValueOnWheel: false,
            emptyInputBehavior: 'press',
        }

        const quantityFloatInputOptions = {
            ...localizedOptions,
            decimalPlaces: 0,
            unformatOnSubmit: true,
            modifyValueOnWheel: false,
            emptyInputBehavior: 'press',
            currencySymbolPlacement: AutoNumeric.options.currencySymbolPlacement.suffix,
        }

        const quantityUnitInputOptions = {
            ...quantityFloatInputOptions,
            // Add custom unit
            currencySymbol: (input) => {
                let unitSymbol = '\u202f€'
                if (input.settings.unit) {
                    const {
                        unit
                    } = input.settings

                    unitSymbol = `\u202f${unit}`
                }

                return unitSymbol
            }
        }

        // Only as formCollection index
        let materialCounter = 0
        const pageContainer = $('.calloffs-container'); 
        const addNewBtn = $('#create-calloff-page #add-calloff')
        const formSubmitBtn = $('#create-calloff-page #call_off_collection_submit')

        const formLoader = $('#create-calloff-page #form-loader')
        const successPopup = $('#create-calloff-page #success-popup')
        const collectionContainer = $('#create-calloff-page .calloffs-container')
        
        // HTML prototype which contains whole rendered HTML for one calloff
        const formPrototype = $('#create-calloff-page #call_off_collection_calloffs').html()
       
        // Call Onload action
        onLoadAction()

        // Add new calloff
        addNewBtn.on('click', initCallOffCreation)
        function initCallOffCreation (){
            // Call action  
            const newCalloff = appendEmptyCalloff()
            // let callOffID = $(`#${newCalloff.attr('id')}`).find('.calloff-popup')
            // Init fields
            initCustomFields(newCalloff)

            // Increment counter
            materialCounter++

            // Show it now when inputs are initialized
            window.updateInputsFloatingLabels()
            newCalloff.removeClass('hidden')
            // // updateTitleIndexes()
        }
        function removeCalloffs(e = null) {
            if (e) {
                e.preventDefault()
            }
            if (submitFromHistoryFlag) {
                let url = window.location.href
                url = url.split('?')[0]
                window.location.href = url
            }
            formValues = {}
            tableBody.empty()
        }
        function confirmationPopup(e){
            e.preventDefault()
            swal({
                className: 'calloff-status-modal', 
                title: formSubmitBtn.data('title'),
                text:  formSubmitBtn.data('content'),
                buttons: [cancelButton.text(), formSubmitBtn.text() ],

            }).then(function(value){
                if (value === true){
                    submitPayload(e)
                } else {
                }
            })
        }
        function submitPayload(e) {
           
            e.preventDefault()
            const $form = tableBody.closest('form')
            const formData = new FormData()
            const requestURI = $form.attr('action')
            const formFields = $form.serializeArray()
            // Create payload
            formFields.forEach(field => {
                let value = field.value
                const fieldName = field.name

                // Fetch unformatted values
                const input = $form.find(`[name="${field.name}"]`)
                let ANInputs = [].concat.apply([], autoNumericInputs)
                ANInputs.forEach(autonumericInput => {
                    const { domElement } = autonumericInput
                    // Find input which needs to be unformatted
                    if (input.is(domElement)) {
                        value = autonumericInput.rawValue
                    }
                })

                // Create payload
                formData.append(fieldName, value)
            })

            submitCallOffsForm(requestURI, formData)

        }
        cancelButton.on('click', removeCalloffs)
        formSubmitBtn.on('click', confirmationPopup)
    
        // Handle display of the calloff popup and belonging event listeners
        
        function displayPopup(callOffID, edit = false) {
            let popupContainer = $(callOffID)
            let popup = popupContainer.find('.calloff-popup')
            let incoterm = popupContainer.find('.incoterm-selection input:checked')
            if (!edit){
                incoterm.trigger('change')
            }
            let closePopup = popup.find('.close-calloff-popup')
            closePopup.off()
            let nextButton = popup.find('.next-button')
            nextButton.off()
            let callOffContainers = popup.find('.calloff-content.form-container')
            const paginatorItems = popup.find('.paginator-page')
            const backButton = popup.find('.back-button')
            backButton.off()
            const cancelButton = popup.find('.cancel-button')
        
            cancelButton.off()
            closePopup.on('click', function() {  
                if (edit) {
                    fillOldData(popupContainer)
                    
                } else {
                    popupContainer.remove()
                }
            })
            cancelButton.on('click', function() {
                if (edit) {

                    fillOldData(popupContainer)
                   
                } else {
                    popupContainer.remove()
                }
            })
            nextButton.on('click', function(e){
                e.preventDefault();
               
                //add handler for  submit
                if (!popup.find('.page-2').hasClass('active')) {
                    $('.paginator-page.page-2').trigger('click')
                } else {
                    //validate inputs 
                    validateCallOff(e, callOffID)
                }
            })
            backButton.on('click', function() {
                $('.paginator-page.page-1').trigger('click')
            })
            paginatorItems.on('click', function() {
                paginatorItems.removeClass('active');
                $(this).addClass('active')
                callOffContainers.addClass('hidden')
                let selection = $(this).data('target')
                popup.find(selection).removeClass('hidden')
            })
        }
        //check when to enable/disable submit buttons
        function checkData(){
            if (Object.entries(formValues).length) {
                blockButton.children('button').attr('disabled', false)

            } else {
                blockButton.children().attr('disabled', true)
            }

        }
        function fillOldData(callOffPopup, copyFromID = null, duplicate = false) {
        
            let callOffID = `#${callOffPopup.attr('id')}`
            // let initFields = copyFromID ? false : true; 
            copyFromID = copyFromID ? copyFromID : callOffID
            callOffPopup.addClass('hidden')
            let keys = Object.keys(formValues[copyFromID])
            let materialValue
            keys.forEach(key => {
                let input = callOffPopup.find(`[data-name="${key}"]`)
                if (input.exists()){
                    if (formValues[copyFromID][key]=== null){
                        formValues[copyFromID][key] = ''
                    }
                    if (key == 'incoterm' ) {
                        input.find(`input[value="${formValues[copyFromID][key]}"]`).prop('checked', true).trigger('change')
                                               
                    } else if (key == 'tonnage' && formValues[copyFromID][key] ) {
                        if (formValues[copyFromID]['incoterm'] == 1){
                            input.find(`input[value="${formValues[copyFromID][key]}"]`).prop('checked', true).trigger('change')

                        }
                    } else if (key == 'lkw' && formValues[copyFromID][key]) {
                        if (formValues[copyFromID]['incoterm'] == 2){
                            input.find(`input[value="${formValues[copyFromID][key]}"]`).prop('checked', true).trigger('change')

                        }
                    }else if (key == 'material-category') {
                        input.val(formValues[copyFromID][key])  
                        input.trigger('change')
                    } else if (key == 'material' ){
                        materialValue = formValues[copyFromID]['material-category']
                        let value = formValues[copyFromID][key]
                        value = Object.keys(defaultMaterialCategories[materialValue]['material_numbers']).find(key => key == value)
                       

                        input.val(value)
                        input.trigger('change')
                        
                    } else if (key == 'vehicle_type'){
                        input.val(formValues[copyFromID][key])
                        input.trigger('change')

                    } else if (input.hasClass('formatted-quantity-input') || input.hasClass('formatted-quantity-unit-input')) {
                            const inputId = input.attr('id')
                            const acInstance = AutoNumeric.getAutoNumericElement(`${callOffID} #${inputId}`)
                            if(acInstance) {
                               let { unit } = input.data()
                                let inputOptions = input.hasClass('formatted-quantity-input') ? quantityInputOptions : quantityUnitInputOptions
                                let currentValue = AutoNumeric.unformat(formValues[copyFromID][key], {
                                    ...inputOptions,
                                    currencySymbol: `\u202f${unit}`,
                                })
                                acInstance.set(currentValue)
                               const { domElement } = acInstance
                                $(domElement).trigger('change')
                            }
              
                    } else if (key == 'delivery_date_start_date' || key == 'delivery_date_end_date' ) {
                        if (duplicate) {
                            input.val('')
                        } else {
                            input.val(formValues[copyFromID][key]) 
                        }
                    } else  if (key == 'continuous'){
                       if (formValues[copyFromID][key]){
                           input.prop('checked', 'checked')
                       }
                    } else {  
                        input.val(formValues[copyFromID][key])    
                    }   
                }   
            })
            window.updateInputsFloatingLabels()
        }

        function appendPopupEvents(popup) {
        //    let tonnageFlag = true
           let materialFlag = false
           let materialAlart, material;

            // Toggle inputs connected via "incoterm logic"
            popup.on('change', '.incoterm-selection input', function() {
  
                // const $this = $(this)
                // const selectedValue = $this.val()
                // const callOffContainer = $this.closest('.calloff-popup')
                // const deliveryModes = callOffContainer.find('.delivery-mode')
                // deliveryModes.addClass('hidden')
                // if (selectedValue == "1"){
                //     tonnageFlag = true
                //     callOffContainer.find('.tonnage-selection').removeClass('hidden')
                //     callOffContainer.find('.tonnage-selection input:checked').trigger('change')
                // } else {
                //     tonnageFlag = false
                //     callOffContainer.find('.lkw-selection').removeClass('hidden')
                //     callOffContainer.find('.lkw-selection input:checked').trigger('change')
                // }
            })
            popup.on('change', '.delivery-mode input', function() {
                // const $this = $(this)
                // const selectedValue = $this.val()
                // const callOffContainer = $this.closest('.calloff-popup')
                // const choiceInputs = callOffContainer.find('.choice').find('input')

                // choiceInputs.each((index, item) => {
                //     const fieldName = $(item).data('name')
                   
                //     if(fieldName == selectedValue) {
                //         $(item).parents('.choice').removeClass('hidden')
                //         $(item).data('validate', 'required')
                //          if ($(item).data('name') == "continuous"){
                //             $(item).prop('checked', true).trigger('change')
                          
                //          }
                //     } else {
                //         if (fieldName == 'daily_quantity' && tonnageFlag) {
                //             $(item).parents('.choice').removeClass('hidden')
                //             $(item).data('validate', 'required')
                //         } else if ( fieldName=='nr_vehicles' && selectedValue=='continuous') {
                //             $(item).parents('.choice').removeClass('hidden')
                //             $(item).data('validate', 'required')
                //         } else {
                //             $(item).parents('.choice').addClass('hidden')
                //             $(item).data('validate', '')
                //             // find previous value in autonumericInputs list and empty it 
                //             let ANInputs = [].concat.apply([], autoNumericInputs)
                //             let input = ANInputs.find((el)=> {
                //                 if ($(item).is($(el.domElement))){
                //                     return el
                //                 }
                //             })
                //             if (input) {
                //                 input.global.set('')
                //             }
                //             $(item).trigger('change')
                      
                //             callOffContainer.find('.continuous-input').prop('checked', false).trigger('change')
                //         }    
                //     }
                // })
            })
    
            //fil end date to match the start date
            popup.on('change', '[data-name="delivery_date_start_date"]', function() {
                let startDateValue = $(this).val()
                let endDate = popup.find('[data-name="delivery_date_end_date"]')

                    endDate.val(startDateValue).trigger('change')
                //disable start time before current time
                let startTime = popup.find('[data-name="delivery_date_start_time"]')
                let flatpickrInstance = startTime[0]._flatpickr
                if (startDateValue === todayDate) {
                    flatpickrInstance.clear()
                    let value = startTime.data('start-time') ? startTime.data('start-time'): currentTime
                    flatpickrInstance.set('minTime', value)
                    let times = value.split(':')
                    flatpickrInstance.set('defaultHour', times[0])
                    flatpickrInstance.set('defaultMinute',  times[1])
                  
                } else {
                    
                    flatpickrInstance.set('minTime', undefined)
                    flatpickrInstance.set('defaultHour', 6)
                    flatpickrInstance.set('defaultMinute', 0)
                    
                }
                window.updateInputsFloatingLabels()
            })
            // popup.on('change', '[data-name="delivery_date_end_date"]', function() {
            //     let endTime = popup.find('[data-name="delivery_date_end_time"]')
            //     let startTime = popup.find('[data-name="delivery_date_start_time"]')
            //     endTime.val(null) 
            //     startTime.val(null)
            // })

            popup.on('change', '[data-name="delivery_date_start_time"]', function() {
                let startDateValue = popup.find('[data-name="delivery_date_start_date"]').val()
                // let endDateValue = popup.find('[data-name="delivery_date_end_date"]').val()
                let endTimePicker = popup.find('[data-name="delivery_date_end_time"]')
                let endTimeInstance = endTimePicker[0]._flatpickr
                // if ( startDateValue) {
                     //&&(startDateValue == endDateValue) 
                    let startTimeValue
                    if (startDateValue == todayDate) {
                        startTimeValue = $(this).data('start-time') ? $(this).data('start-time') : currentTime
                    } else {
                        startTimeValue = $(this).val()
                    }
                    endTimeInstance.set('minTime', startTimeValue)
                    let times = startTimeValue.split(':')
                    endTimeInstance.set('defaultHour', times[0])
                    endTimeInstance.set('defaultMinute',  times[1])
                    endTimePicker.val(null)
                // }
                //  else {
                //     endTimeInstance.set('minTime', undefined)
                //     endTimeInstance.set('defaultHour', 6)
                //     endTimeInstance.set('defaultMinute', 0)

                // }
                window.updateInputsFloatingLabels()
            } )
            popup.on('change', '[data-name="delivery_date_end_time"]', function() {
                const input = $(this)
                // let startDateValue = popup.find('[data-name="delivery_date_start_date"]').val()
                // let endDateValue = popup.find('[data-name="delivery_date_end_date"]').val()
                let startValue = popup.find('[data-name="delivery_date_start_time"]').val()
                if (startValue) { //(startDateValue == endDateValue) && 
                   
                    this._flatpickr.set('minTime', startValue) 
                    let times = startValue.split(':')
                    this._flatpickr.set('defaultHour', times[0])
                    this._flatpickr.set('defaultMinute',  times[1])
                }
            })
            popup.on('change', '.material-category-select', function() {
                const selectedValue = $(this).val()  
                materialAlart = selectedValue    
                if (materialFlag) {
                    // if category selection was triggered by material select         
                    materialFlag = false
                } else {
                    // reinit material choices with the selected category
                    let materialSelect = popup.find('.material-select')
                    materialSelect.empty()
                    materialSelect.trigger('change')
                    materialSelect.select2('destroy')
                
                    let obj = defaultMaterialCategories[selectedValue]['material_numbers']
                    let keys = Object.keys(obj)
                    let data = keys.map((key)=>{
                        return { 'id':key, 'text': obj[key].material_name }
                    })
                
                    let selectOptions = {
                        containerCssClass: 'js-select-simple',
                        dropdownCssClass: 'js-select-simple',
                        placeholder: materialSelect.data('placeholder'),
                        dropdownParent: materialSelect.closest('.form-group'),
                        // minimumResultsForSearch: -1,
                        data: data
                    }

                    materialSelect.select2(selectOptions).trigger('change')
                }
            })

            // Handle preselecting of default vechile types
            popup.on('change', '.material-select', function() {
                
                const selectedValue = $(this).val()
                if(!onLoadFlag && selectedValue) {
                    material = selectedValue
                    let categoryKeys = Object.keys(defaultMaterialCategories)
                    let categoryValue 
                    categoryKeys.forEach((index) => {
                        let materials = Object.keys(defaultMaterialCategories[index]['material_numbers'])
                        materials.forEach(material => {
                            if (material == selectedValue){
                                categoryValue = index
                                }
                            })
                    })
                    // set category value upon material selection
                    if (popup.find('.material-category-select').val() == "") {
                        materialFlag = true
                        popup.find('.material-category-select').val(categoryValue).trigger('change')
                        materialAlart = categoryValue
                    }
                    // preselect a vehicle if material values are selected and preselection exists
                    if (materialAlart && material) {
                        const vechileTypeInput = popup.find('.vehicle-type-select')
                        let presetVehicles = defaultMaterialCategories[materialAlart]['material_numbers'][material]['truck_type']
                        let connectedValue = Object.keys(presetVehicles)[0]
                        // Preselect
                        if(connectedValue && vechileTypeInput.exists()) {
                            vechileTypeInput.val(connectedValue).trigger('change')
                        } else if (!connectedValue) {
                            vechileTypeInput.val('').trigger('change')
                        }
                    } 
                   
                    window.updateInputsFloatingLabels()
                }
            })
            popup.on('change', '.hourly-quantity-input', function(){
                checkValues(true)
               
            })
            popup.on('change', '[data-name="daily_quantity"]', function() {
                checkValues(false)
            })
            function checkValues(hourlyFlag) {
                let hourlyValue = popup.find('.hourly-quantity-input')
                if (!hourlyValue.parents('choice').hasClass('hidden')){

                    let dailyValue = popup.find('[data-name="daily_quantity"]')
                    let ANInputs = [].concat.apply([], autoNumericInputs)
                    let daily, hourly;
                    ANInputs.forEach(autonumericInput => {
                        const { domElement } = autonumericInput
                        // Find input which needs to be cleared
                        if (hourlyValue.is(domElement)) {
                            hourly = autonumericInput
                        }
                        if (dailyValue.is(domElement)) {
                            daily = autonumericInput
                        }
                    })
                    if ((parseInt(daily.rawValue) < parseInt(hourly.rawValue)) && daily.rawValue !=="" && hourly.rawValue !== "" ) {
                        if (hourlyFlag){
                            hourly.global.set('')
                            hourlyValue.trigger('change')
                        } else {
                            daily.global.set('')
                            dailyValue.trigger('change')
                        }   
                    }
                }
               
            }
        }

        // Add new calloff form to collection
        // Returns selector of appended div, so we can use it after
        function appendEmptyCalloff(historyDuplicate = false) {
            
            // pageContainer.append()
            let collectionContent = formPrototype.replace(/__name__/g, materialCounter)
            pageContainer.append(collectionContent)
            
            const callOffID = `#calloff-single-${materialCounter}`
            const newCalloff = pageContainer.find(callOffID)
            appendPopupEvents(newCalloff)

            if (!historyDuplicate){
                displayPopup(callOffID)
                newCalloff.find('.incoterm-selection input').first().attr('checked', 'checked').trigger('change')
                newCalloff.removeClass('hidden')
            } 
            return newCalloff
        }
           
        // remove callof
        function removeCallOff(callOffRow, callOffID){
            delete formValues[callOffID]
            callOffRow.remove()
            checkData()
        }

        // Duplicate calloff from the list
        function duplicateCallOff( callOffID) {
            let popup = appendEmptyCalloff()
            initCustomFields(popup)
            let newID = `#${popup.attr('id')}`
            fillOldData(popup, callOffID, true)
            skipStep(newID)
            popup.removeClass('hidden')    
            materialCounter++
        }

        // duplicate callofs from calloff history 
        function duplicateCallOffHistory(data) {
            let popup = appendEmptyCalloff()
            initCustomFields(popup)
            let newID = popup.attr('id');
            data.material = data.material*1 
            formValues[`#${newID}`] = {...data}
            
            // appendPopupEvents(popup)
            fillRadios(`#${newID}`) 
            fillOldData(popup)            
            let $form = $(`#${newID}`).closest('form')
            const formFields = $form.serializeArray()
            let viewFields = displayFields(formFields, `#${newID}`)
            generateTableRow( viewFields, `#${newID}`)
            checkData()
            return popup   
        }
        //set radio buttons value upon data from fetchDuplicatesRoute
        function fillRadios(callOffID) {
            if (formValues[callOffID]['incoterm'] == 1){
                if (formValues[callOffID]['hourly_quantity'] && formValues[callOffID]['hourly_quantity'] !== null){
                    formValues[callOffID]['tonnage'] = 'hourly_quantity'
                } else {
                    formValues[callOffID]['tonnage'] = 'rounds'
                } 

            } else {
                if (formValues[callOffID]['rounds'] && formValues[callOffID]['rounds'] !== null){
                    formValues[callOffID]['lkw'] = 'rounds'
                } else {
                    formValues[callOffID]['lkw'] ='continuous'
                }
            }

        }
        // Init of calloffs customized inputs
        function initCustomFields (newCalloff) {
            const callOffID = `#${newCalloff.attr('id')}`
            // Select inputs init 
            let selectInputs = newCalloff.find('.js-select')

            selectInputs.each((index, input) => {
                const $input = $(input)

                let selectOptions = {
                    //allowClear: true,
                    containerCssClass: 'js-select-simple',
                    dropdownCssClass: 'js-select-simple',
                    placeholder: $input.data('placeholder'),
                    dropdownParent: $input.closest('.form-group'),
                    minimumResultsForSearch: -1,
                    
                }
                if ($input.hasClass('material-select')){
                    selectOptions = {
                        ...selectOptions,
                        escapeMarkup: (markup) =>{
                            if ( markup.search(/(\\\")/g) > 0){
                                markup = markup.replace(/\\\"/g, "\"")
                            }
                            return markup
                        },
                        minimumResultsForSearch: -1,
                    }
                }
                if ($input.hasClass('vehicle-type-select')){
                    const showMore = new Option(window.showMoreText, null, false, false)
                    $(showMore).addClass('toggle-vehicles', window.showMore)
                    $input.append(showMore).trigger('change')
                    selectOptions = {
                        ...selectOptions,
                        escapeMarkup: (markup) => markup,
                        templateResult: function(item, container) {
                            let $option = $(item.element)
                            let $container = $(container)

                            if (!$option.data('is-initial-option') && !$option.hasClass('toggle-vehicles')){
                                $container.addClass('hidden togglable-option')
                            } else {
                                $container.addClass('initial-option')
                            }
                            if ($option.hasClass('toggle-vehicles')){
                                $container.removeClass('hidden')
                                $container.addClass('toggle-vehicles')  
                                return `<a href="javascript:;" class="show-more"> ${ $option.text()} </a> 
                                <a href="javascript:;" class="show-less hidden"> ${window.showLessText} </a>`
                            }
                            
                            return $option.text()
                        }
              
                    }
                    $input.select2(selectOptions)
                    .on('select2:closing', function(e){
                       
                        if ($(e.target.selectedOptions).hasClass('toggle-vehicles')) {
                            e.preventDefault()
                            $input.val(null).trigger('change')
                            let optionsContainer =  $(e.target).siblings('.select2-container')
                            let options = optionsContainer.find('.togglable-option')
                            options.toggleClass('hidden')
                            optionsContainer.find('.toggle-vehicles a').toggleClass('hidden')
                        } 
                        
                    })
                } else {
                    $input.select2(selectOptions)
                }

            })

            datePickerInit(callOffID)            
            timePickerInit(callOffID)


            // Without units
            const quantityInputSelector = `${callOffID} .formatted-quantity-input`
            const quantityInput = new AutoNumeric.multiple(quantityInputSelector, quantityInputOptions)
           
            // With units
            const quantityUnitInputSelector = `${callOffID} .formatted-quantity-unit-input`
            const quantityUnitInput = new AutoNumeric.multiple(quantityUnitInputSelector, quantityUnitInputOptions)
           
            autoNumericInputs.push(quantityInput)
            autoNumericInputs.push(quantityUnitInput)

        }
        window.initCustomFields = initCustomFields 
        // Date picker init
        function datePickerInit(callOffID){
            let datePickerSelector = `${callOffID} .flatpickr-date-input`
            let datePickerOptions = $(datePickerSelector).data()
            let options = {
                dateFormat: 'd.m.Y',
                locale: datePickerOptions.locale,
                minDate: "today",
                maxDate: datePickerOptions.endDate,
                }
                return flatpickr(datePickerSelector, options)

           
        }
        // Time picker init
        function timePickerInit(callOffID) {
            let timePickerSelector = `${callOffID} .flatpickr-time-input`
            let timePickerOptions = $(timePickerSelector).data()
            let options = {
                time_24hr: true,
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
                defaultHour: 6,
                locale: timePickerOptions.locale,
                maxTime: timePickerOptions.endTime,
                onValueUpdate: (val) => {
                    $(timePickerSelector).closest('.form-group').removeClass('is-empty')
                   
                },
            }  
            return flatpickr(timePickerSelector, options)
        }
        

        /**
         * Popup form validation 
         */
        function validateCallOff(event, callOffID) {
            event.preventDefault()
            const $form = $(callOffID).closest('form')
           
            const validation = window.validateFormFields($form)
         
            if (validation && validation.isValid) {
               const formFields = $form.serializeArray()
               let viewFields = displayFields(formFields, callOffID)
               generateTableRow( viewFields, callOffID)
               checkData()
               
            }
        }
       // set data for preview
       //formValues
        function displayFields(formData, callOffID) {
            let displayFormData = {}
            formValues[`${callOffID}`]= {}
            let materialValue
            // let fields = [...formFields]
            formData.forEach((element)=> {
                const selector = `[name="${element.name}"]`
                let selection = $(callOffID).find(selector)
                let { name } = selection.data() ? selection.data() : ''
                let value = element.value;  
                if (!name) {
                    // incoterms data attr is set on the parent
                    let parentElement = selection.parent()
                    name = parentElement.data('name') 
                } 
                if (name =='tonnage'){
                    if (formValues[`${callOffID}`]['incoterm'] == 1) {
                        formValues[`${callOffID}`][name] = value
                    }
                } else if ( name == 'lkw') {
                    if (formValues[`${callOffID}`]['incoterm'] == 2) {
                        formValues[`${callOffID}`][name] = value
                    }
                } else {
                    formValues[`${callOffID}`][name] = value
                }
    
                if (name == 'incoterm') {
                    let label = $(`${callOffID} div[data-name="${name}"] input[value=${value}] + label`)
                    value = label.text()
                }

                if (name == 'material-category') {
                    materialValue = value 
                }
                if (name == 'material') {
                    value = defaultMaterialCategories[materialValue]['material_numbers'][value]['material_name']
                }
                if (name == 'vehicle_type'){
                    let vehicle = Object.keys(defaultVehicleTypes).find(key => defaultVehicleTypes[key] == value)
                    value = vehicle
                }    
                if (name == 'continuous' ){
                   
                    if (formValues[`${callOffID}`]['incoterm']== 1){
                        value = ''
                        formValues[`${callOffID}`]['continuous']= ''
                    }
                }      
                if (name == 'hourly_quantity' || name == 'daily_quantity'){
                    if (formValues[`${callOffID}`]['incoterm']== 2){
                        value = ''
                        formValues[`${callOffID}`]['hourly_quantity']= ''
                        formValues[`${callOffID}`]['daily_quantity']= ''

                    }
                }           
                displayFormData[name] = value 

            }) 
            return displayFormData
        }
       //append menu events for handling edit delete and duplicate on every created tablerow
        function appendMenuEvents(callOffRow) {
            
            let callOffID = `#${callOffRow.find('.calloff-popup-container').attr('id')}`
            callOffRow.on('click', '.calloff-edit', function() {
                $(callOffID).removeClass('hidden')
                displayPopup(callOffID, true)
            })
            callOffRow.on('click', '.duplicate-calloff', () => duplicateCallOff(callOffID))
            callOffRow.on('click', '.remove-calloff', ()=> removeCallOff(callOffRow, callOffID))

        }
       // generate a table row representing the calloff data and append it to the table 
       function generateTableRow(fields, callOffID, editing = false) {
        let rowID = `row-${callOffID.slice(1)}`
        let callOffPopup = $(callOffID)
        callOffPopup.detach()
        
            let markup = `   <tr class="clickable calloff-row ${editing ? 'editing': ''}" id="${rowID}">            
                    <td>${fields.material}</td>
                    <td>${fields.incoterm}</td>
                    <td>${fields.vehicle_type}</td>
                    <td>${fields.delivery_date_start_date}</td>
                    <td>${fields.delivery_date_start_time}</td>
                    <td>${fields.hourly_quantity ? fields.hourly_quantity : noDataText }</td>
                    <td>${fields.daily_quantity ? fields.daily_quantity : noDataText } </td>
                    <td>${fields.rounds ? fields.rounds : noDataText} </td>
                    <td>${fields.continuous ? continuous : uncontinuous }</td>
                    <td class="additional-info"> ${fields.additional_info} </td>
                    <td>
                    ${penMenu}                    
                    </td>
                </tr>`
            const tableBody = $('#calloff-creation-container')
            if ($(`#${rowID}`).length){
                $(`#${rowID}`).remove()
            } 
            tableBody.append(markup);
            let callOffRow = $(`#${rowID}`)
            callOffRow.children().last('td').append(callOffPopup)

            callOffPopup.addClass('hidden')        
            appendMenuEvents(callOffRow)
    }
        // Close success popup
        successPopup.on('click', '.popup-close', function() {
            const popup = $(this).closest('#success-popup')
            const container = $('#create-calloff-page .card')

            container.removeClass('is-Loading')
            popup.addClass('animated fadeOut faster')
            popup.removeClass('fadeIn')
            setTimeout(() => {
                popup.addClass('hidden')
                popup.removeClass('faster fadeOut animated')
            }, 500)
        })

        // Overwrite custom checkbox logic
        collectionContainer.on('change', '.form-group.checkbox .custom-checkbox', function(event) {
            // Just to ignore material dashboard logic
            event.stopPropagation()

            const $this = $(this)
            const parentFormGroup = $this.closest('.form-group.checkbox')

            if(this.checked) {
                parentFormGroup.removeClass('is-empty')
            } else {
                parentFormGroup.addClass('is-empty')
            }
        })

        /**
         * Fetch preloaded data or create empty calloff
         */
        async function onLoadAction () {
            if (window.fetchDuplicatesCallOffsRoute && window.fetchDuplicatesCallOffsRoute !== '') {
                try {
                    const requestURI = window.fetchDuplicatesCallOffsRoute

                    const response = await axios.get(requestURI, window.axiosConfig)
                    const { data } = response
                    // Create and set values
                    data.forEach(callOff => {
                        const emptyCallOff = duplicateCallOffHistory(callOff)
                        materialCounter++
                        window.updateInputsFloatingLabels()
                      
                    })
                    // Show them all!
                    formLoader.addClass('hidden')
                    submitFromHistoryFlag = true
                    // updateTitleIndexes()
                    collectionContainer.find('.hidden-on-load').removeClass('hidden').removeClass('hidden-on-load')
                } catch (err) {
                    formLoader.addClass('hidden')
                    window.showErrorPopup()
                    throw err
                }
            } else {
                formLoader.addClass('hidden')
            }

            onLoadFlag = false
            if (!submitFromHistoryFlag) {
                initCallOffCreation()

            }
        }

        /**
         * Form submit handler
         * @param {*} requestURI
         * @param {*} payload
         */
        async function submitCallOffsForm(requestURI, payload) {
            try {
                window.showLoaderPopup()
                formSubmitBtn.trigger('toggleState')

                const response = await axios.post(requestURI, payload, window.axiosConfig)
        
                removeCalloffs()
                window.closeLoaderPopup()

                // Show success popup
                const container = $('#create-calloff-page .card')
                container.addClass('is-Loading')
                successPopup.addClass('animated fadeIn faster').removeClass('hidden')
                formSubmitBtn.trigger('toggleState')
                if (submitFromHistoryFlag) {
                    let url = window.location.href
                    url = url.split('?')[0]
                    window.location.href = url
                }
            } catch(err) {
                window.closeLoaderPopup()
                window.showErrorPopup()
                formSubmitBtn.trigger('toggleState')
                throw err
            }
        }
        // trigger display of step-2 in the popup 
        function skipStep(callOffID){
            $(`${callOffID} .paginator-page.page-2`).trigger('click')
        }
    }

})
