import { initAjaxFilter } from 'Select2/filter'
import { fetchAndShowAnnouncement } from '../../../../../AppBundle/Resources/public/js/announcement/announcement'

$(document).ready(() => {
    if ($('#customer-dashboard-page').exists()) {
        const contractList = $('#contract-list')
        const companySelect = $('#customer-dashboard-company-filter')
        const supplierSelect = $('#supplier-choices')
        const contractTypeSelect = $('#dashboard-tabs-select')
        const hierarchyToggler = $('.toggle-amounts')
        const phoneTooltip = $('.has-today-delivery').data('tooltip')
        const clockTooltip = $('.has-today-calloff').data('tooltip')
        const qualityTooltip = $('.has-new-quality-document').data('tooltip')
        const searchLoader = $('.customer-dashboard-search #search-loader').html()

        let url = window.location.href
        let previousHorizontalScrollValue = 0


        // Logic for fetching the announcement data and showing it if necessary
        fetchAndShowAnnouncement()
        

        // Logic for company select dropdown
        if (companySelect.exists()) {
            companySelect.select2({
                minimumResultsForSearch: -1,
                dropdownParent: $('#customer-dashboard-filter')
            }).on('select2:select', function() {
                url = $(this).find(':selected').data('path')

                contractList.addClass('is-requesting')
                window.location.href = url

                // Remove cached page on company change
                localStorage.removeItem('dashboardPage')
            })
        }


        // Logic for supplier select dropdown
        if (supplierSelect.exists()) {
            supplierSelect.select2({
                minimumResultsForSearch: -1,
                dropdownParent: $('.dashboard-supplier-filter')
            }).on('select2:select', function() {
                url = window.updateUrlParameter(url, 'filter[vk_org]', $(this).val())
                url = window.updateUrlParameter(url, 'page', '')

                contractList.addClass('is-requesting')
                window.location.href = url
            })
            $('.dashboard-supplier-select-container').find('.select2-selection__arrow b').html('<i class="material-icons">expand_more</i>')
        }


        // Logic for contract type select dropdown
        if (contractTypeSelect.exists()) {
            contractTypeSelect.select2({
                minimumResultsForSearch: -1,
                dropdownParent: $('.customer-dashboard-select-container'),
                escapeMarkup: markup => markup,
                templateResult: styleOption,
                templateSelection: styleOption
            }).on('select2:select', function() {
                url = window.updateUrlParameter(url, 'tab', $(this).val())
                url = window.updateUrlParameter(url, 'page', '')

                contractList.addClass('is-requesting')
                window.location.href = url
            })

            function styleOption(item) {
                let markup = ''
                const { text } = item
                const icon = $(item.element).data('icon')
                const count = $(item.element).data('count')

                if (icon) {
                    markup = `<i class="material-icons">${icon}</i> ${text} <span class="contract-count">(${count})</span>`
                } else {
                    markup = `${text} <span class="contract-count">(${count})</span>`
                }
                return markup
            }
            $('.dashboard-contract-select-container').find('.select2-selection__arrow b').html('<i class="material-icons">expand_more</i>')
        }

        // Logic for toggling hierarchy bars visibility
        if (hierarchyToggler.exists()) {
            hierarchyToggler.on('click', () => {
                // this action also fires the scroll event so it had to be unbinded temporarily
                $(window).off('scroll', toggleHierarchy)

                $(this).toggleClass('hide-hierarchy')
                contractList.toggleClass('hierarchy-hidden')
                $('#header-hierarchy').toggleClass('hidden')

                setTimeout(() => {
                    $(window).on('scroll', toggleHierarchy)
                }, 100)
            })
        }

        // Logic for scroll to top button
        $('#scroll-to-top').on('click', function() {
            $('html').animate({ scrollTop: 0 }, 'slow')
        })

        // Logic for toggling hierarchy visibility based on scroll
        const animationStartHeight = $('#page-header').height()

        function toggleHierarchy() {
            // fire only if the scroll is vertical
            if (previousHorizontalScrollValue == $(window).scrollLeft()) {
                if ($(window).scrollTop() > animationStartHeight + 100) {
                    hierarchyToggler.addClass('hide-hierarchy')
                    contractList.addClass('hierarchy-hidden')
                    $('.customer-navigation').addClass('shrink')
                    $('#header-hierarchy').addClass('hidden')
                    $('#scroll-to-top').removeClass('hidden')
                } else if ($(window).scrollTop() < animationStartHeight - 50) {
                    $('.customer-navigation').removeClass('shrink')
                    $('#scroll-to-top').addClass('hidden')
                }
            }
            previousHorizontalScrollValue = $(window).scrollLeft()
        }
        $(window).on('scroll', toggleHierarchy)


        // Logic for expanding a single tile
        if ($('.contract-preview-toggler').exists()) {
            contractList.on('click', '.card .contract-preview-toggler', function() {
                const cardWrapper = $(this).closest('.contract-wrapper')
                const card = $(this).closest('.contract-item')

                cardWrapper.toggleClass('contract-expanded')
                card.toggleClass('contract-item-visible')
                initMasonry()

                if (cardWrapper.hasClass('contract-expanded')) {
                    card[0].scrollIntoView({ block: 'end' })
                } else {
                    card[0].scrollIntoView({ block: 'center' })
                }
            })
        }


        // Logic for expanding a single table row
        if ($('.toggle-material').exists()) {
            contractList.on('click', '.toggle-material', function() {
                const id = $(this).data('id')
                const dataRow = $(this).closest('tr')
                const connectedRow = $(`.contracts-table-container .material-row[data-id="${id}"]`)

                $(this).toggleClass('active')
                dataRow.toggleClass('expanded')
                connectedRow.toggleClass('hidden')
            })
        }


        // Logic for expanding all tiles/rows
        if ($('#expand-contracts').exists()) {
            $('#expand-contracts').on('click', function() {
                let expandIsActive = $('#expand-contracts').hasClass('expanded')

                if (!expandIsActive) {
                    // expand all rows
                    $('.toggle-material').each(function() {
                        const id = $(this).data('id')
                        const dataRow = $(this).closest('tr')
                        const connectedRow = $(`.contracts-table-container .material-row[data-id="${id}"]`)

                        $(this).addClass('active')
                        dataRow.addClass('expanded')
                        connectedRow.removeClass('hidden')
                    })

                    // expand all tiles
                    $('.card .contract-preview-toggler').each(function() {
                        const cardWrapper = $(this).closest('.contract-wrapper')
                        const card = $(this).closest('.contract-item')

                        cardWrapper.addClass('contract-expanded')
                        card.addClass('contract-item-visible')
                    })

                    expandIsActive = false
                    $('#expand-contracts').addClass('expanded')
                    initMasonry()
                } else {
                    // close all rows
                    $('.toggle-material').each(function() {
                        const id = $(this).data('id')
                        const dataRow = $(this).closest('tr')
                        const connectedRow = $(`.contracts-table-container .material-row[data-id="${id}"]`)

                        $(this).removeClass('active')
                        dataRow.removeClass('expanded')
                        connectedRow.addClass('hidden')
                    })

                    // close all tiles
                    $('.card .contract-preview-toggler').each(function() {
                        const cardWrapper = $(this).closest('.contract-wrapper')
                        const card = $(this).closest('.contract-item')

                        cardWrapper.removeClass('contract-expanded')
                        card.removeClass('contract-item-visible')
                    })

                    expandIsActive = true
                    $('#expand-contracts').removeClass('expanded')
                    initMasonry()
                }
            })
        }


        // Logic for changing view type
        if ($('.view-switch-button').exists()) {
            $('.view-switch-button').on('click', function() {
                const viewType = $(this).data('value')

                contractList.addClass('is-requesting')
                url = window.updateUrlParameter(url, 'view', viewType)
                window.location.href = url
            })
        }


        // Logic for initializing the library for grid behavior
        function initMasonry() {
            if (contractList.exists()) {
                contractList.masonry({
                    itemSelector: '.contract-wrapper'
                })
            }
        }
        initMasonry()


        // Logic for contract date tooltip
        const contractEndTooltip = $('.contract-item-date').data('contractEndTooltip')
        const exipredContractTooltip = $('.contract-item-date').data('exipredContractTooltip')

        if($('.contract-item-date').hasClass('date-tooltip')) {
            tippy('.date-tooltip', {
                content: contractEndTooltip
            })
        }

        if($('.contract-item-date').hasClass('expired-contract')) {
            tippy('.expired-contract', {
                content: exipredContractTooltip
            })
        }

        // Logic for initializing pagination
        function reinitPagination() {

            // had to be defined here because new one is used every time in the getContractListHtml()
            const pagination = $('#number-pagination')
            if (pagination.exists()) {
                pagination.twbsPagination('destroy')

                let { first, last, next, prev, startPage, totalPages, visiblePages } = pagination.data()

                pagination.twbsPagination({
                    first,
                    last,
                    next,
                    prev,
                    startPage,
                    totalPages,
                    visiblePages,
                    initiateStartPageClick: false,
                    onPageClick: async (event, page) => {
                        localStorage.setItem('dashboardPage', page)
                        const params = {
                            ...window.getUrlParams(url),
                            'page': page
                        }
                        contractList.addClass('is-requesting')

                        await getContractListHtml(params, false, true)
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                          })

                        // Change page number on BAG logo href, menu header navigation links
                        let homeLink = $('#page-header').find('.home-path')
                        let dashboardLink = $('.navigation-container').find('.nav-item a.active')
                        let currentUrl = homeLink.attr('href')
                        if(localStorage.getItem('dashboardPage')) {
                            const pageNumber = localStorage.getItem('dashboardPage')
            
                            currentUrl = window.updateUrlParameter(homeLink.attr('href'), 'page', pageNumber)
                        }
                        homeLink.attr('href', currentUrl)
                        dashboardLink.attr('href', currentUrl)
                        $('.apps-list .nav-link.dashboard-btn').attr('href', currentUrl)

                        contractList.removeClass('is-requesting')
                    }
                })
            }
        }
        reinitPagination()


        // Logic for initializing tooltips for icons and buttons
        function initIconTooltips() {
            tippy('.has-today-delivery', {
                content: phoneTooltip
            })
            tippy('.has-today-calloff', {
                content: clockTooltip
            })
            tippy('.has-new-quality-document', {
                content: qualityTooltip
            })
            tippy('.favorite-on', {
                content: window.favoriteOnTooltip,
            })
            tippy('.favorite-off', {
                content: window.favoriteOffTooltip,
            })
        }
        function initButtonTooltips() {
            tippy('.close-contract-button', {
                content: window.closeMessage,
                placement: 'top'
            })
            tippy('.reopen-contract-button', {
                content: window.reopenMessage,
                placement: 'top'
            })
            tippy('.closed-contract-button', {
                content: window.closedMessage,
                placement: 'top'
            })
            tippy('.request-reopen-contract-button', {
                content: window.waitingReactivationMessage,
                placement: 'top'
            })
        }
        initIconTooltips()
        initButtonTooltips()


        // Logic for toggling favorite status of contracts
        if ($('.favorite-button').exists()) {
            contractList.on('click', '.favorite-button', async function() {
                let { favorite, url} = $(this).data()
                const card = $(this).closest('.card')

                try {
                    url = window.updateUrlParameter(url, 'status', !favorite)
                    card.addClass('is-requesting')
                    contractList.addClass('is-requesting')

                    await axios.get(url, window.axiosConfig)

                    if ($(this).hasClass('favorite-off')) {
                        $(this).removeClass('favorite-off').addClass('favorite-on')
                        $(this).data('favorite', 1)
                        $(this)[0]._tippy.setContent(window.favoriteOnTooltip)
                    } else {
                        $(this).removeClass('favorite-on').addClass('favorite-off')
                        $(this).data('favorite', 0)
                        $(this)[0]._tippy.setContent(window.favoriteOffTooltip)
                    }
                    card.removeClass('is-requesting')
                    contractList.removeClass('is-requesting')

                } catch (err) {
                    card.removeClass('is-requesting')
                    contractList.removeClass('is-requesting')

                    window.showErrorPopup()
                }
            })
        }


        // Logic for opening the contract when clicking on it, except when clicking on buttons
        if ($('.contract-item').exists) {
            contractList.on('click', '.contract-item', function() {
                window.location.href = $(this).data('uri')
            }).on('click', '.contract-item .contract-preview-toggler', e => {
                e.stopPropagation()
            }).on('click', '.contract-item .favorite-button', e => {
                e.stopPropagation()
            }).on('click', '.contract-item .submit-button', e => {
                e.stopPropagation()
            })
        }


        // Logic for clicking the delete/reactivate contract button
        if ($('.submit-button').exists) {

            // 1st step
            contractList.on('click', '.submit-button', function() {
                $(this).addClass('checked')
            })

            // 2nd step cancel action
            contractList.on('click', '.submit-button .cancel', function(event) {
                event.stopPropagation()
                $(this).closest('.submit-button').removeClass('checked')
            })

            // 2nd step confirm action
            contractList.on('click', '.submit-button .submit', async function(event) {
                event.stopPropagation()

                const requestURI = $(this).data('url')
                const card = $(this).closest('.card')

                card.addClass('is-requesting')

                try {
                    await axios.get(requestURI, window.axiosConfig)
                    await getContractListHtml()

                    card.removeClass('is-requesting')

                } catch {
                    card.removeClass('is-requesting')
                    window.showErrorPopup()
                }
            })
        }


        // Logic for initializing vk_org filter on pricelist dashboard
        if($('#vkorg-choices').exists()) {
            const filter = $('#vkorg-choices')
            const filterParent = $('.dashboard-vkorg-select-container')
            initAjaxFilter(filter, filterParent, '', contractList, searchLoader)
        }


        // Logic for initializing factory filter on pricelist dashboard
        if($('#factory-choices').exists()) {
            const filter = $('#factory-choices')
            const filterParent = $('.dashboard-factory-select-container')
            initAjaxFilter(filter, filterParent, '', contractList, searchLoader)
        }


        // Logic for replacing page content without reload
        async function getContractListHtml(urlParams = {}, reload = false, pagination = false) {
            if (!urlParams.isEmpty) {
                Object.keys(urlParams).forEach(function (key) {
                    let value = urlParams[key]
                    url = window.updateUrlParameter(url, key, value)
                })
                if (!pagination) {
                    url = window.updateUrlParameter(url, 'page', '')
                }
                if (typeof window.history.pushState != 'undefined') {
                    window.history.pushState(null, null, url)
                }
                if (reload) {
                    window.location.href = url
                }
            }

            if (!reload) {
                try {
                    const response = await axios.get(url, window.axiosConfig)
                    const { data } = response

                    const accountBoxHtml = $(data).find('#logged-account-bar').html() || ''
                    const contractListHtml = $(data).find('#contract-list').html() || ''
                    const headerMaterialData = $(data).find('#header-hierarchy').html() || ''
                    const newPagination = $(data).find('.number-pagination-container').html() || ''

                    $('#logged-account-bar').html(accountBoxHtml)
                    $('#contract-list').html(contractListHtml)
                    $('#header-hierarchy').html(headerMaterialData)
                    $('.number-pagination-container').html(newPagination)

                    reinitPagination()
                    initButtonTooltips()
                    initIconTooltips()

                    // Update submit buttons logic
                    window.updateSubmitButtons()

                    // reset the expand-all button
                    $('#expand-contracts').removeClass('expanded')

                    contractList.masonry('destroy')
                    initMasonry()

                    return

                } catch (err) {
                    window.showErrorPopup()
                    contractList.removeClass('is-requesting')
                    throw err
                }
            }
        }
        window.getContractListHtml = getContractListHtml
    }

    //Accept new terms of use popup
    if($('#page-header').exists()) {
        const acceptedTermsOfUse = $('#page-header').data('acceptedTermsOfUse')
        const popupContent = $('#page-header').data('termsOfUsePage')
        const url = $('#page-header').data('path')

        if(!acceptedTermsOfUse) {
            const wrapper = document.createElement('div')

            wrapper.innerHTML = popupContent

            // Show warning popup
            swal({
                title: 'terms_of_use.agreement.title'.trans(),
                content: wrapper,
                buttons: {
                    submit: {
                        text: 'terms_of_use.agreement.confirm_button'.trans(),
                    },
                },
                className: 'terms-of-use-popup',
                closeOnEsc: false,
                closeOnClickOutside: false,
            }).then(async function(value){
                if(value === 'submit') {
                    try {
                        await axios.get(url, window.axiosConfig)
                    } catch (err) {
                        window.showErrorPopup()
                        throw err
                    }
                }
            })
        }
    }
})