$(document).ready(() => {

    // Show invite-User popup
    $('.open-add-users-popup--user').on('click', handleUserInvite)
    //$('body').on('click', '.open-add-users-popup--user', handleUserInvite)

    // Close invite-User popup
    const inviteUserPopup = $('.add-owner-popup')

    const closePopup = () => {
        inviteUserPopup.addClass("animated fadeOut faster")
        setTimeout(function(){
            inviteUserPopup.addClass("hidden fadeOut").removeClass("animated fadeIn faster")
        }, 500)
    }

    // Ajax request for inviting User
    async function handleUserInvite () {
        try {
            // Make request & set inner HTML
            const requestURI = $('.open-add-users-popup--user').data('url')
            const response = await axios.get(requestURI, window.axiosConfig)
            const { data } = response
            const popupContent = $(data)

            inviteUserPopup.find('.invite-owner-content').html(popupContent)
            handleDynamicContent()
            inviteUserPopup.removeClass('hidden fadeOut').addClass("animated fadeIn faster")
            // Init Select2
            // selectInit()
        } catch (error) {
            window.showErrorPopup()
            console.error(error)
            throw error
        }
    }
    $(document).on('click', '.back-to-login, .popup-exit', () => {
        closePopup()
    })
    /**
     * Function to create checkboxes
     */
    const handleDynamicContent = () => {
        const checkboxFormInput = $('.add-users__inputs .checkbox input')
        checkboxFormInput.after("<span class='checkbox-material'><span class='check'></span></span>")
    }

    // Logic for checkboxes to behave like radio buttons
    $(document).on('change', '.add-bag-user-check', function() {
        $('.add-bag-user-check').prop('checked', false)
        $(this).prop('checked', true)
    })

    $(document).on('click', '#invite_customer_user__submit', submitInvitation)
    async function submitInvitation(e) {
        e.preventDefault()
        let submitButton = $(this).parent('#bag-user-change-role')
        const $form = $(this).closest('form')
        const requestURI = $form.attr('action')
        const validation = window.validateFormFields($form)
        if(validation && validation.isValid) {
            const formData = new FormData($form[0])
            // const payload = $form[0]
            
            inviteUserPopup.addClass('hidden fadeOut').removeClass("animated fadeIn faster")
            window.showLoaderPopup()
            // formSubmitBtn.trigger('toggleState')

            await axios.post(requestURI, formData, window.axiosConfig)
                .then((res)=> {
                    window.closeLoaderPopup()
                    
                    swal({
                        className: 'calloff-status-modal', 
                        title: submitButton.data('title'),
                        text:  submitButton.data('content'),
                        buttons:  {
                            submit: {
                                text: submitButton.data('submit'),
                                closeModal: true,
                            }
                        }
        
                    })
                  
                   
                }).catch(err => {
                    // inviteOwnerPopup.addClass('hidden fadeOut').removeClass("animated fadeIn faster")
                    window.closeLoaderPopup()
                    window.showErrorPopup(err.response.data.message)
                    // formSubmitBtn.trigger('toggleState')
                    throw err
                })
        
        } 
    }
   
  
})