const { default: tippy } = require("tippy.js")

$(document).ready(() => {
    if($('#calloff-creation.material-step').exists()) {
        const stepOneContainer = $('.material-step')
        const loadingContainer = $('.calloff-creation-brick-container')
        const categoryButton = $('.category-select')
        const cartTable = $('.cart-step-one-table')

        let url = window.location.href
        let path = stepOneContainer.data('url')


        // Logic for changing material categories
        if (categoryButton.exists()) {
            stepOneContainer.on('click', '.category-select', async function() {

                loadingContainer.addClass('is-requesting')

                $('.category-select').removeClass('active')
                $(this).addClass('active')
                
                // had to convert to string because of the case value=0
                let params = {
                    'material_category': $(this).data('value').toString()
                }

                // get all filters and remove their selections
                $('.calloff-step-one-filter').each(function() {
                    params = {
                        ...params,
                        [$(this).data('filterName')]: ''
                    }
                })
                try {
                    await updateContractTableAndFilters(params)
                    flashFilters()
                    initStepOneTooltips()
                }
                catch {
                    window.showErrorPopup()
                }

                loadingContainer.removeClass('is-requesting')
            })
        }


        // Logic for initializing filters above the table
        function initFilters() {
            $('.calloff-step-one-filter').each(function() {
                const { filterName, placeholder, selection, noResults, hasSearch } = $(this).data()

                $(this).select2({
                    minimumResultsForSearch: hasSearch ? 1 : -1,
                    dropdownParent: $(this).closest('.calloff-step-one-filter-container'),
                    placeholder: placeholder.toString(),
                    language: {
                        noResults: () => noResults
                    },
                    templateSelection: (data, container) => styleSelection(data, container, selection, filterName),
                    templateResult: data => window.callOffCreation.styleResults(data, selection),
                    escapeMarkup: markup => markup
                }).on('select2:select', async function(e) {
                    let filterText = e.params.data.text
                    let filterValue = e.params.data.id
                    let params = {
                        [filterName]: $(this).val()
                        
                    }

                    // if its already selected remove it instead
                    if (selection == filterText.toString().trim() || selection == filterValue) {
                        params = {
                            [filterName]: ''
                        }
                    }

                    try {
                        loadingContainer.addClass('is-requesting')
                        await updateContractTableAndFilters(params)
                        initStepOneTooltips()
                    }
                    catch {
                        window.showErrorPopup()
                    }

                    loadingContainer.removeClass('is-requesting')
                })
            })
        }
        initFilters()


        // Logic for removing a material table active filter
        stepOneContainer.on('click', '.active-filter', async function() {
            $(this).closest('.calloff-step-one-filter-container').find('select').select2('close')

            const filterName = $(this).data('filterName')

            const params = {
                [filterName]: ''
            }

            try {
                loadingContainer.addClass('is-requesting')
                await updateContractTableAndFilters(params)
                initStepOneTooltips()
            }
            catch {
                window.showErrorPopup()
            }

            loadingContainer.removeClass('is-requesting')
        })


        // Logic for remove all active filters button
        if ($('.remove-all-filters').exists()) {
            stepOneContainer.on('click', '.remove-all-filters', async function() {
                let params = {}

                // get all filters and remove their selections
                $('.calloff-step-one-filter').each(function() {
                    params = {
                        ...params,
                        [$(this).data('filterName')]: ''
                    }
                })
                
                try {
                    loadingContainer.addClass('is-requesting')
                    await updateContractTableAndFilters(params)
                    flashFilters()
                    initStepOneTooltips()
                }
                catch {
                    window.showErrorPopup()
                }
                
                loadingContainer.removeClass('is-requesting')
            })
        }


        // Logic for table sorters
        stepOneContainer.on('click', '.table-sort', async function() {
            const { sortOrder, sortKey } = $(this).data()

            let params = {
                'order': sortOrder,
                'order_by': sortKey
            }

            try {
                await updateView(params)
            }
            catch {
                window.showErrorPopup()
            }
        })


        // Initialize pagination
        function initPagination() {
            if ($('#number-pagination').exists()) {
                let { first, last, prev, next, startPage, totalPages, pageSize, visiblePages } = $('#number-pagination').data()
                
                if (totalPages == 0) {
                    totalPages = 1
                }
    
                $('#number-pagination').twbsPagination({
                    first,
                    last,
                    prev,
                    next,
                    startPage,
                    totalPages,
                    visiblePages,
                    initiateStartPageClick: false,
                    onPageClick: (event, page) => {
                        url = window.updateUrlParameter(url, 'page', page)
    
                        loadingContainer.addClass('is-requesting')
                        
                        window.location.href = url
                    }
                })
            }
        }
        initPagination()


        // Logic for adding a contract item to cart below
        if ($('.cart-action').exists()) {
            stepOneContainer.on('click', '.add-to-cart svg', async function() {
                const tableRow = $(this).closest('tr')
                const path = $(this).closest('.cart-action').data('path')

                // clone the row that was clicked on
                const tableRowClone = tableRow.clone()

                // create the layout for the cloned row so it can take styles from its parents' classes
                let createdTable = $(`
                    <table class="table contract-table">
                        <tbody>
                            ${tableRowClone.html()}
                        </tbody>
                    </table>`)

                try {
                    // add styling to the created table and animate it
                    createdTable.offset({
                        top: tableRow[0].offsetTop + 10,
                        left: tableRow[0].offsetLeft + 10
                    })
                    .css({
                        'opacity': '0.8',
                        'position': 'absolute',
                        'width': '1400px'
                    })
                    .appendTo($('#calloff-creation'))
                    .animate({
                        top: cartTable[0].offsetTop,
                        left: cartTable[0].offsetLeft
                    }, 1000, function() {
                        // after the animation is done, delete the clone
                        createdTable.remove()
                    })

                    loadingContainer.addClass('is-requesting')

                    await axios.get(path)
                    await updateView({}, false, true)
                    
                    loadingContainer.removeClass('is-requesting')

                } catch {
                    loadingContainer.removeClass('is-requesting')
                    window.showErrorPopup()
                }
            })
        }


        // Logic for removing a cart item
        if ($('.cart-action').exists()) {
            stepOneContainer.on('click', '.remove-from-cart svg', async function() {
                const tableRow = $(this).closest('tr')
                const path = $(this).closest('.cart-action').data('path')
                const additionalTopOffset = 5

                window.callOffCreation.animateCalloffDeletion(tableRow, '', additionalTopOffset)
                loadingContainer.addClass('is-requesting')

                try {
                    await axios.get(path)
                    await updateView({}, false, true)
                } catch {
                    window.showErrorPopup()
                }

                loadingContainer.removeClass('is-requesting')
            })
        }


        // Helper function for flashing filters when changing material category or removing all filters
        function flashFilters() {
            setTimeout(() => {
                $('.material-step-filters-container .select2-selection').addClass('border-blue')
            }, 300)
            setTimeout(() => {
                $('.material-step-filters-container .select2-selection').removeClass('border-blue')
            }, 800)
        }


        // Helper function for styling the selections in filters
        function styleSelection(data, container, selection, filterName) {
            let markup = ''
            let { id, text } = data

            if (selection != '') {
                markup = `<div class="active-filter" data-filter-name="${filterName}">
                            <span class="active-filter-text">${text}</span>
                            <i class="material-icons">clear</i>
                        </div>`
            } else {
                markup = `${text}`
            }

            return markup
        }


        // Logic for replacing contract item table when using filters
        async function updateContractTableAndFilters(urlParams = {}) {
            Object.keys(urlParams).forEach(function(key) {
                path = window.updateUrlParameter(path, key, urlParams[key])
                url = window.updateUrlParameter(url, key, urlParams[key])
                url = window.updateUrlParameter(url, 'page', '')
            })

            if (typeof window.history.pushState != 'undefined') {
                window.history.pushState(null, null, url)
            }

            try {
                const response = await axios.get(path, window.axiosConfig)
                const { data } = response

                const contractItemsTableContainer = $(data).find('.contract-items-table-container').html() || ''
                const paginationContainer = $(data).find('.pagination-container').html() || ''

                $('.contract-items-table-container').html(contractItemsTableContainer)
                $('.pagination-container').html(paginationContainer)

                initFilters()
                initPagination()
            } catch {
                window.showErrorPopup()
            }
        }


        // Logic for replacing page content without reload
        async function updateView(urlParams = {}, reload = false, pagination = false) {
            if (!urlParams.isEmpty) {
                Object.keys(urlParams).forEach(function(key) {
                    url = window.updateUrlParameter(url, key, urlParams[key])
                })
                if (!pagination) {
                    url = window.updateUrlParameter(url, 'page', '')
                }
                if (typeof window.history.pushState != 'undefined') {
                    window.history.pushState(null, null, url)
                }
                if (reload) {
                    window.location.href = url
                }
            }
            if (!reload) {
                try {
                    const response = await axios.get(url, window.axiosConfig)
                    const { data } = response
                    
                    const categoriesContainer = $(data).find('.material-step-category-container').html() || ''
                    const filtersContainer = $(data).find('.material-step-filters-container').html() || ''
                    const calloffTable = $(data).find('.calloff-step-one-table').html() || ''
                    const cartTable = $(data).find('.cart-step-one-table').html() || ''
                    const stepsNavigation = $(data).find('.steps-navigation-container').html() || ''
                    const stepsTopNavigation = $(data).find('.calloff-steps-container').html() || ''
                    const cartItemsCounter = $(data).find('.cart-items-number-contanier').html() || ''
                    const pagination = $(data).find('.number-pagination-container').html() || ''

                    $('.material-step-category-container').html(categoriesContainer)
                    $('.material-step-filters-container').html(filtersContainer)
                    $('.calloff-step-one-table').html(calloffTable)
                    $('.cart-step-one-table').html(cartTable)
                    $('.number-pagination-container').html(pagination)
                    $('.steps-navigation-container').html(stepsNavigation)
                    $('.calloff-steps-container').html(stepsTopNavigation)
                    $('.cart-items-number-contanier').html(cartItemsCounter)

                    initFilters()
                    initPagination()
                    initStepOneTooltips()

                } catch {
                    window.showErrorPopup()
                }
            }
        }
        
        // Logic for initializing tooltips
        function initStepOneTooltips() {
            $('.material-name .has-tippy').each( function() {
                const materialDescriptionTooltip = $(this).data('materialDescription')
                const materialDescriptionMoreTooltip = $(this).data('materialDescriptionMore')
                const numberTooltip = $(this).data('number')
                const epTooltip = $(this).data('ep')
                const avvCodeTooltip = $(this).data('avvCode')
                const avvTooltip = $(this).data('avv')

                tippy(this, {
                    content: materialDescriptionTooltip + (materialDescriptionMoreTooltip ? '<br>'+ materialDescriptionMoreTooltip : '')
                    + (numberTooltip ? '<br>'+epTooltip+numberTooltip : '') 
                    + (avvCodeTooltip ? '<br>'+avvTooltip+avvCodeTooltip : ''),
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('td.factory .has-tippy').each( function() {
                const factoryNameTooltip = $(this).data('factoryName')
                const factoryStreetTooltip = $(this).data('factoryStreet')
                const factoryStreetNumberTooltip = $(this).data('factoryStreetNumber')
                const factoryZipTooltip = $(this).data('factoryZip')
                const factoryCityTooltip = $(this).data('factoryCity')

                tippy(this, {
                    content: factoryNameTooltip + "<br>" + factoryStreetTooltip + " " + factoryStreetNumberTooltip + "<br>" +
                    factoryZipTooltip + " " + factoryCityTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            const addToCartTooltip = $('.add-to-cart .has-tippy').data('tooltipTranslation')
            tippy('.add-to-cart .has-tippy', {
            content: addToCartTooltip,
            placement: 'bottom',
            theme: 'cockpit-tippy'
            })

            const removeFromCartTooltip = $('.remove-from-cart .has-tippy').data('tooltipTranslation')
            tippy('.remove-from-cart .has-tippy', {
                content: removeFromCartTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
        }
        initStepOneTooltips()
    }
})