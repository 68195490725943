$(function() {
    let $body = $('body')
    
    window.showMa = function(snippetId, ruleId) {
        $.ajax({
            url: '/ma/snippet?id=' + snippetId,
            context: document.body
        }).done(function(response) {
            handlePopupType(response, ruleId)
            trackPopupOpen(response)
        });
    }

    var trackPopupClose = function (data) {
        $.ajax({
            type: "POST",
            url: '/ma/ajax/track-closed-clicks/' + data.snippet_id,
            dataType: 'json'
        }).done(function (response) {

        }).fail(function (response) {

        })
    }


    // Logic for counting clicks on an element with certain ID
    async function countClicks(rule_id) {
        let url = '/ma/ajax/button-counter/increase'

        let formData = new FormData()
        formData.append('rule_id', rule_id)

        const response = await axios.post(url, formData)
        const { data } = response

        if (data.show_popup) {
            window.showMa(data.popup_data.snippet_id, rule_id)
        }
    }
    window.countClicks = countClicks


    $('.downloads-container .download').each(function (index, download) {
        $(this).on('click', function () {
            let href = $(this).find('a').attr('href')

            var hrefArray = href.split('/');

            let downloadId = hrefArray[hrefArray.length - 1]

            $.ajax({
                type: "POST",
                url: '/ma/ajax/track-opened-download-tags/' + downloadId,
                dataType: 'json'
            }).done(function (response) {

            }).fail(function (response) {

            })
        })
    })

    var trackPopupOpen = function (data) {
        $.ajax({
            type: "POST",
            url: '/ma/ajax/track-opened-popups/' + data.snippet_id,
            dataType: 'json'
        }).done(function (response) {

        }).fail(function (response) {

        })
    }

    var handlePopupType = function(data, ruleId) {
        let typeFunction = data.type
        if(typeof handleTypes[typeFunction] === 'function') {
            handleTypes[typeFunction](data, ruleId);
        } else {
            console.log('No function for type ' + data.type + '!')
        }
    }
 
    var setInputRadio = function(input) {
        let inputRadio = input.closest('.input-radio')
        let inputGroup = $('.input-radio input[name="' + input.attr('name') + '"]')

        if(input.is(':checked')) {
            inputGroup.each(function() {
                $(this).closest('.input-radio').removeClass('checked')
            })
            inputRadio.addClass('checked')
        } else {
            inputRadio.removeClass('checked')
        }
    }

    var fadeInOption = function(option) {
        let optionTargetsContainer = $('.option-targets-container')
        let optionTargets = $('.option-targets-container .option-targets')
        
        if(optionTargetsContainer.hasClass('fade-in')) {
            optionTargetsContainer.css('height', optionTargetsContainer.outerHeight())
            optionTargetsContainer.removeClass('fade-in')
            
            optionTargets.removeClass('visible')
            
            setTimeout(function() {
                option.addClass('visible')
            }, 300)
        
            setTimeout(function() {
                optionTargetsContainer.css('height', option.outerHeight())
            }, 600)
            
            setTimeout(function() {
                optionTargetsContainer.addClass('fade-in')
            }, 900)
        } else {
            option.addClass('visible')
            
            setTimeout(function() {
                optionTargetsContainer.css('height', option.outerHeight())
            }, 300)
            
            setTimeout(function() {
                optionTargetsContainer.addClass('fade-in')
            }, 600)
        }
    }

    var insertPopup = function (element) {
        // visualiser site
        if($('header > .container').length > 0) {
            $('header > .container').after(element)

            return
        }

        // 'regular' site
        if($(".nav-main").length > 0) {
            $(".nav-main").after(element)

            return
        }
    }

    var handleTypes = {
        'overlay-type' : function(data, ruleId) {
            let element = $(data.html)

            element.find('.target-close').on('click', function (e) {
                let $element = $(e.currentTarget)

                $element.closest('.overlay').remove()

                trackPopupClose(data)

                $body.removeClass('ma-open')
            })

            insertPopup(element)

            $('.option-source').on('click', function (e) {
                if(!$('.option-targets-container').hasClass('fade-in')) {
                    $(this).trigger('change')
                }
            })
            
            $('.option-source').on('change', function (e) {
                e.preventDefault()
                
                setInputRadio($(this))
                
                let $element = $(e.currentTarget)

                let targetElementClass = $element.attr('value')

                let optionTargets = $('.option-targets')
                
                $.each(optionTargets, function (index, optionTarget) {
                    let optionTargetElement = $(optionTarget)

                    if(optionTargetElement.hasClass(targetElementClass)) {
                        fadeInOption(optionTargetElement)
                    }
                })
            })

            $("[data-action-gids]").on('click', function (e) {
                e.preventDefault()

                let $element = $(e.currentTarget)

                let gids = $element.attr('data-action-gids')

                setCookie('gids', gids)

                window.location = $element.attr('href')
            })
        },
        'slide-in-type' : function(data, ruleId) {
            let element = $(data.html)
            
            if($(window).outerWidth() <= 700) {
                $body.addClass('ma-open')
            }

            element.find('.target-close').on('click', function (e) {
                let $element = $(e.currentTarget)
                
                element.removeClass('slide-in')
                
                setTimeout(function() {
                    $element.closest('.overlay').remove()
                }, 1000)

                trackPopupClose(data)
                
                $body.removeClass('ma-open')
            })

            insertPopup(element)
            
            setTimeout(function() {
                element.addClass('slide-in')
            }, 1000)  

            element.find('select').select2({
                minimumResultsForSearch: 10
            })       
        },
        'service-rating-type' : function(data, ruleId) {
            let element = $(data.html)

            element.find('.target-close').on('click', function (e) {
                let $element = $(e.currentTarget)

                $element.closest('.overlay').remove()

                trackPopupClose(data)

                $body.removeClass('ma-open')
            })

            element.find('.rating-element').on('click', function() {
                $('.submit-button-container').removeClass('submit-hidden')
                $('.rating-element').removeClass('rating-element-active')
                $(this).addClass('rating-element-active')

                if ($(this).hasClass('has-textarea')) {
                    $('.service-rating-textarea-container').removeClass('hidden')
                } else {
                    $('.service-rating-textarea-container').addClass('hidden')
                }
            })

            element.find('.service-rating-popup-submit').on('click', async function() {
                const ratingValue = $('.rating-element-active .rating-element-text').text().trim()

                $('.service-rating-popup').addClass('is-requesting')

                let url = '/ma/ajax/submit/reaction'
                url = window.updateUrlParameter(url, 'rule_id', ruleId)
                url = window.updateUrlParameter(url, 'reaction_choice', ratingValue)

                if (!$('.service-rating-textarea-container').hasClass('hidden')) {
                    const textArea = $('.service-rating-textarea-container textarea')
                    url = window.updateUrlParameter(url, textArea.data('param-to-send'), textArea.val())
                }

                try {
                    const response = await axios.get(url)
                    const { data } = response

                    const { titleSuccess, titleFail, buttonText } = $('.service-rating-submit-feedback-popup-data').data()

                    $(this).closest('.overlay').addClass('hidden')
                    $('.service-rating-popup').removeClass('is-requesting')
                    $body.removeClass('ma-open')
    
                    swal({
                        icon: data.status == 'success' ? 'success' : 'error',
                        className: 'service-rating-feedback-popup',
                        title: data.status == 'success' ? titleSuccess : titleFail,
                        text: data.message,
                        buttons: {
                            submit: {
                                text: buttonText,
                                value: true
                            }
                        }
                    })

                } catch {
                    $(this).closest('.overlay').remove()
                    $body.removeClass('ma-open')

                    window.showErrorPopup()
                }
            })

            // Logic for character counter on notes popup input
            element.find('.service-rating-textarea-container textarea').on('input', function() {
                $('.textarea-length-value').text(`${$(this).val().length}/${$(this).attr('maxlength')}`)
            })

            insertPopup(element)
        }
    }
});