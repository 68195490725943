import { initAjaxFilter, initTableAjaxFilter } from 'Select2/filter'

$(document).ready(() => {
    if ($('#bag-users-page').exists()) {

        // Open popup for bag user
        const bagUsersPage = $('#bag-users-page')
        const roleChangePopup = $('#bag-users-page .add-users-popup')
        const tableSearchLoader = $('#search-loader').html()
        const userList = $('#bag-users-list')
        let url = window.location.href

        bagUsersPage.on('click', '.open-add-users-popup', function () {
            const requestURI = $(this).data('url')
            $('#bag-users-page .add-users-popup .invite-company__success').addClass('hidden')
            $('#bag-users-page .add-users-popup .invite-company__loader').removeClass('hidden')
            $('#bag-users-page .add-users-popup .add-users').addClass('hidden')
            handleAjaxChange(requestURI)
        })

        bagUsersPage.on('click', '#role-change-icon', function () {
            const requestURI = $(this).data('url')
            $('#bag-users-page .add-users-popup .invite-company__success').addClass('hidden')
            $('#bag-users-page .add-users-popup .invite-company__loader').removeClass('hidden')
            $('#bag-users-page .add-users-popup .add-users').addClass('hidden')
            handleAjaxChange(requestURI)
        })

        bagUsersPage.on('click', '.back-to-login, .popup-exit', () => {
            $('.add-users-popup').addClass("animated fadeOut faster")
            setTimeout(function () {
                $('.add-users-popup').addClass("hidden fadeOut").removeClass("animated fadeIn faster")
            }, 500)
        })

        /**
         * Function to create checkboxes and select2 input field
         */
        const handleDynamicContent = () => {
            const searchInput = $('.add-users-popup #invite_bag_user_vk_orgs')
            const salesOfficeSelect = $('.add-users-popup #invite_bag_user_vk_offices')
            const salesOfficeInput = $('.add-users-popup .vk-office-input')

            let vkOrgIds = ''

            salesOfficeSelect.attr('data-validate', 'required')

            searchInput.select2({
                width: '',
                multiple: true,
                allowClear: false,
                placeholder: searchInput.data('placeholder'),
                minimumResultsForSearch: -1,
                dropdownParent: $('.add-users__inputs'),
                language: {
                    inputTooShort: () => searchInput.data('messageAddMoreText'),
                    noResults: () => searchInput.data('messageNoResults'),
                    maximumSelected: () => searchInput.data('messageMaxSelected'),
                },
            }).on('change', function() {
                //Show vk_office select only when one or more vk-orgs are chosen
                if($(this).val() == '') {
                    salesOfficeInput.addClass('hidden')
                } else {
                    salesOfficeInput.removeClass('hidden')
                    vkOrgIds = $(this).val().toString()
                }
                salesOfficeSelect.trigger("change")
            }).on('select2:unselect', function() {
                salesOfficeSelect.val('').trigger("change")
            }).on('select2:close', function () {
                setTimeout(function () {
                    $('.select2-container-active').removeClass('select2-container-active');
                    $(':focus').blur();
                }, 1);
            })

            let salesOfficeUrl = salesOfficeInput.data('url')

            //Vk_office select logic
            salesOfficeSelect.select2({
                width: '',
                multiple: true,
                allowClear: false,
                placeholder: salesOfficeSelect.data('placeholder'),
                minimumResultsForSearch: -1,
                dropdownParent: $('.add-users__inputs'),
                closeOnSelect: false,
                language: {
                    inputTooShort: () => searchInput.data('messageAddMoreText'),
                    noResults: () => searchInput.data('messageNoResults'),
                    maximumSelected: () => searchInput.data('messageMaxSelected'),
                },
                ajax: {
                    url: () => {
                        salesOfficeUrl = window.updateUrlParameter(salesOfficeUrl, 'vk_organisations', vkOrgIds)

                        return salesOfficeUrl;
                    },
                    dataType: 'json',
                    processResults: data => {
                        let options = {
                            results: data.map( key => ({
                                    text: key.key,
                                    id: key.value,
                                    value: key.value
                            }))
                        }

                        return options
                    }
                },
            }).on('select2:select', function() {
                salesOfficeInput.removeClass('has-error')
            }).on('select2:close', function() {
                setTimeout(function() {
                    $('.select2-container-active').removeClass('select2-container-active');
                    $(':focus').blur();
                }, 1);
            })

            salesOfficeSelect.trigger("change")
            searchInput.trigger("change")

            const checkboxFormInput = $('.add-users__inputs .checkbox input')
            checkboxFormInput.after("<span class='checkbox-material'><span class='check'></span></span>")
        }

        // Ajax request for change role
        async function handleAjaxChange(requestURI) {
            // Show popup
            roleChangePopup.removeClass("hidden fadeOut").addClass("animated fadeIn faster")

            try {
                // Make request & set inner HTML
                const response = await axios.get(requestURI, window.axiosConfig)
                const { data } = response

                roleChangePopup.html(data)
                handleDynamicContent()
            } catch (error) {
                window.showErrorPopup()
                console.error(error)
                throw error
            }
        }

        // On load check all bag user statuses
        const statusCheck = () => {
            let userStatus = bagUsersPage.find('.change-users-status')
            userStatus.map((i, el) => {
                const tr = $(el).parents('tr')
                if ($(el).is(':checked')) {
                    $(el).attr('data-status_id', 1)
                    tr.removeClass('disabled-user')
                } else {
                    $(el).attr('data-status_id', 0)
                    tr.addClass('disabled-user')
                }
            })
        }

        //Change bag user status
        bagUsersPage.on('click', '.change-bag-user-status', async function () {
            try {
                const $this = $(this)

                let {
                    url,
                    status_id,
                } = $this.data()

                if (status_id == 1) {
                    $this.data('status_id', 0)
                    $this.closest('tr').addClass('disabled-user')
                    status_id = 0
                } else {
                    $this.data('status_id', 1)
                    $this.closest('tr').removeClass('disabled-user')
                    status_id = 1
                }

                url = `${url}?status=${status_id}`

                let response = await axios.post(url, {}, window.axiosConfig)
                if (response.status == 200) {
                    $this.closest('.togglebutton').find('.success-icon').animate({ opacity: 1 }, 400, function () {
                        $(this).animate({ opacity: 0 }, 2000)
                    })
                }
            } catch (err) {
                window.showErrorPopup()
                console.error(err)
                throw err
            }

        })

        // Logic for checkboxes to behave like radio buttons
        bagUsersPage.on('change', '.add-bag-user-check', function () {
            $('.add-bag-user-check').prop('checked', false)
            $(this).prop('checked', true)
        })

        // Add material dashboard class to form input
        $('#invite_bag_user_vk_orgs').addClass('form-control')

        // Show vk_org input if sales role is checked
        bagUsersPage.on('click', '.add-bag-user-check', function () {
            const vkOrgInput = $(this).parents('.add-users__inputs').find('.vk-org-input')
            const salesRoles = $(this).parents('.add-users__inputs').data('salesId')
            let salesRoleIds = ''

            if (isNaN(salesRoles)) {
                salesRoleIds = salesRoles.split('|')
                if (salesRoleIds.includes($(this).val())) {
                    vkOrgInput.removeClass('hidden')
                    vkOrgInput.find('#invite_bag_user_vk_orgs').attr('data-validate', 'required')
                } else {
                    vkOrgInput.addClass('hidden')
                    vkOrgInput.find('#invite_bag_user_vk_orgs').attr('data-validate', '')
                }
            } else {
                salesRoleIds = salesRoles
                if (salesRoleIds == $(this).val()) {
                    vkOrgInput.removeClass('hidden')
                    vkOrgInput.find('#invite_bag_user_vk_orgs').attr('data-validate', 'required')
                } else {
                    vkOrgInput.addClass('hidden')
                    vkOrgInput.find('#invite_bag_user_vk_orgs').attr('data-validate', '')
                }
            }
        })

        // Form validation
        function validateForm() {
            const $form = $(this).closest('form')
            const validation = window.validateFormFields($form)

            if (validation && validation.isValid) {
                $('#bag-users-page #invite_bag_user__submit').attr('disabled', false)
            } else {
                $('#bag-users-page #invite_bag_user__submit').attr('disabled', true)
            }
        }

        bagUsersPage.on('change keyup', '.form-group input, #invite_bag_user_vk_orgs', validateForm)
        bagUsersPage.on('change keyup', '.form-group input, #invite_bag_user_vk_offices', validateForm)

        // Form submit for adding bag user
        let formContainer = $('#bag-users-page')
        formContainer.on('click', '#invite_bag_user__submit', function (event) {
            event.stopPropagation()
            event.preventDefault()

            const $form = $(this).closest('form')
            const requestURI = $(this).closest('form').attr('action')
            const validation = window.validateFormFields($form)

            if (validation && validation.isValid) {
                // Create "plain" formData
                const formData = window.createFromData($form)

                submitBagUserRoleChange(requestURI, formData)
            }
        })

        // Filter bag users
        $('#bag-users-filter').on('change', () => {
            const status = $('#bag-users-filter').val()
            try {
                userList.addClass('is-requesting')
                let requestURI = window.updateUrlParameter(window.location.href, 'status', status)
                window.location.href = requestURI
            } catch (err) {
                window.showErrorPopup()
                userList.removeClass('is-requesting')
                console.error(err)
                throw err
            }
        })

        if ($('.dashboard-user-vkorg-filter').exists()) {
            const filter = $('.user-vkorg-filter-select')
            const filterParent = $('.dashboard-status-form--user-vkorg-filter')
            initAjaxFilter(filter, filterParent, '', userList, tableSearchLoader)
        }

        if ($('.dashboard-role-filter').exists()) {
            const filter = $('.user-role-filter-select')
            const filterParent = $('.dashboard-status-form--user-role-filter')
            initAjaxFilter(filter, filterParent, '', userList, tableSearchLoader)
        }

        if ($('.bag-users-content').exists()) {
            const tableFilters = $('.bag-users-content .header-filter')
            tableFilters.each(function () {
                const filter = $(this)
                const filterParent = filter.parent()
                initTableAjaxFilter(filter, filterParent, userList, tableSearchLoader)
            })

            $('.table-sort').on('click', function () {
                userList.addClass('is-requesting')
                const { sortKey, sortOrder } = $(this).data()
                url = window.updateUrlParameter(url, 'sort_key', sortKey)
                url = window.updateUrlParameter(url, 'sort_order', sortOrder)
                window.location.href = url
            })
        }

        /**
         * Make submit request via ajax call
         */
        async function submitBagUserRoleChange(requestURI, payload) {
            try {
                $('.add-users-popup .invite-company__loader').removeClass('hidden')
                $('.add-users-popup .add-users').addClass('hidden')

                const response = await axios.post(requestURI, payload, window.axiosConfig)
                const { data } = response

                if (data.status == 'success') {
                    // Popup actions
                    $('.add-users-popup .invite-company__success').removeClass('hidden')
                    $('.add-users-popup .invite-company__loader').addClass('hidden')
                    $('.add-users-popup .add-users').addClass('hidden')

                    const successMessage = data.message
                    const messagePlaceholder = bagUsersPage.find('.success-message')

                    messagePlaceholder.html(successMessage)

                } else {
                    roleChangePopup.html(data)
                    handleDynamicContent()
                }

            } catch (err) {
                window.showErrorPopup()
                console.error(err)
                throw err
            }
        }

        // Remove active filters on click
        if ($('.active-filter').exists()) {
            bagUsersPage.on('click', '.active-filter', function(){
                let filterKey = $(this).find('.remove-active-filter').data('filter-key')

                if (filterKey == 'delivery_date' ) {
                    url = window.updateUrlParameter(url, 'filter[delivery_from_date]', '')
                    url = window.updateUrlParameter(url, 'filter[delivery_to_date]', '')
                } else {
                    url = window.updateUrlParameter(url, filterKey, '')
                }

                $(this).closest('.active-filters').remove()
                userList.addClass('is-requesting')

                window.location.href = url
            })
        }
    }
})