$(document).ready(() => {
    if ($('.calloff-customize-table-container').exists()) {
        const tableContainer = $('.calloff-table-container')
        const table = $('.calloff-overview-table')
        const popup = $('.customize-table-popup-container')
        const headerCheckbox = $('.customize-table-popup-content').find('input[type=checkbox]')
        const saveBtn = popup.find('.customize-table-popup-save')
        const layoutBtn = popup.find('.change-overview-layout-btn')
        const resetBtn = popup.find('.customize-table-popup-reset')


        // Logic for custom headers - checboxes for removing or adding headers and updating excel export url
        headerCheckbox.on('click', function() {
            const headerId = $(this).attr('id')

            // getting the selected headers from excel export link
            let excelExportUrl = $('.excel-export-container a').attr('href')

            if($(this).is(':checked') == false) {
                table.find(`.${headerId}`).addClass('hidden')
                excelExportUrl = excelExportUrl.replace(headerId, "")
            } else {
                table.find(`.${headerId}`).removeClass('hidden')
                excelExportUrl = excelExportUrl.concat(','+headerId)
            }
            const updatedExcelExportUrl = excelExportUrl.replace(/,+/g,',')

            $('.excel-export-container a').attr('href', updatedExcelExportUrl)
        })


        // Logic for reseting table layout to default 
        resetBtn.on('click', async function() {
            const path = $(this).data('path')

            try {
                tableContainer.addClass('is-requesting')

                const response = await axios.get(path)
                const { data } = response.data

                const headers = data.headers
                const keys = Object.keys(headers)

                keys.forEach(key => {
                    if(headers[key].is_selected) {
                        table.find(`.${key}`).removeClass('hidden')
                    } else if(headers[key].is_hidden) {
                        table.find(`.${key}`).addClass('hidden')
                    }
                })
                location.reload()
            } catch {
                tableContainer.removeClass('is-requesting')
                window.showErrorPopup()
            }
        })


        // Logic for saving custom table to user
        saveBtn.on('click', async function() {
            const { path, layoutId} = $(this).data()
            
            const formData = new FormData()
            formData.append('selected_layout', layoutId)

            headerCheckbox.each(function() {
                if($(this).is(':checked')) {
                    formData.append('selected_fields[]', $(this).attr('id'))
                }
            })

            try {
                tableContainer.addClass('is-requesting')

                await axios.post(path, formData)

                $(this).closest(popup).addClass('closed')
            } catch {
                tableContainer.removeClass('is-requesting')
                window.showErrorPopup()
            }
            
            tableContainer.removeClass('is-requesting')
        })


        // Logic for changing overview layouts
        layoutBtn.on('click', async function() {
            let url = window.location.href
            let href = $(this).closest('a').attr('href')

            const activeStatuses = window.getUrlParams(url)['status']

            if (activeStatuses) {
                activeStatuses.map(status => {
                    href = window.updateUrlParameterMulti(href, 'status', status)
                })
            }

            // quick fix for table layouts not remembering the selected statuses on change
            $(this).closest('a').attr('href', href)
            tableContainer.addClass('is-requesting')
        })
    }
})