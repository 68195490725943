import { fetchAndShowAnnouncement } from '../../../../AppBundle/Resources/public/js/announcement/announcement'

$(document).ready(function() {
    if($('#login-page').exists()) {
        // Get "onload" data-logic
        let autoNumericInputs = []
        const {
            email,
            registerPopupOpen,
            passwordForgotPopupOpen,
            registerInitialToken,
            initialTokenPopupMessage,
            
        } = window

        const loginForm = $('#login-page #login-form')
      
        // Get loader inline-SVG content
        const SVGLoader = $('.login-page__popup').html()

        const popupWrapper = $('.login-page-container .login-page__popup'),
              popupWrapperMain = $('.login-page-container .login-popup-container'),
              passwordForgotButton = $('.login-page #password-forgot'),
              inviteButton = $('.login-page .login-page__invitation'),
              noInviteButton = $('.login-page .login-page__noinvitation')


        // Logic for fetching the announcement data and showing it if necessary
        fetchAndShowAnnouncement()
        
        
        /**
         * Login logic
        */
       loginForm.on('submit', function(event) {
            event.preventDefault()

            const $form = $(this)
            const submitButton = $form.find('.submit-button')

            // Already requesting
            if (submitButton.hasClass('is-requesting')) {
                return
            }

            // Get Request URI & formData
            const requestURI = $form.attr('action')
            const payload = window.createFromData($form)

            submitLoginForm(requestURI, payload)
        })

        /**
         * Popup triggers
         */
        passwordForgotButton.on('click', function() {
            const requestURI = $(this).data('url')
            handleAjaxPopup(requestURI, 'password-forgot')
        })

        inviteButton.on('click',  function() {
            const requestURI = $(this).data('url')
            handleAjaxPopup(requestURI)
        })

        noInviteButton.on('click',  function() {
            const requestURI = $(this).data('url')
            handleAjaxPopup(requestURI)
        })

        // Onload trigger
        if (registerPopupOpen == 'true') {
            const requestURI = inviteButton.data('url')
            handleAjaxPopup(requestURI)
        }

        if (passwordForgotPopupOpen == 'true') {
            const requestURI = passwordForgotButton.data('url')
            handleAjaxPopup(requestURI)
        }

        // "Closer's"
        popupWrapper.on('click', '.login-exit', closePopup)
        popupWrapper.on('click', '.back-to-login', closePopup)

        /**
         * Submit actions
         */
        popupWrapper.on('click', '#password-forgot__submit', function(event) {
            event.stopPropagation()
            event.preventDefault()

            // Get form
            const $form = $(this).closest('form')

            // Get Request URI & formData
            const requestURI = $form.attr('action')
            const payload = $form.serialize()

            // Show loader
            showPopupLoader()

            // Call handler
            submitForgotPassword(requestURI, payload)
        })

        popupWrapper.on('click', '#register-form_register-submit', function(event) {
            event.stopPropagation()
            event.preventDefault()
            const $form = $(this).closest('form')

            // Get Request URI & formData
            const requestURI = $form.attr('action')
            const formData = new FormData()
            const formFields = $form.serializeArray()
            formFields.forEach(field => {
                let value = field.value
                const fieldName = field.name

                // Fetch unformatted values
                const input = $form.find(`[name="${field.name}"]`)
                let ANInputs = [].concat.apply([], autoNumericInputs)
                ANInputs.forEach(autonumericInput => {
                    const { domElement } = autonumericInput
                    // Find input which needs to be unformatted
                    if (input.is(domElement)) {
                        value = autonumericInput.rawValue
                    }
                })

                // Create payload
                formData.append(fieldName, value)
            })

            // Get form
         

            // Show loader
            showPopupLoader()

            submitRegisterForm(requestURI, formData)
        })

        popupWrapper.on('click', '#request_invite__submit', function(event) {
            event.stopPropagation()
            event.preventDefault()

            // Get form
            const $form = $(this).closest('form')

            // Get Request URI & formData
            const requestURI = $form.attr('action')
            const payload = $form.serialize()

            // Show loader
            showPopupLoader()

            submitInviteForm(requestURI, payload)
        })

        $('#login-page').on('click', '.entry', function() {
            window.location.href = $(this).find('.entry-link').attr('href')
        })

        /**
         * Login form handler
         */
        async function submitLoginForm(requestURI, payload) {
            const submitButton = loginForm.find('.submit-button')

            submitButton.trigger('toggleState')

            try {
                const response = await axios.post(requestURI, payload, window.axiosConfig)

                if (response.status = 201) {
                    const { data } = response
                    window.location.href = data.link
                } else {
                    submitButton.trigger('toggleState')
                }
            } catch (err) {
                // Handle response on "proper way"
                const { response } = err

                // "Unprocessable Entity"
                if (response.status == 422) {
                    const { data: {
                        error
                    } } = response

                    handleLoginFormError(error)
                } else {
                    // Server error
                    window.showErrorPopup()
                    console.error(err)
                }

                submitButton.trigger('toggleState')
                throw err
            }
        }

        /**
         * Forgot password handler
         */
        async function submitForgotPassword (requestURI, payload) {
            try {
                const response = await axios.post(requestURI, payload, window.axiosConfig)
                const { data } = response
                popupWrapper.html(data)
            } catch(err) {
                closePopup()
                window.showErrorPopup()
                console.error(err)
                throw err
            }
        }

        /**
         * Submit register handler
         */
        const submitRegisterForm = async (requestURI, payload) => {
            try {
                const response = await axios.post(requestURI, payload, window.axiosConfig)

                if (response.status == 201) {
                    // Successfull
                    const { data } = response

                    // Show success popup content & update redirection url
                    if (data && data.link) {
                        setRegisterSuccessPopup(data.link)
                    }
                } else {
                    const { data } = response
                    popupWrapper.html(data)

                    // Tippy init for customer message popup
                    tippy('.login-page__popup #register-form_customerInitialToken', {
                        content: initialTokenPopupMessage,
                        followCursor: false
                    })

                    // Init select element
                    let companyPositionSelect = $('.login-page__popup #register-form_customerCompanyPosition')
                    companyPositionSelect.select2({
                        minimumResultsForSearch: -1,
                        dropdownParent: companyPositionSelect.closest('.form-group'),
                        placeholder: companyPositionSelect.data('positionPlaceholder')
                    })

                    // Update floating labels if values are set onload
                    window.updateInputsFloatingLabels()
                }
            } catch (err) {
                closePopup()
                window.showErrorPopup()
                console.error(err)
                throw err
            }

        }

        /**
         * Submit Invite handler
         */
        async function submitInviteForm(requestURI, payload) {
            try {
                const response = await axios.post(requestURI, payload, window.axiosConfig)
                const { data } = response
                popupWrapper.html(data)

                // Update floating labels if values are set onload
                window.updateInputsFloatingLabels()
            } catch(err) {
                closePopup()
                window.showErrorPopup()
                console.error(err)
                throw err
            }
        }

        /**
         * Main ajax handler
         * @param {*} requestURI
         */
        async function handleAjaxPopup (requestURI, additionalClass = '') {
            // Show popup
            popupWrapper.removeClass("hidden fadeOut").addClass(`animated fadeIn faster ${additionalClass}`)
            popupWrapperMain.removeClass("hidden")

            try {
                // Make request & set inner HTML
                const response = await axios.get(requestURI, window.axiosConfig)
                const { data } = response
                popupWrapper.html(data)
                if (popupWrapper.find('.login-register').exists()){
                    const countryInputs = $('.country-select')
                    
                    const phoneSelector = '.phone-field'
                    let ANOptions = {
                        unformatOnSubmit: true,
                        modifyValueOnWheel: false,
                        emptyInputBehavior: 'null',
                        currencySymbol: '(0)',
                        currencySymbolPlacement: AutoNumeric.options.currencySymbolPlacement.prefix,
                        decimalPlaces: 0,        
                        digitGroupSeparator: '',
                        minimumValue: 1
                       
                    }
                    const phoneAN = new AutoNumeric.multiple(phoneSelector, ANOptions)
                    autoNumericInputs.push(phoneAN)
                       // handle input clear since minimum value disables user input clear 
                    $(phoneSelector).on('keyup', function(e){
                        // check for backspace and delete keypress
                        if (e.keyCode == 8 || e.keyCode == 46) {
                            let input = $(this)
                            let ANInstance = AutoNumeric.getAutoNumericElement(this)
                            // check if input is empty or displaying only currency symbol 
                            if (!input.val() || input.val() == '(0)'){
                                ANInstance.update({minimumValue: 0})
                                ANInstance.clear()
                                ANInstance.update({minimumValue: 1})
                        
                            }
                            
                        }
                    })
                    countryInputs.each((index, select)=>{
                        let $select = $(select)
                        let selectOptions = {
                            dropdownParent: $select.closest('.form-group'),
                            minimumResultsForSearch: -1,
                            
            
                        }
                        $select.select2(selectOptions)
                    })
                }
                // Tippy init for customer message popup
                tippy('.login-page__popup #register-form_customerInitialToken', {
                    content: initialTokenPopupMessage,
                    followCursor: false
                })
                const showHidePassword =  $('.change-password-style')
                const passwords = popupWrapper.find('.login-register input[type=password]')

                passwords.on('keyup change', regexPassword)
                showHidePassword.on('click', passwordChanger)
                // Init select element
                let companyPositionSelect = $('.login-page__popup #register-form_customerCompanyPosition')
                companyPositionSelect.select2({
                    minimumResultsForSearch: -1,
                    dropdownParent: companyPositionSelect.closest('.form-group'),
                    placeholder: companyPositionSelect.data('positionPlaceholder')
                })

                // Set flags and data if user was invited
                if (registerPopupOpen == 'true') {
                    const intialToken = $('.login-page__popup #register-form_customerInitialToken')
                    const customerEmail = $('.login-page__popup #register-form_customerEmail')

                    if (registerInitialToken !== '') {
                        intialToken.val(registerInitialToken)
                        intialToken.prop('readonly', true)
                        intialToken.parent().removeClass('is-empty')
                    }

                    if (email !== '') {
                        customerEmail.prop('readonly', true)
                        customerEmail.val(email)
                        customerEmail.parent().removeClass('is-empty')
                    }
                }
            } catch (error) {
                popupWrapper.removeClass(additionalClass)
                closePopup()
                window.showErrorPopup()
                console.error(error)
                throw error
            }
        }
        

        /**
         * Login form error handler
         * @param {*} errMessage
         */
        function handleLoginFormError(errMessage) {
            // Clear old ones
            loginForm.find('.alert-danger').remove()

            const errorMarkup = `
                <div class="alert alert-danger">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <i class="material-icons">close</i>
                    </button>
                    <span>
                        ${errMessage}
                    </span>
                </div>
            `
            const formError = $(errorMarkup)
            formError.prependTo(loginForm).hide().fadeIn(320)
        }

        /**
         * Popup closing helpers
         */

        function showPopupLoader() {
            popupWrapper.children('.login-popup').replaceWith(SVGLoader)
        }

        function popupClosing() {
            popupWrapper.addClass('animated fadeOut faster')
            popupWrapper.removeClass('password-forgot')
            setTimeout(() => {
                popupWrapper.addClass('hidden')
                popupWrapperMain.addClass('hidden')
            }, 500)
        }

        function closePopup() {
            popupClosing()
            tippy.hideAll()
            setTimeout(showPopupLoader, 500)
        }

        function setRegisterSuccessPopup(redirectURI) {
            // Register Success content
            const registerSuccess = $('#login-page #register-success-popup-content')
           
            registerSuccess.find('#redirect-btn').attr('href', redirectURI)

            const registerSuccessContent = registerSuccess.html()
            popupWrapper.html(registerSuccessContent)

            if(redirectURI) {
                window.location = redirectURI
            }
        }
        //check if all the conditions are fulfilled 
        function regexPassword () {
            const passwordWarnings = $('.password-tips')
            const newPassword =  $('#register-form_customerPassword').val()
            const newPassword2 = $('#register-form_customerPasswordRepeat')
           
            if (newPassword.length >= 8 ){
                if (!passwordWarnings.find(`[data-type='symbols']`).hasClass('is-valid')){
                    passwordWarnings.find(`[data-type='symbols']`).addClass('is-valid')
    
                }
            } else {
                if (passwordWarnings.find(`[data-type='symbols']`).hasClass('is-valid')){
                    passwordWarnings.find(`[data-type='symbols']`).removeClass('is-valid')
    
                }
    
            }
             const regexObj = {
                number: /[0-9]/,
                uppercase: /[A-Z]/,
                lowercase: /[a-z]/,
                special_char: /[#!@$%^+'.,:;&§[{}*=`°_\-\]\?]/,
            }
            let keys = Object.keys(regexObj)
            keys.forEach((key)=> {
                if (newPassword.match(regexObj[key])){
                    if (!passwordWarnings.find(`[data-type='${key}']`).hasClass('is-valid')) {
                        passwordWarnings.find(`[data-type='${key}']`).addClass('is-valid')
                    }
                } else {
                    if (passwordWarnings.find(`[data-type='${key}']`).hasClass('is-valid')) {
                        passwordWarnings.find(`[data-type='${key}']`).removeClass('is-valid')
                    }
                }
    
            })
           
        }
        
        // Changing password input visibility
        function passwordChanger() {

            $(this).addClass('hidden')
            $(this).siblings('.change-password-style').removeClass('hidden')

            $(this).siblings('input').attr('type') == 'password' ? $(this).siblings('input').attr('type', 'text') : $(this).siblings('input').attr('type', 'password')
        } 
    }
})
