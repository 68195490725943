/**
 * Create calloff page
 */
$(document).ready(() => {
    if($('#contract-overview-page').exists()) {

        let dateKeys
        let monthSelect
        let filterRequestURI = decodeURIComponent(window.location.href)
        const contractOverviewContainer = $('.contract-overview-container')
        const calendarContainer = $('#contract-overview-page #calendar-container')
        const tableContainer = $('#contract-overview-page #calendar-table-container')
        
        
        function reinitDateFilter() {
            const dateFilter = tableContainer.find('.flatpickr-date-filter')
            if (dateFilter.exists()){
                let { filterValues, filterKey, activeMonth, activeYear, selected } = dateFilter.data()
                let preselectedDates = selected.split(',').map( x => x.split('-').join('.'))
                let keys = Object.keys(filterValues)
                let dateFilterInstance = flatpickr('.flatpickr-date-filter', {
                    dateFormat: 'd.m.Y',
                    defaultDate: preselectedDates,
                    locale: window.shortLocale,
                    enable: keys,
                    appendTo: dateFilter[0],
                    mode: 'multiple',
                    closeOnSelect: false,
                    onReady: (selectedDates, dateString, instance) => {
                        // Append title to calendar
                        const { calendarContainer } = instance
                        $(calendarContainer).append(`<button class="date-filter-submit">${window.translationConfirm}</button>`)
                    },
                    onValueUpdate: function(date, dateString) {
                        dateKeys = dateString
                    }
                   
                })
                if (!activeMonth && !activeYear) {
                    let lastDate = keys[0].split('.')
                    activeMonth = lastDate[1] 
                    activeYear = lastDate[2] 

                }
                dateFilterInstance.changeMonth(activeMonth - 1, false)
                dateFilterInstance.changeYear(activeYear)
            }
           
            
        }
        reinitDateFilter();

        $(document).on('click', '.date-filter-submit', function() {
            if (dateKeys) {
                let dateValues = []
                let dates = dateKeys.split(', ');
                let { filterValues, filterKey } = $('.flatpickr-date-filter').data()
                dateValues =  dates.map((date)=> filterValues[date])
                let stringValues = dateValues.join(',')
               
                filterRequestURI = window.updateUrlParameter(filterRequestURI, filterKey, stringValues)
                if (typeof window.history.pushState != 'undefined') {
                 window.history.pushState(null, null, filterRequestURI)
                }
                handleFilterRequest()
                location.reload();
            } 
           
        })

        initCalendar()
        // get vehicle and material types
        let defaultVehicleTypes = {}
        let defaultMaterialCategories = {}
    
        // Do it in try-catch block, since parsing can go soo wrong..
        try {
            if(window.defaultVehicleTypes) {
                defaultVehicleTypes = JSON.parse(window.defaultVehicleTypes)
            }
            if(window.defaultMaterialCategories) {
                defaultMaterialCategories = JSON.parse(window.defaultMaterialCategories)
            }
        } catch(err) {
            defaultVehicleTypes = {}
            defaultMaterialCategories = {}
        }

        // Expand row data on heading click action
        calendarContainer.on('click', '.toggle-expand', function() {
            const name = $(this).data('name')
            const tableMaterial = $(this).closest('tbody.materials')

            let expandedGroup = name
            if (tableMaterial.hasClass('expanded')) {
                expandedGroup = ''
                tableMaterial.removeClass('expanded')
            } else {
                tableMaterial.addClass('expanded')
            }

            // Update URI's
            filterRequestURI = window.updateUrlParameter(filterRequestURI, 'expanded_group', expandedGroup)
            if (typeof window.history.pushState != 'undefined') {
                window.history.pushState(null, null, filterRequestURI)
            }

            calendarContainer.find('tbody.materials').not(tableMaterial).removeClass('expanded')
        })

        // Filter by selected filter from table
        tableContainer.on('click', '.table-filter', function() {
            const { filterValue, filterKey } = $(this).data()

            if(filterKey !== '' && filterValue !== '' ) {
                filterRequestURI = window.updateUrlParameter(filterRequestURI, filterKey, filterValue.toString())
                if (typeof window.history.pushState != 'undefined') {
                    window.history.pushState(null, null, filterRequestURI)
                }

                // Make request
                handleFilterRequest()
                location.reload()
            }
        })

        // Remove active filters
        tableContainer.on('click', '.remove-active-filter', function() {
            const { filterKey } = $(this).data()

            filterRequestURI = window.updateUrlParameter(filterRequestURI, filterKey, null)
            if (typeof window.history.pushState != 'undefined') {
                window.history.pushState(null, null, filterRequestURI)
            }

            // Make request
            handleFilterRequest()
        })

        // Sorting by table headers
        tableContainer.on('click', '.table-sort', function() {
            const { sortOrder, sortKey } = $(this).data()

            filterRequestURI = window.updateUrlParameter(filterRequestURI, 'sortOrder', sortOrder)
            filterRequestURI = window.updateUrlParameter(filterRequestURI, 'sortKey', sortKey)
            if (typeof window.history.pushState != 'undefined') {
                window.history.pushState(null, null, filterRequestURI)
            }

            // Make request
            handleFilterRequest()
            location.reload();
        })

        // Highlight table row on calendar item click
        calendarContainer.on('click', '.calloffs .calloff-single', function() {
            const id = $(this).data('calloffId')
            const connectedRowSelector = `.calendar-detail-table tr[data-calloff-id="${id}"]`

            const connectedRow = tableContainer.find(connectedRowSelector)

            connectedRow.addClass('highlight')
            setTimeout(() => {
                connectedRow.removeClass('highlight')
            }, 5000)
        })

        calendarContainer.on('click', '.toggle-table-grouped.stacked', function() {
            const calloffs = $(this).find('.calloff-single')

            calloffs.each(function() {
                const id = $(this).data('calloffId')
                const connectedRowSelector = `.calendar-detail-table tr[data-calloff-id="${id}"]`

                const connectedRow = tableContainer.find(connectedRowSelector)

                connectedRow.addClass('highlight')
                setTimeout(() => {
                    connectedRow.removeClass('highlight')
                }, 5000)
            })
        })

        // Remove calloff from table
        tableContainer.on('click', '.remove-calloff', function() {
            removeCallOff($(this))
        })
        //edit calloff from table
       


        // Change calloff status from table
        tableContainer.on('click', '.change-calloff-status', function() {
            composeConfirmationPopup($(this))
        })
        calendarContainer.on('click', '.change-calloff-status', function() {
            composeConfirmationPopup($(this))
        })
      

        /**
         * Show confirmation modal [status change]
         * @param $this [jQery selector of clicked link in dropdown]
         */
        function composeConfirmationPopup($this) {
            const { requestUri, submitBtn, cancelBtn, confirmationTitle, confirmationContent, confirmationSubmit} = $this.data()
            const popupContent = $this.find('.popup-content-holder').html()

            const wrapper = document.createElement('div')

            // Insert HTML
            wrapper.innerHTML = popupContent

            swal({
                className: 'change-calloff-popup',
                content: wrapper,
                buttons: {
                    submit: {
                        text: submitBtn,
                        closeModal: false,
                    },
                    cancel: cancelBtn,
                },
            })

            $('.change-calloff-popup').closest('.swal-overlay').addClass('no-overlay')

            $('.change-calloff-popup .swal-button--submit').on('click', () => {
                handleStatusChange(requestUri, confirmationTitle, confirmationContent, confirmationSubmit)
            })
        }
        //get popup form html
       
        function removeCallOff ($this) {

            const { requestUri, submitBtn, cancelBtn, confirmationTitle, confirmationContent, confirmationSubmit } = $this.data()
            let popupContent = $this.find('.popup-content-holder').html()

            const wrapper = document.createElement('div')

            // Insert HTML
            wrapper.innerHTML = popupContent

            swal({
                className: 'remove-calloff-popup',
                content: wrapper,
                buttons: {
                    submit: {
                        text: submitBtn,
                        closeModal: false,
                    },
                    cancel: cancelBtn,
                },
            })

            $('.remove-calloff-popup').closest('.swal-overlay').addClass('no-overlay')

            $('.remove-calloff-popup .swal-button--submit').on('click', () => {
                handleStatusChange(requestUri, confirmationTitle, confirmationContent, confirmationSubmit)
            })
        }

        /**
         * Init calendar custom component
         */
        function initCalendar() {
            if ($('#calendar-container').exists()){
                const monthPickerSelector = '#contract-overview-page #calendar-container #select-month'
                const monthPickerOptions = $(monthPickerSelector).data()
                const prevArrow = $('.calendar-table-heading .prev-month i')
                const nextArrow = $('.calendar-table-heading .next-month i')
    
                let minDate = monthPickerOptions.minDate.split('-')
                minDate = new Date(minDate[0], minDate[1])
    
                let maxDate = monthPickerOptions.maxDate.split('-')
                maxDate = new Date(maxDate[0], maxDate[1])
    
                const monthsValues = monthPickerOptions.monthValues
    
                monthSelect = flatpickr(monthPickerSelector, {
                    dateFormat: 'm-Y',
                    defaultDate: monthPickerOptions.currentMonth + '-' + monthPickerOptions.currentYear,
                    locale: monthPickerOptions.locale,
                    plugins: [
                        new monthSelectPlugin({
                            dateFormat: 'm-Y',
                            shorthand: false,
                            minDate: minDate,
                            maxDate: maxDate,
                            staticArrowPrev: prevArrow,
                            staticArrowNext: nextArrow,
                            monthsValues,
                            onSelect: (fp) => {
                                const { currentYear, currentMonth } = fp
                                fp.close()
                                const { filterOptions } = calendarContainer.data()
    
                                // Clear selected filters
                                if (filterOptions && Array.isArray(filterOptions) && filterOptions.length > 0) {
                                    filterOptions.forEach(option => {
                                        filterRequestURI = window.updateUrlParameter(filterRequestURI, `filter[${option}]`, null)
                                    })
                                }
    
                                // Update year & month
                                filterRequestURI = window.updateUrlParameter(filterRequestURI, 'y', currentYear)
                                filterRequestURI = window.updateUrlParameter(filterRequestURI, 'm', currentMonth + 1)
    
                                // Update header search
                                const headerSearch = $('body .header-search')
                                let apiRequestUri = headerSearch.data('message-api_route')
    
                                apiRequestUri = window.updateUrlParameter(apiRequestUri, 'activeMonth', currentMonth + 1)
                                apiRequestUri = window.updateUrlParameter(apiRequestUri, 'activeYear', currentYear)
    
                                headerSearch.data('message-api_route', apiRequestUri)

                                if($('body .header-search').length > 0) {
                                    window.reInitHeaderSearch()
                                }

                                if (typeof window.history.pushState != 'undefined') {
                                    window.history.pushState(null, null, filterRequestURI)
                                }
    
                                // Make request
                                handleFilterRequest()
                                location.reload()
                            }
                        })
                    ],
                })
            }
           
        }

        /**
         * Filter request handler
         */
        async function handleFilterRequest() {
            try {
                //close the date-picker filter
                let dpf = document.querySelector('.flatpickr-date-filter')
                if (dpf) {
                    dpf._flatpickr.close()
                }

                contractOverviewContainer.addClass('is-requesting')

                // Make request
                const response = await axios.get(filterRequestURI, window.axiosConfig)

                // Update whole view
                const { data } = response
                updateView(data)

                //contractOverviewContainer.removeClass('is-requesting')

            } catch(err) {
                contractOverviewContainer.removeClass('is-requesting')

                window.showErrorPopup()
                throw err
            }
        }

        /**
         * Change calloff status handler
         */
        async function handleStatusChange(requestURI, title = '', content='', submit= '') {
            try {
                // Close sweetalert popup
                $('.change-calloff-popup').closest('.swal-overlay').removeClass('no-overlay')
                swal.close()

                tableContainer.addClass('is-requesting')
                calendarContainer.addClass('is-requesting')

                // Make status change request
                await axios.post(requestURI, {}, window.axiosConfig)
                swal({
                    icon: 'success',
                    className: 'change-calloff-popup',
                    title: title,
                    text: content,
                    buttons: {
                        submit: {
                            text: submit,
                            value: true
                        },
                    },
                }).then(()=>{
                    const response = axios.get(filterRequestURI, window.axiosConfig)

                    // Update whole view
                    const { data } = response
                    updateView(data)
                })
                // Make request for now updated view
                const response = await axios.get(filterRequestURI, window.axiosConfig)

                // Update whole view
                const { data } = response
                updateView(data)

                tableContainer.removeClass('is-requesting')
                calendarContainer.removeClass('is-requesting')
            } catch (err) {
                tableContainer.removeClass('is-requesting')
                calendarContainer.removeClass('is-requesting')
                window.showErrorPopup()
                throw err
            }
        }

        /**
         * Update whole view helper function
         * @param {*} data
         */
        function updateView(data) {
            const calendarContent = $(data).find('#contract-overview-page #calendar-container').html()
            calendarContainer.html(calendarContent)

            const tableContent = $(data).find('#contract-overview-page #calendar-table-container').html()
            tableContainer.html(tableContent)
          
            const headerData = $(data).find('#contract-overview-page .users-page-search')
            $('.users-page-search').html(headerData)

            if($('body .header-search').length > 0) {
                window.reInitHeaderSearch()
            }
    
            // Remove loaders & reInit calendar select
            /*if (monthSelect!==undefined){
                monthSelect.destroy()

            }*/

            initCalendar()
            reinitDateFilter()
        }
    }
})
