$(document).ready(() => {
    if($('#calloff-creation.termin-step').exists()) {
        const stepThreeContainer = $('.termin-step')
        const loadingContainer = $('.calloff-creation-brick-container')

        let url = window.location.href


        // Logic for initializing table filters
        function initFilters() {
            $('.header-filter').each(function() {
                const { filterName, placeholder, selection, filterIcon, noResults } = $(this).data()

                $(this).select2({
                    minimumResultsForSearch: -1,
                    dropdownParent: $(this).closest('.filter-heading'),
                    language: {
                        noResults: () => noResults
                    },
                    templateSelection: () => {
                        return `${placeholder.toString()} <i class="material-icons">${filterIcon}</i>`
                    },
                    templateResult: data => window.callOffCreation.styleResults(data, selection),
                    escapeMarkup: markup => markup
                }).on('select2:opening', function() {
                    $(this).closest('.responsive-table-wrapper').addClass('filter-opened')
                }).on('select2:select', async function(e) {

                    let filterText = e.params.data.text
                    let filterValue = e.params.data.id

                    let params = {
                        [filterName]: $(this).val()
                    }

                    // if its already selected remove it instead
                    if (selection == filterText.toString().trim() || selection == filterValue) {
                        params = {
                            [filterName]: ''
                        }
                    }
                    try {
                        loadingContainer.addClass('is-requesting')
                        await updateView(params)
                    }
                    catch {
                        window.showErrorPopup()
                    }

                    loadingContainer.removeClass('is-requesting')
                }).on('select2:closing', function() {
                    $(this).closest('.responsive-table-wrapper').removeClass('filter-opened')
                })
            })
        }
        initFilters()


        // Logic for table sorters
        stepThreeContainer.on('click', '.table-sort', async function() {
            const { sortOrder, sortKey } = $(this).data()

            let params = {
                'order': sortOrder,
                'order_by': sortKey
            }

            try {
                loadingContainer.addClass('is-requesting')
                await updateView(params)
            }
            catch {
                window.showErrorPopup()
            }

            loadingContainer.removeClass('is-requesting')
        })


        // Logic for submitting multiselect changes
        $('.calloff-quick-edit-save-btn').on('click', async function() {
            const selectedRows = $('.multiselect-selected')
            try {
                loadingContainer.addClass('is-requesting')
                await window.callOffCreation.updateMultipleCartItems(selectedRows)

                window.callOffCreation.multiselectActive = false
                $('.calloff-quick-edit-btn').removeClass('active')

                await updateView()
            }
            catch {
                window.showErrorPopup()
            }

            loadingContainer.removeClass('is-requesting')
        })


        // Logic for initializing datepickers for date_start and date_end
        function initDatePickers() {
            if ($('.calloff-date-input').exists()) {
                $('.calloff-date-input').each(function() {
                    let isDateEnd = $(this).hasClass('calloff-date-end-input')
                    let { locale, preselect } = $(this).data()
                    let minDate = "today"
                    let enabledDates = []

                    if (isDateEnd) {
                        minDate = $(this).closest('tr').find('.calloff-date-start-input').data('preselect')

                        const currentlySelectedStartDate = flatpickr.parseDate($(this).closest('tr').find('.calloff-date-start-input').data('preselect'), 'D, d.m.y')
                        const dayAfter = typeof currentlySelectedStartDate !== 'undefined' ? currentlySelectedStartDate.fp_incr(1) : ''
                        const nightShift = $(this).closest('tr').find('.calloff-night-shift input').prop('checked') == true
                        const statusBinding = $(this).closest('tr').find('.calloff-status--binding input').prop('checked') == true

                        if (statusBinding && nightShift) {
                            enabledDates = [currentlySelectedStartDate, dayAfter]
                        } else if (statusBinding && !nightShift) {
                            enabledDates = [currentlySelectedStartDate]
                        }
                    }

                    let options = {
                        dateFormat: 'D, d.m.y',
                        locale: locale,
                        minDate: minDate,
                        defaultDate: preselect,
                        enable: enabledDates,
                        position: "auto center",
                        disableMobile: true
                    }
                    flatpickr($(this), options)
                })
            }
        }
        initDatePickers()


        // Logic for initializing timepickers for time_start and time_end
        function initTimePickers() {
            if ($('.calloff-time-input').exists()) {
                $('.calloff-time-input').each(function() {
                    const timePicker = $(this)
                    let isTimeEnd = timePicker.hasClass('calloff-time-end-input')
                    let { locale, preselect } = timePicker.data()

                    const dateStartDatepicker = timePicker.closest('tr').find('.calloff-date-start-input')
                    const dateEndDatepicker = timePicker.closest('tr').find('.calloff-date-end-input')
                    const currentDate = moment(dateStartDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')
                    const endDate = moment(dateEndDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')

                    const thisRowId = timePicker.closest('tr').data('id')

                    let isSameDay = currentDate == endDate

                    let options = {
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        minDate: (isSameDay && isTimeEnd) ? timePicker.closest('tr').find('.calloff-time-start-input').data('preselect') : '',
                        defaultDate: preselect,
                        defaultHour: isTimeEnd ? '15' : '7',
                        locale: locale,
                        position: "auto center",
                        disableMobile: true,
                        onReady: (selectedDates, dateString, instance) => {
                            let { calendarContainer } = instance

                            // add class to the dropdown
                            $(calendarContainer).addClass('timepicker-with-clear')

                            // append delete button to timepicker
                            $(calendarContainer).append(`
                                <span class="clear-timepicker ${isTimeEnd ? 'clear-timepicker-end' : 'clear-timepicker-start'}">
                                    <i class="material-icons">clear</i>
                                </span>
                            `)
                        },
                        onClose: async function(selectedDates, dateStr, instance) {
                            const thisTimepicker = $(this.element)
                            const thisRow = timePicker.closest('tr')
                            const thisRowCheckboxChecked = thisRow.find('.calloff-quick-edit input').prop('checked') == true

                            // timepickers data
                            const timeStartDatepicker = timePicker.closest('tr').find('.calloff-time-start-input')
                            const timeEndDatepicker = timePicker.closest('tr').find('.calloff-time-end-input')
                            const startTime = moment(timeStartDatepicker.val(), 'HH:mm').format('HH:mm')
                            const endTime = moment(timeEndDatepicker.val(), 'HH:mm').format('HH:mm')

                            // datepickers data
                            const dateStartDatepicker = timePicker.closest('tr').find('.calloff-date-start-input')
                            const dateEndDatepicker = timePicker.closest('tr').find('.calloff-date-end-input')
                            const currentDate = moment(dateStartDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')
                            const endDate = moment(dateEndDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')

                            // on closing make sure to set that selected time as value of the input
                            // added for the case when you click outside after manually typing in the time value
                            if (thisTimepicker.val()) {
                                instance.setDate(`${instance.hourElement.value}:${instance.minuteElement.value}`)
                                thisTimepicker.trigger('change.dependencies')
                            }

                            // additional logic for turning back on the setting of end-datepicker value if necessary
                            // it was turned off in 'changing inputs dependable on delivery start time' event bugfix
                            if (currentDate == endDate && currentDate != 'Invalid date' && !isTimeEnd && startTime > endTime) {
                                timeEndDatepicker[0]._flatpickr.setDate(startTime)
                            }

                            if (window.callOffCreation.multiselectActive && thisRowCheckboxChecked) {
                                $('.calloff-step-three-table tr').each(function() {
                                    if ($(this).find('.calloff-quick-edit input').prop('checked') == true) {
                                        const timepickerToUpdate = $(this).find(`input[data-name="${thisTimepicker.data('name')}"]`)
                                        timepickerToUpdate[0]._flatpickr.setDate(thisTimepicker.val())

                                        // manually trigger only the change event from namespace
                                        timepickerToUpdate.trigger('change.dependencies')
                                    }
                                })
                                validateTableData()
                            } else {
                                try {
                                    // for some reason, if the sent value is empty, after the updateCartItem(),
                                    // its visually set back to the value of start-time so it has to be reverted below
                                    let sendingEmptyValue = false
                                    if (!thisTimepicker.val()) {
                                        sendingEmptyValue = true
                                    }

                                    await window.callOffCreation.updateCartItem(thisRow)

                                    if (sendingEmptyValue) {
                                        thisTimepicker.val('')
                                    }

                                    validateTableData()

                                } catch {
                                    window.showErrorPopup()
                                }
                            }
                        }
                    }  
                    flatpickr(timePicker, options)

                    // set rowId to timepicker container so it's x-button can remove value from appropriate table row
                    $(this)[0]._flatpickr.calendarContainer.setAttribute('data-row-id', thisRowId)
                })
            }

            // Logic for clearing time from timepickers
            $('.clear-timepicker').on('click', function() {
                const rowId = $(this).closest('.timepicker-with-clear').data('rowId')

                if ($(this).hasClass('clear-timepicker-start')) {
                    $(`tr[data-id=${rowId}]`).find('.calloff-time-start-input')[0]._flatpickr.clear()
                } else {
                    $(`tr[data-id=${rowId}]`).find('.calloff-time-end-input')[0]._flatpickr.clear()
                }
            })
        }
        initTimePickers()


        // Logic for calling the update on change of any input except for the time input,
        // because that one can change multiple times before finalizing the choice
        stepThreeContainer.on('change', 'input:not(.calloff-time-input, .dont-send)', async function() {
            const thisRow = $(this).closest('tr')
            const thisRowCheckboxChecked = thisRow.find('.calloff-quick-edit input').prop('checked') == true
            const inputElement = $(this)

            // added timeout to make sure other on-change events finish before this
            setTimeout(async function() {

                // if multiselect is active dont immediately request update
                if (window.callOffCreation.multiselectActive && thisRowCheckboxChecked) {
                    $('.calloff-step-three-table tr').each(function() {

                        // only affect the checked rows
                        if ($(this).find('.calloff-quick-edit input').prop('checked') == true) {
                            let inputToUpdate = $(this).find(`input[data-name="${inputElement.data('name')}"]`)

                            if (inputElement.hasClass('flatpickr-input')) {
                                inputToUpdate[0]._flatpickr.setDate(flatpickr.parseDate(inputElement.val(), 'D, d.m.y'))

                                // manually trigger only the change event from namespace
                                inputToUpdate.trigger('change.dependencies')
                            }
                            else if (inputElement.hasClass('calloff-status-planning-input') ||
                                inputElement.hasClass('calloff-status-binding-input') ||
                                inputElement.hasClass('calloff-night-shift-input')
                            ) {
                                inputToUpdate = $(this).find(`input.${inputElement.attr('class')}`)
                                inputToUpdate.prop('checked', inputElement.prop('checked') == true)

                                // manually trigger only the change event from namespace
                                inputToUpdate.trigger('change.dependencies')
                            }
                        }
                    })
                    validateTableData()
                } else {
                    try {
                        if (inputElement.attr('type') == 'radio') {
                            loadingContainer.addClass('is-requesting')
                        }

                        await window.callOffCreation.updateCartItem(thisRow)

                        // validation for the next-button
                        validateTableData()

                        // if the status changed then update the steps navigation
                        if (inputElement.attr('type') == 'radio') {
                            try {
                                const response = await axios.get(url, window.axiosConfig)
                                const { data } = response

                                const stepsTopNavigation = $(data).find('.calloff-steps-container').html() || ''
                                $('.calloff-steps-container').html(stepsTopNavigation)

                            } catch {
                                window.showErrorPopup()
                            }
                            loadingContainer.removeClass('is-requesting')
                        }
                    } catch {
                        window.showErrorPopup()
                    }
                }
            })
        })


        // Logic for changing inputs dependable on delivery start date,
        // added custom namespace so it can be manually triggered without triggering the normal change event above
        stepThreeContainer.on('change.dependencies', '.calloff-date-start-input', function() {

            // datepickers data
            const dateEndDatepicker = $(this).closest('tr').find('.calloff-date-end-input')
            const currentDate = moment($(this).val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')
            const endDate = moment(dateEndDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')

            // timepickers data
            const timeStartDatepicker = $(this).closest('tr').find('.calloff-time-start-input')
            const timeEndDatepicker = $(this).closest('tr').find('.calloff-time-end-input')
            const currentTime = moment(timeStartDatepicker.val(), 'HH:mm').format('HH:mm')
            const endTime = moment(timeEndDatepicker.val(), 'HH:mm').format('HH:mm')

            const currentDateFormatted = flatpickr.parseDate($(this).val(), 'D, d.m.y')
            const increasedDate = typeof currentDateFormatted !== 'undefined' ? currentDateFormatted.fp_incr(1) : ''
            const statusBinding = $(this).closest('tr').find('.calloff-status--binding input').prop('checked') == true
            const nightShift = $(this).closest('tr').find('.calloff-night-shift input').prop('checked') == true

            dateEndDatepicker[0]._flatpickr.set('minDate', $(this).val())
            dateEndDatepicker[0]._flatpickr.set('enable', [])

            if (statusBinding && nightShift) {
                dateEndDatepicker[0]._flatpickr.setDate(increasedDate)
                dateEndDatepicker[0]._flatpickr.set('enable', [currentDateFormatted, increasedDate])

                timeEndDatepicker[0]._flatpickr.set('minDate', '')

            } else if (statusBinding && !nightShift) {
                dateEndDatepicker[0]._flatpickr.setDate(currentDateFormatted)
                dateEndDatepicker[0]._flatpickr.set('enable', [currentDateFormatted])

                if (currentTime > endTime) {
                    timeEndDatepicker[0]._flatpickr.setDate(timeStartDatepicker.val())
                    timeEndDatepicker[0]._flatpickr.set('minDate', timeStartDatepicker.val())
                }
            } else {
                if (currentDate >= endDate) {
                    dateEndDatepicker[0]._flatpickr.setDate($(this).val())
                    timeEndDatepicker[0]._flatpickr.set('minDate', timeStartDatepicker.val())
    
                    if (currentTime > endTime) {
                        timeEndDatepicker[0]._flatpickr.setDate(timeStartDatepicker.val())
                        timeEndDatepicker[0]._flatpickr.set('minDate', timeStartDatepicker.val())
                    }
                } else  {
                    timeEndDatepicker[0]._flatpickr.set('minDate', '')
                }
            }
        })


        // Logic for changing inputs dependable on delivery end date
        stepThreeContainer.on('change.dependencies', '.calloff-date-end-input', function() {

            // datepickers data
            const dateStartDatepicker = $(this).closest('tr').find('.calloff-date-start-input')
            const endDate = moment($(this).val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')
            const startDate = moment(dateStartDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')

            // timepickers data
            const timeStartDatepicker = $(this).closest('tr').find('.calloff-time-start-input')
            const timeEndDatepicker = $(this).closest('tr').find('.calloff-time-end-input')
            const currentTime = moment(timeStartDatepicker.val(), 'HH:mm').format('HH:mm')
            const endTime = moment(timeEndDatepicker.val(), 'HH:mm').format('HH:mm')

            if (startDate != endDate) {
                timeEndDatepicker[0]._flatpickr.set('minDate', '')

            } else {
                timeEndDatepicker[0]._flatpickr.set('minDate', timeStartDatepicker.val())

                if (currentTime > endTime) {
                    timeEndDatepicker[0]._flatpickr.setDate(timeStartDatepicker.val())
                }
            }
        })


        // Logic for changing inputs dependable on delivery start time
        stepThreeContainer.on('change.dependencies', '.calloff-time-start-input', function() {

            // timepickers data
            const timeEndDatepicker = $(this).closest('tr').find('.calloff-time-end-input')
            const currentTime = moment($(this).val(), 'HH:mm').format('HH:mm')
            const endTime = moment(timeEndDatepicker.val(), 'HH:mm').format('HH:mm')

            // datepickers data
            const dateStartDatepicker = $(this).closest('tr').find('.calloff-date-start-input')
            const dateEndDatepicker = $(this).closest('tr').find('.calloff-date-end-input')
            const currentDate = moment(dateStartDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')
            const endDate = moment(dateEndDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')

            // additional logic for checking if the end-timepicker needs to actually be changed
            // necessary only because of the bug with the start-timepicker when it took the current real time as selected when it was opened
            // prevents changing end-timepicker to current time immediately after the start-timepicker is opened
            const timeStartTimepickerValue = $(this)[0]._flatpickr.selectedDates[0] ? $(this)[0]._flatpickr.selectedDates[0].toString() : ''
            const currentRealTime = moment()._d.toString()

            if (currentDate == endDate && timeStartTimepickerValue != currentRealTime) {
                timeEndDatepicker[0]._flatpickr.set('minDate', $(this).val())

                if (currentTime > endTime) {
                    timeEndDatepicker[0]._flatpickr.setDate($(this).val())
                }
            }
        })


        // Logic for changing inputs dependable on night_shift checkbox and status radio buttons
        stepThreeContainer.on('change.dependencies', '.calloff-night-shift input, .calloff-status input', function() {
            if ($(this).attr('type') == 'checkbox') {
                $(this).closest('.calloff-night-shift').toggleClass('nightshift-active')
            }

            const dateStartDatepicker = $(this).closest('tr').find('.calloff-date-start-input')
            const dateEndDatepicker = $(this).closest('tr').find('.calloff-date-end-input')
            const timeStartTimepicker = $(this).closest('tr').find('.calloff-time-start-input')
            const timeEndTimepicker = $(this).closest('tr').find('.calloff-time-end-input')
            const dateStart = flatpickr.parseDate(dateStartDatepicker.val(), 'D, d.m.y')
            const increasedDate = typeof dateStart !== 'undefined' ? dateStart.fp_incr(1) : ''
            const statusBinding = $(this).closest('tr').find('.calloff-status--binding input').prop('checked') == true
            const nightShift = $(this).closest('tr').find('.calloff-night-shift input').prop('checked') == true

            dateEndDatepicker[0]._flatpickr.set('enable', [])

            if (statusBinding && nightShift) {
                dateEndDatepicker[0]._flatpickr.setDate(increasedDate)
                dateEndDatepicker[0]._flatpickr.set('enable', [dateStart, increasedDate])
                timeEndTimepicker[0]._flatpickr.set('minDate', '')

            } else if (statusBinding && !nightShift) {
                dateEndDatepicker[0]._flatpickr.setDate(dateStart, true)
                dateEndDatepicker[0]._flatpickr.set('enable', [dateStart])

            } else if (!statusBinding && $(this).hasClass('calloff-status-planning-input')) {
                timeStartTimepicker[0]._flatpickr.setDate('', true)
                timeEndTimepicker[0]._flatpickr.setDate('', true)
            }
        })


        // Logic for note-input-popup related buttons
        if ($('.calloff-note').exists()) {

            // svg from the table
            stepThreeContainer.on('click', '.calloff-note svg', function() {
                $(this).closest('tr').find('.calloff-steps-notes-popup').removeClass('hidden')
            })

            // x button from the popup
            stepThreeContainer.on('click', '.notes-popup-close-btn', function() {
                $(this).closest('.calloff-steps-notes-popup').addClass('hidden')
            })

            // submit and delete buttons from the popup
            stepThreeContainer.on('click', '.notes-popup-button', async function() {
                const thisRow = $(this).closest('tr')

                // Check if clicked button is delete note button
                // empty textarea if it is
                if($(this).hasClass('notes-popup-delete-btn')) {
                    thisRow.find('textarea').val('')
                }
                
                const thisRowCheckboxChecked = thisRow.find('.calloff-quick-edit input').prop('checked') == true
                const noteText = thisRow.find('textarea').val()
                const emptyNotepadIcon = $(this).closest('.calloff-note').data('emptyNotepad')
                const notepadIcon = $(this).closest('.calloff-note').data('notepad')

                if (window.callOffCreation.multiselectActive && thisRowCheckboxChecked) {
                    $('.calloff-step-two-table tr').each(function() {
                        if ($(this).find('.calloff-quick-edit input').prop('checked') == true) {
                            $(this).find('textarea').val(noteText)
                        }
                    })
                    $(this).closest('.calloff-steps-notes-popup').addClass('hidden')
                    validateTableData()

                } else {
                    try {
                        $(this).closest('.calloff-steps-notes-popup').addClass('hidden')
                        loadingContainer.addClass('is-requesting')

                        await window.callOffCreation.updateCartItem(thisRow)

                        // Change notepad icon depending on if there is a note
                        if(thisRow.find('textarea').val() == '') {
                            $(this).closest('.calloff-note').find('.has-tippy').html(emptyNotepadIcon)
                        } else {
                            $(this).closest('.calloff-note').find('.has-tippy').html(notepadIcon)
                        }

                        $('.calloff-quick-edit-btn').removeClass('active')
                    } catch {
                        $(this).closest('.calloff-steps-notes-popup').addClass('hidden')
                        window.showErrorPopup()
                    }
                    
                    loadingContainer.removeClass('is-requesting')
                }
                validateTableData()
            })
        }


        // Logic for duplicating cart item
        stepThreeContainer.on('click', '.calloff-duplicate-btn', function() {
            window.callOffCreation.duplicateCartItem($(this), updateView)
        })


        // Logic for removing a cart item
        if ($('.cart-action').exists()) {
            stepThreeContainer.on('click', '.remove-from-cart svg', async function() {
                const tableRow = $(this).closest('tr')
                const path = $(this).closest('.cart-action').data('path')
                const additionalTableClass = 'calloff-step-three-table'

                window.callOffCreation.animateCalloffDeletion(tableRow, additionalTableClass)
                loadingContainer.addClass('is-requesting')

                // if the cart will become empty then redirect
                try {
                    const response = await axios.get(path)
                    const { data } = response

                    if (data.redirect_url) {
                        window.location.href = data.redirect_url
                    } else {
                        loadingContainer.removeClass('is-requesting')
                        await updateView({}, false, true)
                    }
                } catch {
                    loadingContainer.removeClass('is-requesting')
                    window.showErrorPopup()
                }
            })
        }


        // Helper function for table data validation
        function validateTableData() {
            const selectedRows = $('.multiselect-selected')
            let validationGood = true

            stepThreeContainer.find('tbody tr:not(.clone)').each(function() {
                const statusBinding = $(this).find('.calloff-status--binding input').prop('checked') == true
                const dateStart = $(this).find('.calloff-date-start-input').val()
                const dateEnd = $(this).find('.calloff-date-end-input').val()
                const timeStart = $(this).find('.calloff-time-start-input').val()
                const timeEnd = $(this).find('.calloff-time-end-input').val()
                
                if (statusBinding) {
                    if (dateStart == '' || timeStart == '' || timeStart == '00:00'|| timeEnd == '' || timeEnd == '00:00') {
                        validationGood = false
                    }
                } else {
                    if (dateStart == '' || dateEnd == '') {
                        validationGood = false
                    }
                }
            })
            if (window.callOffCreation.multiselectActive || $('.step-next-button').data('itemsCount') == 0) {
                validationGood = false
            }
            if (selectedRows.exists()) {
                $('.calloff-quick-edit-save-btn').removeClass('disabled')
            } else {
                $('.calloff-quick-edit-save-btn').addClass('disabled')
            }
            if (validationGood) {
                stepThreeContainer.find('.step-next-button').removeClass('disabled')
            } else {
                stepThreeContainer.find('.step-next-button').addClass('disabled')
            }
        }
        validateTableData()


        // Logic for replacing page content without reload
        async function updateView(urlParams = {}, reload = false, pagination = false) {
            if (!urlParams.isEmpty) {
                Object.keys(urlParams).forEach(function(key) {
                    url = window.updateUrlParameter(url, key, urlParams[key])
                })
                if (!pagination) {
                    url = window.updateUrlParameter(url, 'page', '')
                }
                if (typeof window.history.pushState != 'undefined') {
                    window.history.pushState(null, null, url)
                }
                if (reload) {
                    window.location.href = url
                }
            }
            if (!reload) {
                try {
                    const response = await axios.get(url, window.axiosConfig)
                    const { data } = response
                    
                    const calloffTable = $(data).find('.calloff-step-three-table').html() || ''
                    const stepsNavigation = $(data).find('.steps-navigation-container').html() || ''
                    const stepsTopNavigation = $(data).find('.calloff-steps-container').html() || ''
                    const cartItemsCounter = $(data).find('.cart-items-number-contanier').html() || ''

                    $('.calloff-step-three-table:not(.clone)').html(calloffTable)
                    $('.steps-navigation-container').html(stepsNavigation)
                    $('.calloff-steps-container').html(stepsTopNavigation)
                    $('.cart-items-number-contanier').html(cartItemsCounter)
                    $('.calloff-quick-edit-container').addClass('hidden')

                    initFilters()
                    initDatePickers()
                    initTimePickers()
                    initStepThreeTooltips()
                    validateTableData()

                } catch {
                    window.showErrorPopup()
                }
            }
        }

        // Logic for initializing tooltips
        function initStepThreeTooltips() {
            $('.material-name .has-tippy').each( function() {
                const materialDescriptionTooltip = $(this).data('materialDescription')
                const materialDescriptionMoreTooltip = $(this).data('materialDescriptionMore')
                const numberTooltip = $(this).data('number')
                const epTooltip = $(this).data('ep')
                const avvCodeTooltip = $(this).data('avvCode')
                const avvTooltip = $(this).data('avv')

                tippy(this, {
                    content: materialDescriptionTooltip + (materialDescriptionMoreTooltip ? '<br>'+ materialDescriptionMoreTooltip : '')
                    + (numberTooltip ? '<br>'+epTooltip+numberTooltip : '') 
                    + (avvCodeTooltip ? '<br>'+avvTooltip+avvCodeTooltip : ''),
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('.incoterm .has-tippy').each(function() {
                const incotermTitleTooltip = $(this).data('incotermTitle')
                const incotermTooltip = $(this).data('incoterm')
                const truckTitleTooltip = $(this).data('truckTitle')
                const truckTooltip = $(this).data('truck')

                tippy(this, {
                    content: (incotermTitleTooltip ? '<br>'+incotermTooltip+': '+incotermTitleTooltip : '') + 
                    (truckTitleTooltip ? '<br>'+truckTooltip+': '+truckTitleTooltip : ''),
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('.factory .has-tippy').each( function() {
                const factoryNameTooltip = $(this).data('factoryName')
                const factoryStreetTooltip = $(this).data('factoryStreet')
                const factoryStreetNumberTooltip = $(this).data('factoryStreetNumber')
                const factoryZipTooltip = $(this).data('factoryZip')
                const factoryCityTooltip = $(this).data('factoryCity')

                tippy(this, {
                    content: factoryNameTooltip + "<br>" + factoryStreetTooltip + " " + factoryStreetNumberTooltip + "<br>" +
                    factoryZipTooltip + " " + factoryCityTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })


            const planningStatusTooltip = $('.planning-status .has-tippy').data('tooltipTranslation')
            tippy('.planning-status .has-tippy', {
                content: planningStatusTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })

            const bindingStatusTooltip = $('.binding-status .has-tippy').data('tooltipTranslation')
            tippy('.binding-status .has-tippy', {
                content: bindingStatusTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })

            const nightShiftTooltip = $('.night-shift .has-tippy').data('tooltipTranslation')
            tippy('.night-shift .has-tippy', {
                content: nightShiftTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })

            const editNoteTooltip = $('.calloff-note .has-tippy.edit').data('tooltipTranslation')
            tippy('.calloff-note .has-tippy.edit', {
                content: editNoteTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })

            const addNoteTooltip = $('.calloff-note .has-tippy.add').data('tooltipTranslation')
            tippy('.calloff-note .has-tippy.add', {
                content: addNoteTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })

            const duplicateItemTooltip = $('.calloff-duplicate-btn .has-tippy').data('tooltipTranslation')
            tippy('.calloff-duplicate-btn .has-tippy', {
                content: duplicateItemTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
            
            const removeItem = $('.remove-from-cart .has-tippy').data('tooltipTranslation')
            tippy('.remove-from-cart .has-tippy', {
                content: removeItem,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
        }
        initStepThreeTooltips()
    }
})