<template>
  <div class="calloff-content form-container step-2" :class="loading ? 'is-requesting': ''">
      <div ref="editForm" v-show="editContent" v-html="editContent"></div>
  </div>
</template>

<script>
import EventBus from './../eventBus.js';
export default {
    props: {
        initialEditContent: {
            type: String
        },
        loading: {
            type: Boolean
        },
        editId: {
            type: Number
        }
    },
    data() {
        return {
            editContent : '',
            autoNumericInputs: [],
            currentMaterialData: {}, 
        }
    },
    methods:{
        initCustomFields() {
            this.initSelects()
            this.initFlatpickrs()
            this.initAutonumeric()
            this.appendPopupEvents()
        },
        appendPopupEvents() {
            let currentTime = flatpickr.formatDate(new Date(), 'H:i')
            let todayDate = flatpickr.formatDate(new Date(), 'd.m.yy')
            const vueComponent = this
            const popup = $(this.$refs.editForm)

            let hourlyTonnage = popup.find('[data-name="hourly_quantity"]')
            let dailyTonnage = popup.find('[data-name="daily_quantity"]')
            if (hourlyTonnage.exists && dailyTonnage.exists()) {
                // hourly tonnage can't have value greater than daily 
                popup.on('change', '.hourly-quantity-input', function(){
                    vueComponent.checkValues(true)
                
                })
                popup.on('change', '[data-name="daily_quantity"]', function() {
                    vueComponent.checkValues(false)
                })
            

            }
            let selectOptions = {
                //allowClear: true,
                containerCssClass: 'js-select-simple',
                dropdownCssClass: 'js-select-simple',
                minimumResultsForSearch: -1,
                
            }
            // Toggle inputs connected via "incoterm logic"flatpoickr
            popup.on('click','.back-button', function(){
                EventBus.$emit('clickedBack');
            })
            //fil end date to match the start date
            popup.on('change', '[data-name="delivery_start[date]"]',function() {
                
                let startDateValue= $(this).val()
                let endDate = popup.find('[data-name="delivery_end[date]"]')
                endDate.val(startDateValue).trigger('change')
                let startTime = popup.find('[data-name="delivery_start[time]"]')
                let flatpickrInstance = startTime[0]._flatpickr
                if (startDateValue === todayDate) {
                    flatpickrInstance.clear()
                    let value = startTime.data('start-time') ? startTime.data('start-time'): currentTime
                    flatpickrInstance.set('minTime', value)
                    let times = value.split(':')
                    flatpickrInstance.set('defaultHour', times[0])
                    flatpickrInstance.set('defaultMinute',  times[1])
                  
                } else {
                    
                    flatpickrInstance.set('minTime', undefined)
                    flatpickrInstance.set('defaultHour', 6)
                    flatpickrInstance.set('defaultMinute', 0)
                    
                }
                window.updateInputsFloatingLabels()
                
            })
           
            popup.on('change', '[data-name="delivery_start[time]"]', function() {
                let startDateValue = popup.find('[data-name="delivery_start[date]"]').val()
                // let endDateValue = popup.find('[data-name="delivery_end[date]"]').val()
                let endTimePicker = popup.find('[data-name="delivery_end[time]"]')
                let endTimeInstance = endTimePicker[0]._flatpickr
               
                    let startTimeValue
                    if (startDateValue == todayDate) {
                        startTimeValue = $(this).data('start-time') ? $(this).data('start-time') : currentTime
                    } else {
                        startTimeValue = $(this).val()
                    }
                    endTimeInstance.set('minTime', startTimeValue)
                    let times = startTimeValue.split(':')
                    endTimeInstance.set('defaultHour', times[0])
                    endTimeInstance.set('defaultMinute',  times[1])
                    endTimePicker.val(null)
                
                window.updateInputsFloatingLabels()
            } )
            popup.on('change', '[data-name="delivery_end[time]"]', function() {
                const input = $(this)
            
                    let startValue = popup.find('[data-name="delivery_start[time]"]').val()
                    this._flatpickr.set('minTime', startValue) 
                    let times = startValue.split(':')
                    this._flatpickr.set('defaultHour', times[0])
                    this._flatpickr.set('defaultMinute',  times[1])
                
                window.updateInputsFloatingLabels()

            })
          
            // Handle preselecting of default vechile types
            popup.on('change', '.material-select', function() {
                const selectedValue = $(this).val()

                vueComponent.currentMaterialData = vueComponent.availableMaterials[selectedValue]['materials'] 
                vueComponent.reinitMaterialSelects()
            })
            popup.on('change', '.material-number-select', function() {
                let selectedValue = $(this).val()
                if (selectedValue){
                    let materialInstance =  vueComponent.currentMaterialData.find(el => el.number ==  selectedValue)
                    const factorySelect = popup.find('.factory-select')
                    if (factorySelect.val() != materialInstance.factory) {
                            factorySelect.val(materialInstance.factory).trigger('change')
                           
                    }
                    
                }
                if(selectedValue) {
                   
                    const vechileTypeInput = popup.find('.vehicle-type-select')
                    // preselect a vehicle if material values are selected and preselection exists
                    if (selectedValue &&  vechileTypeInput.exists()) {
                       
                           let presetVehicles =  vueComponent.currentMaterialData.find((el)=> el.number == selectedValue)
                        let connectedValue = presetVehicles.truck_type
                        // Preselect
                        if(connectedValue && vechileTypeInput.exists()) {
                            vechileTypeInput.val(connectedValue).trigger('change')
                        } else if (!connectedValue) {
                            vechileTypeInput.val('').trigger('change')
                        }
                     
                    }
                    window.updateInputsFloatingLabels()
                }
            })
             popup.on('change', '.factory-select', function() {
                let selectedValue = $(this).val()
                const materialSelect = popup.find('.material-select')
                if (selectedValue && materialSelect.val()){ 
                    let materialInstance =  vueComponent.currentMaterialData.find(el => el.factory ==  selectedValue)

                    const materialNameSelect = popup.find('.material-number-select')
                    // vueComponent.updateMaterial = true
                    if (materialNameSelect.val() != materialInstance.number){
                        materialNameSelect.val(materialInstance.number).trigger('change')
                    }
                 }
                
            })   
            
        },
        initSelects(){
            
            let container = $(this.$refs.editForm)
            const defaultMaterialCategories = this.defaultMaterialCategories
            let selectInputs = container.find('.js-select')
            let materialVal, materialNumberVal, factoryVal, materialText
            selectInputs.each((index, input) => {
                const $input = $(input)

                let selectOptions = {
                    //allowClear: true,
                    containerCssClass: 'js-select-simple',
                    dropdownCssClass: 'js-select-simple',
                    placeholder: $input.data('placeholder'),
                    dropdownParent: $input.closest('.form-group'),
                    minimumResultsForSearch: -1,
                    escapeMarkup: (markup) =>{
                        if ( markup.search(/(\\\")/g) > 0){
                            markup = markup.replace(/\\\"/g, "\"")
                        }
                        return markup
                    } ,

                }
                if ($input.hasClass('material-category-select')){
                    selectOptions = { ...selectOptions,
                    disabled: true
                    }
                }
                if ($input.hasClass('material-select')){
                    // materialText = $input.find('option:selected').text()
                    $input.find('option').remove() 
                    let keys =  Object.keys(this.availableMaterials)
                    materialNumberVal = container.find('.material-number-select').val()
                    let data = keys.map((key)=> {
                        let text = this.availableMaterials[key]['designation']
                       
                        
                        if (this.availableMaterials[key]['materials'].find(el => el.number == materialNumberVal) ){
                            materialVal = this.availableMaterials[key]['materials'].find(el => el.number == materialNumberVal)['key']
                        }
                       
                        return { 'id': text, 'text':  text}
                    })
                 
                    selectOptions = {
                        ...selectOptions,
                        minimumResultsForSearch: -1,
                        data: data,
              
                    }
                } else if ($input.hasClass('material-number-select')) {
                    materialNumberVal = $input.val()
                    this.currentMaterialData = this.availableMaterials[materialVal]['materials']
                    // .find(element => element.number == materialNumberVal)
                    $input.find('option').remove()   
                    let data = this.currentMaterialData.map((material)=> {
                         return { 'id': material.number, 'text':  material.number}
                        
                        })                         
                   
                    selectOptions = {
                        ...selectOptions,
                        minimumResultsForSearch: -1,
                        data: data
                    }
                } else if ($input.hasClass('factory-select')) {
                    factoryVal = $input.val()
                    $input.find('option').remove()   
                    let data = this.currentMaterialData.map((material)=> {
                         return { 'id': material.factory, 'text':  material.factory_preview}
                        
                        }) 
                    
                    selectOptions = {
                        ...selectOptions,
                        minimumResultsForSearch: -1,
                        data: data
                    }
                }
                if ($input.hasClass('vehicle-type-select')){
                    const showMore = new Option(window.showMoreText, null, false, false)
                    $(showMore).addClass('toggle-vehicles')
                    $input.append(showMore).trigger('change')
                    selectOptions = {
                        ...selectOptions,
                        escapeMarkup: (markup) => markup,
                        templateResult: function(item, container) {
                            let $option = $(item.element)
                            let $container = $(container)
                            if (!$option.data('is-initial-option') && !$option.hasClass('toggle-vehicles')){
                                $container.addClass('hidden togglable-option')
                            } else {
                                $container.addClass('initial-option')
                            }
                            if ($option.hasClass('toggle-vehicles')){
                                $container.removeClass('hidden')
                                $container.addClass('toggle-vehicles')  
                                return `<a href="javascript:;" class="show-more"> ${ $option.text()} </a> 
                                <a href="javascript:;" class="show-less hidden"> ${window.showLessText} </a>`
                            }
                            
                            return $option.text()
                        }
              
                    }
                    $input.select2(selectOptions)
                    .on('select2:closing', function(e){
                       
                        if ($(e.target.selectedOptions).hasClass('toggle-vehicles')) {
                            e.preventDefault()
                            $input.val(null).trigger('change')
                            let optionsContainer =  $(e.target).siblings('.select2-container')
                            let options = optionsContainer.find('.togglable-option')
                            options.toggleClass('hidden')
                            optionsContainer.find('.toggle-vehicles a').toggleClass('hidden')
                        } 
                        
                    })
                } else {
                    
                    $input.select2(selectOptions)
                    if ($input.hasClass('material-select')){
                        $input.val(materialVal).trigger('change')
                    } else  if ($input.hasClass('material-number-select')){
                        $input.val(materialNumberVal).trigger('change')
                    } else if ($input.hasClass('factory-select')){
                        $input.val(factoryVal).trigger('change')
                    }
                }
                
            })
        },
      
        reinitMaterialSelects(){
            let popup = $(this.$refs.editForm)
            let inputs
            let materialSelect = popup.find('.material-number-select')
            let factorySelect = popup.find('.factory-select')
            
            let selectOptions = {
                //allowClear: true,
                containerCssClass: 'js-select-simple',
                dropdownCssClass: 'js-select-simple',
                minimumResultsForSearch: -1,
                
            }
            let materialNumberSelection, factorySelection
            materialNumberSelection = this.currentMaterialData[0].number
            if (materialSelect.hasClass("select2-hidden-accessible")) {
                
                let data = this.currentMaterialData.map((material)=> {
                    return { 'id': material.number, 'text':  material.number}
                    
                
                })                         
                materialSelect.empty()
                materialSelect.val()
                materialSelect.trigger('change')
                materialSelect.select2('destroy');
                materialSelect.select2({
                    ...selectOptions, 
                    placeholder: materialSelect.data('placeholder'),
                    dropdownParent: materialSelect.closest('.form-group'),
                    'data': data
                    })
                    materialSelect.val(materialNumberSelection).trigger('change')
            }
            if (factorySelect.hasClass("select2-hidden-accessible")) {
                let data = this.currentMaterialData.map((material)=> {
                        if (material.number == materialNumberSelection){
                        factorySelection =material.factory
                    }
                    return { 'id': material.factory, 'text':  material.factory_preview}
                
                }) 
                
                factorySelect.empty()
                factorySelect.val()
                factorySelect.trigger('change')
                factorySelect.select2('destroy');
                    
                factorySelect.select2({
                    ...selectOptions,
                    placeholder: factorySelect.data('placeholder'),
                    dropdownParent: factorySelect.closest('.form-group'), 
                    'data': data
                    })
                factorySelect.val(factorySelection).trigger('change')
            }
        },
        initFlatpickrs(){
            
            let datePickerSelector = '.calloff-popup .flatpickr-date-input'
            let datePickerOptions = $(datePickerSelector).data()
          
            let dateOptions = {
                dateFormat: 'd.m.Y',
                locale: datePickerOptions.locale,
                minDate: "today",
                maxDate: datePickerOptions.endDate,
                disableMobile: true,
                }
            flatpickr(datePickerSelector, dateOptions)
            let timePickerSelector = '.calloff-popup .flatpickr-time-input'
            let timePickerOptions = $(timePickerSelector).data()
            let timeOptions = {
                time_24hr: true,
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
                defaultHour: 6,
                locale: timePickerOptions.locale,
                maxTime: timePickerOptions.endTime,
                disableMobile: true,
                onValueUpdate: (val) => {
                    $(timePickerSelector).closest('.form-group').removeClass('is-empty')
                   
                },
            }  
            flatpickr(timePickerSelector, timeOptions)
           
        },
        initAutonumeric() {
            this.autoNumericInputs = []
            let localizedOptions
            if(window.shortLocale && window.shortLocale == 'de') {
                localizedOptions = {
                    digitGroupSeparator: '.',
                    decimalCharacter: ',',
                }
            } else {
                localizedOptions = {
                    digitGroupSeparator: ',',
                    decimalCharacter: '.',
                }
            }
             const quantityInputOptions = {
                ...localizedOptions,localizedOptions,
                decimalPlaces: 0,
                unformatOnSubmit: true,
                modifyValueOnWheel: false,
                emptyInputBehavior: 'press',
            }

            const quantityFloatInputOptions = {
                ...localizedOptions,
                decimalPlaces: 0,
                unformatOnSubmit: true,
                modifyValueOnWheel: false,
                emptyInputBehavior: 'press',
                currencySymbolPlacement: AutoNumeric.options.currencySymbolPlacement.suffix,
            }
            const quantityUnitInputOptions = {
            ...quantityFloatInputOptions,
            // Add custom unit
            currencySymbol: (input) => {
                let unitSymbol = '\u202f€'
                if (input.settings.unit) {
                    const {
                        unit
                    } = input.settings

                    unitSymbol = `\u202f${unit}`
                }

                return unitSymbol
            }
        }
               // Without units
                           

            const quantityInputSelector = '.formatted-quantity-input'
            // With units
            if ($(quantityInputSelector).exists() && AutoNumeric.getAutoNumericElement($(quantityInputSelector)) === null){
                const quantityInput = new AutoNumeric.multiple(quantityInputSelector, quantityInputOptions)

                this.autoNumericInputs.push(quantityInput)

            }
        
            const quantityUnitInputSelector = '.formatted-quantity-unit-input'
            // With units
            if ($(quantityUnitInputSelector).exists() && AutoNumeric.getAutoNumericElement($(quantityUnitInputSelector)) === null){
                const quantityUnitInput = new AutoNumeric.multiple(quantityUnitInputSelector, quantityUnitInputOptions)
                this.autoNumericInputs.push(quantityUnitInput)
            }
        },
        submitPayload(){
            const $form = $(this.$refs.editForm).find('form')
            $form.find('.material-category-select').prop('disabled', false);
            let endDate = $form.find('[data-name="delivery_end[date]"]')
            let endDateValue = endDate.val()
             if (!endDateValue){
                let startDate = $form.find('[data-name="delivery_start[date]"]').val()
                endDate.val(startDate).trigger('change')
             } 

            const formData = new FormData()
            const requestURI = $form.attr('action')
            const formFields = $form.serializeArray()
            let autoNumericInputs = this.autoNumericInputs
            const validation = window.validateFormFields($form)
            if (validation && validation.isValid) {
            EventBus.$emit('loading', true);

            // Create payload
            formFields.forEach(field => {
                let value = field.value
                const fieldName = field.name

                // Fetch unformatted values
                const input = $form.find(`[name="${field.name}"]`)
                
                let ANInputs = [].concat.apply([], autoNumericInputs)
                ANInputs.forEach(autonumericInput => {
                    const { domElement } = autonumericInput
                    // Find input which needs to be unformatted
                    if (input.is(domElement)) {
                        value = autonumericInput.rawValue
                    }
                })

                // Create payload
                formData.append(fieldName, value)
            })
            
            formData.append('call_off_type_id',this.editId)
            this.submitCallOffsForm(requestURI, formData)
            } else {
                $form .find('.material-category-select').prop('disabled', true);
            }
        },
        submitCallOffsForm(requestURI, formData){
            
            axios({ method: 'POST', 
                url: requestURI, 
                data: formData
            })
            .then(res=>{
                // EventBus.$emit('calloffEdit');
                location.reload() 
            })
            .catch(e=>{
                window.showErrorPopup()
            })
            
        },
        checkValues(hourlyFlag) {    
            const vueComponent = this                
            let ANInputs = [].concat.apply([], vueComponent.autoNumericInputs)
            const popup = $(this.$refs.editForm)
            let hourlyTonnage = popup.find('[data-name="hourly_quantity"]')
            let dailyTonnage = popup.find('[data-name="daily_quantity"]')

            let daily, hourly;
            ANInputs.forEach(autonumericInput => {
                const { domElement } = autonumericInput
                // Find input which needs to be cleared
                if (hourlyTonnage.is(domElement)) {
                    hourly = autonumericInput
                }
                if (dailyTonnage.is(domElement)) {
                    daily = autonumericInput
                }
            })
            if ((parseInt(daily.rawValue) < parseInt(hourly.rawValue)) && daily.rawValue !=="" && hourly.rawValue !== "" ) {
                if (hourlyFlag){
                    hourly.global.set('')
                    hourlyTonnage.trigger('change')
                } else {
                    daily.global.set('')
                    dailyTonnage.trigger('change')
                }   
            }
        }
    },
    updated: function () {
        this.$nextTick(function () {
            let contentChildren =  $(this.editContent).children() 
            if (contentChildren.length){
                // this.defaultMaterialCategories =  $(this.editContent).find('.calloff-refactored').data('material-categories')
                this.initCustomFields()
            }
        })
    },
    watch: {
        initialEditContent: function(newVal, oldVal){  
            if (newVal != undefined){
                this.editContent = newVal
            }
        },
    },
    computed: {
        defaultMaterialCategories(){
              if (this.editContent != ''){
                return $(this.editContent).find('.calloff-refactored').data('material-categories')
            }
            return false
        },
        materialCategoryValue(){
            if (this.editContent != ''){
                return $(this.$refs.editForm).find('.material-category-select option:selected').val()
            }
            return false
        },
        availableMaterials() {
            if (this.editContent != '' && this.defaultMaterialCategories && this.materialCategoryValue){

                let materialObjects = Object.assign(this.defaultMaterialCategories[this.materialCategoryValue]['materials_by_designation'])
                let materialKeys = Object.keys(materialObjects)

                materialKeys.forEach((materialKey)=> {
                    let materialInstances = materialObjects[materialKey]['materials']
                    let materialIndexes = Object.keys(materialInstances)
                    let materialArray = materialIndexes.map((materialIndex)=>{
                        let current = materialInstances[materialIndex]
                        let factory = Object.values(current.factory).reduce((acc, curr)=>acc.concat(curr))
                        let factoryText = Object.keys(current.factory).reduce((acc, curr)=>acc.concat(curr))
                        let number =  Object.keys(current.number).reduce((acc, curr)=>acc.concat(curr))
                        let truck_value = current.truck_type ? Object.keys(current.truck_type).reduce((acc, curr)=>acc.concat(curr)) : null
                        return { 
                            'key': materialKey, 
                            'factory': factory,
                            'factory_preview': factoryText,
                            'number': number,
                            'truck_type': truck_value
                            }
                           
                    })
                    materialObjects[materialKey]['materials'] = materialArray
                        
                    return materialArray
                })
                return materialObjects
                // return materialObjects
            }
            return false
        },
    }
}
</script>
