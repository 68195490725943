(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
    typeof define === 'function' && define.amd ? define(factory) :
    (global = global || self, global.monthSelectPlugin = factory());
}(this, function () { 'use strict';

    var __assign = function() {
        __assign = Object.assign || function __assign(t) {
            for (var s, i = 1, n = arguments.length; i < n; i++) {
                s = arguments[i];
                for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
            }
            return t;
        };
        return __assign.apply(this, arguments);
    };

    var monthToStr = function (monthNumber, shorthand, locale) { return locale.months[shorthand ? "shorthand" : "longhand"][monthNumber]; };

    var defaultConfig = {
        shorthand: false,
        dateFormat: "F Y",
        altFormat: "F Y",
        theme: "light"
    };
    function monthSelectPlugin(pluginConfig) {
        var config = __assign({}, defaultConfig, pluginConfig);
        return function (fp) {
            fp.config.dateFormat = config.dateFormat
            fp.config.altFormat = config.altFormat
            fp.staticArrowPrev = config.staticArrowPrev
            fp.staticArrowNext = config.staticArrowNext
            var self = { monthsContainer: null }

            let maxDateObj
            let minDateObj

            if (config.maxDate && config.maxDate instanceof Date) {
                maxDateObj = config.maxDate
                fp.config._maxDate = config.maxDate
            } else if (config.maxDate && config.maxDate instanceof Date == false) {
                maxDateObj = new Date(config.maxDate)
                fp.config._maxDate = new Date(config.maxDate)
            }

            if (config.minDate && config.minDate instanceof Date) {
                minDateObj = config.minDate
                fp.config._minDate = config.minDate
            } else if (config.minDate && config.minDate instanceof Date == false) {
                minDateObj = new Date(config.minDate)
                fp.config._minDate = new Date(config.minDate)
            }

            function clearUnnecessaryDOMElements() {
                if (!fp.rContainer || !fp.daysContainer || !fp.weekdayContainer)
                    return;
                fp.rContainer.removeChild(fp.daysContainer);
                fp.rContainer.removeChild(fp.weekdayContainer);
                fp.monthElements.forEach(function (element) {
                    if (!element.parentNode)
                        return;
                    element.parentNode.removeChild(element)
                })
            }
            function addListeners() {
                // Previous month behave like previous year
                fp._bind(fp.prevMonthNav, "mousedown", function (e) {
                    e.preventDefault()
                    e.stopPropagation()

                    if(minDateObj) {
                        if (new Date(fp.currentYear - 1, 11) >= minDateObj) {
                            fp.currentYear -= 1
                            selectYear()
                        }
                    } else {
                        fp.currentYear -= 1
                        selectYear()
                    }
                });

                // Next month behave like next year
                fp._bind(fp.nextMonthNav, "mousedown", function (e) {
                    e.preventDefault()
                    e.stopPropagation()

                    if (maxDateObj) {
                        if (new Date(fp.currentYear + 1, 0) <= maxDateObj) {
    
                            fp.currentYear += 1
                            selectYear();
                        }
                    } else {
                        fp.currentYear += 1
                        selectYear();
                    }
                });
                fp.staticArrowPrev.on('click', function (e){
                    let checkMonth, checkYear;
                    if (fp.currentMonth == 0) {
                        checkYear = fp.currentYear - 1
                        checkMonth = 11
                    } else {
                        checkYear = fp.currentYear 
                        checkMonth = fp.currentMonth - 1
                    } 
                    if (minDateObj) {
                        if (new Date(checkYear, checkMonth) < minDateObj) {
                            return
                        }
                    }                   
                    fp.currentYear = checkYear
                    fp.currentMonth = checkMonth 
            
                    setMonth(new Date(fp.currentYear, fp.currentMonth))
                })
                fp.staticArrowNext.on('click', function (e){
                    let checkMonth, checkYear;
                    if (fp.currentMonth == 11) {
                        checkYear = fp.currentYear + 1
                        checkMonth = 0
                    } else {
                        checkYear = fp.currentYear
                        checkMonth = fp.currentMonth + 1
                    }
                    if (maxDateObj) {
                        if (new Date(checkYear, checkMonth) > maxDateObj) {
                            return
                        }
                    }
                   fp.currentMonth = checkMonth
                   fp.currentYear = checkYear
                    
                    setMonth(new Date(fp.currentYear, fp.currentMonth))
                })
            }
            function addMonths() {
                if (!fp.rContainer)
                    return;
                self.monthsContainer = fp._createElement("div", "flatpickr-monthSelect-months");
                self.monthsContainer.tabIndex = -1;
                fp.calendarContainer.classList.add("flatpickr-monthSelect-theme-" + config.theme);
                const { monthsValues } = config
                for (var i = 0; i < 12; i++) {
                    const month = fp._createElement("span", "flatpickr-monthSelect-month")
                    const currentDateObj = new Date(fp.currentYear, i)

                    month.dateObj = currentDateObj
                    month.$i = i;

                    // Localized month name
                    let textContent = monthToStr(i, config.shorthand === true, fp.l10n)

                    // Append an extra value if provided
                    if (monthsValues && monthsValues[fp.currentYear] && monthsValues[fp.currentYear][i+1]) {
                        textContent += ` (${monthsValues[fp.currentYear][i+1]})`
                    }
                    // disable delivery overview contract months without deliverynotes ( and not calloff overview)

                    else if (monthsValues) {
                        month.classList.add('disabled')
                    } 

                    // Set innerHTML
                    month.textContent = textContent;

                    month.tabIndex = -1;
                    month.addEventListener("click", selectMonth);

                    // Set onload
                    if(maxDateObj && (currentDateObj > maxDateObj)) {
                        month.classList.add('disabled')
                    }

                    if(minDateObj && (currentDateObj < minDateObj)) {
                        month.classList.add('disabled')
                    }

                    // Add day to container
                    self.monthsContainer.appendChild(month);
                }
                fp.rContainer.appendChild(self.monthsContainer);
            }
            function setCurrentlySelected() {
                if (!fp.rContainer)
                    return;
                var currentlySelected = fp.rContainer.querySelectorAll(".flatpickr-monthSelect-month.selected");
                currentlySelected.forEach(function (month) {
                    month.classList.remove("selected");
                });
                var month = fp.rContainer.querySelector(".flatpickr-monthSelect-month:nth-child(" + (fp.currentMonth + 1) + ")");
                if (month) {
                    month.classList.add("selected");
                }
            }
            function selectYear() {
                fp.currentYearElement.value = fp.currentYear

                // Months container needs to be updated
                if (minDateObj || maxDateObj) {
                    updateMonths()
                }

            }

            function updateMonths() {
                self.monthsContainer.innerHTML = ''
                const { monthsValues } = config
                for (let i = 0; i < 12; i++) {
                    const month = fp._createElement("span", "flatpickr-monthSelect-month")

                    const currentDateObj = new Date(fp.currentYear, i)
                    month.dateObj = currentDateObj
                    month.$i = i;
                    // Localized month name
                    let textContent = monthToStr(i, config.shorthand === true, fp.l10n);
                    // Append an extra value if provided
                    if (monthsValues && monthsValues[fp.currentYear] && monthsValues[fp.currentYear][i+1]) {
                        textContent += ` (${monthsValues[fp.currentYear][i+1]})`
                    } 
                    // disable delivery overview contract months without deliverynotes ( and not calloff overview)
                    else if (monthsValues) {
                        month.classList.add('disabled')
                    } 

                    // Set innerHTML
                    month.textContent = textContent;
        
                    
                    month.tabIndex = -1;
                    month.addEventListener("click", selectMonth);

                    if(
                        (maxDateObj && (currentDateObj > maxDateObj)) ||
                        (minDateObj && (currentDateObj < minDateObj))
                    ) {
                        month.classList.add('disabled')
                    }

                    month.classList.remove('selected')

                    // Add day to container
                    self.monthsContainer.appendChild(month)
                }
            }

            function selectMonth(e) {
                e.preventDefault();
                e.stopPropagation();

                if (e.target.classList.contains('disabled')) {
                    return
                }
                setMonth(e.target.dateObj);
            }
            function setMonth(date) {
                var selectedDate = new Date(date)
                selectedDate.setFullYear(fp.currentYear)
                fp.currentMonth = selectedDate.getMonth()
                fp.setDate(selectedDate, true)
                setCurrentlySelected()

                config.onSelect(fp)
            }
            var shifts = {
                37: -1,
                39: 1,
                40: 3,
                38: -3
            };
            function onKeyDown(_, __, ___, e) {
                var shouldMove = shifts[e.keyCode] !== undefined;
                if (!shouldMove && e.keyCode !== 13) {
                    return;
                }
                if (!fp.rContainer || !self.monthsContainer)
                    return;
                var currentlySelected = fp.rContainer.querySelector(".flatpickr-monthSelect-month.selected");
                var index = Array.prototype.indexOf.call(self.monthsContainer.children, document.activeElement);
                if (index === -1) {
                    var target = currentlySelected || self.monthsContainer.firstElementChild;
                    target.focus();
                    index = target.$i;
                }
                if (shouldMove) {
                    self.monthsContainer.children[(12 + index + shifts[e.keyCode]) % 12].focus();
                }
                else if (e.keyCode === 13 &&
                    self.monthsContainer.contains(document.activeElement)) {
                    setMonth(document.activeElement.dateObj);
                }
            }
            function destroyPluginInstance() {
                if (self.monthsContainer !== null) {
                    self.monthsContainer
                        .querySelectorAll(".flatpickr-monthSelect-month")
                        .forEach(function (element) {
                        element.removeEventListener("click", selectMonth);
                    });
                }
            }
            return {
                onParseConfig: function () {
                    fp.config.mode = "single";
                    fp.config.enableTime = false;
                },
                onValueUpdate: setCurrentlySelected,
                onKeyDown: onKeyDown,
                onReady: [
                    clearUnnecessaryDOMElements,
                    addListeners,
                    addMonths,
                    setCurrentlySelected,
                    function () {
                        fp.loadedPlugins.push("monthSelect");
                    },
                ],
                onDestroy: destroyPluginInstance
            };
        };
    }

    return monthSelectPlugin;

}));