import { initSearch } from 'Select2/search'
import { initTableAjaxFilter } from 'Select2/filter'
import { initDatepicker } from 'Flatpickr/datepicker'
import { initLetterPagination } from 'Pagination/letter-pagination'

$(document).ready(() => {
    if ($('#construction-site-monitor').exists()) {
        const tableContainer = $('.disposition-table-container')
        const searchContainer = $('.construction-site-search-container')
        const calendarContainer = $('#calendar-container')
        const datePicker = $('.flatpickr-date-input')
        const datePickerContainer = $('.datepicker-container')
        const pagination = $('#pagination')
        const itemLoadingIcon = $('#search-loader').html()
        const phoneTooltip = $('.has-today-delivery')
        const activeFilter = $('.active-filters-container').find('.active-filter')
        const dropdownArrow = $('.dropdown-arrow-svg').html()
        let url = window.location.href

        tableContainer.removeClass('is-requesting')


        // Initialize search and table filters by sending 4 parameters:
        //  1) the item itself
        //  2) its parent
        //  3) container which will have 'is-requesting' class when loading
        //  4) html for the loading icon inside the item (not the main screen loader)
        if (searchContainer.exists()) {
            initSearch($('.construction-site-search'), searchContainer, tableContainer, itemLoadingIcon)
        }
        if (tableContainer.exists()) {
            const tableFilters = $('.header-filter')

            tableFilters.each(function() {
                const filter = $(this)
                const filterParent = filter.parent()
                initTableAjaxFilter(filter, filterParent, tableContainer, itemLoadingIcon)
            })
        }


        // Logic for table sorters
        tableContainer.on('click', '.table-sort', function() {
            const { sortOrder, sortKey } = $(this).data()
            tableContainer.addClass('is-requesting')

            url = window.updateUrlParameter(url, 'order', sortOrder)
            url = window.updateUrlParameter(url, 'order_by', sortKey)
            window.location.href = url
        })


        // Logic for clearing active filters above the table
        if (activeFilter.exists()) {
            activeFilter.on('click', function() {
                let filterKey = $(this).find('.remove-active-filter').data('filter-key')

                $(this).remove()
                tableContainer.addClass('is-requesting')

                // in addition to removing the active filter, also remove term which can come from search
                url = window.updateUrlParameter(url, filterKey, '')
                url = window.updateUrlParameter(url, 'term', '')
                window.location.href = url
            })
        }


        // Initialize calendar which uses a custom plugin
        if (calendarContainer.exists()){
            const calendar = $('#calendar-container #select-month')
            const { minDate, maxDate, monthValues, currentMonth, currentYear } = calendar.data()
            const prevArrow = $('.calendar-table-heading .prev-month')
            const nextArrow = $('.calendar-table-heading .next-month')

            flatpickr(calendar, {
                defaultDate: currentYear + '-' + currentMonth,
                disableMobile: true,
                plugins: [
                    new monthSelectPlugin({
                        dateFormat: 'Y-m',
                        minDate: minDate,
                        maxDate: maxDate,
                        staticArrowPrev: prevArrow,
                        staticArrowNext: nextArrow,
                        monthsValues: monthValues,
                        onSelect: flatpicker => {
                            const { currentYear, currentMonth } = flatpicker
                            tableContainer.addClass('is-requesting')
                            flatpicker.close()

                            // months are indexed from 0
                            url = window.updateUrlParameter(url, 'y', currentYear)
                            url = window.updateUrlParameter(url, 'm', currentMonth + 1)

                            window.location.href = url
                        }
                    })
                ],
            })
        }


        // Logic for expanding a row in calendar, only one at the time can be expanded
        calendarContainer.on('click', '.toggle-expand', function() {
            const tableMaterial = $(this).closest('.materials')
            tableMaterial.toggleClass('expanded')
            calendarContainer.find('.materials').not(tableMaterial).removeClass('expanded')
        })


        // Logic for highlighting rows in the table when clicking on a calendar item
        // 1) single item
        calendarContainer.on('click', '.calloff-single', function() {
            const id = $(this).data('calloffId')
            const highlightedRow = tableContainer.find(`tr[data-calendar-highlight-id="${id}"]`)

            highlightedRow.addClass('highlight')
            setTimeout(() => {
                highlightedRow.removeClass('highlight')
            }, 1000)
        })
        // 2) stacked item
        calendarContainer.on('click', '.toggle-table-grouped.stacked', function() {
            const calloffs = $(this).find('.calloff-single')

            calloffs.each(function() {
                const id = $(this).data('calloffId')
                const highlightedRow = tableContainer.find(`tr[data-calendar-highlight-id="${id}"]`)

                highlightedRow.addClass('highlight')
                setTimeout(() => {
                    highlightedRow.removeClass('highlight')
                }, 1000)
            })
        })


        // Initialize datepickers
        if (datePickerContainer.exists()) {
            initDatepicker(
                datePicker,
                datePickerContainer,
                { 'startParamName': 'delivery_from_date',
                     'endParamName': 'delivery_to_date' },
                tableContainer
            )
        }

    
        // Live update switch logic
        const liveUpdateSwitch = $('.live-update-switch .toggle')

        if (liveUpdateSwitch.exists()) {
            const refreshTime = $('.change-live-status').data('interval')

            liveUpdateSwitch.on('click', async function() {
                liveUpdateSwitch.toggleClass('live-active')
                if (liveUpdateSwitch.hasClass('live-active')) {

                    setInterval(async function() {
                        tableContainer.addClass('is-requesting')

                        const response = await axios.get(url)
                        let container = $(response.data).find('.disposition-table-container').html()
                        tableContainer.html(container)

                        tableContainer.removeClass('is-requesting')
                    }, refreshTime * 1000)
                } else {
                    location.reload()
                }
            })
        }


        // Logic for contract daily/overall quantity page dropdown select
        const dropdownContainer = $('.construction-site-page-dropdown-select')

        if (dropdownContainer.exists()){
            const quantityPageSelect = $('.quantity-page-select')
            const parent = $('.construction-site-page-dropdown-select')

            quantityPageSelect.select2({
                dropdownParent: dropdownContainer,
                minimumResultsForSearch: -1
            }).on('select2:select', function (e) {
                tableContainer.addClass('is-requesting')
                const path = e.params.data.element.dataset.href
                window.location.href = path
            })
            parent.find('.select2-selection__arrow b').html(dropdownArrow)
        }


        // Initialize pagination
        if (pagination.exists()) {
            let { first, last, prev, next, startPage, totalPages, visiblePages } = pagination.data()
            
            if (totalPages == 0) {
                totalPages = 1
            }
            pagination.twbsPagination({
                first,
                last,
                prev,
                next,
                startPage,
                totalPages,
                visiblePages,
                initiateStartPageClick: false,
                onPageClick: (event, page) => {
                    url = window.updateUrlParameter(url, 'page', page)
                    window.location.href = url
                }
            })
        }


        // Initialize letter pagination and logic for infinite scroll
        if ($('.letter-pagination-container').exists()) {
            initLetterPagination(tableContainer)

            let currentPage = 1
            let isRequesting = false

            $(window).on('scroll', async function() {
                const { scrollTop, scrollHeight, clientHeight } = document.documentElement
                const scrollPercent = scrollTop / (scrollHeight - clientHeight)
                const { pageSize, totalPages } = $('.letter-pagination-container').data()

                if (scrollPercent > 0.66 && !isRequesting && currentPage < totalPages) {
                    currentPage++
                    isRequesting = true
                    $('.main-container').addClass('scroll-is-requesting')

                    try {
                        url = window.updateUrlParameter(url, 'page_size', pageSize)
                        url = window.updateUrlParameter(url, 'page', currentPage)

                        const response = await axios.get(url)
                        const data = $(response.data).find('tbody').html()
                        
                        tableContainer.find('tbody').append(data)
                        isRequesting = false
                        $('.main-container').removeClass('scroll-is-requesting')
                    } catch {
                        isRequesting = false
                        $('.main-container').removeClass('scroll-is-requesting')
                        window.showErrorPopup()
                    }
                }
            })
        }


        // Show loader when clicking a single-button and zuruck button
        $('.details-link, .back').on('click', () => {
            tableContainer.addClass('is-requesting')
        })


        // Create tooltip for phone icon
        if (phoneTooltip.exists()) {
            tippy(phoneTooltip[0], {
                content: phoneTooltip.data('tooltip')
            })
        }


        // Toggle canceled delivery notes visibility
        if ($('.corrected-delivery-note').exists()) {
            $('.toggle-item-changes').on('click', function () {
                $(this).parent().toggleClass('expand-hidden')
            })
        }
    }
})