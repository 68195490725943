$(document).ready(() => {
    if($('#highway-calloff-dashboard').exists()) {
        const popupContainer = $('.customize-filters-popup-container')
        const calloffDashboardContainer = $('#highway-calloff-dashboard')
        const filterContainer = $('.customize-preset-filters')
        const itemLoadingIcon = $('#search-loader').html()
        const popup = $('.popup-scrollable-area')
        const updateButton = $('.customize-preset-popup-save')
        const deletePresetButton = $('.customize-preset-popup-delete')

        let presetUrl = popupContainer.data('path')
        let presetData = ''
        let presetInfo = ''

        let presetFiltersUrl = ''

        window.callOffEdit = {}

        // Get preset info on load
        async function getPresets() {
            try {
                const response = await axios.get(presetUrl)
                const { data } = response

                presetData = data.data

                // Set data in preset popup
                if(presetData[0]) {
                    const presetPath = $('.customize-preset-filters-select option').first().val()

                    updatePresetPopup(presetData[0].id, presetPath)

                    initFilters()

                    // Logic for cancel button in popup
                    calloffDashboardContainer.on('click', '.customize-preset-popup-cancel', function() {
                        const presetPopupContainer = calloffDashboardContainer.find('.customize-filters-popup-container')

                        presetPopupContainer.addClass('closed')
                        updatePresetPopup(presetData[0].id, presetPath)

                        // update filter placeholders with counters
                        updateFilterCounters()
                    })

                    // disable save button if no statuses are selected
                    if($('.calloff-preset-button').hasClass('active')) {
                        $('.customize-preset-popup-save').attr('disabled', false)
                    } else {
                        $('.customize-preset-popup-save').attr('disabled', true)

                        tippy('.customize-preset-popup-save-container', {
                            content: $('.customize-preset-popup-save').data('tooltip'),
                            placement: 'top',
                            theme: 'cockpit-tippy'
                        })
                    }
                } else {
                    initFilters()
                }
            }
            catch {
                window.showErrorPopup()
            }
        }
        getPresets()


        // Logic for creating a new filter preset
        calloffDashboardContainer.on('click', '.preset-save-button', async function() {
            const presetPath = $(this).data('presetUrl')
            const filtersUrl = decodeURIComponent(window.location.href.split('?')[1])
            const name = $('#preset-name').val()
            const currentUrl = $(this).data('currentUrl')

            let path = `${presetPath}?${filtersUrl}&source_route=${currentUrl}&name=${name}`

            // remove id from url when creating a new preset
            // leaving the id updates the old preset
            path = window.updateUrlParameter(path, 'id', '')

            presetFiltersUrl = popupContainer.data('currentRoute')

            if(name) {
                calloffDashboardContainer.addClass('is-requesting')

                try {
                    const response = await axios.post(path)
                    const { data } = response

                    $('.edit-preset-button').attr('disabled', false)
                    $('.calloff-preset-delete-all').remove()

                    // Set status buttons to active
                    await $.each(data.data.statuses, function(key, value) {
                        $(`#status${value.id}`).removeClass('active')

                        if(value.is_active) {
                            $(`#status${value.id}`).addClass('active')
                        }
                    })

                    // set url for filters for option loading filtered by status
                    $('.calloff-preset-button').each(function() {
                        if($(this).hasClass('active')) {
                            presetFiltersUrl = window.updateUrlParameterMulti(presetFiltersUrl, $(this).data('filterKey'), $(this).data('filterValue'))
                        }
                    })

                    // Remove old active filters content
                    $('.customize-preset-active-container').empty()

                    // Add active filters
                    $.each(data.data.active_display_filters, function(key, value) {
                        const markup = `<span class="calloff-preset-filter-label active-filter" data-value="${value.value}">
                                            ${value.value}
                                            <a href="javascript:;" class="remove-preset-active-filter" 
                                                data-filter-key="${value.name}"
                                                data-filter-value="${value.value}"
                                            ></a>
                                        </span>`

                        $('.customize-preset-active-container').append(markup)
                    })

                    // add remove active filter button if there are active filters
                    if($('.customize-preset-active-container .active-filter').children().length > 0) {
                        const removeFiltersButtonMarkup = `<button class="calloff-preset-delete-all light-blue">
                                                        ${'calloff.filter_button.delete_all_filters'.trans()}
                                                    </button>`
                        
                        $('.customize-preset-filters').append(removeFiltersButtonMarkup)
                    }
                    
                    // add new preset to presect select
                    const currentRoute = popupContainer.data('currentRoute')
                    let presetValue = `${currentRoute}?name=${data.data.name}&id=${data.data.id}`

                    $.each(data.data.active_filters, function(i, element) {
                        presetValue = `${presetValue}&${element.name}[]=${element.value}`
                    })
                    $('.customize-preset-filters-select').append(`<option data-id="${data.data.id}" value="${presetValue}" selected>${data.data.name}</option>`)

                    // set values to data attributes that are used to update presets
                    updateButton.data('presetUrl', presetValue)
                    updateButton.attr('data-preset-url', presetValue)
                    updateButton.attr('data-preset-id', `${data.data.id}&name=${data.data.name}`)
                    updateButton.attr('data-id', data.data.id)

                    // update filter placeholders with counters
                    updateFilterCounters()

                    popupContainer.removeClass('closed')
                    $('.save-preset-popup-container').addClass('hidden')

                    // set data for deleting presets and enable buttons
                    deletePresetButton.attr('data-preset-id', data.data.id)

                    deletePresetButton.attr('disabled', false)
                    updateButton.attr('disabled', false)

                    // // add the new created preset to preset dropdown
                    window.callOffEditOverview.updateCalloffOverviewContainer(presetValue)

                    const allPresetsResponse = await axios.get(presetUrl)
                    presetData = allPresetsResponse.data.data
    
                } catch (err) {
                    // Handle response on "proper way"
                    const { response } = err

                    $('#preset-name').removeClass('error')
                    $('.existing-name-error').remove()

                    if(response.status == 409) {
                        $('.preset-popup-input').append(`<p class="existing-name-error">${response.data.message.trans()}</p>`)
                        $('#preset-name').addClass('error')
                    } else {
                        window.showErrorPopup()    
                    }
                }

                calloffDashboardContainer.removeClass('is-requesting')
            } else {
                $('#preset-name').addClass('error')
            }
            
        })

        // Remove all popup filters
        calloffDashboardContainer.on('click', '.calloff-preset-delete-all', function() {
            $('.customize-preset-active-container').empty()
            $('.calloff-preset-delete-all').remove()
            $('.calloff-preset-button').removeClass('active')

            // return filter placeholders to default state with no counter
            updateFilterCounters(true)

            const presetId = updateButton.data('presetId')

            updateButton.attr('data-preset-url', popupContainer.data('currentRoute')+'?id='+presetId)

            presetFiltersUrl = popupContainer.data('currentRoute')

            // disable save button if no statuses are selected
            $('.customize-preset-popup-save').attr('disabled', true)

            tippy('.customize-preset-popup-save-container', {
                content: $('.customize-preset-popup-save').data('tooltip'),
                placement: 'top',
                theme: 'cockpit-tippy'
            })
        })


        // Remove red border from input when users types something
        $('#preset-name').on('keyup',function(){
            if($(this).val().length > 0){
                $(this).removeClass('error')
                $('.existing-name-error').remove()
            }
        })


        // Logic for preset select
        function initPresetSelect() {
            const filter = $('.customize-preset-filters-select')

            filter.select2({
                dropdownParent: $('.customize-preset-select'),
                containerCssClass: 'calloff-preset-select' ,
                dropdownCssClass: 'calloff-preset-dropdown',
                minimumResultsForSearch: -1,
                language: {
                    noResults: () => 'highway.calloff.no_results'.trans()
                },
            }).on('select2:select', async function(e) {
                let filterData = e.params.data

                // on select, fill popup with preset data
                updatePresetPopup(filterData.element.dataset.id, filterData.id)

                // update filter placeholders with counters
                updateFilterCounters()

                if($('.calloff-preset-button').hasClass('active')) {
                    $('.customize-preset-popup-save').attr('disabled', false)
                } else {
                    $('.customize-preset-popup-save').attr('disabled', true)
    
                    tippy('.customize-preset-popup-save-container', {
                        content: $('.customize-preset-popup-save').data('tooltip'),
                        placement: 'top',
                        theme: 'cockpit-tippy'
                    })
                }
            })
        }
        initPresetSelect()


        // Logic for filter status buttons
        calloffDashboardContainer.on('click', '.calloff-preset-button', function() {
            $(this).toggleClass('active')
            let filterKey = $(this).data('filterKey')
            let filterValue = $(this).data('filterValue')

            calloffDashboardContainer.addClass('is-requesting')

            let url = updateButton.data('presetUrl')
            let newUrl = window.updateUrlParameterMulti(url, filterKey, filterValue)

            // update route for updating preset
            updateButton.data('presetUrl', newUrl)
            updateButton.attr('data-preset-url', newUrl)

            calloffDashboardContainer.removeClass('is-requesting')

            presetFiltersUrl = popupContainer.data('currentRoute')
            $('.calloff-preset-button.active').each(function() {
                presetFiltersUrl = window.updateUrlParameterMulti(presetFiltersUrl, $(this).data('filterKey'), $(this).data('filterValue'))
            })

            // disable save button if no statuses are selected
            if($('.calloff-preset-button').hasClass('active')) {
                $('.customize-preset-popup-save').attr('disabled', false)
            } else {
                $('.customize-preset-popup-save').attr('disabled', true)

                tippy('.customize-preset-popup-save-container', {
                    content: $('.customize-preset-popup-save').data('tooltip'),
                    placement: 'top',
                    theme: 'cockpit-tippy'
                })
            }
        })


        // Initialize select filters
        function initFilters() {
            const filter = $('.customize-preset-filter')

            filter.each(function() {
                const filter = $(this)
                const filterParent = filter.parent()

                initAjaxFilter(filter, filterParent, filterContainer, itemLoadingIcon, '', 'true')
            }) 
        }


        // Logic for clearing active filters (x on active filter labels)
        calloffDashboardContainer.on('click', '.calloff-preset-filter-label.active-filter', async function() {
            let filterKey = $(this).find('.remove-preset-active-filter').data('filter-key')
            let filterValue = $(this).find('.remove-preset-active-filter').data('filter-value')

            $(this).remove()

            let url = updateButton.data('presetUrl')
            url = window.updateUrlParameterMulti(url, filterKey, filterValue)

            updateFilterCounters()

            updateButton.data('presetUrl', url)
            updateButton.attr('data-preset-url', url)

            if($('.customize-preset-active-container .active-filter').length == 0) {
                $('.customize-preset-filters .calloff-preset-delete-all').remove()
            }
        })


        // Logic for replacing popup content without reload
        function updatePresetPopup(presetId, presetPath) {
            // Get object by id that is selected in preset filter
            // and fill popup with data from that object
            deletePresetButton.attr('disabled', false)
            updateButton.attr('disabled', false)

            presetInfo = presetData.filter(x => x.id == presetId)

            presetFiltersUrl = popupContainer.data('currentRoute')

            if(presetInfo) {
                $('.calloff-preset-delete-all').remove()

                // change preset filter value
                $('.customize-preset-filters-select').val(presetPath).trigger('change')

                // Set ID to delete button to enable preset delete function
                deletePresetButton.data('presetId', presetId)
                deletePresetButton.attr('data-preset-id', presetId)

                // Set url to save button for updating preset
                updateButton.attr('data-preset-url', `${presetPath}&name=${presetInfo[0].name}`)
                updateButton.attr('data-preset-id', `${presetId}&name=${presetInfo[0].name}`)
                updateButton.attr('data-id', presetId)

                // Activate filter buttons
                $.each(presetInfo[0].statuses, function(key, value) {
                    if(value.is_active) {
                        $(`#status${value.id}`).addClass('active')
                    } else {
                        $(`#status${value.id}`).removeClass('active')
                    }
                })

                // set url for filters for option loading filtered by status
                $('.calloff-preset-button').each(function() {
                    if($(this).hasClass('active')) {
                        presetFiltersUrl = window.updateUrlParameterMulti(presetFiltersUrl, $(this).data('filterKey'), $(this).data('filterValue'))
                    }
                })

                // Remove old active filters content
                $('.customize-preset-active-container').empty()

                // Add active filters labels
                $.each(presetInfo[0].active_display_filters, function(key, value) {
                    const markup = `<span class="calloff-preset-filter-label active-filter" data-value="${value.value}">
                                        ${value.value}
                                        <a href="javascript:;" class="remove-preset-active-filter" 
                                            data-filter-key="${value.name}"
                                            data-filter-value="${value.value}"
                                        ></a>
                                    </span>`

                    $('.customize-preset-active-container').append(markup)
                })

                if(presetInfo[0].active_display_filters != 0) {
                    $('.customize-preset-filters').append(`<button class="calloff-preset-delete-all light-blue">
                                                                ${'calloff.filter_button.delete_all_filters'.trans()}
                                                            </button>`)
                }
            }
        }
        window.callOffEdit.updatePresetPopup = updatePresetPopup


        // Logic for select filters
        function initAjaxFilter(
                filter,
                filterParent,
                loadingContainer,
                filterLoadingIcon,
                dropdownArrow = '<i class="material-icons">expand_more</i>',
            ) {
                
            presetFiltersUrl = popupContainer.data('currentRoute')

            let { filterUrl, filterName, placeholder, noResults } = filter.data()

            $('.calloff-preset-button').each(function() {
                if($(this).hasClass('active')) {
                    presetFiltersUrl = window.updateUrlParameterMulti(presetFiltersUrl, $(this).data('filterKey'), $(this).data('filterValue'))
                }
            })

            let selection = presetData

            let searchMinimum = 1

            filter.select2({
                dropdownParent: filterParent,
                minimumResultsForSearch: searchMinimum,
                ajax: {
                    // updates request url with all the current url params except for the current pagination params
                    url: () => {
                        let params = window.getUrlParams(presetFiltersUrl)
                        let keys = Object.keys(params)
                        if (keys.length){
                            keys.forEach(key => {
                                if (params[key] !== '' && key !== filterName && key != 'page_size' && key != 'page'){
                                    filterUrl = window.updateUrlParameterMulti(filterUrl.split('?')[0], key, params[key])
                                }
                            })
                            return filterUrl
                        } else {
                            return filterUrl.split('?')[0]
                        }
                    },
                    dataType: 'json',
                    processResults: data => {
                        // structure the response json to match select2 needs for filling dropdown
                        let keys = data.items[filterName] ? Object.keys(data.items[filterName]): []
                        let options = {
                            results: keys.map( key => ({
                                    text: data.items[filterName][key],
                                    id: key,
                                    value: key,
                                    name: filterName
                            })),
                            pagination: {
                                more: data.pagination.has_next_page
                            }
                        }

                        return options
                    }
                },
                language: {
                    noResults: () => noResults,
                    searching: () => filterLoadingIcon,
                    loadingMore: () => filterLoadingIcon
                },
                templateSelection: () => styleSelection(placeholder, filterName),
                templateResult: (data, container) => styleResults(data, container, selection),
                escapeMarkup: markup => markup
                
            }).on('select2:select', async function(e) {
                loadingContainer.addClass('is-requesting')
                calloffDashboardContainer.addClass('is-requesting')

                let data = e.params.data

                // add active filter labels
                const markup = `<span class="calloff-preset-filter-label active-filter" data-value="${data.text}">
                                        ${data.text}
                                        <a href="javascript:;" class="remove-preset-active-filter" 
                                            data-filter-key="${data.name}"
                                            data-filter-value="${data.value}"
                                        ></a>
                                    </span>`
                
                // if filter exists, remove active label or if it doesn't, add label
                let activeFilterArray = []
                $('.active-filter').each(function(i, element){
                    activeFilterArray.push(element.dataset.value)
                })

                if(activeFilterArray.includes(data.text)) {
                    $(`.active-filter[data-value="${data.text}"]`).remove()
                } else {
                    $('.customize-preset-active-container').append(markup)
                }

                // add remove all filters button
                const deleteButton = $('.customize-preset-filters .calloff-preset-delete-all')
                
                if($('.customize-preset-active-container .active-filter').length == 1 && !deleteButton.exists()) {
                    $('.customize-preset-filters').append(`<button class="calloff-preset-delete-all light-blue">
                                                                ${'calloff.filter_button.delete_all_filters'.trans()}
                                                            </button>`)
                }

                // remove all filters button
                if($('.customize-preset-active-container .active-filter').length == 0) {
                    $('.customize-preset-filters .calloff-preset-delete-all').remove()
                }

                // revert the selected value back to placeholder option so that the same option can be chosen again,
                // used when you immediately want to remove the chosen filter
                filter.val('preselected-option').trigger('change')

                // update path for updating preset
                let presetUrlPath = updateButton.data('presetUrl')
                presetUrlPath = window.updateUrlParameterMulti(presetUrlPath, data.name, data.text)

                // update filter placeholder with counter
                const filterCount = $('.customize-preset-active-container').find(`a[data-filter-key=${data.name}]`).length

                if (filterCount == '0') {
                    $(this).closest('.select-filter-container').find('.select2-selection__rendered').text(placeholder)

                } else {
                    let placeholderText = `<span class="text-blue">${placeholder} ( ${filterCount} )</span>`
                    $(this).closest('.select-filter-container').find('.select2-selection__rendered').html(placeholderText)
                }
    
                updateButton.data('presetUrl', presetUrlPath)
                updateButton.attr('data-preset-url', presetUrlPath)

                calloffDashboardContainer.removeClass('is-requesting')
            })
        
            // append a preselect option
            let preselectedOption = new Option(
                '',
                'preselected-option',
                true,
                true
            )
            filter.append(preselectedOption)
        
            // custom dropdown arrow
            filterParent.find('.select2-selection__arrow b').html(dropdownArrow)

            function styleSelection(placeholder, filterName) {
                const filterCount = $('.customize-preset-active-container').find(`a[data-filter-key=${filterName}]`).length
                let markup = ''

                if (filterCount == '0') {
                    markup = placeholder

                } else {
                    markup = `<span class="text-blue">${placeholder} ( ${filterCount} )</span>`
                }

                return markup
            }
            
            function styleResults(data, container, selection) {
                // Option item
                let { text, id, value, name } = data

                // list of active filter values visible in blue containers, get() is used to convert to array
                const activeFilters = $('.calloff-preset-filter-label.active-filter').map(function() {
                    return $(this).data('value')
                }).get()

                if(presetInfo[0]) {
                    selection = selection.filter(x => x.id == updateButton.data('data-id'))
                    selection = presetInfo[0].active_display_filters.filter(x => x.name == name).map(x => x.value)
                }

                let markup = ''
        
                if (activeFilters.includes(text.toString().trim())) {
                    markup = `<p class="highlighted">${text}</p>`
                } else {
                    markup = `<p>${text}</p>`
                }

                $(container).addClass('position-relative')

                return markup
            }
        }

        // Logic for deleting preset
        calloffDashboardContainer.on('click', '.customize-preset-popup-delete', async function() {
            const presetId = $(this).attr('data-preset-id')
            const path = `/api/call-offs/filter-presets/${presetId}/delete`

            try {
                calloffDashboardContainer.addClass('is-requesting')
                const { successTitle, successText, successButton } = $('.customize-preset-popup-delete').data()

                await axios.post(path)

                swal({
                    icon: 'success',
                    className: 'submit-calloff-changes-popup',
                    title: successTitle,
                    text: successText,
                    buttons: {
                        submit: {
                            text: successButton,
                            value: true
                        },
                    },
                }).then(() => {
                    location.reload()
                })
            }
            catch {
                window.showErrorPopup()
            }
        })


        // Logic for updating preset
        calloffDashboardContainer.on('click', '.customize-preset-popup-save', async function() {
            const path = $(this).data('presetUrl').split('?')[1]
            const updatePath = `/api/call-offs/filter-preset/save?${path}`

            try {
                calloffDashboardContainer.addClass('is-requesting')
                const { successTitle, successText, successButton } = $('.customize-preset-popup-save').data()

                await axios.post(updatePath)

                swal({
                    icon: 'success',
                    className: 'submit-calloff-changes-popup',
                    title: successTitle,
                    text: successText,
                    buttons: {
                        submit: {
                            text: successButton,
                            value: true
                        },
                    },
                }).then(() => {
                    window.location.href = $(this).data('presetUrl')
                })
            }
            catch {
                window.showErrorPopup()
            }
            calloffDashboardContainer.removeClass('is-requesting')
        })

        // Helper function for updating filter placeholders with counters
        function updateFilterCounters(removeAll = false) {
            $('.customize-preset-filter').each(function() {
                let { filterName, placeholder } = $(this).data()
                let filterCount = $('.customize-preset-active-container').find(`a[data-filter-key=${filterName}]`).length

                if (filterCount == '0' || removeAll) {
                    $(this).closest('.select-filter-container').find('.select2-selection__rendered').text(placeholder)

                } else {
                    let placeholderText = `<span class="text-blue">${placeholder} ( ${filterCount} )</span>`
                    $(this).closest('.select-filter-container').find('.select2-selection__rendered').html(placeholderText)
                }
            })
        }
        window.callOffEdit.updateFilterCounters = updateFilterCounters
    }
})