$(document).ready(() => {
    if($('#calloff-creation').exists()) {
        const viewContainer = $('#calloff-creation')
        const loadingContainer = $('.calloff-creation-brick-container')
        const contractId = viewContainer.data('contractId')
        const updateRoute = viewContainer.data('updateRoute')

        window.callOffCreation = {}
        window.callOffCreation.multiselectActive = false


        // Helper function for creating formData payload from post params
        function createPayload(params) {
            const keys = Object.keys(params)
            let data = new FormData()

            keys.map(key => {
                data.append(key, params[key])
            })

            return data
        }
        window.callOffCreation.createPayload = createPayload


        // Helper function for animating the calloff deletion on steps 2+
        function animateCalloffDeletion(tableRow, additionalTableClass = '', additionalTopOffset = -25) {

            // clone the row that was clicked on
            const tableRowClone = tableRow.clone()

            // create the layout for the cloned row so it can take styles from its parents' classes
            let createdTable = $(`
                <table class="table contract-table clone ${additionalTableClass}">
                    <tbody>
                        ${tableRowClone.html()}
                    </tbody>
                </table>`)
            createdTable.find('tr').addClass('clone')

            // add styling to the created table and animate it
            createdTable.offset({
                top: tableRow[0].offsetTop + additionalTopOffset,
                left: tableRow[0].offsetLeft + 10
            })
            .css({
                'opacity': '0.8',
                'position': 'absolute',
                'width': '1400px'
            })
            .appendTo($('#calloff-creation'))
            .animate({
                left: -1400
            }, 1000, function() {
                // after the animation is done, delete the clone
                createdTable.remove()
                tableRow.remove()
            })
        }
        window.callOffCreation.animateCalloffDeletion = animateCalloffDeletion


        // Logic for updating a single cart item
        async function updateCartItem(currentRow, duplicate = false) {
            let params = {}

            // get all values from the current table row
            currentRow.find('input:not(.dont-send), textarea, select').each(function() {

                // unchecked radio buttons need to be skipped manually because they are treated as separate inputs
                if ($(this).attr('type') == 'radio' && $(this).prop('checked') == false) {
                }
                else if ($(this).attr('type') == 'checkbox') {
                    params[$(this).data('name')] = $(this).prop('checked') == true
                }
                else if ($(this).attr('type') == 'number') {
                    if ($(this).data('valueToSend') == $(this)[0].defaultValue) {
                        params[$(this).data('name')] = $(this)[0].defaultValue.replace(/[^0-9\.]/g, '')
                    } else {
                        params[$(this).data('name')] = $(this).data('valueToSend')
                    }
                }
                // datepicker values need to be formatted before being sent
                else if ($(this).hasClass('calloff-date-input')) {
                    params[$(this).data('name')] = $(this).val() ? moment($(this).val().split(', ')[1], 'DD.MM.YY').format('DD.MM.YYYY') : ''
                }
                else {
                    params[$(this).data('name')] = $(this).val()
                }
            })

            // also append the id-s that are not part of inputs
            if (duplicate) {
                params['call_off_cart_item_id[]'] = ''
            } else {
                params['call_off_cart_item_id[]'] = currentRow.data('id').toString()
            }
            params['original_item_id[]'] = currentRow.data('originalItemId')
            params['contract_id'] = contractId.toString()

            // convert all params to formData
            const payload = createPayload(params)

            try {
                viewContainer.find('.step-next-button').addClass('disabled')
                await axios.post(updateRoute, payload)
                viewContainer.find('.step-next-button').removeClass('disabled')
            } catch {
                window.showErrorPopup()
            }
        }
        window.callOffCreation.updateCartItem = updateCartItem


        // Logic for updating multiple cart items
        async function updateMultipleCartItems(selectedRows, duplicate = false) {
            let data = new FormData()

            selectedRows.each(function() {
                let params = {}
                let currentRow = this

                // get all values from the current table row
                $(currentRow).find('input:not(.dont-send), textarea, select').each(function() {

                    // unchecked radio buttons need to be skipped manually because they are treated as separate inputs
                    if ($(this).attr('type') == 'radio' && $(this).prop('checked') == false) {
                    }
                    else if ($(this).attr('type') == 'checkbox') {
                        params[$(this).data('name')] = $(this).prop('checked') == true
                    }
                    else if ($(this).attr('type') == 'number') {
                        if ($(this).data('valueToSend') == $(this)[0].defaultValue) {
                            params[$(this).data('name')] = $(this)[0].defaultValue.replace(/[^0-9\.]/g, '')
                        } else {
                            params[$(this).data('name')] = $(this).data('valueToSend')
                        }
                    }
                    // datepicker values need to be formatted before being sent
                    else if ($(this).hasClass('calloff-date-input')) {
                        params[$(this).data('name')] = $(this).val() ? moment($(this).val().split(', ')[1], 'DD.MM.YY').format('DD.MM.YYYY') : ''
                    }
                    else {
                        params[$(this).data('name')] = $(this).val()
                    }
                })

                // also append the id-s that are not part of inputs
                if (duplicate) {
                    params['call_off_cart_item_id[]'] = ''
                } else {
                    params['call_off_cart_item_id[]'] = $(currentRow).data('id').toString()
                }
                params['original_item_id[]'] = $(currentRow).data('originalItemId')
                params['contract_id'] = contractId.toString()

                const keys = Object.keys(params)
                keys.map(key => {
                    data.append(key, params[key])
                })
            })

            try {
                viewContainer.find('.step-next-button').addClass('disabled')
                await axios.post(updateRoute, data)
                viewContainer.find('.step-next-button').removeClass('disabled')
            } catch {
                window.showErrorPopup()
            }
        }
        window.callOffCreation.updateMultipleCartItems = updateMultipleCartItems


        // Logic for styling filter options with or without delete icon
        function styleResults(data, selection) {
            let markup = ''
            let { text, id } = data

            if (selection == text.toString().trim() || selection == id) {
                markup = `
                            <span class="calloff-step-filter-clear"><i class="material-icons">close</i></span>
                            <p class="calloff-step-filter" href="#">
                                ${text}
                            </p>
                        `
            } else {
                markup = `
                            <p class="calloff-step-filter" href="#">
                                ${text}
                            </p>
                        `
            }
            return markup
        }
        window.callOffCreation.styleResults = styleResults


        // Logic for the navigation buttons below the table
        if ($('.navigation-button').exists()) {
            viewContainer.on('click', '.step-back-button', function() {
                loadingContainer.addClass('is-requesting')
                window.location.href = $(this).data('path')
            })
            viewContainer.on('click', '.remove-all-button', async function() {
                const path = $(this).data('path')

                try {
                    loadingContainer.addClass('is-requesting')

                    const response = await axios.get(path)
                    window.location.href = response.data.redirect_url
                } catch {
                    window.showErrorPopup()
                }
            })
            viewContainer.on('click', '.step-next-button', function() {
                loadingContainer.addClass('is-requesting')
                window.location.href = $(this).data('path')
            })
        }


        // Logic for skipping steps, only backwards
        $('.calloff-steps-container').on('click', '.calloff-step', function() {
            loadingContainer.addClass('is-requesting')
            window.location.href = $(this).data('path')
        })


        // Logic for quick-edit toggler buttons
        $('.calloff-quick-edit-btn').on('click', function() {
            $(this).addClass('active')
            $('.calloff-quick-edit-container, .quick-edit, .calloff-quick-edit').removeClass('hidden')
            window.callOffCreation.multiselectActive = true
        })
        $('.calloff-quick-edit-cancel-btn').on('click', () => {
            $('.calloff-quick-edit-container, .quick-edit, .calloff-quick-edit').addClass('hidden')
            window.callOffCreation.multiselectActive = false
            location.reload()
        })


        // Toggle class on the row when selecting a checkbox for multiselect
        viewContainer.on('change', '.calloff-quick-edit input', function () {
            $(this).closest('tr').toggleClass('multiselect-selected')
        })


        // Logic for check-all checkbox in the header
        viewContainer.on('change', '.quick-edit input', function() {
            const isChecked = $(this).prop('checked')

            $('.calloff-quick-edit input').each(function() {
                $(this).prop('checked', isChecked)
                if ($(this).prop('checked') == true) {
                    $(this).closest('tr').addClass('multiselect-selected')
                } else {
                    $(this).closest('tr').removeClass('multiselect-selected')
                }
            })
        })


        // Logic for character counter on notes popup input
        if ($('.calloff-item-note').exists()) {
            // init
            $('.calloff-item-note').each(function() {
                setNotesLength($(this))
            })

            $('.calloff-item-note').on('input', function() {
                const popupContainer = $(this).closest('.notes-popup-container')
                const lengthValueContainer = popupContainer.find('.notes-length-value')

                const text = `${$(this).val().length}/${$(this).attr('maxlength')}`

                lengthValueContainer.text(text)

                setNotesLength($(this))
            })
        }

        function setNotesLength(note) {
            const popupContent = note.closest('.notes-popup-content')
            const notesLengthValue = popupContent.find('.notes-length-value')
            const textLength = note.val().length
            const maxLength = note.attr('maxLength')

            notesLengthValue.text(`${textLength}/${maxLength}`)
        }

        // Helper function for duplicating cart item
        async function duplicateCartItem(duplicateButton, updateView) {
            const thisRow = duplicateButton.closest('tr')
            const thisRowCheckboxChecked = thisRow.find('.calloff-quick-edit input').prop('checked') == true
            const selectedRows = $('.multiselect-selected')

            loadingContainer.addClass('is-requesting')

            if (window.callOffCreation.multiselectActive && thisRowCheckboxChecked) {
                try {
                    await window.callOffCreation.updateMultipleCartItems(selectedRows, true)
                    await updateView()
                } catch {
                    window.showErrorPopup()
                }

            } else {
                try {
                    await window.callOffCreation.updateCartItem(thisRow, true)
                    await updateView()
                } catch {
                    window.showErrorPopup()
                }
            }

            loadingContainer.removeClass('is-requesting')
            $('.calloff-quick-edit-btn').removeClass('active')
        }
        window.callOffCreation.duplicateCartItem = duplicateCartItem


        // Remove loader when the page is fully loaded
        loadingContainer.removeClass('is-requesting')
    }
})