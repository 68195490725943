$(document).ready(function () {

      const navigation = $('.menu-template-container')

      if (navigation.exists()) {
        const body = $('body')
        const mainNavToggler = $('#toggle-nav-menu')
        const menuToggler = $('#menu-icon')

        const iconMenuToggler = $('#toggle-icon-menu')
       
        function toggleNav() {
          if (body.hasClass('active-menu')){
              body.removeClass('active-menu')
          } else {
            body.addClass('active-menu')
          }
        }
        mainNavToggler.on('click', toggleNav)
        menuToggler.on('click', toggleNav)

        function toggleIconMenu() {
          const leftMenu = $('.apps-list')
          const rightMenu = $('.current-app-menu')
          const menuItemBtn = $('.nav-item-button')
          const menuItemIcon = $('.nav-item-icon')
          const menuListItemBtn = $('.nav-list-item-button')
          const menuListItemIcon = $('.nav-list-item-icon')
  
          iconMenuToggler.toggleClass('narrow')

          // if (!body.hasClass('active-menu') && body.hasClass('empty-menu')) {
          //   body.addClass('active-menu')
          // }
  
          if (iconMenuToggler.hasClass('narrow')) {
            //if (body.hasClass('empty-menu')) {
              body.removeClass('active-menu')
            //}
            body.addClass('narrow-menu')
            leftMenu.addClass('hide-items')
            rightMenu.addClass('hide-items')
            menuItemBtn.addClass('hidden')
            menuItemIcon.removeClass('hidden')
            menuListItemBtn.addClass('hidden')
            menuListItemIcon.removeClass('hidden')
            localStorage.setItem('iconMenuActive', true)
          } else {
            if (body.hasClass('empty-menu')) {
              body.addClass('active-menu')
            }
            body.removeClass('narrow-menu')
            leftMenu.removeClass('hide-items')
            rightMenu.removeClass('hide-items')
            menuItemIcon.addClass('hidden')
            menuListItemIcon.addClass('hidden')
            localStorage.setItem('iconMenuActive', false)
            setTimeout(function() {
              menuItemBtn.removeClass('hidden')
              menuListItemBtn.removeClass('hidden')
            }, 250)  
          }
        }
        iconMenuToggler.on('click', toggleIconMenu)


        // Logic for checking if menu item needs to be toggleable or just redirect to its href
        $(document).on('click', '.nav-item--collapsible', function(e) {
          let childnavElements = $('.nav-item--child')
          let target = $(e.target)
          if (!(childnavElements.is(target) || childnavElements.has(target).length)){
            e.preventDefault()
            $(this).toggleClass('open')
          }
        })


        // Logic for showing loaders when a menu item is selected
        $(document).on('click', '.apps-list .nav-item', () => {
          $('body').addClass('is-requesting')
        })
        $(document).on('click', '.current-app-menu .nav-item', function() {
          if (!$(this).hasClass('nav-item--collapsible')) {
            $('body').addClass('is-requesting')
          }
        })


      $('.apps-list').on('click', '.nav-link', function() {
          console.log($(this).attr('href'))
          if($(this).attr('href').indexOf('/customer-dashboard') >= 0) {
            $(this).addClass('dashboard-btn')
            let url = $(this).attr('href')

            // Add page number that was choosen on the dashboard to left navigation button
            if(localStorage.getItem('dashboardPage')) {
                const pageNumber = localStorage.getItem('dashboardPage')
                url = window.updateUrlParameter(url, 'page', pageNumber)
            }
            $(this).attr('href', url)
          }
        })
      }
}); 