$(document).ready(() => {
    if ($('#highway-calloff-creation').exists()) {
        const callOffCreationContainer = $('.calloff-edit-content-container')
        const materialGroupInput = $('.material-group')
        const materialCategoryInput = $('.material-category')
        const materialNumberInput = $('.material-popup-opener .material-number')
        const materialNameInput = $('.material-popup-opener .material-name')
        const factoryInput = $('.material-popup-opener .factory')
        const materialPopup = $('.highway-calloff-edit-material-popup-container .material-popup')
        const noResults = $('#select-with-translation-data').data('noResults')

        // Status select2 init
        initCalloffEditStatusSelect()

        // Initialize contact person
        const {
            contactPersonPopupRoute,
            ContactPersonPopupClass
        } = window.highwayCOCData

        const contactPersonsPopupContainer = $('.calloff-creation-contact-popup-container')
        const ContactPersonsPopup = new ContactPersonPopupClass(contactPersonsPopupContainer, {
            apiRoute: contactPersonPopupRoute,
            onSubmit: data => setContactPersonsData(data)
        })

        // Initialize factoryDaily plan
        const {
            factoryDailyPlanRoute,
            FactoryDailyPlanClass,
        } = window.highwayCOCData

        const factoryDailyPlanContainer = callOffCreationContainer.find('.calloff-edit-side-dropdown-content.factory-daily')

        if(factoryDailyPlanContainer.exists()) {
            const FactoryDailyPlan = new FactoryDailyPlanClass(factoryDailyPlanContainer, {
                apiRoute: factoryDailyPlanRoute,
                contractItemId: window.highwayCOCData.contractData.contract_item_id,
            })

            initFactoryDailyPlan()
        }

        // Logic for the bottom back button
        callOffCreationContainer.on('click', '.calloff-back-button', async function() {
            // TODO: Add cart clear request

            callOffCreationContainer.addClass('is-requesting')
            window.location.href = $(this).data('path')
        })

        // Save current progress
        callOffCreationContainer.on('click', '.calloff-edit-controls-container .save-button', function() {
            const $button = $(this)
            const factoryInput = $('.material-popup-opener .factory')

            let valid = true

            if(!factoryInput.val()) {
                valid = false
                callOffCreationContainer.find('.material-popup-opener').addClass('has-error')
            }

            if (valid) {
                saveFormAction($button, 0)
            } else {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }

        })

        // Trigger review button
        callOffCreationContainer.on('click', '.calloff-edit-controls-container .review-button', function() {
            const $button = $(this)

            const factoryInput = callOffCreationContainer.find('.material-popup-opener .factory')
            const dateInput = callOffCreationContainer.find('.calloff-edit-date-input')
            const statusSelect = callOffCreationContainer.find('.status-select')
            const startTimeInput = callOffCreationContainer.find('.calloff-edit-time-start-input')
            const endTimeInput = callOffCreationContainer.find('.calloff-edit-time-end-input')
            const quantityInput = callOffCreationContainer.find('.calloff-edit-quantity-input')

            let valid = true

            // Contract item needs to be selected
            if(!factoryInput.val()) {
                valid = false
                callOffCreationContainer.find('.material-popup-opener').addClass('has-error')
            }

            // Dates need to be set
            if(!dateInput.val()) {
                valid = false
                dateInput.addClass('date-changed')
            }

            // Status is not "0" (Blue one) => date inputs are required
            if(statusSelect.val() != 0) {
                if(!startTimeInput.val()) {
                    valid = false
                    startTimeInput.addClass('date-changed')
                }

                if(!endTimeInput.val()) {
                    valid = false
                    endTimeInput.addClass('date-changed')
                }
            }

            // Quantity is required
            if (!quantityInput.data('value-to-send')) {
                valid = false
                quantityInput.addClass('empty-value')
            }

            // Contact persons are required
            const { contact_person, created_by_customer } = window.highwayCOCData.contactPersons
            if (!contact_person || !created_by_customer) {
                valid = false

                const contactPersonsContainer = callOffCreationContainer.find('.calloff-edit-side-content-container .calloff-edit-customer-data')
                contactPersonsContainer.addClass('has-error')
            }

            if (valid) {
                submitFormAction($button, 1)
            } else {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }
        })

        // Logic for submitting the calloff changes
        callOffCreationContainer.on('click', '.calloff-edit-controls-container .submit-button', function() {
            const $button = $(this)

            const factoryInput = callOffCreationContainer.find('.material-popup-opener .factory')
            const dateInput = callOffCreationContainer.find('.calloff-edit-date-input')
            const statusSelect = callOffCreationContainer.find('.status-select')
            const startTimeInput = callOffCreationContainer.find('.calloff-edit-time-start-input')
            const endTimeInput = callOffCreationContainer.find('.calloff-edit-time-end-input')
            const quantityInput = callOffCreationContainer.find('.calloff-edit-quantity-input')

            let valid = true

            // Contract item needs to be selected
            if(!factoryInput.val()) {
                valid = false
                callOffCreationContainer.find('.material-popup-opener').addClass('has-error')
            }

            // Dates need to be set
            if(!dateInput.val()) {
                valid = false
                dateInput.addClass('date-changed')
            }

            // Status is not "0" (Blue one) => date inputs are required
            if(statusSelect.val() != 0) {
                if(!startTimeInput.val()) {
                    valid = false
                    startTimeInput.addClass('date-changed')
                }

                if(!endTimeInput.val()) {
                    valid = false
                    endTimeInput.addClass('date-changed')
                }
            }

            // Quantity is required
            if (!quantityInput.data('value-to-send')) {
                valid = false
                quantityInput.addClass('empty-value')
            }

            // Contact persons are required
            const { contact_person, created_by_customer } = window.highwayCOCData.contactPersons
            if (!contact_person || !created_by_customer) {
                valid = false

                const contactPersonsContainer = callOffCreationContainer.find('.calloff-edit-side-content-container .calloff-edit-customer-data')
                contactPersonsContainer.addClass('has-error')
            }

            if (valid) {
                submitFormAction($button, 0)
            } else {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }

        })

        // Logic for sidebar accordions
        callOffCreationContainer.on('click', '.calloff-edit-side-dropdown-toggler', function() {
            const dropdownContent = $(this).closest('.calloff-edit-side-dropdown').find('.calloff-edit-side-dropdown-content')
            const togglerArrow = $(this).find('.toggler-arrow')

            $('.calloff-edit-side-content-container').find('.calloff-edit-side-dropdown-toggler.active').removeClass('active')
            $('.calloff-edit-side-content-container').find('.calloff-edit-side-dropdown-content:not(.hidden)').addClass('hidden')
            $('.calloff-edit-side-content-container').find('.toggler-arrow.rotate').removeClass('rotate')

            $(this).toggleClass('active')
            dropdownContent.toggleClass('hidden')
            togglerArrow.toggleClass('rotate')
        })

        // Logic for opening contact persons popup
        callOffCreationContainer.on('click', '.calloff-edit-side-content .contact-person-input', () => {
            // Show popup with selected data
            const { contactPersons } = window.highwayCOCData
            ContactPersonsPopup.showPopup(contactPersons)
        })

        // Update factory daily plan days
        callOffCreationContainer.on('change', '.calloff-edit-date-start-input', function() {
            if(typeof FactoryDailyPlan === 'undefined') {
                return
            }

            const fPInstance = this._flatpickr
            const selectedDate = fPInstance.selectedDates[0]

            if(selectedDate) {
                callOffCreationContainer.find('.calloff-edit-date-input').removeClass('date-changed')
                FactoryDailyPlan.setStartDate(selectedDate)
            } else {
                FactoryDailyPlan.setStartDate(null)
            }
        })

        // Remove validation error when time is set
        callOffCreationContainer.on('change', '.calloff-edit-time-input', function() {
            const $input = $(this)

            if($input.val()) {
                $input.removeClass('date-changed')
            }
        })

        // Logic for opening the material popup
        callOffCreationContainer.on('click', '.material-popup-opener', () => {
            $('.highway-calloff-edit-material-popup-container').removeClass('hidden')

            $('.material-popup-table tbody tr').removeClass('active')

            const selectedContractItemId = window.highwayCOCData.contractData.contract_item_id

            // set the class 'active' on the selected contract item
            materialPopup.find('.material-popup-table tbody tr').each(function() {
                if ($(this).data('contractItemId') == selectedContractItemId) {
                    $(this).addClass('active')
                }
            })

            // remove the leftover param if there isnt any selected material
            if (!selectedContractItemId) {
                window.highwayCOCData.contractData.contract_item_id = ''
            }

            // hide scroll from body to avoid having 2 scroll bars
            $('html').css('overflow-y', 'hidden')
        })

        // Logic for submitting the material popup changes
        materialPopup.on('click', '.add-to-cart-button', function() {
            const thisRow = $(this).closest('tr')
            const quantityInputPlaceholder = $('.calloff-edit-quantity-input').attr('placeholder')
            const quantityInputValue = parseInt(quantityInputPlaceholder.replaceAll('.', ''))

            // get the new values from the popup
            const { contractItemId, materialGroup, materialCategory, warningAmount, quantityUnit, availableIncoterms, availableVehicles } = thisRow.data()
            const materialNumber = thisRow.find('.material-number-new').text().trim()
            const materialName = thisRow.find('.material-name-new').text().trim()

            let factory = thisRow.find('.factory-new').text().trim()
            factory = factory == '—' ? '' : factory

            // change the input values
            materialNumberInput.val(materialNumber)
            materialNameInput.val(materialName)
            factoryInput.val(factory)

            // change the text values
            materialGroupInput.text(materialGroup)
            materialCategoryInput.text(materialCategory)

            // Set contract_item_id
            window.highwayCOCData.contractData.contract_item_id = contractItemId

            callOffCreationContainer.find('.material-popup-opener').removeClass('has-error')

            // close the popup and return the scroll functionality to body
            $('.highway-calloff-edit-material-popup-container').addClass('hidden')
            $('html').css('overflow-y', 'auto')

            // change the data attribute values
            //$('.calloff-edit-table').data('selectedContractItemId', contractItemId)
            $('.calloff-edit-quantity-input').data('warningLimit', warningAmount)
            $('.calloff-edit-quantity-input').data('unit', quantityUnit)
            $('.calloff-edit-hourly-quantity-input').data('unit', `${quantityUnit}/h`)

            // toggle amount warning if necessary
            if (warningAmount && (quantityInputValue > warningAmount)) {
                $('.quantity-warning').removeClass('hidden')
            } else {
                $('.quantity-warning').addClass('hidden')
            }

            //  create new options and fill the incoterm select after destroying the old instance
            const availableIncotermsOptions = availableIncoterms.map(option => ({
                'id': option.id,
                'text': option.title
            }))

            $('.incoterm-select').select2('destroy')
            $('.incoterm-select').html('')
            $('.incoterm-select').select2({
                minimumResultsForSearch: -1,
                dropdownCssClass: 'calloff-edit-data-dropdown',
                language: {
                    noResults: () => noResults
                },
                data: availableIncotermsOptions
            })

            // same for the vehicle select
            const availableVehiclesOptions = availableVehicles.map(option => ({
                'id': option.id,
                'text': option.title
            }))

            $('.truck-type-select').select2('destroy')
            $('.truck-type-select').html('')
            $('.truck-type-select').select2({
                minimumResultsForSearch: -1,
                dropdownCssClass: 'calloff-edit-data-dropdown',
                language: {
                    noResults: () => noResults
                },
                data: availableVehiclesOptions
            })
        
            // Update daily plan
            FactoryDailyPlan.setContractItemId(contractItemId)
        })

        // Logic for removing the selected material through material popup
        materialPopup.on('click', '.remove-from-cart-button', function() {
            // change the input values
            materialNumberInput.val('')
            materialNameInput.val('')
            factoryInput.val('')

            materialGroupInput.text('')
            materialCategoryInput.text('')

            $('.quantity-warning').addClass('hidden')

            window.highwayCOCData.contractData.contract_item_id = ''
            // close the popup and return the scroll functionality to body
            $('.highway-calloff-edit-material-popup-container').addClass('hidden')
            $('html').css('overflow-y', 'auto')

            FactoryDailyPlan.setContractItemId('')            
        })

        /**
         * Save action
         */
        async function saveFormAction($button, $in_review) {
            try {
                $button.addClass('is-requesting')
                callOffCreationContainer.addClass('is-requesting')

                const { saveRoute } = window.highwayCOCData
                const payload = crateFormPayload($in_review)

                // Make request
                await axios.post(saveRoute, payload)

                $button.removeClass('is-requesting')
                callOffCreationContainer.removeClass('is-requesting')

            } catch(err) {
                $button.removeClass('is-requesting')
                callOffCreationContainer.removeClass('is-requesting')
                window.showErrorPopup()
                throw err
            }
        }

        /**
         * Submit Action
         */
        async function submitFormAction($button, $in_review = 0) {
            try {
                $button.addClass('is-requesting')
                callOffCreationContainer.addClass('is-requesting')

                const { submitRoute } = window.highwayCOCData
                const payload = crateFormPayload($in_review)

                // Make request
                const response = await axios.post(submitRoute, payload)
                const { data } = response

                showSuccessPopup(data)

                $button.removeClass('is-requesting')
                callOffCreationContainer.removeClass('is-requesting')

            } catch(err) {
                $button.removeClass('is-requesting')
                callOffCreationContainer.removeClass('is-requesting')
                window.showErrorPopup()
                throw err
            }
        }

        /**
         * Create proper FormData
         */
        function crateFormPayload($in_review = 0) {
            const payload = new FormData()

            // Contract & Contract item
            const { contract_id, contract_item_id } = window.highwayCOCData.contractData

            payload.append('id', contract_id)
            payload.append('contract_item_id', contract_item_id)
            payload.append('in_review', $in_review)

            // Get all of the selected values from selects and text-areas
            callOffCreationContainer.find('select, textarea').each((_, element) => {
                const $input = $(element)

                const value = $input.val()
                const { name } = $input.data()

                // Add to form data
                payload.append(name, value)
            })

            // Get all of the selected values from inputs
            callOffCreationContainer.find('input').each((_, element) => {
                const $input = $(element)

                let value = null
                const { name } = $input.data()

                // Skip "preview" ones
                if($input.hasClass('contact-person-input')) {
                    return
                }

                if ($input.attr('type') == 'checkbox') {
                    value = $input.prop('checked') == true ? '1' : '0'
                } else if ($input.attr('type') == 'number') {
                    if ($input.data('valueToSend') == $input[0].defaultValue) {
                        value = $input[0].defaultValue.replace(/[^0-9\.]/g, '')
                    } else {
                        value = $input.data('valueToSend')
                    }
                } else if($input.hasClass('calloff-edit-date-input')) {
                    // Get propper date format from flatpicker instance
                    const fPInstance = element._flatpickr
                    const selectedDate = fPInstance.selectedDates[0]

                    value = selectedDate
                        ? fPInstance.formatDate(selectedDate, 'd.m.Y')
                        : ''
                } else if($input.hasClass('calloff-edit-time-input')) {
                    value = $input.val() || '00:00'
                } else {
                    value = $input.val()
                }

                // Add to form data
                payload.append(name, value)
            })

            // Contact persons
            const { contact_person, created_by_customer } = window.highwayCOCData.contactPersons
            if (contact_person && created_by_customer) {
                payload.append('contact_person', contact_person)
                payload.append('created_by_customer', created_by_customer)
            }

            return payload
        }

        // Show success popup
        function showSuccessPopup(responseData = {}) {
            const {
                call_of_edit_path,
            } = responseData

            const {
                title,
                text,
                duplicateButton,
                overviewButton,
                closeButton
            } =  window.highwayCOCData.successPopupData

            // Show popup with info
            const popupContent = `
                <div class="popup-content">
                    <div class="close-popup-button" data-path="${call_of_edit_path}"></div>
                    <div class="popup-title">${title}</div>
                    <div class="popup-text">${text}</div>
                </div>
            `
            const wrapper = document.createElement('div')
            wrapper.innerHTML = popupContent

            swal({
                icon: 'success',
                content: wrapper,
                className: 'submit-calloff-sucess-popup',
                closeOnEsc: false,
                closeOnClickOutside: false, // Disable close
                buttons: {
                    duplicate: {
                        closeModal: false,
                        text: duplicateButton,
                        value: 1,
                    },
                    overview: {
                        closeModal: false,
                        text: overviewButton,
                        value: 2,
                    },
                    edit: {
                        closeModal: false,
                        text: closeButton,
                        value: 3,
                    }
                }
            }).then(value => {
                handleSuccessPopupSubmit(value, responseData)
            })
        }

        // Close success popup listener
        $(document).on('click', '.submit-calloff-sucess-popup .close-popup-button', function() {
            const $button = $(this)
            const { path } = $button.data()

            if(path) {
                window.location.href = path
            } else {
                swal.close()
                location.reload()
            }
        })

        // Succes popup submision handler
        async function handleSuccessPopupSubmit(buttonValue, responseData) {
            try {
                const {
                    call_off_duplicate_path,
                    contract_overview_path,
                    call_of_edit_path,
                } = responseData

                const { buttonLoader } = window.highwayCOCData

                const popup = $('.submit-calloff-sucess-popup')
                const button = popup.find('.swal-button--loading')

                popup.addClass('is-requesting')
                button.append(buttonLoader)

                if (buttonValue === 1) {
                    // Duplicate action
                    const response = await axios.get(call_off_duplicate_path, window.axiosConfig)
                    const { data } = response

                    // Redirect to duplicated screen
                    if(data.redirect_url) {
                        window.location.href = data.redirect_url
                    }
                } else if(buttonValue === 2) {
                    // Redirect to overview
                    window.location.href = contract_overview_path
                } else if(buttonValue === 3) {
                    // Redirect to edit
                    window.location.href = call_of_edit_path
                }

            } catch(err) {
                window.showErrorPopup()
                throw err
            }
        }

        // Set contact persons values
        function setContactPersonsData(data) {
            const { contact_person, created_by_customer } = data

            // Simple data mapper
            const mapData = data => ({
                ...data,
                name: `${data.first_name} ${data.last_name}`,
                phone: data.phone_number ? `${data.phone_country} ${data.phone_number}` : '',
                mobile: data.mobile_number ? `${data.mobile_country} ${data.mobile_number}` : '',
            })

            // Set selected data to sidebar previews
            const contactPersonData = mapData(contact_person)
            const createdByPersonData = mapData(created_by_customer)

            Object.keys(contactPersonData).forEach(key => {
                const value = contactPersonData[key]

                const input = callOffCreationContainer.find(`.calloff-edit-customer-table[data-name="contact_person"] input[data-name="${key}"]`)
                if(input.exists()) {
                    input.val(value)
                }
            })

            Object.keys(createdByPersonData).forEach(key => {
                const value = createdByPersonData[key]

                const input = callOffCreationContainer.find(`.calloff-edit-customer-table[data-name="created_by_customer"] input[data-name="${key}"]`)
                if(input.exists()) {
                    input.val(value)
                }
            })

            const contactPersonsContainer = callOffCreationContainer.find('.calloff-edit-side-content-container .calloff-edit-customer-data')
            contactPersonsContainer.removeClass('has-error')

            window.highwayCOCData.contactPersons = {
                'contact_person': contactPersonData.id,
                'created_by_customer': createdByPersonData.id,
            }
        }

        function initFactoryDailyPlan() {
            // Set days
            const dateStartInput = callOffCreationContainer.find('.calloff-edit-date-start-input')
            if(dateStartInput.val() && dateStartInput[0]._flatpickr) {
                const fPInstance = dateStartInput[0]._flatpickr
                const selectedDate = fPInstance.selectedDates[0]

                if(selectedDate) {
                    FactoryDailyPlan.setStartDate(selectedDate)
                }
            }

            // Set contract item
            const { contract_item_id } = window.highwayCOCData.contractData
            if(contract_item_id) {
                FactoryDailyPlan.setContractItemId(contract_item_id)
            }
        }

        // Logic for initializing selects
        function initCalloffEditStatusSelect() {
            const statusSelect = callOffCreationContainer.find('.status-select')

            const styleOption = (item) => {

                const { text } = item
                const icon = $(item.element).data('icon')
                const iconClasses = $(item.element).data('iconClasses')

                return `
                    <span class="${iconClasses}">${icon}</span>
                    ${text}
                `
            }

            statusSelect.select2({
                minimumResultsForSearch: -1,
                dropdownCssClass: 'calloff-edit-data-dropdown',
                escapeMarkup: markup => markup,
                templateResult: styleOption,
                templateSelection: styleOption,
                language: {
                    noResults: () => noResults
                }

            }).on('select2:select', function(e) {
                // update the data-status on table
                $('.calloff-edit-table').data('status', e.params.data.id)

                // toggle a class on button container
                if (e.params.data.id == '0') $('.calloff-edit-controls-container').addClass('status-proposing')
                else $('.calloff-edit-controls-container').removeClass('status-proposing')
            })
        }
    }
})