<template>
   <div class="note-controls">
        <div class="button-row top-button-row">
            <button class="btn btn-primary" ref="saveSubmit" :disabled="disableSave" @click="submitSave">{{"disposition.calloffs.save_draft"|trans}}</button>
            <button v-if="status==1" @click="dispoExport" ref="dispoSubmit"  class="btn btn-primary" >{{"disposition.calloffs.export_dispo_software"|trans}}</button>
        </div>
        <div class="notes-container">
            <div class="note-container">
                <p class="heading">{{"disposition.call-off.customer_note_title"|trans}}</p> 
                <textarea name="customer_note" v-model="customerNote"></textarea>
            </div>
            <div class="note-container">
                <p class="heading">{{"disposition.call-off.internal_note_title"|trans}}</p>
                <textarea name="internal_note" v-model="internalNote"></textarea>
            </div>
        </div>
        <div class="button-row">
            <span v-if="this.item.is_reminder_sent_today && this.status===0" class="reminder-message-sent">{{"disposition.call-off.reminder_message_already_sent_text"|trans}}</span>
            <button class="btn btn-primary" ref="sendSubmit" :disabled="disableSubmit" @click="submitSend">{{submitButton}}</button>
        </div>
    </div>
   
</template>

<script>
import EventBus from './../eventBus.js';
export default {
    props: {
        status: {
            type: Number
        },
        saveUrl: {
            type: String
        },
        sendUrl:{
            type: String
        },
        exportUrl:{
            type: String
        },
        approveUrl:{
            type: String
        },
        item: {
            type: Object
        },
        approveCancelationUrl: {
            type: String
        },
        proposeCalloffUrl: {
            type: String
        },
        calloffEdited: {
            type: Boolean
        }
    },
    data() {
        return {
            customerNote: this.item.reminder_message_draft && this.item.reminder_message_draft.customer_note ? this.item.reminder_message_draft.customer_note : '',
            internalNote: this.item.reminder_message_draft && this.item.reminder_message_draft.internal_note ? this.item.reminder_message_draft.internal_note : '',
            confirmEditCalloff: false,
            //translations
        }
       
    },
    mounted: function() {
        // EventBus.$on('calloffEdit', this.editCalloffAction)
        // this.checkData()
    },
    computed: {
        submitButton : function() {
            if (this.status==2) {
                return  this.$root.$options.filters.trans("disposition.calloffs.confirm_request")
            } 
            
            if (this.status==1) {
                if (this.calloffEdited) {
                    return this.$root.$options.filters.trans("disposition.calloffs.send_calloff_proposal")
                }
                return  this.$root.$options.filters.trans("disposition.calloffs.send_confirmation_to_customer")
            } 
            
            return this.$root.$options.filters.trans("disposition.calloffs.send_reminder")
         
            // if (!this.confirmEditCalloff){
            //     return window.submitButtonTrans
            // } else {
            //     return window.confirmCalloff
            // }
            
        },
        disableSubmit: function() {
            if (this.status===1 || this.status===2 ) {
                return false
            }
            if (this.item.is_reminder_sent_today && this.status===0) {
                return true
            }
            if (this.customerNote==''){
                return true
            }
            return false
        },
        disableSave: function() {
            if (this.customerNote=='' && this.internalNote==''){
                return true
            }
          
            return false
        },
        popupHeading: function() {
            if (this.status===0){
                return  this.$root.$options.filters.trans("disposition.calloff.reminder.action_success.heading")
            }
            if (this.status==1){
                if (this.calloffEdited){
                    return  this.$root.$options.filters.trans("disposition.calloff.propose_calloff.action_success.heading")
                }
                return  this.$root.$options.filters.trans("disposition.calloff.binding_request.action_success.heading")
            }
            if (this.status==2){
                return  this.$root.$options.filters.trans("disposition.calloff.cancellation_request.action_success.heading")
            }
        },
        popupContent: function(){
             if (this.status===0){
                return  this.$root.$options.filters.trans("disposition.calloff.reminder.action_success.content")
            }
            if (this.status==1){
                  if (this.calloffEdited){
                    return  this.$root.$options.filters.trans("disposition.calloff.propose_calloff.action_success.content")
                }
                return  this.$root.$options.filters.trans("disposition.calloff.binding_request.action_success.content")
            }
            if (this.status==2){
                return  this.$root.$options.filters.trans("disposition.calloff.cancellation_request.action_success.content") 
            }
        },
        editCalloffAction: function() {
            this.confirmEditCalloff = true 
        }
    },
    methods: {
        submitSave(e){
            e.preventDefault()
            this.$refs['saveSubmit'].classList.add('disabled')
            this.saveUrl = window.updateUrlParameter(this.saveUrl, 'customer_note', this.customerNote )
            this.saveUrl = window.updateUrlParameter(this.saveUrl, 'internal_note', this.internalNote )
            window.location.href = this.saveUrl
            
        },
        submitSend(e){
            e.preventDefault()
            let button =this.$refs['sendSubmit']
            button.classList.add('disabled')
            let url 
            if (this.status === 1) {
                if (this.calloffEdited){
                    url = this.proposeCalloffUrl
                } else {
                    url =this.approveUrl 

                }
            } else if ( this.status === 2 ) {
                url = this.approveCancelationUrl
            } else {
                url = this.sendUrl
            }      
            url = window.updateUrlParameter(url, 'customer_note', this.customerNote )
            url = window.updateUrlParameter(url, 'internal_note', this.internalNote )
            axios.get(url)
            .then(res=> {
                if (res.data.status == 200) {
                   
                    swal({
                        className: 'calloff-status-modal',
                        title: this.popupHeading,
                        text:  this.popupContent,
                        buttons: this.$root.$options.filters.trans("calloff.form.popup-success-button"),
                        icon: "success",

                    }).then(function(){
                        
                        window.location.reload()
                        //  res.redirect_url
                    })
                } else {
                    if (res.data && res.data.message) {
                        window.showErrorPopup(res.data.message, null, true)

                    } else if (res.message){                     
                        window.showErrorPopup(res.message, null, true)

                    } else {
                        window.showErrorPopup(null, null, true)
                    }
                    button.classList.remove('disabled')

                   
                } 
            }).catch(err=> {
                    if (err.response && err.response.data && err.response.data.message) {
                        window.showErrorPopup(err.response.data.message, null, true)

                    } else if (err.message){
                        window.showErrorPopup(err.message, null, true)

                    } else {
                        window.showErrorPopup(null, null, true)
                       
                    }
                throw err
            })
           
        },
        dispoExport(e) {
            e.preventDefault()
            this.$refs['dispoSubmit'].classList.add('disabled')
            axios.get(this.exportUrl)
            .then(res=>{
               if (res.status == 200) {
                    swal({
                        className: 'calloff-status-modal',
                        title:  this.$root.$options.filters.trans("disposition.calloff.export.action_success.heading") ,
                        text:  this.$root.$options.filters.trans("disposition.calloff.export.action_success.content"),
                        buttons: this.$root.$options.filters.trans("calloff.form.popup-success-button"),
                        icon: "success",

                    }).then(function(){
                        
                        window.location.reload()
                    })
               
               }

            })
            .catch(err=>{
                window.showErrorPopup()
                throw err
            })
        },
    
        checkData(){
            let last = this.item.reminder_messages.length -1 
            if (this.item.reminder_message_draft && this.item.reminder_message_draft.internal_note){
                this.internalNote = this.item.reminder_message_draft.internal_note
                return
            }
            if (this.item.reminder_messages.length && this.item.reminder_messages[last].internal_note){
                this.internalNote = this.item.reminder_messages[last].internal_note
                return
            }
            this.internalNote = ''
                
        }
    },
  
}
</script>

<style>

</style>