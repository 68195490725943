$(document).ready(() => {
    let searchState = false
    const headerSearch = $('body .customer-dashboard-header-search')
    const searchLoader = $('#search-loader').html()

    let searchQuery = headerSearch.data('value')
    let materialSearchQuery = headerSearch.data('materialValue')

    if(
        headerSearch.length > 0
    ) {
        // Onload init
        initHeaderSearch()

        function initHeaderSearch() {
            searchState = false
            // GETselect

            headerSearch.select2({
                width: '',
                multiple: true,
                allowClear: true,
                minimumInputLength: 1,
                containerCssClass: 'header-search-select2',
                placeholder: headerSearch.data('message-placeholder'),
                dropdownParent: $('.search-bar'),
                ajax: {
                    transport: window.customTransportFunction,

                    url: headerSearch.data('message-api_route'),
                    dataType: 'json',
                    cache: false,

                    data: (params) => {
                        return {
                            term: $.trim(params.term)
                        }
                    },
                    processResults: (data, { term }) => {
                        handleSearchWithContractsList(term)
                        return {
                            results: data
                        }
                    },
                },
                language: {
                    inputTooShort: () => headerSearch.data('message-add_more_text'),
                    noResults: () => headerSearch.data('message-no_results'),
                },
                escapeMarkup: (markup) => markup,
                templateResult: formatSearchData,
                templateSelection: formatSelectedSearch
            }).on('select2:select', function(e) {
                // Redirect on select action
                const path = e.params.data.path
                // window.location.href = path
                window.history.pushState(null, null, path)
            }).on('select2:clear', function(e) {
                window.history.pushState(null, null, window.updateUrlParameter(window.location.href, 'searchTerm', ''))
                
                handleSearchWithContractsList('')
                searchQuery = null
                searchInput.empty()
            }).on('select2:opening', function(e){
                if(searchState){
                    e.preventDefault();

                }
            })
            const searchInput = $('.header-search-select2 .select2-search__field')

            // Clear url params on empty input field
            searchInput.on('keyup', function(event) {
                const value = $(this).val()
    
                if (value == '' && event.keyCode == 8) {
                    handleSearchWithContractsList('')
                }
            })
    
            // Event handlers for input blur and enter
            searchInput.on('blur', function(event) {
                // 2nd target for blur event
                const target = $(event.relatedTarget)
    
                if(target.hasClass('search-result-link')) {
                    event.preventDefault()
    
                    let href = target.attr('href')
                    window.location.href = href
                } else {
                    showSearchQuery($(this))
                }
            })
    
            searchInput.on('keyup', function(e){
                if (e.keyCode == 13) {
                    showSearchQuery($(this))
                }
            })
    
            // Handle search query display
            function showSearchQuery($input) {
               
                const term = $input.val()
                if (term && term == ''){
                    searchState = false
                }
    
                if (term && term !== '' && !searchState){
                    

                    searchInput.addClass('hidden')
                    searchState = true
    
                    let markup = `
                        <div class="search-query">
                            <span>${term}</span>
                            <i class="material-icons">close </i>
                        </div>
                    `
    
                    $input.after(markup)
                    headerSearch.select2('close')
                    // Clear
                    const searchQueryElement = $input.siblings('.search-query')
                    searchQueryElement.on('click', function(){
                        $(this).remove()
                        headerSearch.trigger('select2:clear')
                        searchState = false
                    })
                }
    
            }
            /**
             * Set onload preselected value
             */
            if (searchQuery){
                // param that disables the tips functionality in case of a search query
                window.disableStartupAutoplay = true
                displaySearchQuery(searchQuery)
            }

            /**
             * Set onload if material queries added
             */
            if (materialSearchQuery) {
                window.disableStartupAutoplay = true
                displayMaterialSearchquery(materialSearchQuery)
            }

            async function displaySearchQuery(query){
                if (typeof query !== String) {
                    query = query.toString()
                }
                if (query.length && !searchState){
                    searchInput.addClass('hidden')
                    searchState = true

                    const searchapiRoute = headerSearch.data('message-api_route')
                    let route = window.updateUrlParameter(searchapiRoute, 'term', query)

                    try {
                        const response = await axios.get(route)
                        const { data } = response

                        let markup

                        if (data.length == 1) {
                            markup = `
                                <div class="search-query">
                                    <span>${data[0].name}</span>
                                    <i class="material-icons">close </i>
                                </div>
                            `
                        } else { // replace %20 with ' ' is handling the case with spaces in searchbox + switching tabelle/kachel
                            markup = `
                                <div class="search-query">
                                    <span>${query.replace('%20', ' ')}</span>
                                    <i class="material-icons">close </i>
                                </div>
                            `
                        }

                        if (markup){
                            searchInput.after(markup)
                            let searchQueryElement = searchInput.siblings('.search-query')
                            searchQueryElement.on('click', function(){
                            searchQueryElement.remove()
                            headerSearch.trigger('select2:clear')
                            searchState = false
                        })
                        }

                    } catch (err) {
                        window.showErrorPopup()
                        throw err
                    }
                }
            }

            /**
             * Materials queries
             * @param {*} query
             */
            function displayMaterialSearchquery(query) {
                if (query.length && !searchState){
                    searchInput.addClass('hidden')
                    searchState = true

                    const markup = `
                        <div class="search-query">
                            <span>${query}</span>
                            <i class="material-icons">close </i>
                        </div>
                    `

                    searchInput.after(markup)
                    let searchQueryElement = searchInput.siblings('.search-query')

                    searchQueryElement.on('click', function(){
                        searchQueryElement.remove()
                        headerSearch.trigger('select2:clear')
                        searchState = false

                        let uri = window.location.href
                        uri = window.updateUrlParameter(uri, 'material_hierarchy', null)

                        if (typeof window.history.pushState != 'undefined') {
                            window.history.pushState(null, null, uri)
                        }
                    })
                }
            }

            // Show icon after select2 init
            $('.search-bar .search-icon').removeClass('hidden')

        }

        // Define global scoped function for header reinitialization
        window.reInitDashboardHeaderSearch = initHeaderSearch
        

        // Handle user interactions with select2 input field
   

        if(Object.entries(window.getUrlParams(window.location.href)).length){
            window.disableStartupAutoplay = true
        }

       
        async function handleSearchWithContractsList(term) {
            if ($('#customer-dashboard-page #contract-list').exists()) {

                const contractList = $('#customer-dashboard-page #contract-list')

                const params = {
                    searchTerm: term,
                    material_hierarchy: ''
                }

                contractList.addClass('is-requesting')
                await window.getContractListHtml(params)
                contractList.removeClass('is-requesting')
                let searchInput = $('.customer-dashboard-search').find('.select2-search__field')
                if (!searchState){
                    searchInput.removeClass('hidden')
                }

            }
        }

        function formatSearchData (data, item) {
            let markup = ''

            // Loading
            if (data.loading) {
                markup = searchLoader
            } else {

                // Option item
                let { name, path } = data
                // const params = window.getUrlParams(window.location.href)

                // if(params.hasOwnProperty('tab')) {
                //     path = window.updateUrlParameter(path, 'tab', params['tab'])
                // }

                markup = `
                    <a class="search-result-link" href="${path}">
                        ${name}
                    </a>
                `
            }
            return markup
        }

        function formatSelectedSearch (data, item) {
            const { name } = data

            return name
        }
    }
})