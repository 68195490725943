const { default: tippy } = require("tippy.js")

$(document).ready(() => {
    if ($('#calloff-creation.delivery-step').exists()) {
        const stepTwoContainer = $('.delivery-step')
        const loadingContainer = $('.calloff-creation-brick-container')
        const { callOffCartId } = $('#calloff-creation.delivery-step').data()

        let url = window.location.href

        // Logic for initializating table selects 
        function initSelects() {
            $('.calloff-step-two-filter').each(async function () {
                const { selectName, noResults } = $(this).data()

                $(this).select2({
                    theme: 'default default-calloff-creation-select',
                    minimumResultsForSearch: -1,
                    dropdownCssClass: 'calloff-creation-table-dropdown',
                    placeholder: '',
                    closeOnSelect: false,
                    language: {
                        noResults: () => noResults
                    },
                    templateSelection: data => {
                        if (data.id == 'more') {
                            return ''
                        } else {
                            return data.text
                        }
                    },
                    // used to transfer classes from default select options to select2 select options
                    templateResult: (data, container) => {
                        if (data.element) {
                            $(container).addClass($(data.element).attr("class"))
                        }
                        return data.text
                    }
                }).on('select2:select', async function(e) {

                    //used to expand options in select dropdown 
                    if (e.params.data.id == 'more') {
                        e.preventDefault()

                        $('.select2-results__option').removeClass('hidden')
                        $('.more').addClass('hidden')
                    } else {
                        const thisRowCheckboxChecked = $(this).closest('tr').find('.calloff-quick-edit input').prop('checked') == true
                        const selectedValue = $(this).val()
                        $(this).select2('close')

                        $('.more').removeClass('hidden')

                        if (window.callOffCreation.multiselectActive && thisRowCheckboxChecked) {
                            // only trigger change on appropriate selects, dont send update request
                            $('.calloff-step-two-table tr').each(function() {
                                if ($(this).find('.calloff-quick-edit input').prop('checked') == true) {
                                    const select = $(this).find(`.delivery-step-filters-container select[data-select-name=${selectName}]`)
                                    select.val(selectedValue).trigger('change')
                                }
                            })
                            validateTableData()
                        } else {
                            const thisRow = $(this).closest('tr')
                            try {
                                await window.callOffCreation.updateCartItem(thisRow)
                                validateTableData()
                            } catch {
                                window.showErrorPopup()
                            }
                        }
                    }
                }).on('select2:close', async function(e) {
                    if ($(this).val() == 'more') {
                        const thisRowCheckboxChecked = $(this).closest('tr').find('.calloff-quick-edit input').prop('checked') == true

                        if (window.callOffCreation.multiselectActive && thisRowCheckboxChecked) {
                            $('.more').siblings().removeClass('hidden')
                            validateTableData()
                        } else {
                            try {
                                await updateView()
                            }
                            catch {
                                window.showErrorPopup()
                            }
                        }
                    }
                })
            })
        }
        initSelects()


        // Logic for initializing table filters
        function initFilters() {
            $('.header-filter').each(function() {
                const { filterName, placeholder, selection, filterIcon, noResults } = $(this).data()

                $(this).select2({
                    minimumResultsForSearch: -1,
                    dropdownParent: $(this).closest('.filter-heading'),
                    language: {
                        noResults: () => noResults
                    },
                    templateSelection: () => {
                        return `${placeholder.toString()} <i class="material-icons">${filterIcon}</i>`
                    },
                    templateResult: data => window.callOffCreation.styleResults(data, selection),
                    escapeMarkup: markup => markup
                }).on('select2:opening', function() {
                    $(this).closest('.responsive-table-wrapper').addClass('filter-opened')
                }).on('select2:select', async function (e) {

                    let filterText = e.params.data.text
                    let filterValue = e.params.data.id

                    let params = {
                        [filterName]: $(this).val()
                    }

                    // if its already selected remove it instead
                    if (selection == filterText.toString().trim() || selection == filterValue) {
                        params = {
                            [filterName]: ''
                        }
                    }
                    try {
                        loadingContainer.addClass('is-requesting')
                        await updateView(params)
                    }
                    catch {
                        window.showErrorPopup()
                    }

                    loadingContainer.removeClass('is-requesting')
                }).on('select2:closing', function() {
                    $(this).closest('.responsive-table-wrapper').removeClass('filter-opened')
                })
            })
        }
        initFilters()


        // Logic for table sorters
        stepTwoContainer.on('click', '.table-sort', async function() {
            const { sortOrder, sortKey } = $(this).data()

            let params = {
                'order': sortOrder,
                'order_by': sortKey
            }

            try {
                loadingContainer.addClass('is-requesting')
                await updateView(params)
            }
            catch {
                window.showErrorPopup()
            }

            loadingContainer.removeClass('is-requesting')
        })


        // Logic for submitting multiselect changes
        $('.calloff-quick-edit-save-btn').on('click', async function() {
            const selectedRows = $('.multiselect-selected')
            try {
                loadingContainer.addClass('is-requesting')
                await window.callOffCreation.updateMultipleCartItems(selectedRows)

                window.callOffCreation.multiselectActive = false
                $('.calloff-quick-edit-btn').removeClass('active')
                
                await updateView()
            }
            catch {
                window.showErrorPopup()
            }

            loadingContainer.removeClass('is-requesting')
        })


        // Logic for quantity input field, show confirm amount button when input is in focus
        stepTwoContainer.on('focus', '.calloff-quantity-input', async function() {
            const quantityCheckbox = $(this).next('.quantity-confirm-btn-box')
            quantityCheckbox.removeClass('hidden')
        })


        // Logic for hourly amount input field, show confirm amount button when input is in focus
        stepTwoContainer.on('focus', '.calloff-hourly-amount-input', async function() {
            const hourlyAmountCheckbox = $(this).next('.hourly-amount-confirm-btn-box')
            hourlyAmountCheckbox.removeClass('hidden')
        })


        // Confirm quantity change and hide confirm button
        stepTwoContainer.on('blur', '.calloff-quantity-input', async function() {
            const quantityInputValue = $(this).val()
            const quantityCheckbox = $(this).next('.quantity-confirm-btn-box')

            if (quantityInputValue && quantityInputValue >= 1) {
                const thisRow = $(this).closest('tr')
                const thisRowCheckboxChecked = thisRow.find('.calloff-quick-edit input').prop('checked') == true
                const warningLimit = thisRow.find('.quantity-warning').data('warningLimit')
                const unit = $(this).data('cartItemUnit')
                const currentHourlyQuantity = parseInt(thisRow.find('.calloff-hourly-amount-input').data('valueToSend'))

                // if selected value is lower than current hourly value, clear hourly value
                if (currentHourlyQuantity > parseInt(quantityInputValue)) {
                    thisRow.find('.calloff-hourly-amount-input').data('valueToSend', '')
                    thisRow.find('.calloff-hourly-amount-input')[0].placeholder = ''
                }
                
                // if multiselect is active dont immediately request update
                if (window.callOffCreation.multiselectActive && thisRowCheckboxChecked) {
                    $('.calloff-step-two-table tr').each(function() {
                        if ($(this).find('.calloff-quick-edit input').prop('checked') == true) {
                            const input = $(this).find('.calloff-step-quantity-input input')
                            input.val(quantityInputValue)
                        }
                    })
                } else {
                    try {
                        // set the new placeholder and empty the value in order to show it
                        let formattedValue = parseFloat(quantityInputValue).toLocaleString('de-DE')
                        
                        $(this).attr('placeholder', `${formattedValue} ${unit}`)
                        $(this).data('valueToSend', quantityInputValue)
                        $(this).val('')
                        quantityCheckbox.addClass('hidden')

                        await window.callOffCreation.updateCartItem(thisRow)

                        if (quantityInputValue > warningLimit && warningLimit > 0) {
                            thisRow.find('.quantity-warning').removeClass('hidden')
                        } else {
                            thisRow.find('.quantity-warning').addClass('hidden')
                        }
                    } catch {
                        window.showErrorPopup()
                    }
                }
                validateTableData()
                
            } else {
                $(this).closest('.calloff-step-quantity-input').find('.calloff-quantity-input').val('')
            }
            quantityCheckbox.addClass('hidden')
        })


        // Confirm hourly amount change and hide confirm button
        stepTwoContainer.on('blur', '.calloff-hourly-amount-input', async function() {
            const thisRow = $(this).closest('tr')
            const currentQuantity = parseInt(thisRow.find('.calloff-quantity-input').data('valueToSend'))
            const hourlyAmountCheckbox = $(this).next('.hourly-amount-confirm-btn-box')
            const hourlyAmountInputValue = $(this).val()

            let validateHourly = true
            if (currentQuantity && (currentQuantity < parseInt(hourlyAmountInputValue))) {
                validateHourly = false
            }

            if (hourlyAmountInputValue && hourlyAmountInputValue >= 1 && validateHourly) {
                const thisRowCheckboxChecked = thisRow.find('.calloff-quick-edit input').prop('checked') == true
                const unit = $(this).data('cartItemUnit')
                
                // if multiselect is active dont immediately request update
                if (window.callOffCreation.multiselectActive && thisRowCheckboxChecked) {
                    $('.calloff-step-two-table tr').each(function() {
                        if ($(this).find('.calloff-quick-edit input').prop('checked') == true) {
                            const input = $(this).find('.calloff-hourly-amount-input input')
                            input.val(hourlyAmountInputValue)
                        }
                    })
                } else {
                    try {
                        // set the new placeholder and empty the value in order to show it
                        let formattedValue = parseFloat(hourlyAmountInputValue).toLocaleString('de-DE')
                        $(this).attr('placeholder', `${formattedValue} ${unit}`)
                        $(this).data('valueToSend', hourlyAmountInputValue)
                        $(this).val('')
                        hourlyAmountCheckbox.addClass('hidden')

                        await window.callOffCreation.updateCartItem(thisRow)
                    } catch {
                        window.showErrorPopup()
                    }
                }
                validateTableData()
                
            } else {
                $(this).closest('.calloff-step-hourly-amount-input').find('.calloff-hourly-amount-input').val('')
            }

            hourlyAmountCheckbox.addClass('hidden')
        })


        stepTwoContainer.on('keydown', '.calloff-quantity-input', async function(event) {
            if (event.keyCode == 13 || event.key == 'Enter') {
                $(this).trigger('blur')
            }
            if (event.keyCode == 188 || event.keyCode == 190 || event.keyCode == 108 || event.key == '.' || event.key == ',') {
                event.preventDefault()
                event.stopPropagation()
            }
        })


        stepTwoContainer.on('keydown', '.calloff-hourly-amount-input', async function(event) {
            if (event.keyCode == 13 || event.key == 'Enter') {
                $(this).trigger('blur')
            }
            if (event.keyCode == 188 || event.keyCode == 190 || event.keyCode == 108 || event.key == '.' || event.key == ',') {
                event.preventDefault()
                event.stopPropagation()
            }
        })


        // Logic for duplicating cart item
        stepTwoContainer.on('click', '.calloff-duplicate-btn', function() {
            window.callOffCreation.duplicateCartItem($(this), updateView)
        })


        // Logic for removing a cart item
        if ($('.cart-action').exists()) {
            stepTwoContainer.on('click', '.remove-from-cart svg', async function() {
                const tableRow = $(this).closest('tr')
                const path = $(this).closest('.cart-action').data('path')
                const additionalTableClass = 'calloff-step-two-table'

                window.callOffCreation.animateCalloffDeletion(tableRow, additionalTableClass)
                loadingContainer.addClass('is-requesting')

                // if the cart will become empty then redirect
                try {
                    const response = await axios.get(path)
                    const { data } = response

                    if (data.redirect_url) {
                        window.location.href = data.redirect_url
                    } else {
                        // await updateView()
                        loadingContainer.removeClass('is-requesting')
                    }
                } catch {
                    loadingContainer.removeClass('is-requesting')
                    window.showErrorPopup()
                }
            })
        }


        // Logic for note-input-popup related buttons
        if ($('.calloff-note').exists()) {

            // svg from the table
            stepTwoContainer.on('click', '.calloff-note svg', function() {
                $(this).closest('tr').find('.calloff-steps-notes-popup').removeClass('hidden')
            })

            // x button from the popup
            stepTwoContainer.on('click', '.notes-popup-close-btn', function() {
                $(this).closest('.calloff-steps-notes-popup').addClass('hidden')
            })


            // submit and delete buttons from the popup
            stepTwoContainer.on('click', '.notes-popup-button', async function() {
                const thisRow = $(this).closest('tr')

                // Check if clicked button is delete note button
                // empty textarea if it is
                if($(this).hasClass('notes-popup-delete-btn')) {
                    thisRow.find('textarea').val('')
                }
                
                const thisRowCheckboxChecked = thisRow.find('.calloff-quick-edit input').prop('checked') == true
                const noteText = thisRow.find('textarea').val()
                const emptyNotepadIcon = $(this).closest('.calloff-note').data('emptyNotepad')
                const notepadIcon = $(this).closest('.calloff-note').data('notepad')

                if (window.callOffCreation.multiselectActive && thisRowCheckboxChecked) {
                    $('.calloff-step-two-table tr').each(function() {
                        if ($(this).find('.calloff-quick-edit input').prop('checked') == true) {
                            $(this).find('textarea').val(noteText)
                        }
                    })
                    $(this).closest('.calloff-steps-notes-popup').addClass('hidden')
                    validateTableData()

                } else {
                    try {
                        $(this).closest('.calloff-steps-notes-popup').addClass('hidden')
                        loadingContainer.addClass('is-requesting')

                        await window.callOffCreation.updateCartItem(thisRow)

                        // Change notepad icon depending on if there is a note
                        if(thisRow.find('textarea').val() == '') {
                            $(this).closest('.calloff-note').find('.has-tippy').html(emptyNotepadIcon)
                        } else {
                            $(this).closest('.calloff-note').find('.has-tippy').html(notepadIcon)
                        }

                        $('.calloff-quick-edit-btn').removeClass('active')
                    } catch {
                        $(this).closest('.calloff-steps-notes-popup').addClass('hidden')
                        window.showErrorPopup()
                    }
                    
                    loadingContainer.removeClass('is-requesting')
                }
            })
        }


        // Logic for replacing page content without reload
        async function updateView(urlParams = {}, reload = false, pagination = false) {
            if (!urlParams.isEmpty) {
                Object.keys(urlParams).forEach(function(key) {
                    url = window.updateUrlParameter(url, key, urlParams[key])
                })
                if (!pagination) {
                    url = window.updateUrlParameter(url, 'page', '')
                }
                if (typeof window.history.pushState != 'undefined') {
                    window.history.pushState(null, null, url)
                }
                url = window.updateUrlParameter(url, 'call_off_cart_id', callOffCartId)
                url = window.updateUrlParameter(url, 'after_update', 1)

                if (reload) {
                    window.location.href = url
                }
            }
            if (!reload) {
                try {
                    const response = await axios.get(url, window.axiosConfig)
                    const { data } = response

                    const calloffTable = $(data).find('.calloff-step-two-table').html() || ''
                    const stepsNavigation = $(data).find('.steps-navigation-container').html() || ''
                    const cartItemsCounter = $(data).find('.cart-items-number-contanier').html() || ''

                    $('.calloff-step-two-table:not(.clone)').html(calloffTable)
                    $('.steps-navigation-container').html(stepsNavigation)
                    $('.cart-items-number-contanier').html(cartItemsCounter)
                    $('.calloff-quick-edit-container').addClass('hidden')

                    initFilters()
                    initSelects()
                    initStepTwoTooltips()
                    validateTableData()

                } catch {
                    window.showErrorPopup()
                }
            }
        }


        // Helper function for table data validation
        function validateTableData() {
            const selectedRows = $('.multiselect-selected')
            let validationGood = true

            stepTwoContainer.find('tbody tr:not(.clone)').each(function() {
                const quantity = $(this).find('.calloff-quantity-input').attr('placeholder').replace(/[^0-9\.]/g, '')
                const incoterm = $(this).find('.incoterm-filter-container').find('.calloff-step-two-filter').val()
                const truckType = $(this).find('.truck-type-filter-container').find('.calloff-step-two-filter').val()

                if (quantity == 0 || incoterm == '' || truckType == '') {
                    validationGood = false
                }
            })
            if (window.callOffCreation.multiselectActive || $('.step-next-button').data('itemsCount') == 0) {
                validationGood = false
            }
            if (selectedRows.exists()) {
                $('.calloff-quick-edit-save-btn').removeClass('disabled')
            } else {
                $('.calloff-quick-edit-save-btn').addClass('disabled')
            }

            if (validationGood) {
                stepTwoContainer.find('.step-next-button').removeClass('disabled')
            } else {
                stepTwoContainer.find('.step-next-button').addClass('disabled')
            }
        }
        validateTableData()

        // Logic for initializing tooltips
        function initStepTwoTooltips() {
            const editedItemTooltip = $('.edited-item .has-tippy').data('tooltipTranslation')
            tippy('.edited-item .has-tippy', {
                content: editedItemTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })

            $('.material-name .has-tippy').each( function() {
                const materialDescriptionTooltip = $(this).data('materialDescription')
                const materialDescriptionMoreTooltip = $(this).data('materialDescriptionMore')
                const numberTooltip = $(this).data('number')
                const epTooltip = $(this).data('ep')
                const avvCodeTooltip = $(this).data('avvCode')
                const avvTooltip = $(this).data('avv')

                tippy(this, {
                    content: materialDescriptionTooltip + (materialDescriptionMoreTooltip ? '<br>'+ materialDescriptionMoreTooltip : '')
                    + (numberTooltip ? '<br>'+epTooltip+numberTooltip : '') 
                    + (avvCodeTooltip ? '<br>'+avvTooltip+avvCodeTooltip : ''),
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('td.factory .has-tippy').each( function() {
                const factoryNameTooltip = $(this).data('factoryName')
                const factoryStreetTooltip = $(this).data('factoryStreet')
                const factoryStreetNumberTooltip = $(this).data('factoryStreetNumber')
                const factoryZipTooltip = $(this).data('factoryZip')
                const factoryCityTooltip = $(this).data('factoryCity')

                tippy(this, {
                    content: factoryNameTooltip + "<br>" + factoryStreetTooltip + " " + factoryStreetNumberTooltip + "<br>" +
                    factoryZipTooltip + " " + factoryCityTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            const warningTooltip = $('.quantity-warning').data('tooltipTranslation')
            tippy('.quantity-warning', {
                content: warningTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })

            const editNoteTooltip = $('.calloff-note .has-tippy.edit').data('tooltipTranslation')
            tippy('.calloff-note .has-tippy.edit', {
                content: editNoteTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })

            const addNoteTooltip = $('.calloff-note .has-tippy.add').data('tooltipTranslation')
            tippy('.calloff-note .has-tippy.add', {
                content: addNoteTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })

            const duplicateItemTooltip = $('.calloff-duplicate-btn .has-tippy').data('tooltipTranslation')
            tippy('.calloff-duplicate-btn .has-tippy', {
                content: duplicateItemTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
            
            const removeItem = $('.remove-from-cart .has-tippy').data('tooltipTranslation')
            tippy('.remove-from-cart .has-tippy', {
                content: removeItem,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
        }
        initStepTwoTooltips()
    }
})