import { initSearch } from 'Select2/search'
import { initAjaxFilter, initTableAjaxFilter } from 'Select2/filter'
import { initLetterPagination } from 'Pagination/letter-pagination'
import tippy from 'tippy.js'

$(document).ready(() => {
    if ($('#quality-document').exists()) {
        const searchContainer = $('.quality-document-search-container')
        const search = $('.quality-document-search')
        const tableContainer = $('.quality-content-container')
        const itemLoadingIcon = $('#search-loader').html()
        const activeFilter = $('.active-filters-container').find('.active-filter')
        const viewTypeSelect = $('.quality-view-type-select')
        const contractStatusSelect = $('.quality-contract-status-select')
        const uploadButton = $('#file-upload-button')
        const uploadFormContainer = $('.quality-upload-form-container')
        const uploadForm = $('.quality-upload-form')
        const dropdownArrow = $('.dropdown-arrow-svg').html()
        const d3checkButton = $('.d3-check-button')
        let url = window.location.href
        let uploadRedirectUrl

        tableContainer.removeClass('is-requesting')


        //header expanding
        if($('.header-preview-toggler-highway').exists()) {
            $('.header-preview-toggler-highway').on('click', function () {
                const headerWrapper = $(this).closest('.navigation-full-wrapper')
                const header = $(this).closest('.dashboard-navigation')
                headerWrapper.toggleClass('header-expanded')
                header.toggleClass('header-expanded')
                $('.header-toggler-text').toggleClass('hidden')
            })
        }


        // Initialize search and table filters
        if (searchContainer.exists()) {
            initSearch(search, searchContainer, tableContainer, itemLoadingIcon)
        }
        if (tableContainer.exists()) {
            const tableFilters = $('.header-filter')

            tableFilters.each(function() {
                const filter = $(this)
                const filterParent = filter.parent()
                initTableAjaxFilter(filter, filterParent, tableContainer, itemLoadingIcon)
            })
        }


        // Logic for expanding/shrinking archived documents' rows on cockpit
        $('.toggle-quality-archive .icon-container').on('click', function() {
            const id = $(this).closest('tr').data('id')

            $(`.quality-sub-item[data-id="${id}"]`).toggleClass('hidden')
        })


        // Logic for table sorters
        tableContainer.on('click', '.table-sort', function() {
            const { sortOrder, sortKey } = $(this).data()
            tableContainer.addClass('is-requesting')

            url = window.updateUrlParameter(url, 'page', null)
            url = window.updateUrlParameter(url, 'order', sortOrder)
            url = window.updateUrlParameter(url, 'order_by', sortKey)
            window.location.href = url
        })


        // Logic for table datepicker
        if($('.table-header-datepicker').exists()) {
            const tableDatepicker = $('.table-header-datepicker')
            let { locale, type, single, minDate, maxDate, submitTranslation } = tableDatepicker.data()
            let startDate, endDate

            flatpickr(tableDatepicker, {
                dateFormat: 'd.m.Y',
                locale: locale,
                closeOnSelect: false,
                disableMobile: true,
                mode: single ? 'single' : 'range',
                minDate: minDate,
                maxDate: maxDate,

                onReady: (selectedDates, dateString, instance) => {
                    // append submit button to datepicker
                    let { calendarContainer } = instance
                    $(calendarContainer).append(`<button class="date-submit">${submitTranslation}</button>`)

                    // add class to the dropdown
                    $(calendarContainer).addClass('table-header-datepicker-dropdown')
                },
                onValueUpdate: date => {
                    if (date.length) {
                        startDate = flatpickr.formatDate(date[0], 'd.m.Y')
                        endDate = flatpickr.formatDate(date[date.length - 1], 'd.m.Y')
                    }
                }
            })

            // logic for date submit button
            $('.date-submit').on('click', () => {
                tableDatepicker[0]._flatpickr.close()
                tableContainer.addClass('is-requesting')

                if (type == 'upload') {
                    url = window.updateUrlParameter(url, 'date_of_upload_from', startDate)
                    url = window.updateUrlParameter(url, 'date_of_upload_to', endDate)

                }

                window.location.href = url
            })
        }


        // Logic for clearing active filters above the table
        if (activeFilter.exists()) {
            activeFilter.on('click', function() {
                let filterKey = $(this).find('.remove-active-filter').data('filter-key')

                $(this).remove()
                tableContainer.addClass('is-requesting')

                if (filterKey == 'date_of_upload') {
                    url = window.updateUrlParameter(url, 'date_of_upload_from', '')
                    url = window.updateUrlParameter(url, 'date_of_upload_to', '')

                } else {
                    // in addition to removing the active filter, also remove term which can come from search
                    url = window.updateUrlParameter(url, filterKey, '')
                    url = window.updateUrlParameter(url, 'term', '')
                }
                window.location.href = url
            })
        }


        // Logic for excel export
        if ($('.excel-export-container').exists()) {
            $('.excel-export-link').on('click', async function() {
                let path = $(this).data('path')
                let fileName = $(this).data('fileName')
                tableContainer.addClass('is-requesting')

                try {
                    const response = await axios({
                        url: path,
                        method: 'GET',
                        responseType: 'blob'
                    })
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = $(`<a href="${url}"
                                        target="_blank"
                                        download="${fileName}">
                                    </a>`)
                    link[0].click()
                    tableContainer.removeClass('is-requesting')

                } catch {
                    tableContainer.removeClass('is-requesting')
                    window.showErrorPopup()
                }
            })
        }


        // Initialize pagination
        if ($('#number-pagination').exists()) {
            let { first, last, prev, next, startPage, totalPages, pageSize, visiblePages } = $('#number-pagination').data()

            if (totalPages == 0) {
                totalPages = 1
            }
            $('#number-pagination').twbsPagination({
                first,
                last,
                prev,
                next,
                startPage,
                totalPages,
                visiblePages,
                initiateStartPageClick: false,
                onPageClick: (event, page) => {
                    url = window.updateUrlParameter(url, 'page_size', pageSize)
                    url = window.updateUrlParameter(url, 'page', page)

                    tableContainer.addClass('is-requesting')

                    window.location.href = url
                }
            })
        }


        // Initialize letter pagination and logic for infinite scroll
        if ($('.letter-pagination-container').exists()) {
            initLetterPagination(tableContainer)

            let currentPage = 1
            let isRequesting = false

            $(window).on('scroll', async function() {
                const { scrollTop, scrollHeight, clientHeight } = document.documentElement
                const scrollPercent = scrollTop / (scrollHeight - clientHeight)
                const { pageSize, totalPages } = $('.letter-pagination-container').data()

                if (scrollPercent > 0.66 && !isRequesting && currentPage < totalPages) {
                    currentPage++
                    isRequesting = true
                    tableContainer.addClass('scroll-is-requesting')

                    try {
                        url = window.updateUrlParameter(url, 'page_size', pageSize)
                        url = window.updateUrlParameter(url, 'page', currentPage)

                        const response = await axios.get(url)
                        const data = $(response.data).find('tbody').html()

                        tableContainer.find('tbody').append(data)
                        isRequesting = false
                        tableContainer.removeClass('scroll-is-requesting')
                    } catch {
                        isRequesting = false
                        tableContainer.removeClass('scroll-is-requesting')
                        window.showErrorPopup()
                    }
                }
            })
        }


        // Show loader when clicking on zuruck/navigation button on single or details button on overview
        $('.quality-button, .details-link').on('click', () => {
            tableContainer.addClass('is-requesting')
        })


        // Initialize dropdown select for the status of contract
        if (contractStatusSelect.exists()) {
            contractStatusSelect.select2({
                dropdownCssClass: 'quality-contract-status-select-container-dropdown',
                minimumResultsForSearch: -1
            }).on('select2:select', function() {
                let param = contractStatusSelect.data('parameter')
                let value = $(this).val()

                tableContainer.addClass('is-requesting')

                url = window.updateUrlParameter(url, param, value)
                window.location.href = url
            })
        }


        // Initialize dropdown select for the type of view (table/tile/history)
        if (viewTypeSelect.exists()) {
            const isHighway = viewTypeSelect.hasClass('highway-select')

            viewTypeSelect.select2({
                minimumResultsForSearch: -1,
                dropdownParent: isHighway ? '' : $('.quality-view-type-select-container'),
                dropdownCssClass: isHighway ? 'quality-view-type-select-dropdown' : ''

            }).on('select2:select', function() {
                let value = $(this).val()
                tableContainer.addClass('is-requesting')

                url = window.updateUrlParameter(url, 'view_type', value)
                window.location.href = url
            })
            $('.quality-view-type-select-container').find('.select2-selection__arrow').html(dropdownArrow)
        }


        // Initialize filters above the table
        if($('.quality-filters-container').exists()) {
            let filter = $('.quality-filter-material-select')
            let filterParent = $('.quality-filter-material-container')

            if (filter.exists()) {
                initAjaxFilter(filter, filterParent, '', tableContainer, itemLoadingIcon, dropdownArrow)
            }

            filter = $('.quality-filter-factory-select')
            if (filter.exists()) {
                filterParent = $('.quality-filter-factory-container')
                initAjaxFilter(filter, filterParent, '', tableContainer, itemLoadingIcon, dropdownArrow)
            }
        }


        // Logic for the visibility switch in the table
        if ($('.visibility-switch').exists()) {
            $('.visibility-switch').on('click', async function() {

                let url = $(this).data('path')

                if ($(this)[0].checked) {
                    url = window.updateUrlParameter(url, 'status', '1')
                } else {
                    url = window.updateUrlParameter(url, 'status', '0')
                }

                try {
                    await axios.get(url)
                } catch {
                    $(this)[0].checked = !$(this)[0].checked
                    window.showErrorPopup()
                }
            })
        }


        // Logic for switching tile/table view on cockpit
        if ($('.view-switch-button').exists()) {
            $('.view-switch-button').on('click', function() {
                tableContainer.addClass('is-requesting')
                window.location.href = $(this).data('path')
            })
        }


        // Logic for editing comments in the table
        $('.notes-box').on('click', function() {
            const submitPath = $(this).data('submitPath')
            const message = $(this).find('.tip').text().trim()

            // fill the data inside comment popup
            $('.quality-item-note').val(message)
            $('.notes-length-value').text(message.length)
            $('.notes-popup-button-container').data('submitPath', submitPath)

            $('.quality-notes-popup').removeClass('hidden')
        })


        // Logic for character counter on comment popup
        if ($('.quality-item-note').exists()) {
            $('.quality-item-note').on('input', function() {
                $('.notes-length-value').text($(this).val().length)
            })
        }


        // Logic for delete/submit buttons in the comment popup
        if ($('.notes-popup-button-container').exists()) {
            $('.notes-popup-button').on('click', async function() {

                let url = $('.notes-popup-button-container').data('submitPath')
                const message = $('.quality-item-note').val()
                const relatedCommentFromTable = $(`.info-box[data-submit-path="${url}"]`)

                // if the delete button was clicked
                if ($(this).hasClass('notes-popup-delete-btn')) {
                    url = window.updateUrlParameter(url, 'comment', '')
                    const response = await handleMessageSubmit(url)

                    if (response == 'success') {
                        relatedCommentFromTable.removeClass('has-tooltip')
                        relatedCommentFromTable.find('.tip').text('')
                    }

                // if the submit button was clicked
                } else if (message.length > 0) {
                    url = window.updateUrlParameter(url, 'comment', message)
                    const response = await handleMessageSubmit(url)

                    if (response == 'success') {
                        relatedCommentFromTable.addClass('has-tooltip')
                        relatedCommentFromTable.find('.tip').text(message)
                    }

                // prevent submitting empty message
                } else {
                    return false
                }
            })
        }


        // Helper function for submitting the update message request
        async function handleMessageSubmit(url) {
            try {
                await axios.get(url)
                $('.quality-notes-popup').addClass('hidden')

                return 'success'

            } catch {
                $('.quality-notes-popup').addClass('hidden')
                window.showErrorPopup()

                return 'error'
            }
        }


        // Logic for x button in the comment popup
        if ($('.notes-popup-close-btn').exists()) {
            $('.notes-popup-close-btn').on('click', () => {
                $('.quality-notes-popup').addClass('hidden')
            })
        }


        // Logic for delete button in the table
        if ($('.delete-quality-document').exists()) {
            $('.delete-quality-document').on('click', async function() {
                let url = $(this).data('path')
                let deletePopup = $('.delete-warning-popup')

                if (deletePopup.exists()) {
                    const confirmDeleteButton = deletePopup.find('.delete-button')
                    deletePopup.removeClass('hidden')

                    confirmDeleteButton.on('click', async function() {
                        tableContainer.addClass('is-requesting')
                        try {
                            await axios.get(url)
                            location.reload()
                        } catch {
                            tableContainer.removeClass('is-requesting')
                            window.showErrorPopup()
                        }
                    })
                }
                // logic for x button and close button of the popup is in company-invitation.js
            })
        }


        // Logic for the upload button above the table
        if (uploadButton.exists()) {
            $('#file-upload').on('input', () => {
                uploadFormContainer.addClass('visible')
                uploadFormContainer.closest('.quality-content-container').addClass('popup-z-index')

                // data of the file chosen with input
                const files = $('#file-upload')[0].files

                // copy the data to the input field of the form popup
                uploadForm.find('#file')[0].files = files

                // set the name of the document in the form popup
                $('.form-document-download').text(files[0].name)

                // set the data necessary for creating the download link
                $('.form-document-download')[0].download = files[0].name
                $('.form-document-download')[0].href = URL.createObjectURL(files[0])
            })
        }


        // Logic for the x button and close button of the form
        if (uploadForm.exists()) {
            uploadForm.on('click', '#cancel, .upload-popup-exit', () => {

                // close the popup and empty all data
                uploadFormContainer.removeClass('visible')
                uploadForm.find('select, input').val('').change()
                uploadForm.find('.remove-field-value').addClass('hidden')
                $('.quality-content-container').removeClass('popup-z-index')

                // again show all the hidden options
                $('#material_name option').removeClass('hidden')
                $('#material_number option').removeClass('hidden')
            })
        }


        // Logic for the submit button of the form
        if (uploadForm.exists()) {
            $('#submit').on('click', async function(e) {
                e.preventDefault()

                // validation
                let validationSuccess = true
                const uploadFormSelects = uploadForm.find('.js-select:not(.form-select-hidden)')

                uploadFormSelects.each(function() {
                    if (!$(this).val()) {
                        validationSuccess = false
                        $(this).parent().find('.select2-selection--single').addClass('error')
                    }
                })

                // successful submit logic
                if (validationSuccess) {
                    const formData = new FormData($('.quality-content-container form')[0])

                    // if the type is cover_letter then remove empty field from form data
                    if ($('#document_type').val() == 'cover_letter') {
                        formData.delete('product_hierarchy')
                        formData.delete('factory')
                        formData.delete('material_name')
                        formData.delete('material_number')
                    }

                    try {
                        uploadFormContainer.removeClass('visible')
                        tableContainer.addClass('is-requesting')

                        const { data } = await axios.post(url, formData)

                        if (data.redirect_url) {
                            uploadRedirectUrl = data.redirect_url
                        }

                        tableContainer.removeClass('is-requesting')
                        $('.upload-success-popup').removeClass('hidden')
                    } catch {
                        uploadFormContainer.removeClass('visible')
                        tableContainer.removeClass('is-requesting')
                        window.showErrorPopup()
                    }
                }
            })
        }


        // Logic for the upload-success popup buttons
        if (uploadForm.exists()) {
            $('.upload-success-popup').on('click', '.popup-exit, .confirm-button', () => {
                tableContainer.addClass('is-requesting')

                if (uploadRedirectUrl) {
                    window.location.href = uploadRedirectUrl
                } else {
                    location.reload()
                }
            })
        }


        // Logic for initializing selects from the upload form
        function initUploadFormSelects() {
            if (uploadForm.exists()) {
                const uploadFormSelects = uploadForm.find('.js-select')

                uploadFormSelects.each(function() {
                    let dropdown = $(this)

                    dropdown.select2({
                        dropdownParent: dropdown.parent(),
                        placeholder: '',
                        minimumResultsForSearch: 1,

                        // used to transfer classes from default select options to select2 select options
                        templateResult: function (data, container) {
                            if (data.element) {
                                $(container).addClass($(data.element).attr('class'))
                            }
                            return data.text
                        }
                    })
                    dropdown.parent().find('.select2-selection__arrow').html('')

                    // remove red border from failed validation
                    dropdown.on('select2:select', function() {
                        dropdown.parent().find('.select2-selection--single').removeClass('error')
                        dropdown.parent().find('.remove-field-value').removeClass('hidden')

                        // if the selected document type changed then update the visibility of form selects
                        if ($(this).is('#document_type')) {
                            if ($(this).val() == 'cover_letter') {
                                $('#product_hierarchy, #factory, #material_name, #material_number').addClass('form-select-hidden')
                                $('#product_hierarchy, #factory, #material_name, #material_number').closest('.form-group').addClass('hidden')
                                $('.quality-upload-form-extend-search').addClass('hidden')
                            } else {
                                uploadFormSelects.removeClass('form-select-hidden')
                                uploadFormSelects.closest('.form-group').removeClass('hidden')
                                $('.quality-upload-form-extend-search').removeClass('hidden')
                            }
                        }
                    })

                    // the other selects which this one also sets or clears
                    let { clears, sets } = $(this).data()

                    // logic for clearing other connected selects
                    if (clears) {
                        clears = clears.split('|')
                        dropdown.on('select2:select', () => {

                            clears.forEach(select_id => {
                                if ($(`#${select_id}`).exists()) {
                                    $(`#${select_id}`).val('').trigger('change')
                                    $(`#${select_id}`).parent().find('.remove-field-value').addClass('hidden')
                                }
                            })
                        })
                    }
                    // logic for selecting other connected selects
                    if (sets) {
                        sets = sets.split('|')
                        dropdown.on('select2:select', option => {

                            const valueToSet = $(option.params.data.element).data('setsValue')
                            sets.forEach(select_id => {
                                if ($(`#${select_id}`).exists()) {
                                    $(`#${select_id}`).val(valueToSet).trigger('change')
                                    $(`#${select_id}`).parent().find('.select2-selection--single').removeClass('error')
                                    $(`#${select_id}`).parent().find('.remove-field-value').removeClass('hidden')
                                }
                            })

                            // logic for selecting the appropriate material category when selecting material name/number
                            const materialCategoryToSet = $(option.params.data.element).data('materialCategory')
                            const currentMaterialCategory = $('#product_hierarchy').val()
                            if (materialCategoryToSet && !currentMaterialCategory) {
                                $('#product_hierarchy').val(materialCategoryToSet).trigger('change')
                                $('#product_hierarchy').parent().find('.select2-selection--single').removeClass('error')
                                $('#product_hierarchy').parent().find('.remove-field-value').removeClass('hidden')
                            }
                        })
                    }
                })

                // logic for hiding material_name and material_number options from different category
                $('#product_hierarchy').on('select2:select', option => {
                    let selectedValue = $(option.params.data.element).val()

                    // toggle class="hidden" on basic <select> options
                    // when select2 has been initialized it will transfer the class with the templateResult function
                    $('#material_name option').removeClass('hidden')
                    $(`#material_name option[data-material-category!="${selectedValue}"]`).addClass('hidden')

                    $('#material_number option').removeClass('hidden')
                    $(`#material_number option[data-material-category!="${selectedValue}"]`).addClass('hidden')

                // if the value was removed then again show all of the material options
                }).on('change', function() {
                    if ($(this).val() == '') {
                        $('#material_name option').removeClass('hidden')
                        $('#material_number option').removeClass('hidden')
                    }
                })
            }
        }
        initUploadFormSelects()


        // Logic for expand search results checkbox in the form
        if (uploadForm.exists()) {
            $('#extend-search-results').on('click', function() {
                $(this).closest('.quality-upload-form-extend-search').toggleClass('extended-search-active')
                const uploadFormSelects = uploadForm.find('.ajax-search-possible')

                // reinitialize each select2 to work with ajax request
                if ($(this).prop('checked')) {
                    let { addMoreText, noResults, route } = $('.quality-upload-form-extend-search').data()

                    // destroy the old select2 and unbind all of its events, then initialise new one with ajax
                    uploadFormSelects.each(function() {
                        let dropdown = $(this)

                        dropdown.select2('destroy')
                        dropdown.off('select2:select')
                        dropdown.off('change')

                        dropdown.select2({
                            dropdownParent: dropdown.parent(),
                            placeholder: '',
                            minimumResultsForSearch: -1,
                            minimumInputLength: 1,

                            // used to transfer classes from default select options to select2 select options
                            templateResult: function (data, container) {
                                if (data.element) {
                                    $(container).addClass($(data.element).attr('class'))
                                }
                                return data.text
                            },
                            escapeMarkup: markup => markup,
                            ajax: {
                                url: () => {
                                    route = window.updateUrlParameter(route, 'request_field', dropdown.attr('id'))

                                    // add additional parameters from other selects in the form
                                    uploadFormSelects.each(function() {
                                        if ($(this).attr('id') != dropdown.attr('id')) {
                                            route = window.updateUrlParameter(route, $(this).attr('id'), $(this).val())
                                        } else {
                                            route = window.updateUrlParameter(route, $(this).attr('id'), '')
                                        }
                                    })
                                    return route
                                },
                                dataType: 'json',
                                processResults: data => {
                                    return {
                                        results: data.items.map(key => ({
                                                text: key.display,
                                                id: key.value,
                                                value: key.value,
                                                sets_material_number: key.material_number_value || '',
                                                sets_material_name: key.material_name_value || '',
                                                sets_product_hierarchy_display: key.product_hierarchy_display || '',
                                                sets_product_hierarchy_value: key.product_hierarchy_value || '',
                                        })),
                                        pagination: {
                                            more: data.pagination.has_next_page
                                        }
                                    }
                                }
                            },
                            language: {
                                inputTooShort: () => addMoreText,
                                noResults: () => noResults,
                                searching: () => $('#search-loader').html(),
                                loadingMore: () => $('#search-loader').html()
                            }

                        }).on('select2:select', option => {
                            const selectedOptionAjaxParams = option.params.data
                            const selectedOptionData = option.currentTarget.dataset

                            const clears = selectedOptionData.clears ? selectedOptionData.clears.split('|') : []
                            const sets = selectedOptionData.sets

                            $(option.currentTarget).closest('.form-group').find('.select2-selection--single').removeClass('error')
                            $(option.currentTarget).closest('.form-group').find('.remove-field-value').removeClass('hidden')

                            // clear connected fields when something is selected
                            clears.forEach(select_id => {
                                if ($(`#${select_id}`).exists()) {
                                    $(`#${select_id}`).val('').trigger('change')
                                    $(`#${select_id}`).parent().find('.remove-field-value').addClass('hidden')
                                }
                            })

                            // fill connected fields when something is selected
                            if (sets == 'material_name') {
                                let autoselectedOption = $(`<option selected></option>`)
                                                            .val(selectedOptionAjaxParams.sets_material_name)
                                                            .text(selectedOptionAjaxParams.sets_material_name)
                                $(`#${sets}`).append(autoselectedOption).trigger('change')

                            } else if (sets == 'material_number') {
                                let autoselectedOption = $(`<option selected></option>`)
                                                            .val(selectedOptionAjaxParams.sets_material_number)
                                                            .text(selectedOptionAjaxParams.sets_material_number)
                                $(`#${sets}`).append(autoselectedOption).trigger('change')
                            }
                            $(`#${sets}`).parent().find('.select2-selection--single').removeClass('error')
                            $(`#${sets}`).parent().find('.remove-field-value').removeClass('hidden')

                            if (selectedOptionAjaxParams.sets_product_hierarchy_display) {
                                let autoselectedOption = $(`<option selected></option>`)
                                                            .val(selectedOptionAjaxParams.sets_product_hierarchy_value)
                                                            .text(selectedOptionAjaxParams.sets_product_hierarchy_display)
                                $('#product_hierarchy').append(autoselectedOption).trigger('change')
                                $('#product_hierarchy').parent().find('.select2-selection--single').removeClass('error')
                                $('#product_hierarchy').parent().find('.remove-field-value').removeClass('hidden')
                            }
                        })
                        dropdown.parent().find('.select2-selection__arrow').html('')
                    })

                } else {
                    // destroy all of the old select2 and unbind all of their events, initialise new one without ajax
                    uploadForm.find('.js-select').each(function() {
                        $(this).select2('destroy')
                        $(this).off('select2:select')
                    })

                    initUploadFormSelects()
                }
            })
        }


        // Logic for showing/hiding the remove-button on input fields
        if (uploadForm.exists()) {
            uploadForm.find('input').on('input', function() {
                if ($(this).val()) {
                    $(this).parent().find('.remove-field-value').removeClass('hidden')
                } else {
                    $(this).parent().find('.remove-field-value').addClass('hidden')
                }
            })
        }


        // Logic for emptying a form field
        if (uploadForm.exists()) {
            uploadForm.on('click', '.remove-field-value', function() {
                const fieldsToClear = ['material_name', 'material_number', 'product_hierarchy']
                const thisSelectId = $(this).parent().find('select').attr('id')

                // if any of the material number, material name or product hierarchy are cleared, clear all 3 of them
                if (fieldsToClear.includes(thisSelectId)) {
                    fieldsToClear.forEach(id => {
                        $(`#${id}`).val('').trigger('change')
                        $(`#${id}`).parent().find('.remove-field-value').addClass('hidden')
                    })

                // otherwise just clear the clicked field
                } else {
                    $(this).parent().find('select, input').val('').trigger('change')
                    $(this).addClass('hidden')
                }
            })
        }


        // Logic for drag and drop zone over the table
        // prevent all default actions
        tableContainer.on('dragenter dragleave dragover drop', event => {
            event.preventDefault()
            event.stopPropagation()
        })
        let dragDepthCounter = 0

        // show/hide the drop zone when dragging a file
        tableContainer.on('dragenter', () => {
            dragDepthCounter++
            $('.table-file-drop-zone').addClass('visible')
        })
        tableContainer.on('dragleave', () => {
            dragDepthCounter--
            if (dragDepthCounter == 0) {
                $('.table-file-drop-zone').removeClass('visible')
            }
        })
        // when the file is dropped open the popup with form
        tableContainer.on('drop', e => {
            $('.table-file-drop-zone').removeClass('visible')
            dragDepthCounter = 0

            try {
                let droppedFiles = e.originalEvent.dataTransfer.files
                let { acceptedFormats, errorMessage } = $('.table-file-drop-zone').data()

                // check if the dropped file has the right format
                if (acceptedFormats.split(',').includes(droppedFiles[0].type)) {
                    uploadFormContainer.addClass('visible')
                    uploadForm.find('#file')[0].files = droppedFiles

                    // set the name of the document in the form popup
                    $('.form-document-download').text(droppedFiles[0].name)

                    // set the data necessary for creating the download link
                    $('.form-document-download')[0].download = droppedFiles[0].name
                    $('.form-document-download')[0].href = URL.createObjectURL(droppedFiles[0])
                } else {
                    window.showErrorPopup(errorMessage)
                }
            } catch {
                window.showErrorPopup()
            }
        })


        // Quality table tooltips
        $('.info-box .has-tippy').each(function() {
            const itemCommentTooltip = $(this).data('itemComment')

            tippy(this, {
                content: itemCommentTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
        })

        if ($('.archived-symbol').exists()) {
            tippy('.archived-symbol', {
                content: $('.archived-symbol').data('tooltipTranslation'),
                placement: 'bottom',
                theme: 'cockpit-tippy',
                maxWidth: 'max-content'
            })
        }

        if ($('.inactive-symbol').exists()) {
            tippy('.inactive-symbol', {
                content: $('.inactive-symbol').data('tooltipTranslation'),
                placement: 'bottom',
                theme: 'cockpit-tippy',
                maxWidth: 'max-content'
            })
        }

        if ($('.deactivated-symbol').exists()) {
            tippy('.deactivated-symbol', {
                content: $('.deactivated-symbol').data('tooltipTranslation'),
                placement: 'bottom',
                theme: 'cockpit-tippy',
                maxWidth: 'max-content'
            })
        }


        // Logic for quality document visibility on Highway
        // Vk organistaion filter
        $('#vk-org-select').select2({
            dropdownParent: $('.vk-org-select-container'),
            containerCssClass: 'vk-org-container',
            dropdownCssClass: 'vk-org-dropdown',
            minimumResultsForSearch: -1

        }).on('select2:select', e => {
            let filterValue = e.params.data.id
            tableContainer.addClass('is-requesting')
            url = window.updateUrlParameter(url, 'id', filterValue)
            window.location.href = url
        })


        // Logic for document visibility switch in quality documents header
        const vkOfficeVisibilitySwitch = $('.change-documents-visibility-status')

        vkOfficeVisibilitySwitch.on('click', async function() {
            let vkOfficeVisibilityUrl = ''

            if($(this).is(':checked')) {
                vkOfficeVisibilityUrl = vkOfficeVisibilitySwitch.data('documentSelectUrl') + '&visible=true'
            } else {
                vkOfficeVisibilityUrl = vkOfficeVisibilitySwitch.data('documentSelectUrl') + '&visible=false'
            }

            try {
                $('.quality-content-container').addClass('is-requesting')

                await axios.get(vkOfficeVisibilityUrl)

                // Disable/enable tiles
                if($(this).is(':checked')) {
                    $('.vk-office-tile').find('.change-document-visibility-status').prop("disabled", false)
                    $('.vk-office-tile').removeClass('deactivated-overwrite-mode')
                } else {
                    $('.vk-office-tile').find('.change-document-visibility-status').prop("disabled", true)
                    $('.vk-office-tile').addClass('deactivated-overwrite-mode')
                }

                $('.quality-content-container').removeClass('is-requesting')
            } catch {
                window.showErrorPopup()
            }
        })

        // Logic for selecting/deselecting vk office document types
        const vkDocumentTypeToggle = $('.vk-visibility-tile').find('.vk-document-types .toggle')

        vkDocumentTypeToggle.on('click', async function() {
            let documentTypeStatus = $(this).closest('label').find('.change-document-visibility-status').is(":checked")
            let documentSelectPath = $(this).closest('label').find('.change-document-visibility-status').data('documentSelectUrl')

            try {
                if($(this).closest('.vk-visibility-tile').find('.change-document-visibility-status').prop("disabled") == false) {
                    if(documentTypeStatus) {
                        // deselect document type for vk office
                        documentSelectPath = window.updateUrlParameter(documentSelectPath, 'visible', 'false')

                        $(this).closest('.vk-visibility-tile').addClass('is-requesting')

                        await axios.get(documentSelectPath)

                        $(this).closest('.vk-visibility-tile').removeClass('is-requesting')
                    } else {
                        // select document type for vk office
                        documentSelectPath = window.updateUrlParameter(documentSelectPath, 'visible', 'true')

                        $(this).closest('.vk-visibility-tile').addClass('is-requesting')

                        await axios.get(documentSelectPath)

                        $(this).closest('.vk-visibility-tile').removeClass('is-requesting')
                    }
                }
            } catch {
                window.showErrorPopup()
            }
        })


        // Logic for overwriting vk office tiles with the vk organistaion tile switches
        const vkOrgDocumentToggle = $('.vk-organistaion-tile').find('.vk-document-types .toggle')

        vkOrgDocumentToggle.on('click', function() {
            const documentType = $(this).closest('label').find('.change-document-visibility-status').data('documentId')
            const documentStatus = $(this).closest('label').find('.change-document-visibility-status').prop('checked')

            if(documentStatus) {
                $(this).closest('.quality-documents-visibility-container').find('.deactivated-overwrite-mode').find('.'+documentType).prop('checked', false)
            } else {
                $(this).closest('.quality-documents-visibility-container').find('.deactivated-overwrite-mode').find('.'+documentType).prop('checked', true)
            }
        })

        // Logic for d3 check button
        if (d3checkButton.exists()) {
            d3checkButton.on('click', async function() {
                let path = $(this).data('path')
                try {
                    await axios.get(path)
                    location.reload()
                } catch {
                    window.showErrorPopup()
                }
            })
        }
    }

    // Logic for pricelist quality screen
    if($('#quality-control').exists()) {
        const qualityDocumentsContainer = $('#quality-control')
        const dropdownSelects = $('.pricelist-quality-select')
        let url = window.location.href


        // Logic for initializing masonry
        qualityDocumentsContainer.masonry({
            itemSelector: '.qa-item',
            gutter: 0,
            isAnimated: true,
            animationOptions: {
                duration: 750,
                easing: 'linear',
                queue: false
            }
        })


        // Logic for material category and material group dropdowns
        if (dropdownSelects.exists()) {
            dropdownSelects.each(function() {
                let dropdownSelect = $(this)
                let filterName = dropdownSelect.data('filterName')

                dropdownSelect.select2({
                    minimumResultsForSearch: -1,
                    dropdownParent: dropdownSelect.closest('.pricelist-quality-select-container')

                }).on('select2:select', function() {
                    qualityDocumentsContainer.addClass('is-requesting')

                    url = window.updateUrlParameter(url, filterName, $(this).val())
                    window.location.href = url
                })
            })
            $('.pricelist-quality-select-container').find('.select2-selection__arrow b').html('<i class="material-icons">expand_more</i>')
        }
    }
})