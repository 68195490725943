$(document).ready(() => {
    if($('.web-buttons-container').length > 0) {
        let buttonsContainer = $('.web-buttons-container')
        
        function equalizeButtons() {
            buttonsContainer.equalize({
                    equalize: 'outerWidth',
                    reset: true,
                    children: '.button'
                }
            )
        }
        
        equalizeButtons()
        
        $(window).resize(function() {
            equalizeButtons()
        })
    }
})