
import detailsBlock from './components/details-table-block.vue';
import interactionRow from './components/interaction-row.vue';
import cancellationRow from './components/cancellation-row.vue';
import pagination from './components/pagination.vue';

$(document).ready(function() {
    if ($('#call-off-companies').exists() && $('#details-table').exists() && !$('.customer-block').exists()){
        Vue.filter('trans', (value, locale) => {
            if (typeof value === 'string') {
                // Call prototype function
                return value.trans(locale)
            } else {
                return ''
            }
         })
        document.addEventListener('vue', initVue)
       
        function initVue() {
            Vue.component('details-table-block', detailsBlock)
            Vue.component('interaction-row', interactionRow)
            Vue.component('cancellation-row', cancellationRow)
            Vue.component('pagination', pagination)
            const VueApp = new Vue({
                el: '#details-table',
                // render: (h)=>h(),
                created() {
                },
                components: { detailsBlock, interactionRow, cancellationRow, pagination},
            })
            
    
        } 
        initVue()
       
  
    }
   
     
    
    

})
