var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "calloff-content form-container step-1",
      class: _vm.loading ? "is-requesting" : ""
    },
    [
      _c("p", [
        _vm._v(_vm._s(_vm._f("trans")("calloff.form.add-description")))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "incoterm-selection" }, [
        _c("div", { staticClass: "form-group label-floating" }, [
          _c("label", { staticClass: "control-label" }, [
            _vm._v(
              "\n              " +
                _vm._s(_vm._f("trans")("calloff.form.label_choose_delivery")) +
                "\n          "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "form-group incoterm-selection",
              attrs: { id: "incoterm", "data-name": "incoterm" }
            },
            _vm._l(_vm.callOffTypes, function(callOffType, index) {
              return _c(
                "div",
                { key: callOffType, staticClass: "inline-for" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.callOffTypeSelected,
                        expression: "callOffTypeSelected"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      id: "incoterm_" + index,
                      name: "incoterm",
                      required: "required"
                    },
                    domProps: {
                      value: callOffType.name,
                      checked: _vm._q(_vm.callOffTypeSelected, callOffType.name)
                    },
                    on: {
                      change: [
                        function($event) {
                          _vm.callOffTypeSelected = callOffType.name
                        },
                        _vm.selectCallOffType
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "required",
                      attrs: { for: "incoterm_" + index }
                    },
                    [_vm._v(_vm._s(callOffType.name))]
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "delivery-mode" }, [
            _c("div", { staticClass: "form-group label-floating" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v(
                  "\n                      " +
                    _vm._s(
                      _vm._f("trans")("calloff.form.label_choose_delivery_mode")
                    ) +
                    "\n                  "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group", attrs: { id: "delivery_mode" } },
                _vm._l(_vm.deliveryModes, function(deliveryMode) {
                  return _c(
                    "div",
                    { key: deliveryMode, staticClass: "inline-for" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.deliveryModeSelected,
                            expression: "deliveryModeSelected"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          id: deliveryMode,
                          name: deliveryMode
                        },
                        domProps: {
                          value: deliveryMode,
                          checked: _vm._q(
                            _vm.deliveryModeSelected,
                            deliveryMode
                          )
                        },
                        on: {
                          change: [
                            function($event) {
                              _vm.deliveryModeSelected = deliveryMode
                            },
                            _vm.deliveryModeSelect
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: deliveryMode } }, [
                        _vm._v(
                          "\n                              " +
                            _vm._s(deliveryMode) +
                            "\n                          "
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }