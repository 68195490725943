export async function fetchAndShowAnnouncement() {
    const url = '/api/announcements?device_type=web'

    const oldAnnouncement = localStorage.getItem('WEB_announcement')

    localStorage.setItem('WEB_announcement_show', false)

    try {
        const response = await axios.get(url)
        let responseData = {}

        // if there is more than one only save the first one from the response
        if (Array.isArray(response.data.data)) {
            responseData = response.data.data[0]
        } else {
            responseData = response.data.data
        }

        localStorage.setItem('WEB_announcement', JSON.stringify(responseData))

        // if the announcement is new/different then it needs to be shown
        if (localStorage.getItem('WEB_announcement') != oldAnnouncement) {
            localStorage.setItem('WEB_announcement_show', true)
        } else {
            localStorage.setItem('WEB_announcement_show', false)
        }

        // if the announcement is permanent then it needs to be shown
        if (responseData && responseData.is_permanent) {
            localStorage.setItem('WEB_announcement_show', true)
        }

        // if the announcement gets deleted/expires then dont show it or if the message is empty
        if (!responseData || (responseData && !responseData.message)) {
            localStorage.setItem('WEB_announcement_show', false)
        }

        // fire the swal popup if the announcement needs to be shown
        if (localStorage.getItem('WEB_announcement_show') == 'true') {
            const popupContent = $('<div/>').html(responseData.message)

            swal({
                className: 'swal-announcement-popup',
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: responseData.is_permanent ? false : {
                    cancel: {
                        value: null,
                        visible: true,
                        className: 'close-popup',
                        closeModal: true,
                    }
                },
                content: popupContent[0]
            })

            $('.swal-button.close-popup').html('&times;')
        }

    } catch (error) {
        throw error
    }
}