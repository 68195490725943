export function initAjaxFilter(
        filter,
        filterParent,
        dropdownClass = 'default-ajax-filter-dropdown-class',
        loadingContainer,
        filterLoadingIcon,
        dropdownArrow = '<i class="material-icons">expand_more</i>',
        multiselect = false,
    ) {
        
    let url = window.location.href
    let { filterUrl, filterName, clearFilter, selection, noResults } = filter.data()
    let clearOptionIsAppended = false
    let searchMinimum = ''

    if (multiselect) {
        searchMinimum = 1
    } else {
        searchMinimum = -1
        dropdownClass = dropdownClass + ' no-search-box'
    }

    filter.select2({
        dropdownParent: filterParent,
        minimumResultsForSearch: searchMinimum,
        dropdownCssClass: dropdownClass,
        ajax: {
            // updates request url with all the current url params except for the current pagination params
            url: () => {
                let params = window.getUrlParams(url)
                let keys = Object.keys(params)
                if (keys.length){
                    keys.forEach(key => {
                        if (params[key] !== '' && key !== filterName && key != 'page_size' && key != 'page'){
                            if(multiselect) {
                                filterUrl = window.updateUrlParameterMulti(filterUrl, key, params[key])
                            } else {
                                filterUrl = window.updateUrlParameter(filterUrl, key, params[key])
                            }
                        }
                    })
                }
                return filterUrl
            },
            dataType: 'json',
            processResults: data => {
                // structure the response json to match select2 needs for filling dropdown
                let keys = data.items[filterName] ? Object.keys(data.items[filterName]): []
                let options = {
                    results: keys.map( key => ({
                            text: data.items[filterName][key],
                            id: key,
                            value: key
                    })),
                    pagination: {
                        more: data.pagination.has_next_page
                    }
                }
                // append an option for clearing the filter, only once
                if (!clearOptionIsAppended) {
                    options.results.unshift({
                        text: clearFilter,
                        id: 'clear-filter',
                        value: ''
                    })
                    clearOptionIsAppended = true
                }
                return options
            }
        },
        language: {
            noResults: () => noResults,
            searching: () => filterLoadingIcon,
            loadingMore: () => filterLoadingIcon
        },
        escapeMarkup: markup => markup
        
    }).on('select2:select', e => {
        loadingContainer.addClass('is-requesting')
        let filterValue = e.params.data.value

        if(multiselect) {
            url = window.updateUrlParameterMulti(url, filterName, filterValue)
        } else {
            url = window.updateUrlParameter(url, filterName, filterValue)
        }
        window.location.href = url

    }).on('select2:close', () => {
        clearOptionIsAppended = false
    })

    // append an option that has the last saved state
    let preselectedOption = new Option(
        selection,
        'preselected-option',
        true,
        true
    )
    filter.append(preselectedOption)

    // custom dropdown arrow
    filterParent.find('.select2-selection__arrow b').html(dropdownArrow)
}

export function initTableAjaxFilter(filter, filterParent, loadingContainer, filterLoadingIcon, multiselect = false) {
    let url = window.location.href
    let { filterUrl, filterName, selection, placeholder, filterIcon, noResults } = filter.data()

    filter.select2({
        dropdownParent: filterParent,
        minimumResultsForSearch: -1,
        placeholder: placeholder.toString(),
        ajax: {
            // updates request url with all the current url params except for the current pagination params
            url: () => {
                let params = window.getUrlParams(url)
                let keys = Object.keys(params)
                if (keys.length){
                    keys.forEach(key => {
                        if (params[key] !== '' && key !== filterName && key != 'page_size' && key != 'page'){
                            if(multiselect) {
                                filterUrl = window.updateUrlParameterMulti(filterUrl, key, params[key])
                            } else {
                                filterUrl = window.updateUrlParameter(filterUrl, key, params[key])
                            }
                        }
                    })
                }
                return filterUrl
            },
            dataType: 'json',
            processResults: data => {
                // structure the response json to match select2 needs for filling dropdown
                let keys = data.items[filterName] ? Object.keys(data.items[filterName]): []
                return {
                    results: keys.map( key => ({
                            text: data.items[filterName][key],
                            id: key,
                            value: key
                    })),
                    pagination: {
                        more: data.pagination.has_next_page
                    }
                }
            }
        },
        language: {
            noResults: () => noResults,
            searching: () => filterLoadingIcon,
            loadingMore: () => filterLoadingIcon
        },
        templateSelection: () => styleSelection(placeholder, filterIcon),
        templateResult: (data, container) => styleResults(data, container, selection),
        escapeMarkup: markup => markup

    }).on('select2:select', e => {
        loadingContainer.addClass('is-requesting')
        let filterText = e.params.data.text
        let filterValue = e.params.data.value

        // if its already selected, remove it instead
        if (selection == filterText.toString().trim() || selection == filterValue) {
            if(multiselect) {
                url = window.updateUrlParameterMulti(url, filterName, filterValue)
            } else {
                url = window.updateUrlParameter(url, filterName, '')
            }
        } else {
            if(multiselect) {
                url = window.updateUrlParameterMulti(url, filterName, filterValue)
            } else {
                url = window.updateUrlParameter(url, filterName, filterValue)
            }
        }
        window.location.href = url
    })

    // remove the default dropdown arrow
    filterParent.find('.select2-selection__arrow').html('')

    // custom class for hiding the search-box in dropdown during the ajax request
    filterParent.addClass('no-search-box')

    function styleSelection (placeholder, filterIcon) {
        return `${placeholder} <i class="material-icons">${filterIcon}</i>`
    }

    function styleResults(data, container, selection) {
        let markup = ''

        // Option item
        let { text, id } = data

        // if its selected add the "x" icon for removal
        // in the case that filter doesnt append to URL its text, we have additional check for id
        if (selection == text.toString().trim() || selection == id) {
            markup = `
                        <a class="table-filter-clear"><i class="material-icons">close</i></a>
                        <a class="dropdown-item table-filter" href="#">
                            ${text}
                        </a>
                    `
        } else {
            markup = `
                        <a class="dropdown-item table-filter" href="#">
                            ${text}
                        </a>
                    `
        }
        $(container).addClass('position-relative')

        return markup
    }
}