import { initSearch } from 'Select2/search'

$(document).ready(() => {
    if($('.contract-overview-container').exists() || $('#calloff-creation').exists()) {
        const header = $('#contract-header')
        const headerInfoContainer = $('#contract-header .heading-block')
        const tableContainer = $('.calendar-table-container')
        const searchContainer = $('.overview-search-container')
        const itemLoadingIcon = $('#search-loader').html()
        const activeFilter = $('.active-filters-container').find('.active-filter')
        const pagination = $('#pagination')

        // Create favorite button tooltips
        const favoriteOn = $('.favorite-button.favorite-on').data('defavorite')
        const favoriteOff = $('.favorite-button.favorite-off').data('description')

        let url = window.location.href

        tableContainer.removeClass('is-requesting')

        window.overviewScreens = {}

        // Initialize search by sending 4 parameters:
        //  1) the item itself
        //  2) its parent
        //  3) container which will have 'is-requesting' class when loading
        //  4) html for the loading icon inside the item (not the main screen loader)
        if (searchContainer.exists()) {
            initSearch($('.overview-search'), searchContainer, tableContainer, itemLoadingIcon)
        }

        // Logic for showing loader when changing tabs
        $('.nav-pills-primary .nav-item').on('click', () => {
            $('.calloff-creation-brick-container').addClass('is-requesting')
            $('.contract-overview-container').addClass('is-requesting')
        })

        // Create tooltip for phone icon
        const phoneTooltip = $('.has-today-delivery').data('tooltip')
        tippy('.has-today-delivery', {
            content: phoneTooltip,
            maxWidth: 'max-content'
        })

        // Create tooltip for clock icon
        const clockTooltip = $('.has-today-calloff').data('tooltip')
        tippy('.has-today-calloff', {
            content: clockTooltip,
            maxWidth: 'max-content'
        })

        // Create tooltip for quality icon
        const qualityTooltip = $('.has-new-quality-document').data('tooltip')
        tippy('.has-new-quality-document', {
            content: qualityTooltip,
            maxWidth: 'max-content'
        })

        // "Sind sie sicher" step call
        headerInfoContainer.on('click', '.submit-button', showVerification)

        // Status change Handlers
        // headerInfoContainer.on('click', '.submit-button .submit', changeContractState)

        // Cancel action
        headerInfoContainer.on('click', '.submit-button .cancel', function (event) {
            event.stopPropagation()

            const buttonWrapper = $(this).closest('.submit-button')

            if (buttonWrapper.exists()) {
                buttonWrapper.removeClass('checked')
            }
        })

        /**
         * Verification handler
         */
        function showVerification() {
            const $button = $(this)
            if ($button.hasClass('checked')) {
                return
            }

            $button.addClass('checked')
        }

        /**
         * Status handler
         */
        async function changeContractState() {
            const $this = $(this)

            const requestURI = $this.data('url')
            $this.trigger('toggleState')

            try {
                // Make request
                await axios.get(requestURI, window.axiosConfig)

                if ($(this).closest('#contract-header').exists()) {
                    location.reload()
                } else {
                    // Update view
                    const updateUri = window.location.href
                    const response = await axios.get(updateUri, window.axiosConfig)
                    const { data } = response

                    updateStatusButton(data)
                }
            } catch (err) {
                window.showErrorPopup()
                $this.parents('.submit-button').removeClass('is-requesting')

                throw err
            }
        }

        /**
         * Header update helper
         * @param {*} data
         */
        function updateStatusButton(data) {
            const responseData = $('<div />').append(data)

            const statusButtonSelector = '#contract-header .info-manage'
            const buttonResponse = responseData.find(statusButtonSelector).html()

            headerInfoContainer.html(buttonResponse)

            window.updateSubmitButtons()
        }

        /**
         * Return 'Kontrakt schliesen' button to original state when clicked outside
         */
         $(document).on('click', function (evt) {
            const clickedButton = $('.submit-button.checked')

            if (!clickedButton.is(evt.target) || !clickedButton.has(evt.target)) {
                if (!$(".submit-button").has(evt.target).length) {
                    clickedButton.removeClass('checked')
                }
            }
        })
        // Logic for remembering dashobard pages
        $('.nav-menu-item').find('.nav-link').on('click', function() {
            let dashboardUrl = $(this).data('hrefLink')
            
            if(localStorage.getItem('dashboardPage')) {
                const pageNumber = localStorage.getItem('dashboardPage')

                dashboardUrl = window.updateUrlParameter(dashboardUrl, 'page', pageNumber)
            }
            window.location.href = dashboardUrl
        })


        // Toggle visibility of a single expanded row in the table
        tableContainer.on('click', '.toggle-item-changes', function() {
            // $(this).closest('.corrected-delivery-note, .changed-calloff').toggleClass('canceled-hidden')
        })


        // Toggle visibility of all expanded rows in the table
        $('.changed-toggler-container').on('click', function() {
            const changedCalloffs = $('.calendar-detail-table .changed-calloff')

            if ($(this).hasClass('expand')) {
                $(this).removeClass('expand')
                changedCalloffs.removeClass('canceled-hidden')
            } else {
                $(this).addClass('expand')
                changedCalloffs.addClass('canceled-hidden')
            }
        })


        // Show loader when redirecting to calloff creation from contract items screen
        $('.go_to_create_calloff .go-to-page-btn').on('click', function() {
            tableContainer.addClass('is-requesting')
        })
        

        // Only used on pricelist for overview_navigation select between carrier and normal delivery
        // TODO: Remove once the header for pricelist is redesigned to not use overview_navigation any more
        const deliverySelect = $('.contract-delivery-select')
        deliverySelect.select2({
            dropdownParent: $('.contract-overview-delivery-select'),
            minimumResultsForSearch: -1

        }).on('select2:select', function (e) {
            $('.contract-overview-container').addClass('is-requesting')
            window.location.href = e.params.data.element.dataset.href

        }).on('select2:opening', function(e) {
            if (!$('.yearly-preview-toggler-select').exists()) {
                e.preventDefault()
                $('.content-wrapper').find('.loader').removeClass('hidden')
                $('.contract-overview-container').addClass('is-requesting')

                window.location.href = $('#delivery-select').find(':selected').data('href')
            }
        })
        $('.contract-overview-delivery-select').find('.select2-selection__arrow b').html('<i class="material-icons">expand_more</i>')


        // Only used on pricelist for overview_navigation select between monthly and yearly delivery
        // TODO: Remove once the header for pricelist is redesigned to not use overview_navigation any more
        const yearlySelect = $('.yearly-toggler-select')
        yearlySelect.select2({
            dropdownParent: $('.yearly-preview-toggler-select'),
            minimumResultsForSearch: -1

        }).on('select2:select', function (e) {
            const path = e.params.data.element.dataset.href

            if (path.indexOf('yearly') >= 0) {
                const popupHeading = e.params.data.element.dataset.popupHeading
                const popupContent = e.params.data.element.dataset.popupContent
                const popupCancel = e.params.data.element.dataset.popupCancel
                const popupSubmit = e.params.data.element.dataset.popupSubmit

                swal({
                    className: 'calloff-status-modal',
                    title: popupHeading,
                    text: popupContent,
                    buttons: [popupCancel,  popupSubmit]
                }).then(function(value){
                    if (value === true){
                        $('.contract-overview-container').addClass('is-requesting')
                        window.location.href = path
                    }
                    else {
                        yearlySelect.val("monthly").trigger('change')
                    }
                })
            }
            else {
                $('.contract-overview-container').addClass('is-requesting')
                window.location.href = path
            }
        })
        $('.yearly-preview-toggler-select').find('.select2-selection__arrow b').html('<i class="material-icons">expand_more</i>')


        // Logic for initializing table filters on contract items, calloff and delivery (not pricelist)
        if($('.overview-table-container').exists()) {
            initFilters() 
        }

        function initFilters() {
            $('.header-filter').each(function() {
                const { filterName, placeholder, selection, noResults, filterIcon } = $(this).data()

                $(this).select2({
                    minimumResultsForSearch: -1,
                    dropdownParent: $(this).closest('.filter-heading'),
                    placeholder: placeholder.toString(),
                    language: {
                        noResults: () => noResults
                    },
                    templateSelection: () => `${placeholder} <i class="material-icons">${filterIcon}</i>`,
                    templateResult: data => styleResults(data, selection),
                    escapeMarkup: markup => markup

                }).on('select2:opening', function() {
                    $(this).closest('.responsive-table-wrapper').addClass('filter-opened')
                    $('.contract-overview-container .contract-search-container').addClass('filter-opened')
                }).on('select2:select', async function(e) {
                    let filterText = e.params.data.text
                    let filterValue = e.params.data.id

                    // if its already selected, remove it instead
                    if (selection == filterText.toString().trim() || selection == filterValue) {
                        url = window.updateUrlParameter(url, 'filter['+filterName+']', '')
                    } else {
                        url = window.updateUrlParameter(url, 'filter['+filterName+']', filterValue)
                    }
                    
                    tableContainer.addClass('is-requesting')
                    window.location.href = url
                }).on('select2:closing', function() {
                    $(this).closest('.responsive-table-wrapper').removeClass('filter-opened')
                    $('.contract-overview-container .contract-search-container').removeClass('filter-opened')
                })
            })
        }
        window.overviewScreens.initFilters = initFilters

        // Logic for styling filter options with or without delete icon
        function styleResults(data, selection) {
            let markup = ''
            let { text, id } = data

            if (selection == text.toString().trim() || selection == id) {
                markup = `
                            <span class="calloff-step-filter-clear"><i class="material-icons">close</i></span>
                            <p class="calloff-step-filter" href="#">
                                ${text}
                            </p>
                        `
            } else {
                markup = `
                            <p class="calloff-step-filter" href="#">
                                ${text}
                            </p>
                        `
            }
            return markup
        }


        // Logic for sorters in table headers on contract items, calloff and delivery
        if($('#contract-overview-page').exists()) {
            tableContainer.on('click', '.table-sort', function() {
                const { sortOrder, sortKey } = $(this).data()

                url = window.updateUrlParameter(url, 'sortOrder', sortOrder)
                url = window.updateUrlParameter(url, 'sortKey', sortKey)

                $('.contract-overview-container').addClass('is-requesting')
                
                window.location.href = url
            })
        }


        // Logic for datepickers in table headers on contract items, calloff and delivery
        function initTableHeaderDatepicker() {
            const tableDatepicker = $('.table-header-datepicker')

            if(tableDatepicker.length === 0) {
                return
            }

            let { locale, type, single, minDate, maxDate, submitTranslation } = tableDatepicker.data()
            let startDate, endDate

            flatpickr(tableDatepicker, {
                dateFormat: 'd.m.Y',
                locale: locale,
                closeOnSelect: false,
                disableMobile: true,
                mode: single ? 'single' : 'range',
                minDate: minDate,
                maxDate: maxDate,

                onReady: (selectedDates, dateString, instance) => {
                    // append submit button to datepicker
                    let { calendarContainer } = instance
                    $(calendarContainer).append(`<button class="date-submit">${submitTranslation}</button>`)

                    // add class to the dropdown
                    $(calendarContainer).addClass('table-header-datepicker-dropdown')
                },
                onValueUpdate: date => {
                    if (date.length) {
                        startDate = flatpickr.formatDate(date[0], 'd.m.Y')
                        endDate = flatpickr.formatDate(date[date.length - 1], 'd.m.Y')
                    }
                }
            })

            // logic for date submit button
            $('.date-submit').on('click', () => {
                tableDatepicker[0]._flatpickr.close()
                tableContainer.addClass('is-requesting')
                
                if (type == 'upload') {
                    url = window.updateUrlParameter(url, 'date_of_upload_from', startDate)
                    url = window.updateUrlParameter(url, 'date_of_upload_to', endDate)

                } else {
                    if (single) {
                        url = window.updateUrlParameter(url, 'filter[delivery_date]', startDate)
                    } else {
                        url = window.updateUrlParameter(url, 'filter[delivery_from_date]', startDate)
                        url = window.updateUrlParameter(url, 'filter[delivery_to_date]', endDate)
                    }
                }
                
                window.location.href = url
            })
        }
        window.overviewScreens.initTableHeaderDatepicker = initTableHeaderDatepicker

        if($('.table-header-datepicker').exists()) {
            initTableHeaderDatepicker()
        }


        // Logic for clearing active filters above the table on contract items, calloff and delivery
        if ($('#contract-overview-page').exists()){
            activeFilter.on('click', function() {
                let filterKey = $(this).find('.remove-active-filter').data('filter-key')

                if (filterKey == 'filter[delivery_date]') {
                    url = window.updateUrlParameter(url, 'filter[delivery_from_date]', '')
                    url = window.updateUrlParameter(url, 'filter[delivery_to_date]', '')
                    url = window.updateUrlParameter(url, 'filter[delivery_date]', '')
                } else {
                    url = window.updateUrlParameter(url, filterKey, '')
                }

                activeFilter.remove()
                tableContainer.addClass('is-requesting')

                window.location.href = url
            })
        }


        // Logic for initializing pagination on contract items, calloff and delivery
        function initPagination() {
            let { first, last, next, prev, startPage, totalPages, visiblePages } = pagination.data()
            
            pagination.twbsPagination({
                first,
                last,
                next,
                prev,
                startPage,
                totalPages,
                visiblePages,
                initiateStartPageClick: false,
                onPageClick: async (event, page) => {
                    const params = {
                        ...window.getUrlParams(window.location.href),
                        'page': page
                    }
                    tableContainer.addClass('is-requesting')

                    if (!params.isEmpty) {
                        Object.keys(params).forEach(function (key) {
                            url = window.updateUrlParameter(url, key, params[key])
                        })
                        window.location.href = url
                    }
                }
            })
        }
        if (pagination.exists()) {
            initPagination()
        }

        // Logic for contract items tooltips, separate js file for this screen doesnt exist yet
        if($('.contract-item-table').exists()) {
            // Contract items table - canceled item
            const contracItemCanceledTooltip = $('.canceled-item').data('tooltipTranslation')

            if($('.canceled-item').hasClass('has-tippy')) {
                tippy('.has-tippy .error', {
                    content: contracItemCanceledTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            }

            // item status
            const calloffStatusTooltip = $('.contract-item-table-status .contract-item-clock').data('tooltipTranslation')
            const deliveryStatusTooltip = $('.contract-item-table-status .contract-item-phone').data('tooltipTranslation')

            $('.contract-item-table-status .contract-item-clock').each( function() {
                tippy(this, {
                    content: calloffStatusTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })    
            })

            $('.contract-item-table-status .contract-item-phone').each( function() {
                tippy(this, {
                    content: deliveryStatusTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })   
            })
            

            // material_designation
            $('td.material_designation .has-tippy').each( function() {
                const contractItemMaterialDesignation1 = $(this).data('materialDescription')
                const contractItemMaterialDesignation2 = $(this).data('materialDescriptionMore')

                tippy(this, {
                    content: contractItemMaterialDesignation1 + '<br>' + contractItemMaterialDesignation2,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })  
            })

            // incoterm
            $('td.incoterm .has-tippy').each( function() {
                const contractItemIncotermTooltip = $(this).data('tooltipTitle')

                tippy(this, {
                    content: contractItemIncotermTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })
            

            // factory
            $('td.factory .has-tippy').each( function() {
                const factoryNameTooltip = $(this).data('factoryName')
                const factoryStreetTooltip = $(this).data('factoryStreet')
                const factoryStreetNumberTooltip = $(this).data('factoryStreetNumber')
                const factoryZipTooltip = $(this).data('factoryZip')
                const factoryCityTooltip = $(this).data('factoryCity')

                tippy(this, {
                    content: factoryNameTooltip + '<br>' + factoryStreetTooltip + '<br>' + factoryStreetNumberTooltip + '<br>' + factoryZipTooltip + '<br>' + factoryCityTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })
        }

        // Header tooltips
        const vkOrgTippy = $('#contract-header .vk-org .has-tippy').data('tooltip')
        const vkOrgNumberTranslation = $('#contract-header .vk-org .has-tippy').data('translation')
        tippy('#contract-header .vk-org .has-tippy', {
            content: `${vkOrgNumberTranslation}: ${vkOrgTippy}`,
            placement: 'bottom',
            theme: 'cockpit-tippy'
        })

        const vkOfficeTippy = $('#contract-header .vk-office .has-tippy').data('tooltip')
        const vkOfficeNumberTranslation = $('#contract-header .vk-office .has-tippy').data('translation')
        tippy('#contract-header .vk-office .has-tippy', {
            content: `${vkOfficeNumberTranslation}: ${vkOfficeTippy}`,
            placement: 'bottom',
            theme: 'cockpit-tippy'
        })

        // Go to create calloff
        const goToCreateCalloff = $('.go_to_create_calloff .go-to-page-btn').data('tooltipTranslation')
        tippy('.go_to_create_calloff .go-to-page-btn', {
            content: goToCreateCalloff,
            placement: 'bottom',
            theme: 'cockpit-tippy'
        })

        //Contract header tooltips
        const contractHeaderTooltip = $('#contract-header-message').data('contractHeaderMessage')
        tippy('.submit-button', {
            theme: 'contract-header-message',
            content: contractHeaderTooltip,
            placement: 'bottom',
            appendTo: 'parent',
        })

        // Favorite button tooltip initialization
        let tipOn = tippy('.favorite-on', {
            content: favoriteOn,
            maxWidth: 'max-content'
        })

        let tipOff = tippy('.favorite-off', {
            content: favoriteOff,
            maxWidth: 'max-content'
        })

        // Logic for adding/removing favorite contract
        const changeFavoriteContract = async function () {
            // let favorite = $(this).data('favorite')
            // let url = $(this).data('url')
            // const searchLoader = $(this).next('#search-loader')
            //
            // url = window.updateUrlParameter(url, 'status', !favorite)
            //
            // header.addClass('is-requesting')
            // searchLoader.removeClass('hidden')
            //
            // console.log(url)

            // try {
            //     await axios.get(url, window.axiosConfig)
            //     // await getContractListHtml()
            //     header.removeClass('is-requesting')
            //     searchLoader.addClass('hidden')
            //     if ($('#star').html() == 'star') {
            //         // remove the existing tooltip
            //         tipOn[0].destroy()
            //         $('#star').html('star_border')
            //         $(this).toggleClass('favorite-off favorite-on')
            //         // reasign tooltip value so it can be toggled
            //         tipOff = tippy('.favorite-off', {
            //             content: $('.favorite-off').data('description')
            //         })
            //     } else {
            //         // remove the existing tooltip
            //         tipOff[0].destroy()
            //         $('#star').html('star')
            //         $(this).toggleClass('favorite-off favorite-on')
            //         // reasign tooltip value so it can be toggled
            //         tipOn = tippy('.favorite-on', {
            //             content: $('.favorite-on').data('defavorite')
            //         })
            //     }
            // } catch (err) {
            //     window.showErrorPopup()
            //     header.removeClass('is-requesting')
            //     searchLoader.addClass('hidden')
            //     throw err
            // }
        }
        // header.on('click', '.favorite-button', changeFavoriteContract)
    }
})