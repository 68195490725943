$(document).ready(()=> {
    if (($('#call-off-companies').exists() && ($('.company-overview').exists() || $('.factory-overview').exists()))) {
       
        // toggleAllButton = $('#expand-calloffs')
        // toggle singleblock calloffs' visibility
        $(document).on('click', '#expand-calloffs', toggleAllBlocks)
        $(document).on('click', '.toggle-button', toggleBlock)
        $(document).on('click', '.subject-info', showDetails)

        function toggleBlock() {
            $this = $(this)
            block = $this.parents('.customer-block')
            if (block.hasClass('closed')) {
                block.removeClass('closed')
            } else {
                block.addClass('closed')
            }
        }
       
        // toggle all tables' calloffs
        function toggleAllBlocks() {
            const $button = $(this)
            let markup = ''
            const customerBlocks = $('.customer-block')

            if ($button.find('.toggler-up').exists()) {
                const { showText } = $button.data()
                markup = `${showText}<span class="toggler-down"></span>`
                customerBlocks.each(function() {
                    const block = $(this)
                    if (!block.hasClass('closed')) {
                        block.addClass('closed')
                    }
                })
            } else {
                const { hideText } = $button.data()
                markup = `${hideText} <span class="toggler-up"></span>`
                customerBlocks.each(function() {
                    const block = $(this)
                    if (block.hasClass('closed')) {
                        block.removeClass('closed')
                    }
                })
            }
            $button.html(markup)
        }
        function showDetails(e) {
            $this = $(this)
            let toggler = $this.find('.toggle-button')
            if (toggler.is(e.target) || toggler.has(e.target).length) {
                return
            }
            let {href} = $this.data()
            if (href){
                window.location.href = href 
            }
        }
    }
})
