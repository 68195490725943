var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShowing,
          expression: "isShowing"
        }
      ],
      staticClass: "interaction-row"
    },
    [
      _c(
        "td",
        { attrs: { colspan: _vm.colspan } },
        [
          _c("material-info", {
            attrs: {
              status: _vm.status,
              initialItem: _vm.item,
              calloffTypesUrl: _vm.calloffTypesUrl,
              calloffEditUrl: _vm.calloffEditUrl
            }
          }),
          _vm._v(" "),
          _c("note-controls", {
            attrs: {
              status: _vm.status,
              saveUrl: _vm.saveUrl,
              sendUrl: _vm.sendUrl,
              exportUrl: _vm.exportUrl,
              approveUrl: _vm.approveUrl,
              approveCancelationUrl: _vm.approveCancelationUrl,
              proposeCalloffUrl: _vm.proposeCalloffUrl,
              item: _vm.item,
              calloffEdited: _vm.calloffEdited
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }