class ContactPersonPopup {
    constructor($containerSelector = null, {
        apiRoute = '',
        onSubmit = null,
    }) {
        this.container = $containerSelector
        if(!this.container.exists()) {
            console.error('Please provide proper DOM element for ContactPersonPopup class')
            return
        }

        this.apiRoute = apiRoute

        this.onSubmit = null
        if (onSubmit && typeof onSubmit === 'function') {
            this.onSubmit = onSubmit
        }

        this.searchTerm = null
        this.filterValues = {}
        this.selectedData = {}

        this.mainContainer = this.container.find('.popup-main-container')
        this.popupContent = this.container.find('.calloff-creation-contact-popup-content')
    }

    async showPopup(selectedData = {}) {
        this.selectedData = selectedData

        try {
            // Set flags
            this.container.removeClass('hidden fadeOut').addClass('animated fadeIn faster')
            this.popupContent.addClass('is-requesting')

            // Make request & fetch content
            const popupContent = await this.fetchPopupContent()

            // Update content
            this.mainContainer.html(popupContent)

            // Set selections
            this.setSelections()

            // Initialize event listeners & actions
            this.init()

            // Remove requesting flag
            this.popupContent.removeClass('is-requesting')

        } catch(err) {
            // Remove loading flags
            this.container.addClass('hidden fadeOut').removeClass('animated fadeIn faster')
            this.popupContent.removeClass('is-requesting')

            throw err
        }
    }

    closePopup() {
        // Clear selected values
        this.selectedData = {}

        // Clear filter values
        this.filterValues = {}

        // Hide popup
        this.container.removeClass('animated fadeIn faster').addClass('hidden fadeOut')

        // Clear content
        this.mainContainer.html('')

        // Clear event listeners
        this.destroy()
    }

    submitSelection() {
        const createdByRadio = this.container.find('input[data-name="created_by_customer"]:checked')
        const contactRadio = this.container.find('input[data-name="contact_person"]:checked')

        // Some extra check :-)
        if(!createdByRadio.val() || !contactRadio.val()) {
            return
        }

        let createdByPersonData = createdByRadio.closest('tr').data('contactData')
        createdByPersonData = createdByPersonData || {}

        let contactPersonData = contactRadio.closest('tr').data('contactData')
        contactPersonData = contactPersonData || {}

        if(this.onSubmit) {
            this.onSubmit({
                contact_person: contactPersonData,
                created_by_customer: createdByPersonData,
            })
        }

        this.closePopup()
    }

    /**
     * Initialization method
     * [EVent listeners, select2 search & filters]
     */
    init() {
        /**
         * Event listeners
         */

        // "X" icon click
        this.container.on('click', '.exit-popup-icon', () => this.closePopup())

        // Close button click
        this.container.on('click', '.popup-button.exit', () => this.closePopup())

        // Search option select
        this.container.on('select2:select', '.calloff-creation-popup-search', (event) => this.selectSearchAction(event))

        // Active selection/filter clear on search
        this.container.on('click', '.active-filter', this.handleSearchFilterClear.bind(this))

        // Table header/filter selection
        this.container.on('select2:select', '.calloff-creation-contact-popup-table .header-filter', this.handleFilterSelectAction.bind(this))

        // Radio button change event => check submit button disabled state
        this.container.on('change', '.calloff-popup-contact input[type="radio"]', () => this.setSubmitButtonState())

        // Submit/Save button
        this.container.on('click', '.popup-button.save-data', () => this.submitSelection())

        /**
         * Initialization
         */

        // Search
        this.initSearch()

        // Filters
        this.initFilters()

        // Enable/Disable submit button
        this.setSubmitButtonState()
    }

    /**
     * Remove event listeners and select2 instances
     * [Free up memory space]
     */
    destroy() {
        // Remove propagated click event listeners
        this.container.off('click')

        // Remove propagated change event listeners
        this.container.off('change')

        // Remove propagated "select2" selection events
        this.container.off('select2:select')

        // Destroy search
        this.removeSearch()

        // Remove filters
        this.removeFilters()
    }

    selectSearchAction(event) {
        const { data } = event.params

        this.filterValues = {
            [data.value]: data.id,
            'term': this.searchTerm,
        }

        this.updatePopup()
    }

    handleSearchFilterClear(event) {
        const button = $(event.currentTarget)
        const { filterKey } = button.data()

        if(this.filterValues.hasOwnProperty(filterKey)) {
            delete this.filterValues[filterKey]
            this.updatePopup()
        }
    }

    handleFilterSelectAction(event) {
        const filter = $(event.currentTarget)
        const { filterName, selection } = filter.data()

        const filterText = event.params.data.text
        const filterValue = event.params.data.id

        // Update filters store object
        this.filterValues[filterName] = filterValue

        // If its already selected remove it instead
        if (selection == filterText.toString().trim() || selection == filterValue) {
            this.filterValues[filterName] = ''
        }

        this.updatePopup()
    }

    setSelections() {
        const { contact_person, created_by_customer } = this.selectedData

        // Set selections
        if(contact_person && created_by_customer) {
            const contactPersonRadio = this.container.find(`#calloff-contact-${contact_person}`)
            if(contactPersonRadio.exists()) {
                contactPersonRadio.prop('checked', true)
            }

            const createdByRadio = this.container.find(`#created-by-${created_by_customer}`)
            if(createdByRadio.exists()) {
                createdByRadio.prop('checked', true)
            }
        }
    }

    setSubmitButtonState() {
        let disabled = true
        const submitButton = this.container.find('.popup-button.save-data')

        const createdByRadios = this.container.find('input[data-name="created_by_customer"]:checked')
        const contactRadios = this.container.find('input[data-name="contact_person"]:checked')

        // Both radio button groups need to be selected
        if (createdByRadios.val() && contactRadios.val()) {
            disabled = false
        }

        submitButton.prop('disabled', disabled)
    }

    async updatePopup() {
        this.popupContent.addClass('is-requesting')

        try {
            const popupContent = await this.fetchPopupContent()

            this.destroy()
            this.mainContainer.html(popupContent)
            this.setSelections()
            this.init()

            this.popupContent.removeClass('is-requesting')

        } catch (err) {
            this.popupContent.removeClass('is-requesting')

            throw err
        }
    }

    initSearch() {
        this.searchTerm = null

        const searchInput = this.container.find('.calloff-creation-popup-search')
        const searchContainer = searchInput.closest('.search-container')
        const loaderContent = searchContainer.find('#search-loader').html()

        let {
            messageApi_route: searchUrl,
            messagePlaceholder: placeholder,
            messageAdd_more_text: addMoreText,
            messageNo_results: noResults
        } = searchInput.data()

        const styleResults = (data) =>
           `<a class="search-result-link" href="#">
                ${data.text}
            </a>`

        searchInput.select2({
            dropdownParent: searchContainer,
            minimumResultsForSearch: -1,
            minimumInputLength: 1,
            multiple: true,
            placeholder: placeholder,
            ajax: {
                url: () => searchUrl,
                dataType: 'json',
                data: params => {
                    const { term } = params

                    // Only used to store the search term
                    this.searchTerm = term

                    // "search_term" is query param
                    return {
                        search_term: term
                    }
                },
                processResults: data => {
                    return {
                        results: data.items.map(key => ({
                                text: key.header + ': ' + key.name,
                                id: key.value,
                                value: key.filter_key
                        })),
                        pagination: {
                            more: data.pagination.has_next_page
                        }
                    }
                }
            },
            language: {
                inputTooShort: () => addMoreText,
                noResults: () => noResults,
                searching: () => loaderContent,
                loadingMore: () => loaderContent
            },
            templateResult: data => styleResults(data),
            escapeMarkup: markup => markup
        })

        searchContainer.find('.search-icon').removeClass('hidden')
    }

    removeSearch() {
        this.searchTerm = null
        const searchInput = this.container.find('.calloff-creation-popup-search')

        searchInput.select2('destroy')
    }

    initFilters() {
        const filters = this.container.find('.calloff-creation-contact-popup-table-container .header-filter')

        const styleResults = (data, selection) => {
            let markup = ''
            let { text, id } = data

            if (selection == text.toString().trim() || selection == id) {
                markup = `
                    <span class="calloff-step-filter-clear"><i class="material-icons">close</i></span>
                    <p class="calloff-step-filter" href="#">
                        ${text}
                    </p>
                `
            } else {
                markup = `
                    <p class="calloff-step-filter" href="#">
                        ${text}
                    </p>
                `
            }

            return markup
        }

        filters.each((_, item) => {
            const filter = $(item)

            const { filterName, placeholder, selection, filterIcon, noResults } = filter.data()

            filter.select2({
                minimumResultsForSearch: -1,
                dropdownParent: filter.closest('.filter-heading'),
                language: {
                    noResults: () => noResults
                },
                templateSelection: () => {
                    return `${placeholder.toString()} <i class="material-icons">${filterIcon}</i>`
                },
                templateResult: data => styleResults(data, selection),
                escapeMarkup: markup => markup
            })
        })
    }

    removeFilters() {
        const filters = this.container.find('.calloff-creation-contact-popup-table-container .header-filter')
        filters.each((_, item) => {
            $(item).select2('destroy')
        })
    }

    async fetchPopupContent() {
        // Create url from filter values
        let updateUri = this.apiRoute

        Object.keys(this.filterValues).forEach((key) => {
            updateUri = window.updateUrlParameter(updateUri, key, this.filterValues[key])
        })

        try {
            const response = await axios.get(updateUri, window.axiosConfig)
            const { data } = response

            return data
        } catch(err) {
            window.showErrorPopup()
            throw err
        }
    }
}

window.highwayCOCData = window.highwayCOCData || {}
window.highwayCOCData.ContactPersonPopupClass = ContactPersonPopup

export default ContactPersonPopup
