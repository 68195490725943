$(document).ready(() => {
    if ($('#contract-overview-page').exists()) {
        let yearSelect = $('#contract-year-select')
        let quarterSelect = $('#contract-quarter-select')
        let pricelistIncotermSelect = $('#pricelist-incoterm-select')
        let url = decodeURIComponent(window.location.href)
        let loader = $('.content-wrapper').find('.loader')
        const dropdownArrow = $('.dropdown-arrow-svg').html()
        if (yearSelect.exists()){
            yearSelect.select2({
                minimumResultsForSearch: -1,
                dropdownParent: $('.contract-year-select-container'),
                escapeMarkup: (markup) => markup,
                templateResult: templateResult,
            }).on('select2:select', function(e){
                const path = e.params.data.element.dataset.href
                // let value = e.params.data.element.value
                // if (value == 'all') {
                //     quarterSelect.val('').trigger('change')
                //     quarterSelect.attr('disabled', true)
                // } else {
                //     quarterSelect.attr('disabled', true)
                // }
                if (loader.hasClass('hidden')){
                    loader.removeClass('hidden')
                }
                $('.contract-overview-container').addClass('is-requesting')

                window.location.href = path
            })
            $('.contract-year-select-container').find('.select2-selection__arrow b').html(dropdownArrow)
        }
        if (quarterSelect.exists()){ 
            quarterSelect.select2({
                minimumResultsForSearch: -1,
                dropdownParent: $('.contract-quarter-select-container'),
                escapeMarkup: (markup) => markup,
                templateResult: templateResult,
            }).on('select2:select', function(e){
                const path = e.params.data.element.dataset.href
                window.location.href = path
                if (loader.hasClass('hidden')){
                    loader.removeClass('hidden')
                }
                $('.contract-overview-container').addClass('is-requesting')
            })
            $('.contract-quarter-select-container').find('.select2-selection__arrow b').html(dropdownArrow)
        }
        if (pricelistIncotermSelect.exists()){ 
            pricelistIncotermSelect.select2({
                minimumResultsForSearch: -1,
                placeholder: pricelistIncotermSelect.data('placeholder'),
                dropdownParent: $('.filter-heading-incoterm'),
                escapeMarkup: (markup) => markup,
            }).on('select2:select', function(e){
                const filter = e.params.data.element.dataset.value
                url = window.updateUrlParameter(url, 'filter[incoterm]', filter)
                window.location.href = url
            })
        }
        $('.filter-heading-incoterm').find('.select2-selection__arrow').html(dropdownArrow)
        function templateResult(item){
            let $option = $(item.element)
            let text = $option.text()
            let href = $option.data('href')
            if (href){
               return `<a href="${href}" class="search-result-link">${text}</a>`;
            } else {
                return false
            }
           

        }
    }
})