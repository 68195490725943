$(document).ready(() => {
    if($('.cockpit-calloff-overview').exists()) {
        const calendarContainer = $('#calendar-container')
        const tableContainer = $('.calendar-table-container')


        // Logic for editing and duplicating calloff
        $('html').on('click', '.edit-calloff, .duplicate-calloff', async function() {
            let { requestUri, submitBtn, cancelBtn, confirmationTitle, confirmationContent } = $(this).data()

            try {
                tableContainer.addClass('is-requesting')
                calendarContainer.addClass('is-requesting')

                $('.calloff-dropdown-tippy-theme').closest('.tippy-popper')[0]._tippy.hide()

                let response = await axios.get(requestUri)
                let { data } = response

                // if there is nothing to overwrite continue with the process on calloff creation
                if (data.status == "200") {
                    window.location.href = data.redirect_url

                // if there is something in the calloff creation cart that would be overwritten, open the warning popup
                } else if (data.status == "409") {
                    tableContainer.removeClass('is-requesting')
                    calendarContainer.removeClass('is-requesting')

                    swal({
                        className: 'change-calloff-popup',
                        title: confirmationTitle,
                        text: confirmationContent,
                        buttons: {
                            submit: {
                                text: submitBtn,
                                value: true,
                            },
                            cancel: cancelBtn,
                        }
                    }).then(async function(result) {
                        // if confirm button was clicked:
                        if (result) {
                            requestUri = window.updateUrlParameter(requestUri, 'overwrite', 'true')

                            tableContainer.addClass('is-requesting')
                            calendarContainer.addClass('is-requesting')

                            let response = await axios.get(requestUri)
                            let { data } = response

                            if (data.redirect_url) {
                                window.location.href = data.redirect_url
                            } else {
                                tableContainer.removeClass('is-requesting')
                                calendarContainer.removeClass('is-requesting')
                            }
                        } else {
                            tableContainer.removeClass('is-requesting')
                            calendarContainer.removeClass('is-requesting')
                        }
                    })
                }
            } catch {
                tableContainer.removeClass('is-requesting')
                calendarContainer.removeClass('is-requesting')
                window.showErrorPopup()
            }
        })

        // Logic for editing and duplicating calloff
        $('html').on('click', '.cancel-calloff', async function() {
            const parentContainer = $(this).closest('.status')
            const cancelPopup = parentContainer.find('.decline-calloff-popup-container')

            $('.calloff-dropdown-tippy-theme').closest('.tippy-popper')[0]._tippy.hide()

            cancelPopup.toggleClass('hidden')

            parentContainer.find('.close-decline-calloff-popup').on('click', function() {
                cancelPopup.addClass('hidden')
            })

            parentContainer.find('.decline-calloff-cancel-button').on('click', function() {
                cancelPopup.addClass('hidden')
            })
        })

        function initCancelationPopupSelect() {
            $('.calloff-cancel-select').each(function() {
                $(this).select2({
                    dropdownParent: $(this).closest('.decline-calloff-popup-select-container'),
                    containerCssClass: 'calloff-preset-select' ,
                    dropdownCssClass: 'calloff-preset-dropdown',
                    minimumResultsForSearch: -1
                }).on('select2:select', function(e) {
                })
            })
        }
        initCancelationPopupSelect()

        // Logic for submitting cancel calloff popup data
        $('html').on('click', '.decline-calloff-send-button', async function() {
            let route = $(this).data('path')
            let params = {}

            // get all of the values for sending
            $(this).closest('.decline-calloff-popup').find('select, textarea').each(function() {
                params[$(this).data('name')] = $(this).val()
            })

            // convert all params to formData
            const payload = createPayload(params)

            try {
                $(this).closest('.decline-calloff-popup-container').addClass('hidden')
                
                tableContainer.addClass('is-requesting')
                calendarContainer.addClass('is-requesting')

                await axios.post(route, payload)
                location.reload()
                
            } catch {
                window.showErrorPopup()
            }
        })

        // Logic for changing the calloff status or removing the calloff from table or calendar
        $('html').on('click', '.change-calloff-status, .remove-calloff', function() {
            const { requestUri, submitBtn, cancelBtn, confirmationTitle, confirmationContent, confirmationSubmit } = $(this).data()
            const popupContent = $(this).find('.popup-content-holder').html()

            $('.calloff-dropdown-tippy-theme').closest('.tippy-popper')[0]._tippy.hide()

            const wrapper = document.createElement('div')
            wrapper.innerHTML = popupContent

            swal({
                className: $(this).hasClass('change-calloff-status') ? 'change-calloff-popup' : 'remove-calloff-popup',
                content: wrapper,
                buttons: {
                    submit: {
                        text: submitBtn,
                        closeModal: false,
                    },
                    cancel: cancelBtn,
                },
            }).then( result => {
                // if confirm button was clicked:
                if (result) {
                    tableContainer.addClass('is-requesting')
                    calendarContainer.addClass('is-requesting')
                }
            })

            // logic for submit button
            $('.remove-calloff-popup .swal-button--submit, .change-calloff-popup .swal-button--submit').on('click', async function() {
                try {
                    swal.close()
    
                    await axios.post(requestUri, {}, window.axiosConfig)
    
                    swal({
                        icon: 'success',
                        className: 'change-calloff-popup',
                        title: confirmationTitle,
                        text: confirmationContent,
                        buttons: {
                            submit: {
                                text: confirmationSubmit,
                                value: true
                            },
                        },
                    }).then(() => {
                        tableContainer.addClass('is-requesting')
                        calendarContainer.addClass('is-requesting')
    
                        location.reload()
                    })
    
                } catch {
                    tableContainer.removeClass('is-requesting')
                    calendarContainer.removeClass('is-requesting')
    
                    window.showErrorPopup()
                }
            })
        })

        // Helper function for creating formData payload from post params
        function createPayload(params) {
            const keys = Object.keys(params)
            let data = new FormData()

            keys.map(key => {
                data.append(key, params[key])
            })

            return data
        }

        //Logic for toggling red row visibility
        $('.calloff-changes').each(function() {
            let editedFields = $(this).find('.edited-field')
            let hideRow = true
            const dropdownCell = $(this).prev('.changed-calloff').find('.toggle-item-changes')

            editedFields.each(function() {
                if (!$(this).hasClass('hidden')) {
                    hideRow = false
                    return false
                }
            })

            if (hideRow) {
                $(this).addClass('hidden-row')
                dropdownCell.find('.cancel-dropdown').addClass('hidden')

            } else {
                $(this).removeClass('hidden-row')
                dropdownCell.find('.cancel-dropdown').removeClass('hidden')
            }
        })
        


        //Calloff table tooltips
        $('td.status').each( function() {
            const template = $(this).find('.dropdown-menu').html()
            const tippyParent = $(this)[0]
            const tippyElement = $(this).find('.dropdown-toggler')[0]

            tippy(tippyElement, {
                allowHTML: true,
                content: template,
                theme: 'calloff-dropdown-tippy',
                trigger: 'click',
                hideOnClick: true,
                interactive: true,
                appendTo: tippyParent
            })
        })

        $('td.delivery_date .has-tippy').each( function() {
            const deliveryTimeTooltip = $(this).data('deliveryTime')

            tippy(this, {
                content: deliveryTimeTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
        })

        $('td.shipping_spot .has-tippy').each( function() {
            const shippingNameTooltip = $(this).data('shippingName')
            const shippingStreetTooltip = $(this).data('shippingStreet')
            const shippingStreetNumberTooltip = $(this).data('shippingStreetNumber')
            const shippingZipTooltip = $(this).data('shippingZip')
            const shippingCityTooltip = $(this).data('shippingCity')

            tippy(this, {
                content: shippingNameTooltip + "<br>" + shippingStreetTooltip + " " + shippingStreetNumberTooltip + "<br>" +
                shippingZipTooltip + " " + shippingCityTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
        })

        $('td.material_designation .has-tippy').each( function() {
            const materialDescriptionTooltip = $(this).data('materialDescription')
            const materialDescriptionMoreTooltip = $(this).data('materialDescriptionMore')

            tippy(this, {
                content: materialDescriptionTooltip + "<br>" + materialDescriptionMoreTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
        })

        $('td.incoterm .has-tippy').each( function() {
            const incotermTooltip = $(this).data('incotermTooltip')

            tippy(this, {
                content: incotermTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
        })

        $('td.info-box .has-tippy').each( function() {
            const template = $(this).find('.tooltip-message').html()

            tippy(this, {
                allowHTML: true,
                content: template,
                theme: 'calloff-messages-tippy'
            })
        })

        $( '.info-box' ).mouseenter(function() {
            $('.contract-search-container').addClass('z-index-hack')
        })

        $( '.info-box' ).mouseleave(function() {
            $('.contract-search-container').removeClass('z-index-hack')
        })

        const nightshiftTooltip = $('.nightshift-status').data('tooltipTranslation')
        tippy('.nightshift-status', {
            content: nightshiftTooltip,
            placement: 'bottom',
            theme: 'cockpit-tippy'
        })
        
        $('.toggle-item-changes').each(function() {
            const oldValuesTooltip = $(this).data('tooltip')
            const allHiddenTooltip = $(this).data('dropdownTooltip')

            tippy($(this).find('.error')[0], {
                content: $(this).closest('tr').next().hasClass('hidden-row') ? allHiddenTooltip : oldValuesTooltip,
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
        })
    }
})