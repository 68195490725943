var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagination-container" }, [
    _c(
      "ul",
      { staticClass: "pagination", attrs: { id: "vue-pagination" } },
      [
        _c(
          "li",
          {
            class:
              _vm.pagination.current_page == 1
                ? "page-item first disabled"
                : "page-item first"
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    return _vm.redirect(1)
                  }
                }
              },
              [_vm._v(_vm._s(_vm._f("trans")("pagination.first_page")))]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class:
              _vm.pagination.current_page == 1
                ? "page-item prev disabled"
                : "page-item prev"
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    return _vm.redirect(_vm.pagination.current_page - 1)
                  }
                }
              },
              [_vm._v(_vm._s(_vm._f("trans")("pagination.previous_page")))]
            )
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.pages, function(page) {
          return _c(
            "li",
            {
              key: page.name,
              class: page.isActive ? "active page-item" : "page-item"
            },
            [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function($event) {
                      return _vm.redirect(page.name)
                    }
                  }
                },
                [_vm._v(_vm._s(page.name))]
              )
            ]
          )
        }),
        _vm._v(" "),
        _c(
          "li",
          {
            class:
              _vm.pagination.current_page == _vm.pagination.total_pages
                ? "page-item next disabled"
                : "page-item next"
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    return _vm.redirect(_vm.pagination.current_page + 1)
                  }
                }
              },
              [_vm._v(_vm._s(_vm._f("trans")("pagination.next_page")))]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class:
              _vm.pagination.current_page == _vm.pagination.total_pages
                ? "page-item last disabled"
                : "page-item last"
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    return _vm.redirect(_vm.pagination.total_pages)
                  }
                }
              },
              [_vm._v(_vm._s(_vm._f("trans")("pagination.last_page")))]
            )
          ]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }