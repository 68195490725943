$(document).ready(() => {
    if ($('.cockpit-delivery-overview').exists()) {
        const tableContainer = $('.calendar-table-container')
        const headerFilters = $('.delivery-overview-table .header-filter')
        const searchLoader = $('#search-loader').html()
        const dropdownArrow = $('.dropdown-arrow-svg').html()
        const liveUpdateSwitch = $('.live-update-switch .toggle')
        let url = window.location.href


        // Logic for day select dropdown on daily overview
        function initDeliveryDaySelect() {
            $('.delivery-day-select').select2({
                dropdownParent: $('.cockpit-delivery-overview'),
                dropdownCssClass: 'delivery-day-select-dropdown',
                placeholder: $('.delivery-day-select').data('placeholder'),
                minimumResultsForSearch: -1

            }).on('select2:select', function() {
                const filterKey = $(this).data('filter-key')
                const filterValue = $(this).val()

                tableContainer.addClass('is-requesting')
                
                url = window.updateUrlParameter(url, 'filter[delivery_from_date]', '')
                url = window.updateUrlParameter(url, 'filter[delivery_to_date]', '')
                url = window.updateUrlParameter(url, filterKey, filterValue)

                window.location.href = url
            })
            $('.delivery-day-select-container').find('.select2-selection__arrow b').html(dropdownArrow)
        }

        // Logic for table layout select dropdown (daily/monthly/yearly)
        function initTableLayoutSelect() {
            $('#delivery-overview-table-layout').select2({
                dropdownParent: $('.active-layout-select-container'),
                containerCssClass: 'delivery-overview-table-layout-select' ,
                dropdownCssClass: 'delivery-overview-table-layout-dropdown',
                minimumResultsForSearch: -1
            }).on("select2:open", function() {
                 $(this).closest('#calendar-table-container').addClass('table-z-index-class')
            }).on("select2:close", function() {
                $(this).closest('#calendar-table-container').removeClass('table-z-index-class')
            }).on('select2:select', function(e) {
                tableContainer.addClass('is-requesting')

                let filterValue = e.params.data.id
                window.location.href = filterValue
            })

            $('.delivery-overview-table-layout-select').find('.select2-selection__arrow b').html(dropdownArrow)
        }

        initTableLayoutSelect()

        if ($('.delivery-day-select-container').exists()) {
            initDeliveryDaySelect()
        }

        // Logic for live update switch
        if (liveUpdateSwitch.exists()) {

            // set the default state of switch to local storage, only the first time
            if (localStorage.getItem('deliveryLiveSwitch') == null) {
                localStorage.setItem('deliveryLiveSwitch', 'false')
            }

            // if the switch is active immediately start the update function
            if (localStorage.getItem('deliveryLiveSwitch') == 'true') {
                liveUpdateSwitch.addClass('live-active')
                $('.change-live-status').prop('checked', true)
                toggleLiveUpdate()
            }
            
            window.overviewScreens.initFilters()
            window.overviewScreens.initTableHeaderDatepicker()
            window.initCockpitCalendar()
            initDeliveryDaySelect()
            initDeliveryTooltips()
            initTableLayoutSelect()

            // also when clicking the switch start the update function
            liveUpdateSwitch.on('click', async function() {
                liveUpdateSwitch.toggleClass('live-active')
                toggleLiveUpdate()
            })
        }


        // Logic for initializing year and quarter selects on yearly overview
        if ($('#contract-year-select').exists() || $('#contract-quarter-select').exists()) {
            $('#contract-year-select').select2({
                minimumResultsForSearch: -1,
                dropdownParent: $('.contract-year-select-container'),
                templateResult: data => `<a class="search-result-link">${data.text}</a>`,
                escapeMarkup: markup => markup

            }).on('select2:select', function(e) {
                $('.contract-overview-container').addClass('is-requesting')
                window.location.href = e.params.data.element.dataset.href
            })
            $('.contract-year-select-container').find('.select2-selection__arrow b').html(dropdownArrow)

            $('#contract-quarter-select').select2({
                minimumResultsForSearch: -1,
                dropdownParent: $('.contract-quarter-select-container'),
                templateResult: data => `<a class="search-result-link">${data.text}</a>`,
                escapeMarkup: markup => markup

            }).on('select2:select', function(e) {
                $('.contract-overview-container').addClass('is-requesting')
                window.location.href = e.params.data.element.dataset.href
            })
            $('.contract-quarter-select-container').find('.select2-selection__arrow b').html(dropdownArrow)
        }


        // TODO: Remove this and use the same logic as for all other filters when the backend is done
        // Logic for incoterm filter in pricelist table header
        if ($('#pricelist-incoterm-select').exists()){ 
            $('#pricelist-incoterm-select').select2({
                minimumResultsForSearch: -1,
                placeholder: $('#pricelist-incoterm-select').data('placeholder'),
                dropdownParent: $('.filter-heading-incoterm'),
                escapeMarkup: markup => markup
                
            }).on('select2:select', function(e) {
                const filter = e.params.data.element.dataset.value
                url = window.updateUrlParameter(url, 'filter[incoterm]', filter)
                window.location.href = url
            })
        }
        $('.filter-heading-incoterm').find('.select2-selection__arrow').html('<i class="material-icons">filter_list</i>')


        // Logic for initializing table filters on pricelist delivery screens
        if ($('.pricelist-table-container').exists()) {
            function initHeaderFilters() {
                headerFilters.each(function() {
                    const filterParent = $(this).parents('.filter-heading')
                    let { searchUrl, placeholder, name, selection} = $(this).data()

                    $(this).select2({
                        dropdownParent: filterParent,
                        minimumResultsForSearch: -1,
                        placeholder: placeholder,
                        ajax: {
                            url: () => {
                                let params = window.getUrlParams(url)
                                let keys = Object.keys(params)

                                if (keys.length){
                                    keys.forEach(key => {
                                        if (params[key] != '' && key != `filter[${name}]`) {
                                            searchUrl = window.updateUrlParameter(searchUrl, key, params[key])
                                        }
                                    })
                                }
                                return searchUrl
                            },
                            data: params => {
                                return {
                                    term: $.trim(params.value),
                                    page: params.page || 1,
                                }
                            },
                            dataType: 'json',
                            processResults: data => {
                                let keys = data.items[name] ? Object.keys(data.items[name]): []

                                return {
                                    results: Object.values(keys).map(key=> ({ 
                                        text: key,
                                        name: key,
                                        id: data.items[name][key],
                                        value: data.items[name][key]
                                    })),
                                    pagination: {
                                        more: data.pagination.has_next_page
                                    }
                                }
                            },
                        },
                        templateResult: (data, container) => templateOptions(data, container, selection),
                        templateSelection: (data, container) => templateOptions(data, container, selection),
                        escapeMarkup: markup => markup

                    }).on('select2:select', function(e) {
                        tableContainer.addClass('is-requesting')
                        let filterKey = `filter[${name}]`
                        let filterValue = e.params.data.value

                        if (selection == filterValue) {
                            url = window.updateUrlParameter(url, filterKey, '')
                        } else {
                            url = window.updateUrlParameter(url, filterKey, filterValue)
                        }
                        window.location.href = url
                    })

                    filterParent.find('.select2-selection__arrow').html('')
                })
            }

            function templateOptions(data, container, selection) {
                let markup = ''
                let { name, path, value, text } = data
                let icon = $(data.element).data('icon')

                if (data.loading) {
                    markup = searchLoader

                } else {
                    if (icon) {
                        return `${text} <i class="material-icons">${icon}</i>`
                    }

                    markup = `
                                <a class="dropdown-item table-filter" href="${path ? path: 'javascript:;'}">
                                    ${name} <span class="id-span"> ${name != value ? value : ''}</span>
                                </a>
                            `
                    if (selection == value) {
                        markup = `
                                    <a class="table-filter-clear"><i class="material-icons">close</i></a>
                                    <a class="dropdown-item table-filter" href="${path ? path: 'javascript:;'}">
                                        ${name} <span class="id-span"> ${name != value ? value : ''}</span>
                                    </a>
                                `
                        $(container).addClass('position-relative')
                    }
                }
                return markup
            }
            initHeaderFilters()
        }


        // Helper function for getting IDs of all table rows
        function getTableRowIDs() {
            let tableRowIDs = []

            $('.delivery-overview-table tbody').find('tr:not(.canceled-delivery-note)').each(function() {
                tableRowIDs.push($(this).data('calloffId'))
            })

            return tableRowIDs
        }
        let tableRowIDs = getTableRowIDs()


        // Helper function for getting percentages of all progress bars
        function getProgressBarsPercentage() {
            let progressBarArray = []

            $('.delivery-progress-bar').find('.progress').each(function() {
                progressBarArray.push($(this).data('overdelivered'))
                progressBarArray.push($(this).data('incoming'))
                progressBarArray.push($(this).data('delievered'))
                progressBarArray.push($(this).data('remaining'))
            })

            return progressBarArray
        }
        let progressBarPercentages = getProgressBarsPercentage()


        // Logic for updating the data on screen without a page reload
        function toggleLiveUpdate() {
            if (liveUpdateSwitch.hasClass('live-active')) {
                const refreshTime = $('.change-live-status').data('interval')
                localStorage.setItem('deliveryLiveSwitch', 'true')

                setInterval(async function() {
                    tableContainer.addClass('is-requesting')

                    const response = await axios.get(url)
                    let container = $(response.data).find('.calendar-table-container').html()
                    let progressTable = $(response.data).find('.progress-bars-table').html() || ''
                    let calendar = $(response.data).find('#calendar-container').html()

                    tableContainer.html(container)
                    $('.progress-bars-table').html(progressTable)
                    $('#calendar-container').html(calendar)

                    if ($('.table-header-datepicker').exists()) {
                        window.overviewScreens.initTableHeaderDatepicker()
                    }
                    window.overviewScreens.initFilters()
                    window.initCockpitCalendar()
                    initDeliveryDaySelect()
                    initDeliveryTooltips()
                    initTableLayoutSelect()

                    let newTableRowIDs = getTableRowIDs()

                    // check every new table row if it existed before the live reload
                    newTableRowIDs.forEach(item => {
                        if (!tableRowIDs.includes(item)) {

                            // rows that didnt exist before are new and need to be animated
                            let newTableRow = tableContainer.find(`tr[data-calloff-id=${item}]`)

                            newTableRow.addClass('highlight')
                            setTimeout(() => {
                                newTableRow.removeClass('highlight')
                            }, 1000 * 3)
                        }
                    })
                    tableRowIDs = newTableRowIDs

                    // get progress bar values after reload
                    let newProgressBarPercentages = getProgressBarsPercentage()

                    // animate progress bars if values are changed
                    if(JSON.stringify(newProgressBarPercentages) != JSON.stringify(progressBarPercentages)) {
                        progressBarPercentages = getProgressBarsPercentage()
                        $('.delivery-progress-bar').find('.progress-bar').addClass('progress-animation')
                    }

                    tableContainer.removeClass('is-requesting')
                }, refreshTime * 1000)

                // remove animation if progress values are not changed
                $('.delivery-progress-bar').find('.progress-bar').removeClass('progress-animation')
            } else {
                localStorage.setItem('deliveryLiveSwitch', 'false')

                tableContainer.addClass('is-requesting')
                location.reload()
            }
        }


        // Logic for initializing tippy tooltips
        function initDeliveryTooltips() {
            const itemChangeTooltip = $('td.toggle-item-changes .has-tippy').data('tooltipTranslation')

            $('td.toggle-item-changes .has-tippy').each( function() {
                tippy(this, {
                    content: itemChangeTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('td.delivery_date .has-tippy').each( function() {
                const deliveryTimeTooltip = $(this).data('deliveryTime')

                tippy(this, {
                    content: deliveryTimeTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('td.shipping_spot .has-tippy').each( function() {
                const shippingNameTooltip = $(this).data('shippingName')
                const shippingStreetTooltip = $(this).data('shippingStreet')
                const shippingStreetNumberTooltip = $(this).data('shippingStreetNumber')
                const shippingZipTooltip = $(this).data('shippingZip')
                const shippingCityTooltip = $(this).data('shippingCity')

                tippy(this, {
                    content: shippingNameTooltip + "<br>" + shippingStreetTooltip + " " + shippingStreetNumberTooltip + "<br>" +
                    shippingZipTooltip + " " + shippingCityTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('td.material_designation .has-tippy').each( function() {
                const materialDescriptionTooltip = $(this).data('materialDescription')
                const materialDescriptionMoreTooltip = $(this).data('materialDescriptionMore')

                tippy(this, {
                    content: materialDescriptionTooltip + "<br>" + materialDescriptionMoreTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('td.incoterm .has-tippy').each( function() {
                const incotermTooltip = $(this).data('incotermTitle')

                tippy(this, {
                    content: incotermTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('td.vehicle_logistics_company .has-tippy').each( function() {
                const companyNameTooltip = $(this).data('companyName')
                const companyNumberTooltip = $(this).data('companyNumber')

                tippy(this, {
                    content: companyNameTooltip + "<br>" + companyNumberTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('td.confirmed_by .has-tippy').each( function() {
                const { confirmedTimeTranslation, confirmedTime } = $(this).data()

                tippy(this, {
                    content: `${confirmedTimeTranslation} ${confirmedTime} Uhr`,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })
        }
        initDeliveryTooltips()

        function initPricelistTooltips() {
            $('td.date .has-tippy').each( function() {
                const deliveryTimeTooltip = $(this).data('deliveryTime')

                tippy(this, {
                    content: deliveryTimeTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('td.incoterm .has-tippy').each( function() {
                const incotermTooltip = $(this).data('incotermTitle')

                tippy(this, {
                    content: incotermTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })
        }
        initPricelistTooltips()
    }
})