/**
 * Blog pages concerned js
 */
$(document).ready(function() {
    if($('#blog-page').exists()) {
        const loginForm = $('#header-login-form')

        loginForm.on('submit', function() {
            event.preventDefault()

            const $form = $(this)
            const submitButton = $form.find('.submit-button')

            // Already requesting
            if (submitButton.hasClass('is-requesting')) {
                return
            }

            // Get Request URI & formData
            const requestURI = $form.attr('action')
            const payload = window.createFromData($form)

            submitLoginForm(requestURI, payload)
        })


        /**
         * Login form handler
         */
        async function submitLoginForm(requestURI, payload) {
            const submitButton = loginForm.find('.submit-button')

            submitButton.trigger('toggleState')

            try {
                const response = await axios.post(requestURI, payload, window.axiosConfig)

                if (response.status = 201) {
                    const { data } = response
                    window.location.href = data.link
                } else {
                    submitButton.trigger('toggleState')
                }
            } catch (err) {
                // Handle response on "proper way"
                const { response } = err

                // "Unprocessable Entity"
                if (response.status == 422) {
                    const { data: {
                        error
                    } } = response

                    handleLoginFormError(error)
                } else {
                    // Server error
                    window.showErrorPopup()
                    console.error(err)
                }

                submitButton.trigger('toggleState')
                throw err
            }
        }

        /**
         * Login form error handler
         * @param {*} errMessage
         */
        function handleLoginFormError(errMessage) {
            // Clear old ones
            loginForm.find('.alert-danger').remove()

            const errorMarkup = `
                <div class="alert alert-danger">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <i class="material-icons">close</i>
                    </button>
                    <span>
                        ${errMessage}
                    </span>
                </div>
            `
            const formError = $(errorMarkup)
            formError.prependTo(loginForm).hide().fadeIn(320)
        }
    }

    if ($('.pimcore_area_external-content').exists()){
        const externalContent = $('.pimcore_area_external-content')
        const editButton = externalContent.find('.pimcore_area_edit_button')
        if (editButton.exists()){
            const externalSection = externalContent.find('.area-external-content')
            externalSection.css('width', '97%')
        }
    }

    if ($('.accordion').exists()) {
        const accordionItem = $('.accordion button')
        accordionItem[0].ariaExpanded = 'true'
        accordionItem.on('click', e => {
            if(!e.detail || e.detail == 1) {
                if (e.target.closest('button').ariaExpanded == 'true') {
                    $(e.target.closest('button')).find('.toggle-arrow').removeClass('opened')
                } else {
                    $(e.target.closest('button')).find('.toggle-arrow').addClass('opened')
                }
            }
        })
    }
})
