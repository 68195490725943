<template>
  <tr class="interaction-row" v-show="isShowing">
      <td :colspan="colspan">
            <material-info :status="status" :initialItem="item" :calloffTypesUrl="calloffTypesUrl" :calloffEditUrl="calloffEditUrl"/>
            <note-controls :status="status" 
            :saveUrl="saveUrl" 
            :sendUrl="sendUrl" 
            :exportUrl="exportUrl" 
            :approveUrl="approveUrl" 
            :approveCancelationUrl="approveCancelationUrl" 
            :proposeCalloffUrl="proposeCalloffUrl"
            :item="item" 
            :calloffEdited="calloffEdited"/>
          
      </td>
  </tr>
</template>

<script>
import  materialInfo from './material-info.vue' 
import  noteControls from './note-controls.vue' 
import EventBus from './../eventBus.js';

export default {
    data() {
        return {
            isShowing: false,
          

        }
    },
    methods: {
        toggle(id) {
            if (id == this.item.id) {
                this.isShowing= !this.isShowing
            }

        },
        hide(id){
              if (id == this.item.id && this.isShowing) {
                this.isShowing= false
            }
        }
    },
    mounted() {
        EventBus.$on('toggleApprove', this.toggle)
        EventBus.$on('hideApprove', this.hide)
        if (this.item.call_off_previous_data){
            this.isShowing = true
        }
    },
    computed: {
        calloffEdited() {
            if (this.item.call_off_previous_data) {
                return true
            }
            return false
        }
    },
    
    props: {
        colspan: {
            type: Number,
            default: 1
        },
        status: {
            type: Number
        },
        item: {
            type: Object,
            default: ()=> {}
        },
        saveUrl: {
            type: String
        },
        sendUrl:{
            type: String
        },
        exportUrl:{
            type: String
        },
        approveUrl:{
            type: String
        },
        approveCancelationUrl: {
            type: String
        },
        calloffTypesUrl: {
            type: String
        },
        calloffEditUrl: {
            type: String
        },
        proposeCalloffUrl: {
            type: String
        }
    },
    components: {
        materialInfo,
        noteControls
    }

}
</script>

<style>

</style>