<template>
    <tr class="cancellation-row" v-show="isShowing">
      <td :colspan="colspan">
          <div class="cancel-container">
                <p>{{"disposition.calloffs.reason_of_rejection"|trans}}*</p>
               <div class="rejection-select-container">
                    <select2-component v-model="selectValue" :placeholder="$root.$options.filters.trans('disposition.calloffs.choose_reason')" :options="cancelData" @change="selectionUpdate">
                        <option value="" disabled selected></option>
                    </select2-component>
                </div>
                  <p>{{"disposition.calloffs.reason_of_rejection"|trans}}*</p>
                <textarea v-model="rejectionMessage" :placeholder="$root.$options.filters.trans('disposition.calloffs.comment_for_manufacturer')"></textarea>
                <p>* {{"disposition.calloffs.mandatory_field"|trans}}</p>

          </div>
          <div class="button-row">
              <button class="btn btn-primary" ref="sendDeny" :disabled="disableDeny" @click="denyCalloff">{{"calloff.form.send"|trans}}</button>
              <button class="btn btn-white" @click="clearFields">{{"company-invitation.cancel"|trans}}</button>
          </div>
          
      </td>
  </tr>
</template>

<script>

import select2Component from './select2-component.vue'
import EventBus from './../eventBus.js';
export default {
    components: {
        select2Component,
        // Select2
    },
     props: {
        colspan: {
            type: Number,
            default: 1
        },
        status: {
            type: Number
        },
        item: {
            type: Object,
            default: ()=> {}
        },
        declineUrl: {
            type: String
        },
        declineCancellationUrl: {
            type: String
        },
        rejectionReasons: {
            type: Array,
            default: ()=>[]        
        },
        cancelationRejectionReasons: {
            type: Array,
            default: ()=>[]     
        },
        allUrl: {
            type: String
        }
        
    },
    data() {
        return {
            isShowing: false,
            rejectionMessage: '',
            selectValue: '',
            
        
        }
    },
    methods: {
        toggle(id) {
            if (id == this.item.id) {
                this.isShowing= !this.isShowing
            }

        },
        hide(id){
              if (id == this.item.id && this.isShowing) {
                this.isShowing= false
            }
        },
        clearFields() {
            this.hide(this.item.id);
            this.rejectionMessage= ''

        },
        selectionUpdate(val) {
            this.selectValue = val
        },
        denyCalloff(e) {
            e.preventDefault()
            let button =this.$refs['sendDeny']
            button.classList.add('disabled')
            // let redirectUrl = this.allUrl
            let url
            let status= this.status
            if (this.status === 1) {
               
                url = this.declineUrl
            } else {
                url = this.declineCancellationUrl
            }
            url = window.updateUrlParameter(url, 'rejection_reason', this.selectValue )
            url = window.updateUrlParameter(url, 'rejection_reason_note', this.rejectionMessage)
            axios.get(url)
            .then(res=> {
                if (res.data.status == 200) {
                    let swalData
                    if (status ===1){
                        swalData = {
                            title: this.$root.$options.filters.trans('disposition.calloff.binding_cancel.heading'),
                            text:  this.$root.$options.filters.trans('disposition.calloff.binding_cancel.content'),
                           
                        }
                    } else if (status ===2){
                          swalData = {
                            title: this.$root.$options.filters.trans('disposition.calloff.denied_cancelation.heading'),
                            text:  this.$root.$options.filters.trans('disposition.calloff.denied_cancelation.content'),
                          
                        }
                    }
                   
                    swal({
                        ...swalData,
                        className: 'calloff-status-modal',
                        buttons: this.$root.$options.filters.trans("calloff.form.popup-success-button"),
                        icon: "success",
                    }).then(function(){
                        button.classList.remove('disabled')
                        location.reload()
                        //  res.redirect_url
                    })
                } else {
                    window.showErrorPopup(res.data.message)
                    button.classList.remove('disabled')

                   
                } 
            }).catch(err=> {
                window.showErrorPopup()
                throw err
            })
        }
    },
    mounted() {
        EventBus.$on('toggleCancel', this.toggle)
        EventBus.$on('hideCancel', this.hide)
       
    },
   
    computed: {
        disableDeny: function() {
            if (this.rejectionMessage ==""){
                return true
            }
            if (this.selectValue=="") {
                return true
            }
            return false
        },
        cancelData() {
            if (this.status===1) {
                return this.rejectionReasons.map(item => ({
                    ...item,
                    id: item.value,
                    text: item.name
                })
                )
            } else if (this.status===2){
                return this.cancelationRejectionReasons.map(item => ({
                    ...item,
                    id: item.value,
                    text: item.name
                })
                )
            }

            

        }
    }
}
</script>

<style>

</style>