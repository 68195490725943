var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "calloff-content form-container step-2",
      class: _vm.loading ? "is-requesting" : ""
    },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.editContent,
            expression: "editContent"
          }
        ],
        ref: "editForm",
        domProps: { innerHTML: _vm._s(_vm.editContent) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }