
/**
 * Edit calloff from calloff overview
 */
$(document).ready(() => {
    if($('#calendar-table-container').exists() && $('.toggle-status-dropdown').exists()) {
        let todayDate = flatpickr.formatDate(new Date(), 'd.m.Y')
        let currentTime = flatpickr.formatDate(new Date(), 'H:i')
        const contractOverviewContainer = $('.contract-overview-container')

        // Simple flag for select options
        // window.updateInputsFloatingLabels()

        // Will be used as storage for all dynamically created autonumeric instances
        let autoNumericInputs = []
        let editRequestUri = ''
        // flag to prevent removal of data from edit until the radios are rendered
        let emptyData = false

        // For preselecting default vechile types
        let defaultVehicleTypes = {}
        let defaultMaterialCategories = {}

        // Do it in try-catch block, since parsing can go soo wrong..
        try {
            if(window.defaultVehicleTypes) {
                defaultVehicleTypes = JSON.parse(window.defaultVehicleTypes)
            }
            if(window.defaultMaterialCategories) {
                defaultMaterialCategories = JSON.parse(window.defaultMaterialCategories)
            }
        } catch(err) {
            defaultVehicleTypes = {}
            defaultMaterialCategories = {}
        }

        // Formatted quantity inputs
        // Digit separators & decimal characters options for localization
        let localizedOptions
        if(window.shortLocale && window.shortLocale == 'de') {
            localizedOptions = {
                digitGroupSeparator: '.',
                decimalCharacter: ',',
            }
        } else {
            localizedOptions = {
                digitGroupSeparator: ',',
                decimalCharacter: '.',
            }
        }

        const quantityInputOptions = {
            ...localizedOptions,
            decimalPlaces: 0,
            unformatOnSubmit: true,
            modifyValueOnWheel: false,
            emptyInputBehavior: 'press',
        }

        const quantityFloatInputOptions = {
            ...localizedOptions,
            decimalPlaces: 0,
            unformatOnSubmit: true,
            modifyValueOnWheel: false,
            emptyInputBehavior: 'press',
            currencySymbolPlacement: AutoNumeric.options.currencySymbolPlacement.suffix,
        }

        const quantityUnitInputOptions = {
            ...quantityFloatInputOptions,
            // Add custom unit
            currencySymbol: (input) => {
                let unitSymbol = '\u202f€'
                if (input.settings.unit) {
                    const {
                        unit
                    } = input.settings

                    unitSymbol = `\u202f${unit}`
                }

                return unitSymbol
            }
        }








        const formLoader = $('#contract-overview-page #form-loader')
        const successPopup = $('#contract-overview-page #success-popup')
        /*
        const tableContainer = $('#contract-overview-page #calendar-table-container')
        tableContainer.on('click', '.edit-calloff', function() {
            contractOverviewContainer.addClass('is-requesting')

            editCallOff($(this))
        })

        async function editCallOff ($this) {
            const { requestUri } = $this.data()
            try {


                let response = await axios.get(requestUri)
                let { data } = response
                let callOff = $this.data('calloff')
                editRequestUri =  $this.data('request-uri')
                contractOverviewContainer.removeClass('is-requesting')
               displayPopup(data, callOff);
            } catch (err) {
                window.showErrorPopup()
                throw err
            }

        }
        */








        // determine delivery-mode radio value based on the incoterm and choice values from edit
        function checkPopupInputs(popup){
            let incoterm = popup.find('.incoterm-selection input:checked')
            let radioVisible
            let inputs = popup.find('.choice input').not('[data-name="daily_quantity"]')
            if (incoterm.val() == "1") {
                radioVisible = popup.find('.tonnage-selection')
            } else {
                radioVisible = popup.find('.lkw-selection')
            }
            let value
            inputs.each(function(){
                let $this = $(this)
                if ($this.is(':checkbox') && $this.is(':checked')){
                    value = $this.data('name')
                    return false
                }
                if ($this.val() && !$this.is(':checkbox')){
                    value = $this.data('name')
                }
            })
            incoterm.trigger('change')

            let radioInput = radioVisible.find(`input[value="${value}"]`)
            radioInput.prop('checked', true)
            radioInput.trigger('change')
            emptyData = true



        }


        // Handle display of the calloff popup and belonging event listeners
        function displayPopup(html, callOff){
            autoNumericInputs = []
            html = html.replace(/__name__/g, callOff['id'])
            tableContainer.append(html)
            emptyData = false
            let popupContainer = tableContainer.find('.calloff-popup-container')
            let callOffID = `#${popupContainer.attr('id')}`

            appendPopupEvents(popupContainer)
            initCustomFields(popupContainer)
            checkPopupInputs(popupContainer)



            popupContainer.removeClass('hidden')
            let popup = popupContainer.find('.calloff-popup')
            let closePopup = popup.find('.close-calloff-popup')
            closePopup.off()
            let nextButton = popup.find('.next-button')
            nextButton.off()
            let callOffContainers = popup.find('.calloff-content.form-container')
            const paginatorItems = popup.find('.paginator-page')
            const backButton = popup.find('.back-button')
            backButton.off()
            const cancelButton = popup.find('.cancel-button')
            cancelButton.off()
            closePopup.on('click', function() {
                popupContainer.remove()
            })
            cancelButton.on('click', function() {
                popupContainer.remove()
            })
            nextButton.on('click', function(e){
                e.preventDefault();

                //add handler for  submit
                if (!popup.find('.page-2').hasClass('active')) {
                    $('.paginator-page.page-2').trigger('click')
                } else {
                    //validate inputs 
                    validateCallOff(e, callOffID)
                }
            })
            backButton.on('click', function() {
                $('.paginator-page.page-1').trigger('click')
            })
            paginatorItems.on('click', function() {
                paginatorItems.removeClass('active');
                $(this).addClass('active')
                callOffContainers.addClass('hidden')
                let selection = $(this).data('target')
                popup.find(selection).removeClass('hidden')
            })

        }


        function appendPopupEvents(popup) {
            // let tonnageFlag = true
            // Toggle inputs connected via "incoterm logic"
            popup.on('change', '.incoterm-selection input', function() {
                // const $this = $(this)
                // const selectedValue = $this.val()
                // // $this.attr('checked', 'checked')
                // const callOffContainer = $this.closest('.calloff-popup')
                // const deliveryModes = callOffContainer.find('.delivery-mode')
                // deliveryModes.addClass('hidden')
                // if (selectedValue == "1"){
                //     tonnageFlag = true
                //     callOffContainer.find('.tonnage-selection').removeClass('hidden')
                //     callOffContainer.find('.tonnage-selection input:checked').trigger('change')
                // } else {
                //     tonnageFlag = false
                //     callOffContainer.find('.lkw-selection').removeClass('hidden')
                //     callOffContainer.find('.lkw-selection input:checked').trigger('change')
                // }

            })

            popup.on('change', '.delivery-mode input', function() {
                // const $this = $(this)
                // const selectedValue = $this.val()
                // const callOffContainer = $this.closest('.calloff-popup')

                //     const choiceInputs = callOffContainer.find('.choice').find('input')
                //     choiceInputs.each((index, item) => {
                //         const fieldName = $(item).data('name')
                //         if(fieldName == selectedValue) {
                //             $(item).parents('.choice').removeClass('hidden')
                //             $(item).data('validate', 'required')
                //             if ($(item).data('name') == "continuous"){
                //                 $(item).prop('checked', true).trigger('change')
                //             }
                //         } else {
                //             if (fieldName == 'daily_quantity' && tonnageFlag) {
                //                 $(item).parents('.choice').removeClass('hidden')
                //                 $(item).data('validate', 'required')
                //             } else if ( fieldName=='nr_vehicles' && selectedValue=='continuous') {
                //                 $(item).parents('.choice').removeClass('hidden')
                //                 $(item).data('validate', 'required')

                //             } else {
                //                 $(item).parents('.choice').addClass('hidden')
                //                 $(item).data('validate', '')
                //                 if (emptyData) {
                //                     // find previous value in autonumericInputs list and empty it 
                //                     let ANInputs = [].concat.apply([], autoNumericInputs)
                //                     let input = ANInputs.find((el)=> {
                //                         if ($(item).is($(el.domElement))){
                //                             return el
                //                         }
                //                     })
                //                     if (input) {
                //                         input.global.set('')
                //                     }
                //                     $(item).trigger('change')

                //                     callOffContainer.find('.continuous-input').prop('checked', false).trigger('change')
                //                 }
                //             }   
                //         }
                //     })


            })
            //fil end date to match the start date
            popup.on('change', '[data-name="delivery_date_start_date"]', function() {
                let startDateVal= $(this).val()
                let endDate = popup.find('[data-name="delivery_date_end_date"]')
                endDate.val(startDateVal).trigger('change')


            })
            popup.on('change', '[data-name="delivery_date_start_time"]', function() {
                let startDateValue = popup.find('[data-name="delivery_date_start_date"]').val()
                let endTimePicker = popup.find('[data-name="delivery_date_end_time"]')
                let endTimeInstance = endTimePicker[0]._flatpickr
                // if ( startDateValue) {
                //&&(startDateValue == endDateValue)
                let startTimeValue
                if (startDateValue == todayDate) {
                    startTimeValue = $(this).data('start-time') ? $(this).data('start-time') : currentTime
                } else {
                    startTimeValue = $(this).val()
                }
                endTimeInstance.set('minTime', startTimeValue)
                let times = startTimeValue.split(':')
                endTimeInstance.set('defaultHour', times[0])
                endTimeInstance.set('defaultMinute',  times[1])
                endTimePicker.val(null)
                // }
                //  else {
                //     endTimeInstance.set('minTime', undefined)
                //     endTimeInstance.set('defaultHour', 6)
                //     endTimeInstance.set('defaultMinute', 0)

                // }
                window.updateInputsFloatingLabels()
            } )

            popup.on('change', '.material-category-select', function() {
                const selectedValue = $(this).val()
                let materialSelect = popup.find('.material-select')
                materialSelect.empty()
                materialSelect.val()
                materialSelect.trigger('change')
                materialSelect.select2('destroy');
                let obj = defaultMaterialCategories[selectedValue]['material_numbers']
                let keys = Object.keys(obj)
                let data = keys.map((key)=>{
                    return { 'id':key, 'text': obj[key] }
                })


                let selectOptions = {
                    containerCssClass: 'js-select-simple',
                    dropdownCssClass: 'js-select-simple',
                    placeholder: materialSelect.data('placeholder'),
                    dropdownParent: materialSelect.closest('.form-group'),
                    minimumResultsForSearch: -1,
                    data: data,
                    escapeMarkup: (markup) =>{
                        if ( markup.search(/(\\\")/g) > 0){
                            markup = markup.replace(/\\\"/g, "\"")
                        }
                        return markup
                    },
                    minimumResultsForSearch: -1,
                }
                materialSelect.select2(selectOptions)

            })
            // Handle preselecting of default vechile types
            popup.on('change', '.material-select', function() {
                const selectedValue = $(this).val()
                const callOff = $(this).closest('.calloff-popup')

                const vechileTypeInput = callOff.find('.vehicle-type-select')

                const connectedValue = defaultVehicleTypes[selectedValue]

                // Preselect
                if(connectedValue && vechileTypeInput.exists()) {
                    vechileTypeInput.val(connectedValue).trigger('change')
                }

            })
        }

        // Init of calloffs customized inputs
        function initCustomFields (newCalloff) {
            const callOffID = `#${newCalloff.attr('id')}`
            // Select inputs init 
            let selectInputs = newCalloff.find('.js-select')
            selectInputs.each((index, input) => {
                const $input = $(input)

                let selectOptions = {
                    //allowClear: true,
                    containerCssClass: 'js-select-simple',
                    dropdownCssClass: 'js-select-simple',
                    placeholder: $input.data('placeholder'),
                    dropdownParent: $input.closest('.form-group'),
                    minimumResultsForSearch: -1,
                    escapeMarkup: (markup) =>{
                        if ( markup.search(/(\\\")/g) > 0){
                            markup = markup.replace(/\\\"/g, "\"")
                        }
                        return markup
                    },
                }

                $input.select2(selectOptions)

            })

            // Date picker init
            const datePickerSelector = `${callOffID} .flatpickr-date-input`
            const datePickerOptions = $(datePickerSelector).data()
            let datePicker = flatpickr(datePickerSelector, {
                dateFormat: 'd.m.Y',
                locale: datePickerOptions.locale,
                minDate: "today",
                maxDate: datePickerOptions.endDate,
            })

            // Time picker init
            const timePickerSelector = `${callOffID} .flatpickr-time-input`
            const timePickerOptions = $(timePickerSelector).data()
            let timePicker = flatpickr(timePickerSelector, {
                time_24hr: true,
                enableTime: true,
                noCalendar: true,
                defaultHour: 6,
                locale: timePickerOptions.locale,
                onValueUpdate: () => {
                    $(timePickerSelector).closest('.form-group').removeClass('is-empty')
                }
            })

            // Without units
            const quantityInputSelector = `${callOffID} .formatted-quantity-input`
            const quantityInput = new AutoNumeric.multiple(quantityInputSelector, quantityInputOptions)

            // With units
            const quantityUnitInputSelector = `${callOffID} .formatted-quantity-unit-input`
            const quantityUnitInput = new AutoNumeric.multiple(quantityUnitInputSelector, quantityUnitInputOptions)

            autoNumericInputs.push(quantityInput)
            autoNumericInputs.push(quantityUnitInput)
        }


        /**
         * Popup form validation
         */
        function validateCallOff(event, callOffID) {
            event.preventDefault()
            const $form = $(callOffID).closest('form')
            const validation = window.validateFormFields($form)



            if (validation && validation.isValid) {

                const formData = new FormData()
                const formFields = $form.serializeArray()
                // Create payload
                formFields.forEach(field => {
                    let value = field.value
                    const fieldName = field.name

                    // Fetch unformatted values
                    const input = $form.find(`[name="${field.name}"]`)
                    let ANInputs = [].concat.apply([], autoNumericInputs)
                    ANInputs.forEach(autonumericInput => {
                        const { domElement } = autonumericInput
                        // Find input which needs to be unformatted
                        if (input.is(domElement)) {
                            value = autonumericInput.rawValue
                        }
                    })

                    // Create payload
                    formData.append(fieldName, value)
                })

                submitCallOffsForm(editRequestUri, formData, callOffID)



            }
        }

        // Close success popup
        successPopup.on('click', '.popup-close', function() {
            const popup = $(this).closest('#success-popup')
            const container = $('#contract-overview-page .calendar-container')

            container.removeClass('is-Loading')
            popup.addClass('animated fadeOut faster')
            setTimeout(() => {
                popup.addClass('hidden')
                location.reload()
            }, 500)
        })

        /**
         * Form submit handler
         * @param {*} requestURI
         * @param {*} payload
         */
        async function submitCallOffsForm(requestURI, payload, callOffID) {
            try {
                $(callOffID).remove()
                window.showLoaderPopup()
                // formSubmitBtn.trigger('toggleState')


                const response = await axios.post(requestURI, payload, window.axiosConfig)

                // Close loader
                window.closeLoaderPopup()

                // Show success popup
                const container = $('#contract-overview-page .calendar-container')
                container.addClass('is-Loading')
                successPopup.addClass('animated fadeIn faster').removeClass('hidden')

                // formSubmitBtn.trigger('toggleState')
            } catch(err) {
                window.closeLoaderPopup()
                window.showErrorPopup()
                // formSubmitBtn.trigger('toggleState')
                throw err
            }
        }

    }
})
