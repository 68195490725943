$(document).ready(function() {
    // Checking validation of form popups
    if($('.login-page').length > 0) {
        // Just validate onLoad..
        validateRegister()
        validatePasswordForgot()
        validateRequestInvite()

        let popupLogin = $('.login-page__popup')

        /**
         * Forgott password logic
         */
        popupLogin.on('keyup change', '.login-password-forgot [name="password-forgot"]', () => {
            validatePasswordForgot()
        })

        /**
         * Register popup/form validation trigger
         */
        popupLogin.on('keyup change', '.register-form [data-validate]:not([data-validate=""])', () => {
            validateRegister()
        })

        /**
         * Invite request popup/form validation trigger
         */
        popupLogin.on('keyup change', '.login-request [data-validate]:not([data-validate=""])', () => {
            validateRequestInvite()
        })
    }
})


const validatePasswordForgot = () => {
    const submitButton = $("#password-forgot__submit")
    const $form = submitButton.closest('form')

    const validation = window.validateFormFields($form)

    // SubmitButton logic
    if(validation && validation.isValid) {
        submitButton.prop('disabled', false)
    } else {
        submitButton.prop('disabled', true)
    }
}

const validateRegister = () => {
    const submitButton = $("#register-form_register-submit")
    const $form = submitButton.closest('form')

    if(!submitButton.exists()) {
        return
    }

    const validation = window.validateFormFields($form)

    // SubmitButton logic
    if(validation && validation.isValid) {
        submitButton.prop('disabled', false)
    } else {
        submitButton.prop('disabled', true)
    }

    // Show error messages
    const inputsWithMessage = [
        'register-form[customerPassword]',
        'register-form[customerPasswordRepeat]'
    ]

    window.handleValidationErrorMessages(inputsWithMessage, validation.errors, $form)
}

const validateRequestInvite = () => {
    const submitButton = $("#request_invite__submit")
    const $form = submitButton.closest('form')

    if(!submitButton.exists()) {
        return
    }

    const validation = window.validateFormFields($form)

    // SubmitButton logic
    if(validation && validation.isValid) {
        submitButton.prop('disabled', false)
    } else {
        submitButton.prop('disabled', true)
    }

    // Show error messages
    const inputsWithMessage = [
        'request_invite[SapCompanyNr]'
    ]

    window.handleValidationErrorMessages(inputsWithMessage, validation.errors, $form)
}
