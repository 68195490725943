var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "note-controls" }, [
    _c("div", { staticClass: "button-row top-button-row" }, [
      _c(
        "button",
        {
          ref: "saveSubmit",
          staticClass: "btn btn-primary",
          attrs: { disabled: _vm.disableSave },
          on: { click: _vm.submitSave }
        },
        [_vm._v(_vm._s(_vm._f("trans")("disposition.calloffs.save_draft")))]
      ),
      _vm._v(" "),
      _vm.status == 1
        ? _c(
            "button",
            {
              ref: "dispoSubmit",
              staticClass: "btn btn-primary",
              on: { click: _vm.dispoExport }
            },
            [
              _vm._v(
                _vm._s(
                  _vm._f("trans")("disposition.calloffs.export_dispo_software")
                )
              )
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "notes-container" }, [
      _c("div", { staticClass: "note-container" }, [
        _c("p", { staticClass: "heading" }, [
          _vm._v(
            _vm._s(_vm._f("trans")("disposition.call-off.customer_note_title"))
          )
        ]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.customerNote,
              expression: "customerNote"
            }
          ],
          attrs: { name: "customer_note" },
          domProps: { value: _vm.customerNote },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.customerNote = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "note-container" }, [
        _c("p", { staticClass: "heading" }, [
          _vm._v(
            _vm._s(_vm._f("trans")("disposition.call-off.internal_note_title"))
          )
        ]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.internalNote,
              expression: "internalNote"
            }
          ],
          attrs: { name: "internal_note" },
          domProps: { value: _vm.internalNote },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.internalNote = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "button-row" }, [
      this.item.is_reminder_sent_today && this.status === 0
        ? _c("span", { staticClass: "reminder-message-sent" }, [
            _vm._v(
              _vm._s(
                _vm._f("trans")(
                  "disposition.call-off.reminder_message_already_sent_text"
                )
              )
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          ref: "sendSubmit",
          staticClass: "btn btn-primary",
          attrs: { disabled: _vm.disableSubmit },
          on: { click: _vm.submitSend }
        },
        [_vm._v(_vm._s(_vm.submitButton))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }