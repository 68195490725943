$(document).ready(() => {
    if (!$('#quality-document').exists()) {
        // Check if user is active on page load
        let checkboxes = $('#company-users-table .change-users-status')
        checkboxes.map((i, el) => {
            const tr = $(el).parents('tr')
            if($(el).is(':checked')) {
                tr.removeClass('disabled-user')
            } else {
                tr.addClass('disabled-user')
            }
        })
    
        // Check if company is active on page load
        let checkboxCompany = $('.user-activation')
        checkboxCompany.map((i, el) => {
            const labelActive = $(el).parent().siblings('.user-active')
            const labelInactive = $(el).parent().siblings('.user-inactive')
            if($(el).is(':checked')) {
                labelActive.addClass('is-active')
                labelInactive.removeClass('is-active')
            } else {
                labelInactive.addClass('is-active')
                labelActive.removeClass('is-active')
            }
        })

        // Active-Inactive company
        $('.user-activation').click(function() {
            // Don't call it on dashboard list view
            if ($('#user-management-companies').exists()) {
                return
            }

            $(this).parent().siblings('.user-active').toggleClass('is-active')
            $(this).parent().siblings('.user-inactive').toggleClass('is-active')
        })

        // Active-Inactive user
        $('#company-users-table .change-users-status').click(function() {
            if($(this).is(':checked')) {
                $(this).closest('tr').removeClass('disabled-user')
            } else {
                $(this).closest('tr').addClass('disabled-user')
            }
        })

        // Checked-Unchecked user
        $('.remove-user-checkbox').click(function() {
            $(this).closest('tr').toggleClass('row-active')
        })

        //Open delete user popup for confirmation
        const deletePopup = $('.delete-warning-popup')
        const userPopupText = $('.delete-user-text')
        const companyPopupText = $('.delete-company-text')
        const userDeleteButton = $('.delete-button--users')
        const companyDeleteButton = $('.delete-button--company')

    

        function deleteUser() {
            let selectedIDS = []
            let numberOfUsers = 0
        
            deletePopup.removeClass('hidden fadeOut').addClass("animated fadeIn faster")
            companyPopupText.addClass('hidden')
            companyDeleteButton.addClass('hidden')
            userPopupText.removeClass('hidden')
            userDeleteButton.removeClass('hidden')
            const userRow = $(this).closest('tr')
            let companyName = $(this).parents('.dashboard-company-table').siblings('.company-upper-card').find('.company-info__company-name').text()
            if(companyName == "") {
                companyName = $('#company-users-table').find('.company-info__company-name').text()
            }
            // following logic handles delete-company-user inside bagUserList (highway) and customerUsersTable (cockpit)
            // added fix for company/full on highway
            let checkedUsers
            if ($('.table-user-row').exists()) {
                checkedUsers = $('.table-user-row').find('.inline-checkbox .checkbox input').filter((index, element)=> $(element).is(':checked'))
            } else {
                checkedUsers = $('.company-user ').find('.inline-checkbox .checkbox input').filter((index, element)=> $(element).is(':checked'))

            }

            let translationToReplace =  $('.delete-user-translation-text').html()
            let regEx = new RegExp('[0-9]+')
            
            // if a user or more is selected remove them 
            if (checkedUsers.length > 0) {
                checkedUsers.each(function(){
                    $(this).closest('tr').addClass('delete-row')
                    const rowId =  $(this).data('users-id')
                    selectedIDS.push(rowId)
                })
                numberOfUsers = checkedUsers.length
                selectedIDS.forEach(function(el, i) { 
                    formDataUser.set(`users[${i}]`, el)
                })
                //userPopupText.find('.users').text(numberOfUsers)

                if (!regEx.test(translationToReplace)) {
                    $('.delete-user-translation-text').html(translationToReplace.replace('%user_nr%', numberOfUsers))
                } else {
                    $('.delete-user-translation-text').html(translationToReplace.replace(regEx, numberOfUsers))
                }
            } else {
            // if no user is selected remove the user whos' table row was clicked 
                formDataUser.set('users[0]', $(this).data('users-id'))
                $(this).closest('tr').addClass('delete-row')
                if (!regEx.test(translationToReplace)) {
                    $('.delete-user-translation-text').html(translationToReplace.replace('%user_nr%', '1'))
                } else {
                    $('.delete-user-translation-text').html(translationToReplace.replace(regEx, '1'))
                }
            }

            userPopupText.find('.company').text(companyName)

            $('.delete-button--users').attr('data-url', $(this).data('url'))
            $('.delete-button--users').attr('data-company_id', $(this).data('users-id'))
        
        }

        let formDataUser = new FormData

        if ($('#bag-users-page').exists() || $('#customer-users-page').exists() || $('#company-users-table').exists()) {
            $('.delete-company-user').click(deleteUser)
        }

        // Delete user from company
        function handleAjaxDeleteUser() {
            let url = $(this).data('url')
            axios({
                method: 'post',
                config: { headers: {'Content-Type': 'multipart/form-data' }},
                data: formDataUser,
                url: url,
            })
            .then(function (response) {
                //handle success
            
                $('.dashboard-companies').find('.delete-row').remove()
                $('#customer-users-page').find('.delete-row').remove()
                $('#company-users-table').find('.delete-row').remove()
            })
            .catch(function (error) {
                //handle error
                window.showErrorPopup()
                console.log(error)
            })
        }

        userDeleteButton.on('click', handleAjaxDeleteUser)

        //Show delete warning popup for company delete
        $('.button-company-delete').on('click', function() {

            // Don't call it on dashboard list view
            if ($('#user-management-companies').exists()) {
                return
            }

            deletePopup.removeClass('hidden fadeOut').addClass("animated fadeIn faster")
            companyPopupText.removeClass('hidden')
            companyDeleteButton.removeClass('hidden')
            userPopupText.addClass('hidden')
            userDeleteButton.addClass('hidden')

            let companyName = $(this).parents('.company-upper-card').find('.company-info__company-name').text()
            companyPopupText.find('.company').replaceWith('<b class="company">' + companyName + '</b>')

            $(this).parents('.card-dashboard__company').addClass('delete-company')

            $('.delete-button--company').data('url', $(this).data('url'))
            $('.delete-button--company').data('company_id', $(this).data('company_id'))
        })

        // Delete company from page
        function handleAjaxDeleteCompany() {

            // Don't call it on dashboard list view
            if ($('#user-management-companies').exists()) {
                return
            }

            let url = $(this).data('url')
            const companyFull = $('body .dashboard-companies')
            const lang = companyFull.data('locale')
            const companiesPage = `/dashboard/user-management-companies`

            const formDataCompany = new FormData

            formDataCompany.set('company_id', $(this).data('company_id'))

            axios({
                method: 'post',
                config: { headers: {'Content-Type': 'multipart/form-data' }},
                data: formDataCompany,
                url: url,
            })
            .then(function (response) {
                //handle success
                // console.log('response: ', response)
                if ($('#company-users-table')) {
                    window.location = companiesPage
                } else {
                    $('.delete-company').remove()
                }
            })
            .catch(function (error) {
                //handle error
                console.log(error)
            })
        }

        companyDeleteButton.on('click', handleAjaxDeleteCompany)

        // Change company status
        function handleAjaxChangeCompanyStatus() {

            // Don't call it on dashboard list view
            if ($('#user-management-companies').exists()) {
                return
            }

            let status_id = $(this).data('status')
            let url = $(this).data('url')
            let formData = new FormData

            formData.set('status', status_id)

            axios({
                method: 'post',
                data: formData,
                url: url,
            })
            .then(function (response) {
                //handle success
                if ($(this).data('status') == 'active') {
                    $(this).data({status: 'inactive'})
                } else {
                    $(this).data({status: 'active'})
                }
            })
            .catch(function (error) {
                //handle error
                console.error(error)
                window.showErrorPopup()
            })
        }

        $('.user-activation').on('click', handleAjaxChangeCompanyStatus)

        // Change users status
        function handleAjaxChangeUserStatus() {
            let status_id = $(this).data('status_id')
            let url = $(this).data('url')
            let formData = new FormData

            formData.set('status', status_id)

            axios({
                method: 'post',
                data: formData,
                config: { headers: {'Content-Type': 'multipart/form-data' }},
                url: url,
            })
            .then(function (response) {
                //handle success
                if ($(this).data('status_id') == 1) {
                    $(this).data({status_id: 0})
                } else {
                    $(this).data({status_id: 1})
                }
                if (response.status == 200){
                    $(this).closest('.togglebutton').find('.success-icon').animate({ opacity: 1 }, 400, function() {
                        $(this).animate({opacity: 0}, 2000)  
                    })
                }        
            })
            .catch(function (error) {
                //handle error
                window.showErrorPopup()
                console.error(error)
            })
        }

        $('#company-users-table').on('click', '.change-users-status', handleAjaxChangeUserStatus)

        //handle opening/closing of popups with togglers

        const openContractsButton = $('.contract-popup-open')
        const closeContractsButton = $('.change-contract-status-confirm')
        const contractsPopup = $('.change-contract-status-popup')
        openContractsButton.on('click', () => {
            contractsPopup.removeClass('hidden fadeOut').addClass('animated fadeIn faster')
        })
        closeContractsButton.on('click', () => {
            contractsPopup.removeClass('animated fadeIn faster').addClass('hidden fadeOut')
        })
        contractsPopup.on('click', (e) => {
            if (!contractsPopup.find('*').is(e.target)) {
                contractsPopup.removeClass('animated fadeIn faster').addClass('hidden fadeOut')
            }
        })

        const openPricelistsButton = $('.pricelist-popup-open')
        const closePricelistsButton = $('.change-pricelist-status-confirm')
        const pricelistsPopup = $('.change-pricelist-status-popup')
        openPricelistsButton.on('click', () => {
            pricelistsPopup.removeClass('hidden fadeOut').addClass('animated fadeIn faster')
        })
        closePricelistsButton.on('click', () => {
            pricelistsPopup.removeClass('animated fadeIn faster').addClass('hidden fadeOut')
        })
        pricelistsPopup.on('click', (e) => {
            if (!pricelistsPopup.find('*').is(e.target)) {
                pricelistsPopup.removeClass('animated fadeIn faster').addClass('hidden fadeOut')
            }
        })
    }
})