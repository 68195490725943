$(document).ready(function() {
    if($('#reimport-delivery-notes').exists()) {
        const datepicker = $('.delivery-reimport-date-input')

        let url = window.location.href


        // Initialize datepicker
        initDatepicker()


        // Initialize filters that dont use ajax
        $('.reimport-select').each(function() {
            $(this).select2({
                minimumResultsForSearch: -1,
                dropdownCssClass: 'reimport-delivery-select-dropdown'
            })
        })


        // Initialize filters that use ajax
        $('.reimport-select-ajax').each(function() {
            initAjaxFilter($(this))
        })


        // Logic for select filters outside of table
        function initAjaxFilter(filter) {
            let { filterName, placeholder, noResults } = filter.data()

            filter.select2({
                minimumResultsForSearch: 1,
                dropdownCssClass: 'reimport-delivery-select-dropdown',
                placeholder,
                ajax: {
                    // updates request url with all the current url params except for the current pagination params
                    url: () => {
                        // defined here in order to start with a clean url every time
                        let { filterUrl } = filter.data()
                        let params = window.getUrlParams(url)
                        let keys = Object.keys(params)
                        if (keys.length) {
                            keys.forEach(key => {
                                if (params[key] !== '' && key !== filterName && key != 'page_size' && key != 'page'){
                                    filterUrl = window.updateUrlParameter(filterUrl, key, params[key])
                                }
                            })
                        }
                        return filterUrl
                    },
                    dataType: 'json',
                    processResults: data => {
                        // structure the response json to match select2 needs for filling dropdown
                        let keys = data.data.items ? Object.keys(data.data.items): []
                        let options = {
                            results: keys.map(key => ({
                                    text: data.data.items[key],
                                    id: key,
                                    value: key
                            })),
                            pagination: {
                                more: data.data.pagination.has_next_page
                            }
                        }

                        return options
                    }
                },
                language: {
                    noResults: () => noResults,
                    searching: () => window.monitoringSearchLoader,
                    loadingMore: () => window.monitoringSearchLoader
                },
                templateSelection: data => styleSelection(data, filter.val(), filterName),
                escapeMarkup: markup => markup

            }).on('select2:select', option => {
                // update the url
                url = window.updateUrlParameter(url, filterName, filter.val())

                // update the table field
                const optionText = option.params.data.text
                const optionTextSplit = optionText.split(' - ')

                if (optionTextSplit.length > 1) {
                    $(`.${filterName}-text`).text(optionTextSplit[1])
                } else {
                    $(`.${filterName}-text`).text('—')
                }

                // disable the filter opening for the '.active-filter' remove logic
                if (filter.val()) filter.prop('disabled', true)

                // validate input fields
                reimportValidate()
            })
        }


        // Helper function for styling the selected option in ajax selects
        function styleSelection(data, filterValue, filterName) {
            let markup = ''
            let { text } = data

            const textFormatted = text.split(' - ')[0]

            if (filterValue != '') {
                markup = `<div class="active-filter" data-filter-name="${filterName}">
                            <span class="active-filter-text">${textFormatted}</span>
                            <i class="material-icons">clear</i>
                        </div>`
            } else {
                markup = `${text}`
            }

            return markup
        }


        // Logic for removing a selected filter
        $('#reimport-delivery-notes').on('click', '.active-filter', function() {
            const filter = $(this).closest('td').find('.reimport-select-ajax')
            const filterName = filter.data('filterName')

            // remove the active filter container and enable the select
            $(this).remove()
            filter.prop('disabled', false)

            // update the url
            url = window.updateUrlParameter(url, $(this).data('filterName'), '')

            // empty the filter value
            filter.val('').trigger('change')

            // empty the connected table field
            $(`.${filterName}-text`).text('—')

            // extra logic for removing debitor filter; it also removes kontrakt filter
            const contractFilter = $('select[data-filter-name="contract"]')
            if (filterName == 'customer_company' && contractFilter.exists()) {

                contractFilter.closest('td').find('.active-filter').remove()
                contractFilter.prop('disabled', false)

                url = window.updateUrlParameter(url, 'contract', '')

                contractFilter.val('').trigger('change')
                $('.contract-text').text('—')
            }

            // validate input fields
            reimportValidate()
        })


        // Logic for initializing datepicker
        function initDatepicker() {
            const { locale, submitTranslation } = datepicker.data()

            let options = {
                dateFormat: 'd.m.y',
                locale: locale,
                maxDate: 'today',
                mode: 'range',
                closeOnSelect: false,
                position: "auto center",
                disableMobile: true,
                onReady: (selectedDates, dateString, instance) => {
                    let { calendarContainer } = instance
                    $(calendarContainer).append(`<button class="date-submit delivery-reimport-date-submit">
                                                    ${submitTranslation}
                                                </button>`)
                },
                onChange: date => {
                    $('.flatpickr-input').removeClass('is-focused')

                    if (date.length) {
                        const startDateFormatted = flatpickr.formatDate(date[0], 'd.m.Y')
                        const endDateFormatted = flatpickr.formatDate(date[date.length - 1], 'd.m.Y')

                        if (startDateFormatted != endDateFormatted ) {
                            $('.active-date-container').html(`
                                <span class="clear-tag">
                                    ${startDateFormatted} - ${endDateFormatted}
                                    <i class="material-icons">close</i>
                                </span>
                            `).removeClass('hidden')

                        } else {
                            $('.active-date-container').html(`
                                <span class="clear-tag">
                                    ${startDateFormatted}
                                    <i class="material-icons">close</i>
                                </span>
                            `).removeClass('hidden')
                        }
                    }
                },
                onClose: () => {
                    setTimeout(() => {
                        if (!datepicker.val()) {
                            $('.active-date-container').html('').addClass('hidden')
                        }

                        // validate input fields
                        reimportValidate()
                    })
                }
            }

            flatpickr(datepicker, options)
            datepicker[0]._flatpickr.calendarContainer.classList.add('delivery-reimport-date')

            $('.date-submit').on('click', () => {
                datepicker[0]._flatpickr.close()
            })

            $('.active-date-container').on('click', () => {
                // clear the datepicker and open it
                datepicker[0]._flatpickr.clear()
                datepicker[0]._flatpickr.open()

                // hide the selection container
                $('.active-date-container').html('').addClass('hidden')

                // validate input fields
                reimportValidate()
            })
        }


        // Logic for submit buttons
        $('.reimport-button').on('click', async function() {
            let url = $(this).data('route')

            // get reimport type
            url = window.updateUrlParameter(url, 'reimport_type', $('#reimport-delivery-notes').data('type'))

            // get all values from selects
            $('.reimport-select, .reimport-select-ajax').each(function() {
                url = window.updateUrlParameter(url, $(this).data('filterName'), $(this).val())
            })

            // get date
            if (datepicker.val()) {
                const selectedDate = datepicker[0]._flatpickr.selectedDates

                const dateStart = flatpickr.formatDate(selectedDate[0], 'd-m-Y')
                const dateEnd = flatpickr.formatDate(selectedDate[selectedDate.length - 1], 'd-m-Y')

                url = window.updateUrlParameter(url, 'from_date', dateStart)
                url = window.updateUrlParameter(url, 'to_date', dateEnd)
            }

            // get the number of files
            const numberOfDIfiles = $('.number-of-files-di').text().trim() == '—' ? '' : $('.number-of-files-di').text().trim()
            const numberOfVASfiles = $('.number-of-files-vas').text().trim() == '—' ? '' : $('.number-of-files-vas').text().trim()

            url = window.updateUrlParameter(url, 'delivery_note_di_nr', numberOfDIfiles)
            url = window.updateUrlParameter(url, 'delivery_note_vas_nr', numberOfVASfiles)

            try {
                // hide text and show the loader
                $(this).find('.button-text').addClass('hidden')
                $(this).find('.loader-container').removeClass('hidden')
                $(this).addClass('is-requesting')

                const response = await axios.get(url)
                const { data } = response

                // separate logic for 2 different buttons
                if ($(this).hasClass('check-number-of-files')) {
                    $('.number-of-files-di').text(data.data['DeliveryNoteDI'] || data.data['DeliveryNoteDI'] == 0 ? data.data['DeliveryNoteDI'] : '—')
                    $('.number-of-files-vas').text(data.data['DeliveryNoteVAS'] || data.data['DeliveryNoteVAS'] == 0 ? data.data['DeliveryNoteVAS'] : '—')
                }

                if ($(this).hasClass('start-import')) {
                    $('.reimport-actions-container, .reimport-content').addClass('already-running')
                }

                // show text and hide the loader
                $(this).find('.button-text').removeClass('hidden')
                $(this).find('.loader-container').addClass('hidden')
                $(this).removeClass('is-requesting')

            } catch(err) {
                $(this).find('.button-text').removeClass('hidden')
                $(this).find('.loader-container').addClass('hidden')
                $(this).removeClass('is-requesting')

                window.showErrorPopup()
                throw err
            }
        })


        // Logic for validating input fields
        function reimportValidate() {
            let valid = true

            // check the max range if defined
            if (datepicker.data('maxRange')) {
                const maxRange = datepicker.data('maxRange')

                const startDate = moment(datepicker[0]._flatpickr.selectedDates[0])
                const endDate = moment(datepicker[0]._flatpickr.selectedDates.slice(-1)[0])

                const selectedRange = endDate.diff(startDate, 'days')

                if (selectedRange > maxRange) {
                    $('.date-range-error').removeClass('hidden')
                    valid = false
                } else {
                    $('.date-range-error').addClass('hidden')
                    valid = true
                }
            }

            // validate date
            if (datepicker.exists()) {
                if (datepicker.data('required') && !datepicker.val()) {
                    valid = false
                }
            }

            // validate selects
            if ($('.reimport-select-ajax').exists()) {
                $('.reimport-select-ajax').each(function() {
                    if (!$(this).val()) {
                        valid = false
                    }
                })
            }

            if (valid) {
                $('.reimport-button').addClass('active')
            } else {
                $('.reimport-button').removeClass('active')
            }

            return valid
        }
    }
})