import { initDatepicker } from 'Flatpickr/datepicker'
import { initSearch } from 'Select2/search'

$(document).ready(function() {

    if($('.missing-pdfs-container').exists()) {
        const tableContainer = $('.monitoring-content-container')
        const datePickerContainer = $('.datepicker-container')
        const datePicker = $('.flatpickr-date-input')
        const searchContainer = $('.missing-pdf-search-container')
        const search = $('.missing-pdf-search')
        const itemLoadingIcon = $('#search-loader').html()
        const maintenanceButton = $('#activate-maintenance-btn')

        let url = window.location.href
        let time = 0
        const timerUnitSingle = $('.missing-pdfs-container').data('timerUnitSingle')
        const timerUnitPlural = $('.missing-pdfs-container').data('timerUnitPlural')


        // Live-update table content without reloading
        async function updateTableContent() {
            const response = await axios.get(url)
            let container = $(response.data).find('.monitoring-container-data').html()
            tableContainer.find('.monitoring-container-data').html(container)
        }


        // Logic for live-updating page content every 5 minutes
        setInterval(async function() {
            updateTableContent()
            time = 0
            $('.stopwatch-value').text(`${time} ${timerUnitSingle}`)
        }, 1000 * 60 * 5)


        // Logic for showing how many minutes ago the page content updated
        setInterval(() => {
            time += 1
            $('.stopwatch-value').text(`${time} ${time == 1 ? timerUnitSingle : timerUnitPlural}`)
        }, 1000 * 60)


        // Initialize datepicker
        if (datePickerContainer.exists()) {
            initDatepicker(
                datePicker,
                datePickerContainer,
                { 'startParamName': 'date_start',
                    'endParamName': 'date_end' },
                tableContainer
            )
        }


        // Initialize search
        if (searchContainer.exists()) {
            initSearch(search, searchContainer, tableContainer, itemLoadingIcon)

            let activeFilterData = JSON.parse(window.monitoringPDFActiveFilters)
            if (activeFilterData != '') {

                // if there is an active filter show it inside the search bar and disable search
                const searchInput = searchContainer.find('.select2-search__field')
                let markup = `<div class="search-query">
                                <span>${Object.values(activeFilterData)[0]}</span>
                                <i class="material-icons">close</i>
                            </div>`
                searchInput.after(markup)
                searchInput.addClass('hidden')

                // logic for removing active filter
                let activeFilter = searchContainer.find('.search-query')
                activeFilter.on('click', function() {
                    $(this).remove()
                    tableContainer.addClass('is-requesting')
                    url = window.updateUrlParameter(url, Object.keys(activeFilterData)[0], '')
                    url = window.updateUrlParameter(url, 'term', '')
                    window.location.href = url
                })

                // prevent opening while there is an active filter
                search.on('select2:opening', () => {
                    return false
                })
            }
        }


        // Logic for toggling maintenance
        if (maintenanceButton.exists()) {
            maintenanceButton.on('click', async function() {
                const path = $(this).data('path')
                try {
                    await axios.get(path)
                    tableContainer.addClass('is-requesting')
                    location.reload()
                } catch {
                    window.showErrorPopup()
                }
            })
        }


        // Logic for expanding status-message row
        // Used event delegation because the content of tableContainer changes without reload and the event breaks
        if ($('.row-expand').exists()) {
            tableContainer.on('click', '.row-expand', function() {
                $(this).find('.row-expand-icon').toggleClass('expanded')
                $(this).next('.row-status-message').toggleClass('hidden')
            })
        }


        // Logic for generate-button
        if ($('.monitoring-button-generate').exists()) {
            tableContainer.on('click', '.monitoring-button-generate', async function() {
                try {
                    $('.generating-in-progress').removeClass('hidden')
                    $(this).addClass('disabled')

                    const pathToGenerate = $(this).data('path')
                    const pathCheckActivity = $(this).data('checkActivity')

                    await axios.get(pathToGenerate)

                    // check every 1s if the generation is still running, until done
                    const interval = setInterval(async function() {
                        const activityResponse = await axios.get(pathCheckActivity)
                        const isActive = activityResponse.data.is_running

                        if (!isActive) {
                            $('.generating-in-progress').addClass('hidden')
                            $('.monitoring-button-generate').removeClass('disabled')

                            // update the whole table content, mainly for the time of last generating and link to download
                            updateTableContent()

                            $('.monitoring-button-download').removeClass('disabled')

                            clearInterval(interval)
                        }
                    }, 1000)
                } catch {
                    $('.generating-in-progress').addClass('hidden')
                    $('.monitoring-button-generate').removeClass('disabled')
                    window.showErrorPopup()
                }
            })
        }
    }
})