<template>
     <div class="material-info">
            
            <table class="bordered-info-table">
                <tbody>
                    <tr><td colspan="3" class="heading">{{'disposition.calloff_details.materialinfo'|trans}}</td></tr>
                    <tr class="info-table">
                        
                        <td>{{'disposition.calloff_details.sort'|trans}}:</td>
                        <td class="last-edit"> <span v-if="materialCategoryEdit" >{{materialCategoryEdit}}</span></td>

                        <td>{{item.material && item.material.category ? item.material.category : '—' }}</td>
                    </tr>
                    <tr class="info-table">
                        <td>{{'disposition.calloff_details.number'|trans}}:</td>
                        <td class="last-edit"> <span v-if="materialNumberEdit" >{{materialNumberEdit}}</span></td>

                        <td>{{item.material && item.material.number ? parseInt(item.material.number) : '—' }}</td>
                    </tr>
                    <tr class="info-table">
                        <td>{{'disposition.calloff_details.name'|trans}}:</td>
                        <td class="last-edit"> <span v-if="materialNameEdit" >{{materialNameEdit}}</span></td>

                        <td>{{item.material && item.material.designation ? item.material.designation : '—' }}</td>
                    </tr>

                </tbody>
                <tbody v-if=" status==2 || status==0">
                    <tr>
                        <td>{{'disposition.calloff_details.last_reminder'|trans}}:</td>
                        <td v-if="status===0" colspan="2">{{item.last_reminder_date ? item.last_reminder_date : '—' }}</td>
                        <td v-if="status===2" colspan="2">{{item.status_info && item.status_info.cancellation_requested_date ? item.status_info.cancellation_requested_date : '—' }}</td>

                    </tr>
                    <tr>
                        <td>{{"disposition.call-off.table.header.delivery_date"|trans}}:</td>
                        <td colspan="2">{{item.delivery_date ? item.delivery_date : '—'}}</td>
                    </tr>
                    <tr>
                        <td>{{'disposition.calloff_details.time_left'|trans}}:</td>
                        <td colspan="2">{{item.remaining_time ? item.remaining_time : '—' }}</td>
                    </tr>
                </tbody>
              
                <tbody v-if="status==1">

                    <tr>
                        <td>{{"disposition.call-off.table.header.delivery_start_date"|trans}}:</td>
                        <td class="last-edit"> <span v-if="deliveryDateEdit" >{{deliveryDateEdit}}</span></td>
                        <td>{{item.delivery_date ? item.delivery_date : '—'}}</td>
                    </tr>
                    <tr>
                        <td>{{"disposition.delivery-note.table.header.delivery_start_time"|trans}}:</td>
                        <td class="last-edit"> <span v-if="deliveryTimeEdit" >{{deliveryTimeEdit}}</span></td>
                        <td>{{item.delivery_time ? item.delivery_time : '—'}} {{timeTrans}}</td>
                    </tr>
                    <tr>
                        <td>{{"disposition.call-off.table.header.delivery_end_date"|trans}}:</td>
                        <td class="last-edit"> <span v-if="deliveryDateEndEdit" >{{deliveryDateEndEdit}}</span></td>
                        <td>{{item.delivery_end_date ? item.delivery_end_date : '—'}}</td>
                    </tr>
                    <tr>
                        <td>{{"disposition.delivery-note.table.header.delivery_end_time"|trans}}:</td>
                        <td class="last-edit"> <span v-if="deliveryTimeEndEdit" >{{deliveryTimeEndEdit}}</span></td>
                        <td>{{item.delivery_end_time ? item.delivery_end_time : '—'}} {{timeTrans}}</td>
                    </tr>
                    <tr>
                        <td>{{"disposition.call-off.table.header.quantity"|trans}}:</td>
                        <td class="last-edit"> <span v-if="quantityEdit" >{{quantityEdit}}</span></td>
                        <td>{{item.quantity ? item.quantity : '—'}}</td>
                    </tr>
                    <tr>
                        <td>{{"disposition.call-off.table.header.quantity_hourly"|trans}}:</td>
                        <td class="last-edit"> <span v-if="hourlyQuantityEdit" >{{hourlyQuantityEdit}}</span></td>
                        <td>{{item.hourly_quantity ? item.hourly_quantity : '—'}}</td>
                    </tr>
                    <tr>
                        <td>{{"disposition.call-offs.work"|trans}}:</td>
                        <td class="last-edit"> <span v-if="shipping_spotEdit" >{{shipping_spotEdit}}</span></td>
                        <td>{{item.shipping_spot && item.shipping_spot.name ? item.shipping_spot.name : '—'}}</td>
                    </tr>
                    <tr>
                        <td>{{"disposition.calloffs.vehicle_type"|trans}}:</td>
                        <td class="last-edit"> <span v-if="vehicle_typeEdit">{{vehicle_typeEdit}}</span></td>
                        <td>
                            <span class="tooltip-container" v-if="!vehicle_typeEdit && item.vehicle_type_changed">
                                <i class="material-icons"
                                    :data-tooltip="item.vehicle_type_changed_tooltip"
                                >
                                    error_outline
                                </i>
                            </span>
                            {{item.vehicle_type ? item.vehicle_type : '—'}}
                        </td>
                    </tr>
                    <tr>
                        <td>{{"disposition.calloffs.incoterm"|trans}}:</td>
                        <td class="last-edit"> <span v-if="incotermEdit" >{{incotermEdit}}</span></td>
                        <td>
                            <span class="tooltip-container" v-if="!incotermEdit && item.incoterm_changed">
                                <i class="material-icons"
                                    :data-tooltip="item.incoterm_changed_tooltip"
                                >
                                    error_outline
                                </i>
                            </span>
                            {{item.incoterm_data ? item.incoterm_data.title : '—'}}
                        </td>
                    </tr>
                    <tr>
                        <td>{{"disposition.call-off.table.header.number_of_vehicles"|trans}}:</td>
                        <td class="last-edit"> <span v-if="nr_vehiclesEdit" >{{nr_vehiclesEdit}}</span></td>
                        <td>{{item.nr_vehicles ? item.nr_vehicles : '—'}}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr> <td colspan="3" class="heading">{{'disposition.calloff_details.manufacturer_info'|trans}}</td></tr>
                    <tr>
                        <td>{{"disposition.calloffs.customerbox.customer"|trans}}:</td>
                        <td colspan="2">{{item.customer.name}}</td>
                    </tr>
                    <tr>
                        <td>{{"disposition.customer.phone"|trans}}:</td>
                        <td colspan="2">{{item.customer.phone}}</td>
                    </tr>
                    <tr>
                        <td>{{"disposition.calloffs.customerbox.mail"|trans}}:</td>
                        <td colspan="2">{{splitEmail}}</td>
                    </tr>
                    <tr>
                        <td>{{"disposition.calloffs.customerbox.delivery_date"|trans}}:</td>
                        <td colspan="2">{{item.creation_date}}</td>
                    </tr>
                </tbody>  
            </table>
            <div v-if="status==1" class="button-row">
                <button class="btn btn-primary show-edit-calloff-popup" :data-calloff-update-path="calloffEditUrl">
                    {{"disposition.calloffs.calloff_edit"|trans}}
                </button>
            </div>
            <popup-container v-if="popupShow" @close="popupShow = false" :calloffTypesUrl="calloffTypesUrl" :calloffEditUrl="calloffEditUrl" />
          </div>
</template>

<script>
export default {
    props: {
        status: {
            type: Number
        },
        initialItem: {
            type: Object
        },
        calloffTypesUrl: {
            type: String
        },
        calloffEditUrl: {
            type: String
        }
    },
    data() {
        return {
            item: this.initialItem,
            popupShow: false,
            lastEdit: this.initialItem.call_off_previous_data,
        }
    },
    mounted: function() {
        $('.tooltip-container i').each(function() {
            tippy(this, {
                content: $(this).data('tooltip'),
                placement: 'bottom',
                theme: 'cockpit-tippy'
            })
        })
    },
   
    computed: {
        splitEmail: function() {
            if (!this.item.customer.email) {
                return '—'
            }
            let emails = this.item.customer.email.split(';')
            return emails.join('\n')
        }, 
        materialCategoryEdit () {
            if (this.lastEdit && this.lastEdit.material && this.lastEdit.material.category && this.status ==1){
                if (this.item.material && this.item.material.category) {
                    if (this.lastEdit.material.category!==this.item.material.category){
                        return this.lastEdit.material.category
                    }
                }

            }
            return false
        },
        materialNumberEdit () {
            if (this.lastEdit && this.lastEdit.material && this.lastEdit.material.number && this.status ==1){
                if (this.item.material && this.item.material.number) {
                    if (this.lastEdit.material.number!==this.item.material.number){
                        return this.lastEdit.material.number
                    }
                }

            }
            return false
        },
        materialNameEdit () {
            if (this.lastEdit && this.lastEdit.material && this.lastEdit.material.number && this.status ==1){
                if (this.item.material && this.item.material.number) {
                    if (this.lastEdit.material.number!==this.item.material.number){
                        return this.lastEdit.material.designation
                    }
                }

            }
            return false
        },
        deliveryDateEdit(){
            if (this.lastEdit && this.lastEdit.delivery_date && this.status ==1){
                if (this.lastEdit.delivery_date !==this.item.delivery_date){
                    return this.lastEdit.delivery_date
                }
            }
            return false

        },
        deliveryTimeEdit(){
            if (this.lastEdit && this.lastEdit.delivery_time && this.status ==1){
                if (this.lastEdit.delivery_time !==this.item.delivery_time){
                    return this.lastEdit.delivery_time
                }
            }
            return false
        },
        deliveryDateEndEdit(){
            if (this.lastEdit && this.lastEdit.delivery_end_date && this.status ==1){
                if (this.lastEdit.delivery_end_date !==this.item.delivery_end_date){
                    return this.lastEdit.delivery_end_date
                }
            }
            return false

        },
        deliveryTimeEndEdit(){
            if (this.lastEdit && this.lastEdit.delivery_end_time && this.status ==1){
                if (this.lastEdit.delivery_end_time !==this.item.delivery_end_time){
                    return this.lastEdit.delivery_end_time
                }
            }
            return false
        },
        quantityEdit(){
            if (this.lastEdit && this.lastEdit.quantity && this.status ==1){
                if (this.lastEdit.quantity !==this.item.quantity){
                    return this.lastEdit.quantity
                }
            }
            return false
        },
        hourlyQuantityEdit(){
            if (this.lastEdit && this.lastEdit.hourly_quantity && this.status ==1){
                if (this.lastEdit.hourly_quantity !==this.item.hourly_quantity){
                    return this.lastEdit.hourly_quantity
                }
            }
            return false
        },
        shipping_spotEdit() {
            if (this.lastEdit && this.lastEdit.shipping_spot && this.lastEdit.shipping_spot.name && this.status ==1){
                if (this.lastEdit.shipping_spot.id !==this.item.shipping_spot.id){
                    return this.lastEdit.shipping_spot.name
                }
            }
            return false
        },
        vehicle_typeEdit(){
             if (this.lastEdit && this.lastEdit.vehicle_type && this.status ==1){
                if (this.lastEdit.vehicle_type !==this.item.vehicle_type){
                    return this.lastEdit.vehicle_type
                }
            }
            return false
        },
        incotermEdit(){
            if (this.lastEdit && this.lastEdit.incoterm && this.status ==1){
                if (this.lastEdit.incoterm.title !==this.item.incoterm_data.title){
                    return this.lastEdit.incoterm.title
                }
            }
            return false
        },
        nr_vehiclesEdit(){
            if (this.lastEdit && this.lastEdit.nr_vehicles && this.status ==1){
                if (this.lastEdit.nr_vehicles !==this.item.nr_vehicles){
                    return this.lastEdit.nr_vehicles
                }
            }
            return false
        },
        lastInfo() {
            if (this.status == 0 ){
                return this.$root.$options.filters.trans('disposition.calloff_details.last_reminder')
            }
            if (this.status == 2){
                return this.$root.$options.filters.trans('disposition.calloff_details.cancelled_on')
            }
        }
    }

}
</script>

<style>

</style>