var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "link-container" }, [
    _vm.status == 0
      ? _c("a", { on: { click: _vm.openConfirm } }, [
          _vm._v(
            _vm._s(_vm._f("trans")("disposition.calloff_details.send_reminder"))
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.status == 1 || _vm.status == 4 || _vm.status == 2 || _vm.overview
      ? _c("div", { staticClass: "calloff-details-controls" }, [
          _c("a", { staticClass: "decline", on: { click: _vm.openDeny } }, [
            _c("i", { staticClass: "material-icons" }, [_vm._v("close")])
          ]),
          _vm._v(" "),
          _vm.status == 1 || _vm.status === 2
            ? _c(
                "a",
                { staticClass: "confirm", on: { click: _vm.openConfirm } },
                [_c("i", { staticClass: "material-icons" }, [_vm._v("done")])]
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }