$(document).ready(() => {
    if ($('.highway-calloff-edit-popup-container').exists()) {
        const tableContainer = $('.disposition-table-container')
        const calloffEditPopup = $('.highway-calloff-edit-popup-content')


        // Logic for opening edit-calloff popup
        tableContainer.on('click', '.show-edit-calloff-popup', async function() {
            $('.highway-calloff-edit-popup-container').removeClass('hidden fadeOut').addClass('animated fadeIn faster')

            try {
                const response = await axios.get($(this).data('calloffUpdatePath'))
                const { data } = response
                $('.popup-main-container').html($(data))

                initPopupSelects()
                initDatePickers()
                initTimePickers()

                // initialize tippy tooltip for quantity warning
                tippy('.quantity-warning', {
                    content: $('.quantity-warning').data('tooltipContent'),
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })

                $('.highway-calloff-edit-popup-content').removeClass('is-requesting')

            } catch {
                $('.highway-calloff-edit-popup-container').removeClass('animated fadeIn faster').addClass('hidden fadeOut')
                window.showErrorPopup()
            }
        })


        // Logic for closing edit-calloff popup
        calloffEditPopup.on('click', '.exit-popup-icon, .cancel-button', function() {
            $('.highway-calloff-edit-popup-container').removeClass('animated fadeIn faster').addClass('hidden fadeOut')
            $('.popup-main-container').html('')
            $('.highway-calloff-edit-popup-content').addClass('is-requesting')
        })


        // Logic for initializing select2 dropdowns in calloff edit popup
        function initPopupSelects() {
            const noResults = $('#select-with-translation-data').data('noResults')

            $('.select-dropdown').each(function() {
                const dropdown = $(this)

                dropdown.select2({
                    minimumResultsForSearch: $(this).hasClass('type-in') ? 5 : -1,
                    dropdownParent: dropdown.closest('.form-group'),
                    containerCssClass: 'form-control',
                    language: {
                        noResults: () => noResults
                    },
                    templateResult: (data, container) => {
                        if (data.element) {
                            // transfer classes from default select options to select2 select options
                            $(container).addClass($(data.element).attr("class"))

                            // transfer data attributes from default select options to select2 select options
                            if (dropdown.hasClass('factory')) {
                                $(container).attr('data-materials', $(data.element).data('materials'))
                            }
                            if (dropdown.hasClass('material')) {
                                $(container).attr('data-material-id', $(data.element).data('material-id'))
                            }
                        }
                        return data.text
                    }
                })
            })

            // only show the materials that belong to the currently selected factory
            $('.select-dropdown.material').on('select2:open', function() {
                const materialsToShow = $('.select-dropdown.factory').find(':selected').data('materials')

                const dropdownOptions = $('.select-dropdown.material option')
                dropdownOptions.addClass('hidden')

                dropdownOptions.each(function() {
                    if (materialsToShow.includes($(this).data('materialId'))) {
                        $(this).removeClass('hidden')
                    }
                })
            })

            // when one of the material selects is selected, auto select the rest of them
            $('.select-dropdown.material').on('select2:select', function() {
                $('.select-dropdown.material').val($(this).val()).trigger('change')
            })

            // when another factory is seleced clear the material selects
            $('.select-dropdown.factory').on('select2:select', () => {
                $('.select-dropdown.material').val('').trigger('change')
                $('.select-dropdown.material').closest('.form-group').addClass('is-empty')
            })
        }


        // Logic for quantity input
        calloffEditPopup.on('blur', '.calloff-edit-quantity-input', function() {
            const quantityInputValue = $(this).val()
            const hourlyInput = $('.calloff-edit-hourly-quantity-input')

            if (quantityInputValue && parseInt(quantityInputValue) >= 1) {
                const warningLimit = $(this).data('warningLimit')
                const unit = $(this).data('unit')
                const currentHourlyQuantity = parseInt(hourlyInput.data('valueToSend'))

                // if selected value is lower than current hourly value, clear hourly value
                if (currentHourlyQuantity > parseInt(quantityInputValue)) {
                    hourlyInput.data('valueToSend', '')
                    hourlyInput[0].placeholder = hourlyInput.data('unit')
                }

                // set the new placeholder and empty the value in order to show it
                let formattedValue = parseInt(quantityInputValue).toLocaleString('de-DE')
                        
                $(this).attr('placeholder', `${formattedValue} ${unit}`)
                $(this).data('valueToSend', quantityInputValue)
                $(this).val('')

                if (quantityInputValue > warningLimit && warningLimit > 0) {
                    $(this).next('.quantity-warning').removeClass('hidden')
                } else {
                    $(this).next('.quantity-warning').addClass('hidden')
                }

            } else {
                $(this).val('')
            }
        })


        // Logic for hourly quantity input
        calloffEditPopup.on('blur', '.calloff-edit-hourly-quantity-input', function() {
            const currentQuantity = $('.calloff-edit-quantity-input').data('valueToSend')
            const hourlyAmountInputValue = $(this).val()

            let validateHourly = true
            if (currentQuantity && (currentQuantity < parseInt(hourlyAmountInputValue))) {
                validateHourly = false
            }

            if (hourlyAmountInputValue && parseInt(hourlyAmountInputValue) >= 1 && validateHourly) {
                const unit = $(this).data('unit')

                // set the new placeholder and empty the value in order to show it
                let formattedValue = parseInt(hourlyAmountInputValue).toLocaleString('de-DE')
                $(this).attr('placeholder', `${formattedValue} ${unit}`)
                $(this).data('valueToSend', hourlyAmountInputValue)
                $(this).val('')

            } else {
                $(this).val('')
            }
        })


        // Logic for pressing Enter and forbidding , and . keys on inputs
        calloffEditPopup.on('keydown', '.calloff-edit-quantity-input, .calloff-edit-hourly-quantity-input', function(event) {
            if (event.keyCode == 13 || event.key == 'Enter') {
                $(this).trigger('blur')
            }
            if (event.keyCode == 188 || event.keyCode == 190 || event.keyCode == 108 || event.key == '.' || event.key == ',') {
                event.preventDefault()
                event.stopPropagation()
            }
        })


        // Logic for initializing datepickers for date_start and date_end
        function initDatePickers() {
            if ($('.calloff-edit-date-input').exists()) {
                $('.calloff-edit-date-input').each(function() {
                    let isDateEnd = $(this).hasClass('calloff-edit-date-end-input')
                    let { locale, preselect } = $(this).data()
                    let minDate = "today"
                    let enabledDates = []

                    if (isDateEnd) {
                        const currentlySelectedStartDate = flatpickr.parseDate($('.calloff-edit-date-start-input').data('preselect'), 'D, d.m.y')
                        const dayAfter = typeof currentlySelectedStartDate !== 'undefined' ? currentlySelectedStartDate.fp_incr(1) : ''
                        const nightShift = $('.calloff-edit-night-shift-input').prop('checked') == true

                        if (nightShift) {
                            enabledDates = [currentlySelectedStartDate, dayAfter]
                        } else {
                            enabledDates = [currentlySelectedStartDate]
                        }
                    }

                    let options = {
                        dateFormat: 'D, d.m.y',
                        locale: locale,
                        minDate: minDate,
                        defaultDate: preselect,
                        enable: enabledDates,
                        position: "auto center",
                        disableMobile: true,
                        onChange: () => {
                            $('.flatpickr-input').removeClass('is-focused')
                        }
                    }
                    flatpickr($(this), options)
                    $(this)[0]._flatpickr.calendarContainer.classList.add('edit-calloff-date')
                })
            }
        }


        // Logic for initializing timepickers for time_start and time_end
        function initTimePickers() {
            if ($('.calloff-edit-time-input').exists()) {
                $('.calloff-edit-time-input').each(function() {
                    const timePicker = $(this)
                    let isTimeEnd = timePicker.hasClass('calloff-edit-time-end-input')
                    let { locale, preselect } = timePicker.data()

                    const dateStartDatepicker = $('.calloff-edit-date-start-input')
                    const dateEndDatepicker = $('.calloff-edit-date-end-input')
                    const currentDate = moment(dateStartDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')
                    const endDate = moment(dateEndDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')

                    let isSameDay = currentDate == endDate

                    let options = {
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        minDate: (isSameDay && isTimeEnd) ? $('.calloff-edit-time-start-input').data('preselect') : '',
                        defaultDate: preselect,
                        defaultHour: isTimeEnd ? '15' : '7',
                        locale: locale,
                        position: "auto center",
                        disableMobile: true,
                        onClose: function(selectedDates, dateStr, instance) {
                            const thisTimepicker = $(this.element)

                            // timepickers data
                            const timeStartDatepicker = $('.calloff-edit-time-start-input')
                            const timeEndDatepicker = $('.calloff-edit-time-end-input')
                            const startTime = moment(timeStartDatepicker.val(), 'HH:mm').format('HH:mm')
                            const endTime = moment(timeEndDatepicker.val(), 'HH:mm').format('HH:mm')

                            // datepickers data
                            const dateStartDatepicker = $('.calloff-edit-date-start-input')
                            const dateEndDatepicker = $('.calloff-edit-date-end-input')
                            const currentDate = moment(dateStartDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')
                            const endDate = moment(dateEndDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')

                            // on closing make sure to set that selected time as value of the input
                            // added for the case when you click outside after manually typing in the time value
                            if (thisTimepicker.val()) {
                                instance.setDate(`${instance.hourElement.value}:${instance.minuteElement.value}`)
                                thisTimepicker.trigger('change')
                            }

                            // additional logic for turning back on the setting of end-datepicker value if necessary
                            // it was turned off in 'changing inputs dependable on delivery start time' event bugfix
                            if (currentDate == endDate && currentDate != 'Invalid date' && !isTimeEnd && startTime > endTime) {
                                timeEndDatepicker[0]._flatpickr.setDate(startTime)
                            }
                        }
                    }  
                    flatpickr(timePicker, options)
                    $(this)[0]._flatpickr.calendarContainer.classList.add('edit-calloff-date')
                })
            }
        }


        // Logic for changing inputs dependable on delivery start date,
        calloffEditPopup.on('change', '.calloff-edit-date-start-input', function() {

            // datepickers data
            const dateEndDatepicker = $('.calloff-edit-date-end-input')

            // timepickers data
            const timeStartDatepicker = $('.calloff-edit-time-start-input')
            const timeEndDatepicker = $('.calloff-edit-time-end-input')

            const currentDateFormatted = flatpickr.parseDate($(this).val(), 'D, d.m.y')
            const increasedDate = typeof currentDateFormatted !== 'undefined' ? currentDateFormatted.fp_incr(1) : ''
            const nightShift = $('.calloff-edit-night-shift-input').prop('checked') == true

            dateEndDatepicker[0]._flatpickr.set('minDate', $(this).val())
            dateEndDatepicker[0]._flatpickr.set('enable', [])

            if (nightShift) {
                dateEndDatepicker[0]._flatpickr.setDate(increasedDate)
                dateEndDatepicker[0]._flatpickr.set('enable', [currentDateFormatted, increasedDate])

                timeEndDatepicker[0]._flatpickr.set('minDate', '')

            } else {
                dateEndDatepicker[0]._flatpickr.setDate(currentDateFormatted)
                dateEndDatepicker[0]._flatpickr.set('enable', [currentDateFormatted])

                timeEndDatepicker[0]._flatpickr.setDate(timeStartDatepicker.val())
                timeEndDatepicker[0]._flatpickr.set('minDate', timeStartDatepicker.val())

            }
        })


        // Logic for changing inputs dependable on delivery end date
        calloffEditPopup.on('change', '.calloff-edit-date-end-input', function() {

            // datepickers data
            const dateStartDatepicker = $('.calloff-edit-date-start-input')
            const endDate = moment($(this).val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')
            const startDate = moment(dateStartDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')

            // timepickers data
            const timeStartDatepicker = $('.calloff-edit-time-start-input')
            const timeEndDatepicker = $('.calloff-edit-time-end-input')
            const currentTime = moment(timeStartDatepicker.val(), 'HH:mm').format('HH:mm')
            const endTime = moment(timeEndDatepicker.val(), 'HH:mm').format('HH:mm')

            if (startDate != endDate) {
                timeEndDatepicker[0]._flatpickr.set('minDate', '')

            } else {
                timeEndDatepicker[0]._flatpickr.set('minDate', timeStartDatepicker.val())

                if (currentTime > endTime) {
                    timeEndDatepicker[0]._flatpickr.setDate(timeStartDatepicker.val())
                }
            }
        })


        // Logic for changing inputs dependable on delivery start time
        calloffEditPopup.on('change', '.calloff-edit-time-start-input', function() {

            // timepickers data
            const timeEndDatepicker = $('.calloff-edit-time-end-input')
            const currentTime = moment($(this).val(), 'HH:mm').format('HH:mm')
            const endTime = moment(timeEndDatepicker.val(), 'HH:mm').format('HH:mm')

            // datepickers data
            const dateStartDatepicker = $('.calloff-edit-date-start-input')
            const dateEndDatepicker = $('.calloff-edit-date-end-input')
            const currentDate = moment(dateStartDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')
            const endDate = moment(dateEndDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')

            // additional logic for checking if the end-timepicker needs to actually be changed
            // necessary only because of the bug with the start-timepicker when it took the current real time as selected when it was opened
            // prevents changing end-timepicker to current time immediately after the start-timepicker is opened
            const timeStartTimepickerValue = $(this)[0]._flatpickr.selectedDates[0] ? $(this)[0]._flatpickr.selectedDates[0].toString() : ''
            const currentRealTime = moment()._d.toString()

            if (currentDate == endDate && timeStartTimepickerValue != currentRealTime) {
                timeEndDatepicker[0]._flatpickr.set('minDate', $(this).val())

                if (currentTime > endTime) {
                    timeEndDatepicker[0]._flatpickr.setDate($(this).val())
                }
            }
        })


        // Logic for changing inputs dependable on night_shift checkbox
        calloffEditPopup.on('change', '.calloff-edit-night-shift-input', function() {
            $(this).next().toggleClass('nightshift-selected')

            const dateStartDatepicker = $('.calloff-edit-date-start-input')
            const dateEndDatepicker = $('.calloff-edit-date-end-input')
            const timeEndDatepicker = $('.calloff-edit-time-end-input')

            const dateStart = flatpickr.parseDate(dateStartDatepicker.val(), 'D, d.m.y')
            const increasedDate = typeof dateStart !== 'undefined' ? dateStart.fp_incr(1) : ''
            const nightShift = $(this).prop('checked') == true

            if (nightShift) {
                dateEndDatepicker[0]._flatpickr.set('enable', [dateStart, increasedDate])
                dateEndDatepicker[0]._flatpickr.setDate(increasedDate)
                timeEndDatepicker[0]._flatpickr.set('minDate', '')
            } else {
                dateEndDatepicker[0]._flatpickr.set('enable', [dateStart])
                dateEndDatepicker[0]._flatpickr.setDate(dateStart)
            }
        })


        // Logic for submit button
        calloffEditPopup.on('click', '.submit-button', async function() {
            let url = $(this).data('route')
            let params = {}
            
            // get all of the selected values from dropdowns
            calloffEditPopup.find('select').each(function() {
                params[$(this).data('name')] = $(this).val()
            })

            // validate fields (material fields must not be empty)
            if (!params['material_name']) {
                return false
            }

            // get all of the selected values from inputs
            calloffEditPopup.find('input').each(function() {

                if ($(this).attr('type') == 'checkbox') {
                    params[$(this).data('name')] = $(this).prop('checked') == true ? '1' : '0'

                } else if ($(this).attr('type') == 'number') {
                    if ($(this).data('valueToSend') == $(this)[0].defaultValue) {
                        params[$(this).data('name')] = $(this)[0].defaultValue.replace(/[^0-9\.]/g, '')
                    } else {
                        params[$(this).data('name')] = $(this).data('valueToSend')
                    }

                } else if ($(this).hasClass('calloff-edit-date-input')) {
                    params[$(this).data('name')] = $(this).val() ? moment($(this).val().split(', ')[1], 'DD.MM.YY').format('DD.MM.YYYY') : ''
                
                } else {
                    params[$(this).data('name')] = $(this).val()
                }
            })

            // convert all params to formData
            const payload = createPayload(params)

            try {
                calloffEditPopup.addClass('is-requesting-update')
                await axios.post(url, payload)

                location.reload()
            } catch {
                window.showErrorPopup()
            }
        })


        // Helper function for creating formData payload from post params
        function createPayload(params) {
            const keys = Object.keys(params)
            let data = new FormData()

            keys.map(key => {
                data.append(key, params[key])
            })

            return data
        }
    }
})