$(document).ready(() => {
    if($('#highway-contract-overview-dashboard').exists()) {
        const contractOverviewContainer = $('#highway-contract-overview-dashboard')
        const searchContainer = $('.contract-search-container')
        const filterContainer = $('.contract-overview-filter-container')
        const tableContainer = $('.contract-table-container')
        const itemLoadingIcon = $('#search-loader').html()
        const dropdownArrow = $('.dropdown-arrow-svg').html()

        // Stores for instances
        let tableFiltersInstances = []
        let topFiltersInstances = []
        let tippyInstances = []

        const paramsInSingleFormat = []
        let url = window.location.href

        window.contractOverview = window.contractOverview || {}

        function initIconTooltips() {
            // Clear saved instances
            tippyInstances.forEach(instance => {
                if (Array.isArray(instance)) {
                    // "Multiple" => favorite, phone, clock...
                    instance.forEach(singleInstance => singleInstance.destroy())
                } else {
                    // "Single" => company names & dates
                    instance.destroy()
                }
            })

            tippyInstances = []

            const {
                phone_icon_tooltip,
                clock_icon_tooltip,
                add_to_favorites_tooltip,
                remove_from_favorites_tooltip
            } = window.contractOverview.translations

            tippyInstances.push(
                tippy('.favorite-on', {
                    content: remove_from_favorites_tooltip,
                })
            )

            tippyInstances.push(
                tippy('.favorite-off', {
                    content: add_to_favorites_tooltip,
                })
            )

            tippyInstances.push(
                tippy('.has-today-calloff', {
                    content: clock_icon_tooltip,
                })
            )

            tippyInstances.push(
                tippy('.has-today-delivery', {
                    content: phone_icon_tooltip,
                })
            )

            // Customer company name tooltip
            $('td.customer_company_name .has-tippy').each( function() {
                const tooltip = $(this).data('tooltip')

                tippyInstances.push(
                    tippy(this, {
                        content: tooltip,
                        placement: 'bottom',
                        theme: 'cockpit-tippy'
                    })
                )
            })

            // Construction site name tooltip
            $('td.construction_site_name .has-tippy').each( function() {
                const tooltip = $(this).data('tooltip')

                tippyInstances.push(
                    tippy(this, {
                        content: tooltip,
                        placement: 'bottom',
                        theme: 'cockpit-tippy'
                    })
                )
            })

            // Logic for contract date tooltip
            $('td.contract_period').each( function() {
                const contractEndTooltip = $(this).data('contractEndTooltip')

                if($(this).hasClass('date-tooltip')) {
                    tippyInstances.push(
                        tippy('.date-tooltip', {
                            content: contractEndTooltip
                        })
                    )
                }
            })

            $('td.contract_period').each( function() {
                const exipredContractTooltip = $(this).data('exipredContractTooltip')

                if($(this).hasClass('expired-contract')) {
                    tippyInstances.push(
                        tippy('.expired-contract', {
                            content: exipredContractTooltip
                        })
                    )
                }
            })
        }

        initIconTooltips()

        // Logic for adding/removing favorite contract
        const changeFavoriteContract = async function () {
            let favorite = $(this).data('favorite')
            let url = $(this).data('url')

            url = window.updateUrlParameter(url, 'status', !favorite)

            contractOverviewContainer.addClass('is-requesting')

            try {
                await axios.get(url, window.axiosConfig)

                const {
                    add_to_favorites_tooltip,
                    remove_from_favorites_tooltip
                } = window.contractOverview.translations

                if ($(this).hasClass('favorite-off')) {
                    $(this).removeClass('favorite-off').addClass('favorite-on')
                    $(this).data('favorite', 1)
                    $(this)[0]._tippy.setContent(remove_from_favorites_tooltip)
                } else {
                    $(this).removeClass('favorite-on').addClass('favorite-off')
                    $(this).data('favorite', 0)
                    $(this)[0]._tippy.setContent(add_to_favorites_tooltip)
                }

                contractOverviewContainer.removeClass('is-requesting')
            } catch (err) {
                window.showErrorPopup()
                contractOverviewContainer.removeClass('is-requesting')
                throw err
            }
        }
        contractOverviewContainer.on('click', '.calloff-overview-table .favorite-button', changeFavoriteContract)

        // Logic for clearing active filters above the table (x on active filter labels)
        contractOverviewContainer.on('click', '.calloff-filter-label.active-filter', async function() {
            let filterKeys = $(this).find('.remove-active-filter').data('filter-keys')
            let filterKey = $(this).find('.remove-active-filter').data('filter-key')
            let filterValue = $(this).find('.remove-active-filter').data('filter-value')
            let params = {}

            contractOverviewContainer.addClass('is-requesting')

            $(this).remove()

            if (filterKey) {
                params = {
                    [filterKey]: encodeURIComponent(filterValue)
                }

                try {
                    await updateView(params, false, true, true)

                } catch {
                    window.showErrorPopup()
                }

            } else if (filterKeys) {
                params = Object.fromEntries(filterKeys.map(x => [x, '']))

                try {
                    await updateView(params, false, true, false)

                } catch {
                    window.showErrorPopup()
                }
            }

            contractOverviewContainer.removeClass('is-requesting')
        })

        // Initialize filters above the table
        function initFilters() {
            // Clear saved instances
            topFiltersInstances.forEach(select2Instance => select2Instance.select2('destroy'))
            topFiltersInstances = []

            const filter = $('.calloff-table-filter')

            filter.each(function() {
                const filter = $(this)
                const filterParent = filter.parent()

                initAjaxFilter(filter, filterParent, filterContainer, itemLoadingIcon, '', 'true')
            })
        }
        initFilters()

        // Logic for opening/closing the customizable table and preset popup
        contractOverviewContainer.on('click', '.edit-table-button', function() {
            contractOverviewContainer.find('.customize-table-popup-container').removeClass('closed')
        })
        contractOverviewContainer.on('click', '.close-popup-btn', function() {
            $(this).closest('.customize-table-popup-container').addClass('closed')
        })

        // Remove all filters button
        contractOverviewContainer.on('click', '.calloff-filter-delete-all', async function() {
            const originalUrl = $(this).data('originalUrl')

            contractOverviewContainer.addClass('is-requesting')
            window.location.href = originalUrl
        })

        // Logic for table layout select dropdown in the table header
        function initTableLayoutSelect() {
            $('#contract-table-layout').select2({
                dropdownParent: $('.active-layout-select-container'),
                containerCssClass: 'contract-table-layout-select' ,
                dropdownCssClass: 'contract-table-layout-dropdown',
                minimumResultsForSearch: -1
            }).on('select2:select', function(e) {
                contractOverviewContainer.addClass('is-requesting')

                let filterValue = e.params.data.id
                window.location.href = filterValue
            })

            $('.contract-table-layout-select').find('.select2-selection__arrow b').html(dropdownArrow)
        }
        initTableLayoutSelect()

        // Logic for table header filters
        function initTableFilters() {
            // Clear saved instances
            tableFiltersInstances.forEach(select2Instance => select2Instance.select2('destroy'))
            tableFiltersInstances = []

            if (tableContainer.exists()) {
                const tableFilters = $('.header-filter')

                tableFilters.each(function() {
                    const filter = $(this)
                    const filterParent = filter.parent()
                    initTableAjaxFilter(filter, filterParent, tableContainer, itemLoadingIcon, 'true')
                })
            }
        }
        initTableFilters()

        // Logic for initializing pagination
        function initPagination() {
            const pagination = $('#number-pagination')
            if (pagination.exists()) {
                pagination.twbsPagination('destroy')

                let { first, last, next, prev, startPage, totalPages, visiblePages } = pagination.data()

                pagination.twbsPagination({
                    first,
                    last,
                    next,
                    prev,
                    startPage,
                    totalPages,
                    visiblePages,
                    initiateStartPageClick: false,
                    onPageClick: async (event, page) => {
                        contractOverviewContainer.addClass('is-requesting')

                        url = window.updateUrlParameter(url, 'page', page)

                        if (typeof window.history.pushState != 'undefined') {
                            window.history.pushState(null, null, url)
                        }

                        await updateContractOverviewContainer()

                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        })

                        contractOverviewContainer.removeClass('is-requesting')
                    }
                })
            }
        }
        initPagination()

        // Logic for replacing page content without reload
        async function updateView(urlParams = {}, reload = false, pagination = false, multiselect = false) {
            if (!urlParams.isEmpty) {
                Object.keys(urlParams).forEach(function(key) {
                    if(multiselect) {
                        if(key == 'page') {
                            url = window.updateUrlParameter(url, key, urlParams[key])
                        } else {
                            url = window.updateUrlParameterMulti(url, key, urlParams[key])
                        }
                    } else {
                        url = window.updateUrlParameter(url, key, urlParams[key])
                    }
                })
                if (!pagination) {
                    url = window.updateUrlParameter(url, 'page', '')
                }
                if (typeof window.history.pushState != 'undefined') {
                    window.history.pushState(null, null, url)
                }
                if (reload) {
                    window.location.href = url
                }
            }

            if (!reload) {
                try {
                    const response = await axios.get(url, window.axiosConfig)
                    const { data } = response

                    const topFiltersContainer = $(data).find('.contract-top-container').html() || ''
                    const tableContainer = $(data).find('.contract-table-container').html() || ''

                    $('.contract-top-container').html(topFiltersContainer)
                    $('.contract-table-container').html(tableContainer)

                    initFilters()
                    initTableFilters()
                    initTableLayoutSelect()
                    initPagination()
                    initIconTooltips()

                } catch {
                    window.showErrorPopup()
                }
            }
        }

        // Helper function for reloading page
        async function updateContractOverviewContainer(refreshUrl = '') {
            try {
                let response = ''

                if(refreshUrl) {
                    response = await axios.get(refreshUrl)
                } else {
                    response = await axios.get(url)
                }
                const { data } = response

                const topFiltersContainer = $(data).find('.contract-top-container').html() || ''
                const tableContainer = $(data).find('.contract-table-container').html() || ''

                $('.contract-top-container').html(topFiltersContainer)
                $('.contract-table-container').html(tableContainer)

                initFilters()
                initTableFilters()
                initTableLayoutSelect()
                initPagination()
                initIconTooltips()

            } catch {
                window.showErrorPopup()
            }
        }
        window.contractOverview.updateContractOverviewContainer = updateContractOverviewContainer

        // Logic for the search bar
        // Initialize search by sending 4 parameters:
        //  1) the item itself
        //  2) its parent
        //  3) container which will have 'is-requesting' class when loading
        //  4) html for the loading icon inside the item (not the main screen loader)
        function initSearch(search, searchParent, loadingContainer, searchLoadingIcon) {
            let searchTerm

            // get the data and rename it
            let {
                messageApi_route: searchUrl,
                messagePlaceholder: placeholder,
                messageAdd_more_text: addMoreText,
                messageNo_results: noResults
            } = search.data()

            search.select2({
                dropdownParent: searchParent,
                minimumResultsForSearch: -1,
                minimumInputLength: 1,
                multiple: true,
                placeholder: placeholder,
                ajax: {
                    url: () => {
                        let params = window.getUrlParams(url)
                        let keys = Object.keys(params)

                        // update url with all of the currently active parameters except for the current pagination params
                        if (keys.length){
                            keys.forEach(key => {
                                if (key != 'page_size' && key != 'page') {
                                    if (paramsInSingleFormat.includes(key)) {
                                        searchUrl = window.updateUrlParameter(searchUrl, key, params[key])
                                    } else {
                                        let ajaxUrlParams = Object.keys(window.getUrlParams(searchUrl))

                                        // since this url generator only appends params to old url when you search for 2nd
                                        // letter and after, only update url if there wasnt that param, otherwise
                                        // updateUrlParameterMulti function will simply remove it

                                        if (!ajaxUrlParams.includes(key)) {
                                            searchUrl = window.updateUrlParameterMulti(searchUrl, key, params[key])
                                        }
                                    }
                                }
                            })
                        }
                        return searchUrl
                    },
                    dataType: 'json',
                    data: params => {
                        // only used to store the search term
                        searchTerm = params.term
                        return params
                    },
                    processResults: data => {
                        return {
                            results: data.items.map(key => ({
                                text: `${key.header}: ${key.name}`, // Option preview
                                id: key.value,
                                name: key.name,
                                value: key.filter_key
                            })),
                            pagination: {
                                more: data.pagination.has_next_page
                            }
                        }
                    }
                },
                language: {
                    inputTooShort: () => addMoreText,
                    noResults: () => noResults,
                    searching: () => searchLoadingIcon,
                    loadingMore: () => searchLoadingIcon
                },
                templateResult: data => styleResults(data),
                escapeMarkup: markup => markup
            }).on('select2:select', async function(e) {
                let data = e.params.data

                contractOverviewContainer.addClass('is-requesting')

                let params = {
                    [data.value]: encodeURIComponent(data.name)
                }

                try {
                    await updateView(params, false, true, true)
                }
                catch {
                    window.showErrorPopup()
                }

                $(this).val('').trigger('change')

                contractOverviewContainer.removeClass('is-requesting')
            })

            // show the search icon next to placeholder; icon defined in searchParams next to <select>
            $('.search-icon').removeClass('hidden')

            function styleResults(data) {
                return `<a class="search-result-link" href="#">
                            ${data.text}
                        </a>`
            }
        }
        initSearch($('.contract-search'), searchContainer, tableContainer, itemLoadingIcon, 'true')

        // Logic for select filters outside of table
        function initAjaxFilter(
            filter,
            filterParent,
            loadingContainer,
            filterLoadingIcon,
            dropdownArrow = '<i class="material-icons">expand_more</i>',
        ) {

            let url = window.location.href
            let { filterUrl, filterName, selection, placeholder, noResults } = filter.data()

            let searchMinimum = 1

            filter.select2({
                dropdownParent: filterParent,
                minimumResultsForSearch: searchMinimum,
                ajax: {
                    // updates request url with all the current url params except for the current pagination params
                    url: () => {
                        let params = window.getUrlParams(url)
                        let keys = Object.keys(params)
                        if (keys.length){
                            keys.forEach(key => {
                                if (params[key] !== '' && key !== filterName && key != 'page_size' && key != 'page'){
                                    if (paramsInSingleFormat.includes(key)) {
                                        filterUrl = window.updateUrlParameter(filterUrl, key, params[key])
                                    } else {
                                        let ajaxUrlParams = Object.keys(window.getUrlParams(filterUrl))

                                        if (!ajaxUrlParams.includes(key)) {
                                            filterUrl = window.updateUrlParameterMulti(filterUrl, key, params[key])
                                        }
                                    }
                                }
                            })
                        }
                        return filterUrl
                    },
                    dataType: 'json',
                    processResults: data => {
                        // structure the response json to match select2 needs for filling dropdown
                        let keys = data.items[filterName] ? Object.keys(data.items[filterName]): []
                        let options = {
                            results: keys.map( key => ({
                                    text: data.items[filterName][key],
                                    id: key,
                                    value: key
                            })),
                            pagination: {
                                more: data.pagination.has_next_page
                            }
                        }

                        return options
                    }
                },
                language: {
                    noResults: () => noResults,
                    searching: () => filterLoadingIcon,
                    loadingMore: () => filterLoadingIcon
                },
                templateSelection: function (data) {
                    return placeholder
                },
                templateResult: (data, container) => styleResults(data, container, selection),
                escapeMarkup: markup => markup

            }).on('select2:select', async function(e) {
                let data = e.params.data

                contractOverviewContainer.addClass('is-requesting')

                let params = {
                    [filterName]: encodeURIComponent(data.text)
                }

                try {
                    await updateView(params, false, true, true)
                }
                catch {
                    window.showErrorPopup()
                }

                contractOverviewContainer.removeClass('is-requesting')

            })

            topFiltersInstances.push(filter)

            // append an option that has the last saved state
            let preselectedOption = new Option(
                '',
                'preselected-option',
                true,
                true
            )
            filter.append(preselectedOption)

            // custom dropdown arrow
            filterParent.find('.select2-selection__arrow b').html(dropdownArrow)

            function styleResults(data, container, selection) {
                let markup = ''

                // Option item
                let { text, id } = data

                if (selection.includes(text.toString().trim()) || selection.includes(id)) {
                    markup = `
                                <p class="highlighted">${text}</p>
                            `
                } else {
                    markup = `${text}`
                }
                $(container).addClass('position-relative')

                return markup
            }
        }

        // Logic for select filters in table header
        function initTableAjaxFilter(filter, filterParent, loadingContainer, filterLoadingIcon) {
            let url = window.location.href
            let { filterUrl, filterName, selection, placeholder, filterIcon, noResults } = filter.data()

            filter.select2({
                dropdownParent: filterParent,
                minimumResultsForSearch: -1,
                placeholder: placeholder.toString(),
                ajax: {
                    // updates request url with all the current url params except for the current pagination params
                    url: () => {
                        let params = window.getUrlParams(url)
                        let keys = Object.keys(params)

                        if (keys.length){
                            keys.forEach(key => {
                                if (params[key] !== '' && key !== filterName && key != 'page_size' && key != 'page'){
                                    if (paramsInSingleFormat.includes(key)) {
                                        filterUrl = window.updateUrlParameter(filterUrl, key, params[key])
                                    } else {
                                        let ajaxUrlParams = Object.keys(window.getUrlParams(filterUrl))

                                        if (!ajaxUrlParams.includes(key)) {
                                            filterUrl = window.updateUrlParameterMulti(filterUrl, key, params[key])
                                        }
                                    }
                                }
                            })
                        }
                        return `${filterUrl}`
                    },
                    dataType: 'json',
                    processResults: data => {
                        // structure the response json to match select2 needs for filling dropdown
                        let keys = data.items[filterName] ? Object.keys(data.items[filterName]): []

                        return {
                            results: keys.map( key => ({
                                text: data.items[filterName][key],
                                id: key,
                                value: key
                            })),
                            pagination: {
                                more: data.pagination.has_next_page
                            }
                        }
                    }
                },
                language: {
                    noResults: () => noResults,
                    searching: () => filterLoadingIcon,
                    loadingMore: () => filterLoadingIcon
                },
                templateSelection: () => styleSelection(placeholder, filterIcon),
                templateResult: (data, container) => styleResults(data, container, selection),
                escapeMarkup: markup => markup

            }).on('select2:select', async function(e) {
                contractOverviewContainer.addClass('is-requesting')
                let data = e.params.data

                let params = {
                    [filterName]: encodeURIComponent(data.text)
                }

                try {
                    await updateView(params, false, true, true)
                }
                catch {
                    window.showErrorPopup()
                }

                contractOverviewContainer.removeClass('is-requesting')
            })

            tableFiltersInstances.push(filter)

            // remove the default dropdown arrow
            filterParent.find('.select2-selection__arrow').html('')

            // custom class for hiding the search-box in dropdown during the ajax request
            filterParent.addClass('no-search-box')

            function styleSelection (placeholder, filterIcon) {
                return `${placeholder} <i class="material-icons">${filterIcon}</i>`
            }

            function styleResults(data, container, selection) {
                    let markup = ''

                    // Option item
                    let { text, id } = data

                    // if its selected add the "x" icon for removal
                    // in the case that filter doesnt append to URL its text, we have additional check for id
                    if (selection.includes(text.toString().trim()) || selection.includes(id)) {
                        markup = `
                                    <a class="table-filter-clear"><i class="material-icons">close</i></a>
                                    <a class="dropdown-item table-filter" href="#">
                                        ${text}
                                    </a>
                                `
                    } else {
                        markup = `
                                    <a class="dropdown-item table-filter" href="#">
                                        ${text}
                                    </a>
                                `
                    }

                    $(container).addClass('position-relative')

                    return markup
            }
        }


        // Loader for table buttons
        $('.contract-overview-button').on('click', () => {
            contractOverviewContainer.addClass('is-requesting')
        })
    }
})
