$(document).ready(() => {
    if ($('#highway-calloff-edit').exists() || $('#highway-calloff-creation').exists()) {
        const callOffEditContainer = $('.calloff-edit-content-container')
        const materialPopup = $('.highway-calloff-edit-material-popup-container .material-popup')
        const declinePopupContainer = $('.decline-calloff-popup-container')
        const sideContent = $('.calloff-edit-side-content')
        const headerToggler = $('.header-preview-toggler')
        const noResults = $('#select-with-translation-data').data('noResults')

        window.calloffEditCommon = {}


        // Logic for returning to calloff overview page with header navigation buttons
        if ($('.back-button').exists()) {
            $('.back-button').on('click', function() {
                callOffEditContainer.addClass('is-requesting')
                window.location.href = $(this).data('path')
            })
        }


        // Logic for expanding the header
        if (headerToggler.exists()) {
            headerToggler.on('click', () => {
                $('.navigation-full, .calloff-edit-content-container').toggleClass('header-expanded')
            })
        }


        // Logic for initializing selects except for status select
        function initCalloffEditSelects() {
            $('.calloff-edit-table select:not(.status-select)').each(function() {
                $(this).select2({
                    minimumResultsForSearch: -1,
                    dropdownCssClass: 'calloff-edit-data-dropdown',
                    language: {
                        noResults: () => noResults
                    }

                }).on('select2:select', function(e) {

                    // dont show old values for calloff creation screen, only for calloff edit
                    if (!$(this).hasClass('no-old-values')) {
                        const selectedOptionText = e.params.data.text.trim()

                        // if the value changed show the red container and init tooltip
                        if (selectedOptionText != $(this).data('originalValue')) {
                            $(this).addClass('value-changed')
                            $(this).siblings('.old-value-container').text($(this).data('originalValue'))
                            $(this).siblings('.old-value-container').addClass('has-value')
                            window.calloffEdit.initOldValueTooltips()

                        } else {
                            $(this).removeClass('value-changed')
                            $(this).siblings('.old-value-container').removeClass('has-value')
                        }
                    }
                })
            })
        }
        window.calloffEditCommon.initCalloffEditSelects = initCalloffEditSelects
        initCalloffEditSelects()


        // Logic for quantity input
        callOffEditContainer.on('blur', '.calloff-edit-quantity-input', function() {
            const { warningLimit, unit, originalValue } = $(this).data()
            const quantityInputValue = parseInt($(this).val())
            const hourlyInput = $('.calloff-edit-hourly-quantity-input')
            const oldValueFormatted = `${Number(originalValue).toLocaleString('de-DE')} ${unit}`
            const oldHourlyValueFormatted = `${Number(hourlyInput.data('originalValue')).toLocaleString('de-DE')} ${hourlyInput.data('unit')}`
            const currentHourlyQuantity = parseInt(hourlyInput.data('valueToSend'))

            $(this).removeClass('empty-value')

            if (quantityInputValue && quantityInputValue >= 1) {

                // if selected value is lower than current hourly value, clear hourly value and show the red container
                if (currentHourlyQuantity > quantityInputValue) {
                    hourlyInput.data('valueToSend', '')
                    hourlyInput[0].placeholder = hourlyInput.data('unit')

                    if (!hourlyInput.hasClass('no-old-values')) {
                        hourlyInput.addClass('value-changed')
                    }
                    
                    hourlyInput.siblings('.old-value-container').text(oldHourlyValueFormatted)
                    hourlyInput.siblings('.old-value-container').addClass('has-value')
                } else {
                    hourlyInput.siblings('.old-value-container').removeClass('has-value')
                }

                // set the new placeholder and empty the value in order to show it
                let formattedValue = quantityInputValue.toLocaleString('de-DE')

                $(this).attr('placeholder', `${formattedValue} ${unit}`)
                $(this).data('valueToSend', quantityInputValue)
                $(this).val('')

                // if the new value is higher than the limit, show warning
                if (quantityInputValue > warningLimit && warningLimit > 0) {
                    $(this).siblings('.quantity-warning').removeClass('hidden')
                } else {
                    $(this).siblings('.quantity-warning').addClass('hidden')
                }

                // if the new value is different than the original, show the red container
                if (!$(this).hasClass('no-old-values')) {
                    if ($(this).attr('placeholder') != oldValueFormatted) {
                        $(this).addClass('value-changed')
                        $(this).siblings('.old-value-container').text(oldValueFormatted)
                        $(this).siblings('.old-value-container').addClass('has-value')

                    } else {
                        $(this).removeClass('value-changed')
                        $(this).siblings('.old-value-container').removeClass('has-value')
                    }
                }

            } else {
                $(this).val('')
            }
        })


        // Logic for hourly quantity input
        callOffEditContainer.on('blur', '.calloff-edit-hourly-quantity-input', function() {
            const { unit, originalValue } = $(this).data()
            const currentQuantity = $('.calloff-edit-quantity-input').data('valueToSend')
            const hourlyAmountInputValue = parseInt($(this).val())
            const oldValueFormatted = `${Number(originalValue).toLocaleString('de-DE')} ${unit}`
            let validateHourly = true

            if (currentQuantity && (currentQuantity < hourlyAmountInputValue)) {
                validateHourly = false
            }

            // set the new placeholder and empty the value in order to show it
            if (hourlyAmountInputValue && hourlyAmountInputValue >= 1 && validateHourly) {
                let formattedValue = hourlyAmountInputValue.toLocaleString('de-DE')

                $(this).attr('placeholder', `${formattedValue} ${unit}`)
                $(this).data('valueToSend', hourlyAmountInputValue)
                $(this).val('')

            } else {
                $(this).val('')
            }

            // if the new value is different than the original, show the red container
            if (!$(this).hasClass('no-old-values')) {
                if ($(this).attr('placeholder') != oldValueFormatted) {
                    $(this).addClass('value-changed')
                    $(this).siblings('.old-value-container').text(oldValueFormatted)
                    $(this).siblings('.old-value-container').addClass('has-value')

                } else if (validateHourly) {
                    $(this).removeClass('value-changed')
                    $(this).siblings('.old-value-container').removeClass('has-value')
                }
            }
        })


        // Logic for pressing Enter and forbidding , and . keys on inputs
        callOffEditContainer.on('keydown', '.calloff-edit-quantity-input, .calloff-edit-hourly-quantity-input', function(event) {
            if (event.keyCode == 13 || event.key == 'Enter') {
                $(this).trigger('blur')
            }
            if (event.keyCode == 188 || event.keyCode == 190 || event.keyCode == 108 || event.key == '.' || event.key == ',') {
                event.preventDefault()
                event.stopPropagation()
            }
        })


        // Logic for initializing datepickers for date_start and date_end
        function initCalloffEditDatepickers() {
            if ($('.calloff-edit-date-input').exists()) {
                $('.calloff-edit-date-input').each(function() {
                    let isDateEnd = $(this).hasClass('calloff-edit-date-end-input')
                    let { locale, preselect } = $(this).data()
                    let minDate = "today"
                    let enabledDates = []

                    if (isDateEnd) {
                        const currentlySelectedStartDate = flatpickr.parseDate($('.calloff-edit-date-start-input').data('preselect'), 'd.m.y')
                        const dayAfter = typeof currentlySelectedStartDate !== 'undefined' ? currentlySelectedStartDate.fp_incr(1) : ''
                        const nightShift = $('.calloff-edit-night-shift-input').prop('checked') == true
                        const statusBinding = $('.calloff-edit-table').data('status') != '0'

                        if (statusBinding && nightShift) {
                            enabledDates = [currentlySelectedStartDate, dayAfter]
                        } else if (statusBinding && !nightShift) {
                            enabledDates = [currentlySelectedStartDate]
                        }
                    }

                    let options = {
                        dateFormat: 'd.m.y',
                        locale: locale,
                        minDate: minDate,
                        defaultDate: preselect,
                        enable: enabledDates,
                        position: "auto center",
                        disableMobile: true,
                        onChange: () => {
                            $('.flatpickr-input').removeClass('is-focused')
                        }
                    }
                    flatpickr($(this), options)
                    $(this)[0]._flatpickr.calendarContainer.classList.add('edit-calloff-date')
                })
            }
        }
        window.calloffEditCommon.initCalloffEditDatepickers = initCalloffEditDatepickers
        initCalloffEditDatepickers()


        // Logic for initializing timepickers for time_start and time_end
        function initCalloffEditTimepickers() {
            if ($('.calloff-edit-time-input').exists()) {
                $('.calloff-edit-time-input').each(function() {
                    const timePicker = $(this)
                    let isTimeEnd = timePicker.hasClass('calloff-edit-time-end-input')
                    let { locale, preselect } = timePicker.data()

                    const dateStartDatepicker = $('.calloff-edit-date-start-input')
                    const dateEndDatepicker = $('.calloff-edit-date-end-input')
                    const currentDate = moment(dateStartDatepicker.val(), 'DD.MM.YY').format('DD-MM-YYYY')
                    const endDate = moment(dateEndDatepicker.val(), 'DD.MM.YY').format('DD-MM-YYYY')

                    let isSameDay = currentDate == endDate

                    let options = {
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        minDate: (isSameDay && isTimeEnd) ? $('.calloff-edit-time-start-input').data('preselect') : '',
                        defaultDate: preselect != '00:00' ? preselect : '',
                        defaultHour: isTimeEnd ? '15' : '7',
                        locale: locale,
                        position: "auto center",
                        disableMobile: true,
                        onClose: function(selectedDates, dateStr, instance) {
                            const thisTimepicker = $(this.element)

                            // timepickers data
                            const timeStartDatepicker = $('.calloff-edit-time-start-input')
                            const timeEndDatepicker = $('.calloff-edit-time-end-input')
                            const startTime = moment(timeStartDatepicker.val(), 'HH:mm').format('HH:mm')
                            const endTime = moment(timeEndDatepicker.val(), 'HH:mm').format('HH:mm')

                            // datepickers data
                            const dateStartDatepicker = $('.calloff-edit-date-start-input')
                            const dateEndDatepicker = $('.calloff-edit-date-end-input')
                            const currentDate = moment(dateStartDatepicker.val(), 'DD.MM.YY').format('DD-MM-YYYY')
                            const endDate = moment(dateEndDatepicker.val(), 'DD.MM.YY').format('DD-MM-YYYY')

                            // on closing make sure to set that selected time as value of the input
                            // added for the case when you click outside after manually typing in the time value
                            if (thisTimepicker.val()) {
                                instance.setDate(`${instance.hourElement.value}:${instance.minuteElement.value}`)
                                thisTimepicker.trigger('change')
                            }

                            // additional logic for turning back on the setting of end-datepicker value if necessary
                            // it was turned off in 'changing inputs dependable on delivery start time' event bugfix
                            if (currentDate == endDate && currentDate != 'Invalid date' && !isTimeEnd && startTime > endTime) {
                                timeEndDatepicker[0]._flatpickr.setDate(startTime)
                            }
                        }
                    }
                    flatpickr(timePicker, options)
                    $(this)[0]._flatpickr.calendarContainer.classList.add('edit-calloff-date')
                })
            }
        }
        window.calloffEditCommon.initCalloffEditTimepickers = initCalloffEditTimepickers
        initCalloffEditTimepickers()


        // Logic for changing inputs dependable on delivery start date,
        callOffEditContainer.on('change', '.calloff-edit-date-start-input', function() {

            // datepickers data
            const dateEndDatepicker = $('.calloff-edit-date-end-input')
            const currentDate = moment($(this).val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')
            const endDate = moment(dateEndDatepicker.val().split(', ')[1], 'DD.MM.YY').format('DD-MM-YYYY')

            // timepickers data
            const timeStartDatepicker = $('.calloff-edit-time-start-input')
            const timeEndDatepicker = $('.calloff-edit-time-end-input')
            const currentTime = moment(timeStartDatepicker.val(), 'HH:mm').format('HH:mm')
            const endTime = moment(timeEndDatepicker.val(), 'HH:mm').format('HH:mm')

            const currentDateFormatted = flatpickr.parseDate($(this).val(), 'd.m.y')
            const increasedDate = typeof currentDateFormatted !== 'undefined' ? currentDateFormatted.fp_incr(1) : ''
            const nightShift = $('.calloff-edit-night-shift-input').prop('checked') == true
            const statusBinding = $('.calloff-edit-table').data('status') != '0'

            dateEndDatepicker[0]._flatpickr.set('minDate', $(this).val())
            dateEndDatepicker[0]._flatpickr.set('enable', [])

            if (statusBinding && nightShift) {
                dateEndDatepicker[0]._flatpickr.setDate(increasedDate)
                dateEndDatepicker[0]._flatpickr.set('enable', [currentDateFormatted, increasedDate])

                timeEndDatepicker[0]._flatpickr.set('minDate', '')

            } else if (statusBinding && !nightShift) {
                dateEndDatepicker[0]._flatpickr.setDate(currentDateFormatted)
                dateEndDatepicker[0]._flatpickr.set('enable', [currentDateFormatted])

                timeEndDatepicker[0]._flatpickr.setDate(timeStartDatepicker.val())
                timeEndDatepicker[0]._flatpickr.set('minDate', timeStartDatepicker.val())

            } else {
                if (currentDate >= endDate) {
                    dateEndDatepicker[0]._flatpickr.setDate($(this).val())
                    timeEndDatepicker[0]._flatpickr.set('minDate', timeStartDatepicker.val())

                    if (currentTime > endTime) {
                        timeEndDatepicker[0]._flatpickr.setDate(timeStartDatepicker.val())
                        timeEndDatepicker[0]._flatpickr.set('minDate', timeStartDatepicker.val())
                    }
                } else  {
                    timeEndDatepicker[0]._flatpickr.set('minDate', '')
                }
            }
        })


        // Logic for changing inputs dependable on delivery end date
        callOffEditContainer.on('change', '.calloff-edit-date-end-input', function() {

            // datepickers data
            const dateStartDatepicker = $('.calloff-edit-date-start-input')
            const endDate = moment($(this).val(), 'DD.MM.YY').format('DD-MM-YYYY')
            const startDate = moment(dateStartDatepicker.val(), 'DD.MM.YY').format('DD-MM-YYYY')

            // timepickers data
            const timeStartDatepicker = $('.calloff-edit-time-start-input')
            const timeEndDatepicker = $('.calloff-edit-time-end-input')
            const currentTime = moment(timeStartDatepicker.val(), 'HH:mm').format('HH:mm')
            const endTime = moment(timeEndDatepicker.val(), 'HH:mm').format('HH:mm')

            if (startDate != endDate) {
                timeEndDatepicker[0]._flatpickr.set('minDate', '')

            } else {
                timeEndDatepicker[0]._flatpickr.set('minDate', timeStartDatepicker.val())

                if (currentTime > endTime) {
                    timeEndDatepicker[0]._flatpickr.setDate(timeStartDatepicker.val(), true)
                }
            }
        })


        // Logic for changing inputs dependable on delivery start time
        callOffEditContainer.on('change', '.calloff-edit-time-start-input', function() {

            // timepickers data
            const timeEndDatepicker = $('.calloff-edit-time-end-input')
            const currentTime = moment($(this).val(), 'HH:mm').format('HH:mm')
            const endTime = moment(timeEndDatepicker.val(), 'HH:mm').format('HH:mm')

            // datepickers data
            const dateStartDatepicker = $('.calloff-edit-date-start-input')
            const dateEndDatepicker = $('.calloff-edit-date-end-input')
            const currentDate = moment(dateStartDatepicker.val(), 'DD.MM.YY').format('DD-MM-YYYY')
            const endDate = moment(dateEndDatepicker.val(), 'DD.MM.YY').format('DD-MM-YYYY')

            // additional logic for checking if the end-timepicker needs to actually be changed
            // necessary only because of the bug with the start-timepicker when it took the current real time as selected when it was opened
            // prevents changing end-timepicker to current time immediately after the start-timepicker is opened
            const timeStartTimepickerValue = $(this)[0]._flatpickr.selectedDates[0] ? $(this)[0]._flatpickr.selectedDates[0].toString() : ''
            const currentRealTime = moment()._d.toString()

            if (currentDate == endDate && timeStartTimepickerValue != currentRealTime) {
                timeEndDatepicker[0]._flatpickr.set('minDate', $(this).val())

                if (currentTime > endTime) {
                    timeEndDatepicker[0]._flatpickr.setDate($(this).val(), true)
                }
            }
        })


        // Logic for changing inputs dependable on night_shift checkbox and status select
        callOffEditContainer.on('change', '.calloff-edit-night-shift-input, .status-select', function() {
            if ($(this).attr('type') == 'checkbox') {
                $(this).next().toggleClass('nightshift-selected')
            }

            const dateStartDatepicker = $('.calloff-edit-date-start-input')
            const dateEndDatepicker = $('.calloff-edit-date-end-input')
            const timeStartDatepicker = $('.calloff-edit-time-start-input')
            const timeEndDatepicker = $('.calloff-edit-time-end-input')

            const dateStart = flatpickr.parseDate(dateStartDatepicker.val(), 'd.m.y')
            const increasedDate = typeof dateStart !== 'undefined' ? dateStart.fp_incr(1) : ''
            const nightShift = $(this).prop('checked') == true

            // used setTimeout to easily make sure this logic happens after the 'select2:select' event for status
            setTimeout(() => {
                const statusBinding = $('.calloff-edit-table').data('status') != '0'

                dateEndDatepicker[0]._flatpickr.set('enable', [])

                if (statusBinding && nightShift) {
                    dateEndDatepicker[0]._flatpickr.setDate(increasedDate)
                    dateEndDatepicker[0]._flatpickr.set('enable', [dateStart, increasedDate])
                    timeEndDatepicker[0]._flatpickr.set('minDate', '')

                } else if (statusBinding && !nightShift) {
                    dateEndDatepicker[0]._flatpickr.setDate(dateStart, true)
                    dateEndDatepicker[0]._flatpickr.set('enable', [dateStart])

                } else if (!statusBinding && $(this).hasClass('calloff-status-planning-input')) {
                    timeStartDatepicker[0]._flatpickr.setDate('', true)
                    timeEndDatepicker[0]._flatpickr.setDate('', true)
                }
            })
        })


        // Remove red border from textarea when users types something
        callOffEditContainer.on('keyup', '.calloff-chat-message', function(){
            if($(this).val().length > 0){
                $(this).removeClass('error');
            }
        })


        // Logic for typing indicator for chat message
        let typingTimer
        let doneTypingInterval = 10
        let finaldoneTypingInterval = 500

        callOffEditContainer.on('keydown', '.calloff-chat-message', function(){
            clearTimeout(typingTimer)

            typingTimer = setTimeout(function() {
                $('.typing-message').html($('.typing-message').data('translation'))
            }, doneTypingInterval)
        })

        callOffEditContainer.on('keyup', '.calloff-chat-message', function(){
            clearTimeout(typingTimer)

            typingTimer = setTimeout(function() {
                $('.typing-message').html('')
            }, finaldoneTypingInterval)
        })


        /**
         * Material popup logic
         */

        // Logic for closing the material popup
        materialPopup.on('click', '.close-button', () => {
            $('.highway-calloff-edit-material-popup-container').addClass('hidden')
            $('html').css('overflow-y', 'auto')
        })


        // Logic for changing material categories
        materialPopup.on('click', '.category-select', async function() {
            let params = {}

            if (!$(this).hasClass('active')) {

                $('.category-select').removeClass('active')
                $(this).addClass('active')

                // had to convert to string because of the case value=0
                params = {
                    ...params,
                    'material_category': $(this).data('value').toString()
                }

                // get all filters and remove their selections
                $('.material-filter').each(function() {
                    params = {
                        ...params,
                        [$(this).data('filterName')]: ''
                    }
                })

            } else {
                $(this).removeClass('active')
                params = {
                    ...params,
                    'material_category': ''
                }
            }

            try {
                materialPopup.addClass('is-requesting')
                await updateMaterialPopup(params)

            } catch {
                window.showErrorPopup()
            }

            materialPopup.removeClass('is-requesting')
        })


        // Logic for initializing select2 filters on material popup
        function initMaterialTableFilters() {
            if ($('.material-popup-data-filters').exists()) {
                $('.material-filter').each(function() {
                    const { filterName, placeholder, selection, noResults, hasSearch } = $(this).data()

                    $(this).select2({
                        minimumResultsForSearch: hasSearch ? 1 : -1,
                        placeholder: placeholder.toString(),
                        dropdownCssClass: 'material-table-filter-dropdown',
                        language: {
                            noResults: () => noResults
                        },
                        templateSelection: (data, container) => styleSelection(data, container, selection, filterName),
                        escapeMarkup: markup => markup

                    }).on('select2:select', async function(e) {
                        let filterText = e.params.data.text
                        let filterValue = e.params.data.id
                        let params = {
                            [filterName]: $(this).val()
                        }

                        // if its already selected remove it instead
                        if (selection == filterText.toString().trim() || selection == filterValue) {
                            params = {
                                [filterName]: ''
                            }
                        }

                        try {
                            materialPopup.addClass('is-requesting')
                            await updateMaterialPopup(params)

                        } catch {
                            window.showErrorPopup()
                        }

                        materialPopup.removeClass('is-requesting')
                    })
                })
            }
        }
        initMaterialTableFilters()


        // Logic for removing a material table active filter
        materialPopup.on('click', '.active-filter', async function() {
            $(this).closest('.material-filter-container').find('select').select2('close')

            let params = {
                [$(this).data('filterName')]: ''
            }

            try {
                materialPopup.addClass('is-requesting')
                await updateMaterialPopup(params)

            } catch {
                window.showErrorPopup()
            }

            materialPopup.removeClass('is-requesting')
        })

        // Clear all filters (Not category ones)
        materialPopup.on('click', '.material-popup-filter-container .remove-all-filters', async function() {
            let params = {}
            const filters = materialPopup.find('select.material-filter')
            filters.each(function() {
                const $filter = $(this)
                const { filterName } = $filter.data()

                params[filterName] = ''
            })

            try {
                materialPopup.addClass('is-requesting')
                await updateMaterialPopup(params)

            } catch {
                window.showErrorPopup()
            }

            materialPopup.removeClass('is-requesting')
        })

        // Logic for table sorters
        materialPopup.on('click', '.table-sort', async function() {
            const { sortOrder, sortKey } = $(this).data()

            let params = {
                'order': sortOrder,
                'order_by': sortKey
            }

            try {
                materialPopup.addClass('is-requesting')
                await updateMaterialPopup(params)

            } catch {
                window.showErrorPopup()
            }

            materialPopup.removeClass('is-requesting')
        })


        // Helper function for replacing material popup html when using filters
        async function updateMaterialPopup(urlParams = {}) {
            // redefined again to take the latest url value when running this function
            let url = window.location.href

            // additional parameter used on calloff creation popup to have the selected row after requests
            const selectedContractItemId = $('.calloff-edit-table').data('selectedContractItemId')
            if (selectedContractItemId) {
                urlParams = {
                    ...urlParams,
                    'contract_item_id': selectedContractItemId
                }
            }

            Object.keys(urlParams).forEach(function(key) {
                url = window.updateUrlParameter(url, key, urlParams[key])
            })

            if (typeof window.history.pushState != 'undefined') {
                window.history.pushState(null, null, url)
            }

            try {
                const response = await axios.get(url, window.axiosConfig)
                const { data } = response

                const materialPopupContent = $(data).find('.material-popup').html() || ''

                $('.material-popup').html(materialPopupContent)

                // additional logic used on calloff creation popup to have the selected row after response
                const selectedContractItemId = window.getUrlParams(url)['contract_item_id']
                if (selectedContractItemId) {

                    // set the class 'active' on the selected contract item
                    materialPopup.find('.material-popup-table tbody tr').each(function() {
                        if ($(this).data('contractItemId') == selectedContractItemId) {
                            $(this).addClass('active')
                        }
                    })
                }

                initMaterialTableFilters()
                initMaterialTableTooltips()

            } catch {
                window.showErrorPopup()
            }
        }


        // Helper function for styling the selections in filters
        function styleSelection(data, container, selection, filterName) {
            let markup = ''
            let { id, text } = data

            if (selection != '') {
                markup = `<div class="active-filter" data-filter-name="${filterName}">
                            <span class="active-filter-text">${text}</span>
                            <i class="material-icons">clear</i>
                        </div>`
            } else {
                markup = `${text}`
            }

            return markup
        }


        // Logic for initializing tooltips
        const phoneTooltip = $('.has-today-delivery').data('tooltip')
        tippy('.has-today-delivery', {
            content: phoneTooltip,
            maxWidth: 'max-content'
        })

        const clockTooltip = $('.has-today-calloff').data('tooltip')
        tippy('.has-today-calloff', {
            content: clockTooltip,
            maxWidth: 'max-content'
        })

        const headerTippies = $('.header-table-container .conditional-tippy')
        headerTippies.each(function() {
            const translation = $(this).find('.has-tippy').exists() ? $(this).find('.has-tippy').data('translation') : ''
            const tooltip = $(this).find('.has-tippy').exists() ? $(this).find('.has-tippy').data('tooltip') : ''
            const hasAdditionalTooltipData = tooltip != ''

            if ($(this)[0].scrollWidth > $(this)[0].clientWidth) {
                $(this).css('cursor', 'pointer')
                $(this).addClass('has-dashed-underline')

                tippy($(this)[0], {
                    content: hasAdditionalTooltipData ? `${$(this).text()} <br/> ${translation}: ${tooltip}` : `${$(this).text()}`,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            } else if (hasAdditionalTooltipData) {
                $(this).css('cursor', 'pointer')

                tippy($(this)[0], {
                    content: `${translation}: ${tooltip}`,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            }
        })

        const quantityWarningTooltip = $('.quantity-warning').data('tooltip')
        tippy('.quantity-warning', {
            content: quantityWarningTooltip,
            placement: 'bottom',
            theme: 'cockpit-tippy'
        })

        // tooltips inside material table cells
        function initMaterialTableTooltips() {
            $('.material-name .has-tippy').each(function() {
                const { materialDescription, materialDescriptionMore, number, ep, avvCode, avv } = $(this).data()

                const tippyContent = `${materialDescription}
                                        ${materialDescriptionMore ? '<br>' + materialDescriptionMore : ''}
                                        ${number ? '<br>' + ep + ' ' + number : ''}
                                        ${avvCode ? '<br>' + avv + ' ' + avvCode : ''}
                                    `
                tippy(this, {
                    content: tippyContent,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('.factory .has-tippy').each(function() {
                const { factoryName, factoryStreet, factoryStreetNumber, factoryZip, factoryCity } = $(this).data()
                const tippyContent = `${factoryName}
                                        ${factoryStreet ? '<br>' + factoryStreet + ' ' + factoryStreetNumber : ''}
                                        ${factoryCity ? '<br>' + factoryZip + ' ' + factoryCity : ''}
                                    `
                tippy(this, {
                    content: tippyContent,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })

            $('.add-to-cart .has-tippy').each(function() {
                const addToCartTooltip = $(this).data('tooltip')
                tippy(this, {
                    content: addToCartTooltip,
                    placement: 'bottom',
                    theme: 'cockpit-tippy'
                })
            })
        }
        initMaterialTableTooltips()
    }
})