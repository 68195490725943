$(document).ready(function() { 
    if($('.web-headline-bar-container').length > 0) {
        let headlineBarOuterContainer = $('.web-headline-bar-container')
        let headlineBarInnerContainer = $('.web-headline-bar-container .inner-container')
        let headlineBar = $('.web-headline-bar-container .web-headline-bar')
        var headlineBarInitTopPosition = 99999
        let header = $('header')
        
        let headlineElement = $('.web-headline-container .web-headline')
        
        let pimcoreAreaContent = $('.area-brick-container > .pimcore_area_content')
        
        $(document).on('click', '.web-headline-bar-container .web-headline-bar li', function() {
            const scrollToId = $(this).attr('data-scroll-to')
            const scrollToElement = $('.web-headline-container .web-headline[data-scroll-to=' + scrollToId + ']')
            let scrollToPosition = 0
            
            if(!($(this).hasClass('view') && headlineBarOuterContainer.hasClass('fixed'))) {
                scrollToPosition = scrollToElement.offset().top - 137
            }
            
            $('html, body').animate({ 
                scrollTop: scrollToPosition
            }, 600);
        })
        
        function initWebHeadlineBar() {
            if(!($('body').hasClass('edit-mode')) && headlineElement.length > 1) {
                $('body').addClass('show-headline-bar')
                
                headlineElement.each(function(index) {
                    const thisText = $(this).text()

                    if(thisText) {
                        $(this).attr('data-scroll-to', index)
                        
                        $('<li data-scroll-to="' + index + '">' + thisText + '</li>').appendTo(headlineBar)
                    } else {
                        $(this).remove()
                    }
                })
                
                if($('.area-brick-container .product-detail').length > 0) {
                    let productDetailHeader = $('.area-brick-container .product-detail .web-slider-container')
                    
                    if(productDetailHeader.length > 0) {
                        headlineBarOuterContainer.insertAfter(productDetailHeader)
                    } else {
                        $('.area-brick-container .product-detail').prepend(headlineBarOuterContainer)
                    }
                    
                } else {
                    let firstAreaBrickOnPage = $('.area-brick-container > .pimcore_area_content:first-child')
                    let headlineIndex = 0
                    
                    if(firstAreaBrickOnPage.hasClass('pimcore_area_web-slider')) {
                        headlineBarOuterContainer.insertAfter(firstAreaBrickOnPage)
                    } else {
                        headlineBarOuterContainer.insertBefore(firstAreaBrickOnPage)
                    }
 
                    pimcoreAreaContent.each(function() {
                        if($(this).hasClass('pimcore_area_web-headline')) {
                            $(this).attr('data-scroll-index', headlineIndex)
        
                            headlineIndex++
                        }
                    })
                    
                    let loopHeadlineIndex = 0
                    let firstHeadlineDetected = false
                    
                    pimcoreAreaContent.each(function() {
                        if($(this).attr('data-scroll-index') > -1) {
                            firstHeadlineDetected = true
                            loopHeadlineIndex = $(this).attr('data-scroll-index')
                        } else if(firstHeadlineDetected) {
                            $(this).attr('data-scroll-index', loopHeadlineIndex)
                        }
                    })
                }
                
                headlineBarOuterContainer.addClass('visible').addClass('size-' + headlineElement.length).css('height', headlineBarInnerContainer.height() + 'px')
                headlineBarInitTopPosition = headlineBar.offset().top
            } else {
                headlineBarOuterContainer.remove()
            }
        }
        
        function styleHeadlineBarPosition() {
            const windowTop = $(window).scrollTop()
 
            if($('.area-brick-container .product-detail').length > 0) {
                let headlineElementActive = false

                headlineElement.each(function(index) {
                    let thisContainer = $(this).closest('.web-headline-container')
                    
                    if(thisContainer.isInViewport() && !headlineElementActive) {
                        $('.web-headline-bar-container .web-headline-bar li').removeClass('active')
                        $('.web-headline-bar-container .web-headline-bar li[data-scroll-to=' + index + ']').addClass('active')
                        
                        headlineElementActive = true
                    }
                })
            } else {
                let headlineElementActive = false
                
                pimcoreAreaContent.each(function() {
                    if($(this).attr('data-scroll-index') > -1 && $(this).isInViewport() && !headlineElementActive) {
                        $('.web-headline-bar-container .web-headline-bar li').removeClass('active')
                        $('.web-headline-bar-container .web-headline-bar li[data-scroll-to=' + $(this).attr('data-scroll-index') + ']').addClass('active')
      
                        headlineElementActive = true
                    }
                })
            }
            
            if(windowTop + 85 > headlineBarInitTopPosition) {
                headlineBarOuterContainer.addClass('fixed')
                header.addClass('web-headline-bar-fixed')
            } else {
                headlineBarOuterContainer.removeClass('fixed')
                header.removeClass('web-headline-bar-fixed')
                $('.web-headline-bar-container .web-headline-bar li').removeClass('active')
            }
        } 
        
        function adjustHeadlineBarPosition() {
/*
            if($('.product-detail > .product-header').length > 0) {
                headlineBarInitTopPosition = $('.product-detail > .product-header').offset().top + $('.product-detail > .product-header').outerHeight()
            } else {
                headlineBarInitTopPosition = $('.area-brick-container > .pimcore_area_web-slider:first-child').offset().top + $('.area-brick-container > .pimcore_area_web-slider:first-child').outerHeight()
            }
*/
        } 
        
        $.fn.isInViewport = function() {
            let elementTop = $(this).offset().top - 175
            let elementBottom = elementTop + $(this).outerHeight()
        
            let viewportTop = $(window).scrollTop()
            let viewportBottom = viewportTop + $(window).height()
        
            return headlineBarOuterContainer.hasClass('fixed') && elementBottom > viewportTop && elementTop < viewportBottom
        }

        initWebHeadlineBar()
        styleHeadlineBarPosition()
        
        $(window).scroll(function() {
            styleHeadlineBarPosition()
        })
        
        $(window).resize(function() {
            adjustHeadlineBarPosition()
            styleHeadlineBarPosition()
        })
    }
})