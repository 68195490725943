import { initLetterPagination } from 'Pagination/letter-pagination'
import { fetchAndShowAnnouncement } from '../../../../../AppBundle/Resources/public/js/announcement/announcement'

$(document).ready(function() {
    const tableContainer = $('#companies-list-container')
    
    if ($('#bag-dashboard').exists()) {
        function initTippyFavorite() {
            tippy('.star-on', {
                content: window.bagFavoritesOn
            })
            tippy('.star-off', {
                content: window.bagFavoritesOff
            })
        }
        initTippyFavorite()


        // Logic for fetching the announcement data and showing it if necessary
        fetchAndShowAnnouncement()
	}


	if ($('#bag-user-dashboard').exists()) {
		let searchState = false
		const searchLoader = $('.users-page-search-container #search-loader').html()
		const queryNaming = {
			invitation_id: $('.users-search').data('invitationId'),
			company_number: $('.users-search').data('companyNumber'),
            account_email: $('.users-search').data('accountEmail'),
            account_owner: $('.users-search').data('accountOwner')
		}
        let url = decodeURIComponent(window.location.href)
        

        // Initialize letter pagination and logic for infinite scroll
        if ($('.letter-pagination-container').exists()) {
            initLetterPagination(tableContainer)

            let currentPage = 1
            let isRequesting = false

            $(window).on('scroll', async function() {
                const { scrollTop, scrollHeight, clientHeight } = document.documentElement
                const scrollPercent = scrollTop / (scrollHeight - clientHeight)
                const { pageSize, totalPages } = $('.letter-pagination-container').data()

                if (scrollPercent > 0.66 && !isRequesting && currentPage < totalPages) {
                    currentPage++
                    isRequesting = true
                    $('#bag-user-dashboard').addClass('scroll-is-requesting')

                    try {
                        url = window.updateUrlParameter(url, 'page_size', pageSize)
                        url = window.updateUrlParameter(url, 'page', currentPage)

                        const response = await axios.get(url)
                        let data = $(response.data).find('#companies-list-container .card-dashboard__company')

                        console.log(data)

                        // to each item appended through infinite scroll, append toggle button
                        data.each(function() {
                            $(this).find('label').append('<span class="toggle"></span>')

                            if ($(this).find('input').data('status') != 'active') {
                                $(this).find('input').prop('checked', true)
                            }
                        })

                        $('#bag-user-dashboard #companies-list-container').append(data)
                        isRequesting = false
                        $('#bag-user-dashboard').removeClass('scroll-is-requesting')
                    } catch {
                        isRequesting = false
                        $('#bag-user-dashboard').removeClass('scroll-is-requesting')
                        window.showErrorPopup()
                    }
                }
            })
        }
        

        if (window.location.pathname != '/dashboard/user-management-companies') {
            initHeaderSearch()
        }

		function initHeaderSearch() {
			const headerSearch = $('body .users-search')
            let {filter, method, messagePlaceholder, messageApi_route, pathParams} = headerSearch.data()
            if (method == 'GET'){
                let keys =  Object.keys(pathParams)
                if (keys.length){
                    keys.forEach(key => {
                        if (pathParams[key]!==''){
                            messageApi_route = window.updateUrlParameter(messageApi_route, key, pathParams[key])
                        }
                    })
                    
                }
            }
			let searchQuery = filter
            searchState = false
            if (headerSearch.length > 0) {
                headerSearch.select2({
                    minimumResultsForSearch: -1,
                    dataType: 'json',
                    width: '',
                    multiple: true,
                    minimumInputLength: 1,
                    containerCssClass: 'header-search-select2',
                    placeholder: messagePlaceholder,
                    dropdownParent: $('.search-bar'),
                    ajax: {
                        method: method,
                        url: messageApi_route,
                        dataType: 'json',

                        data: (params) => {
                            if (method == 'GET'){
                                return {
                                    term: $.trim(params.term),
                                    page: params.page || 1,
                                }
                            } else {
                                
                                return  {
                                    term: $.trim(params.term),
                                    page: params.page || 1,
                                    ... pathParams
                                }
                            }
                            
                        },
                        processResults: (data, { term }) => {
                            if (method == 'GET'){
                                if (Array.isArray(data)){
                                    return {
                                        results: data.map((item)=> ({
                                            ...item,
                                            id: item.value
                                        })    
                                        ),  
                                    }
                                } else {
                                    return {
                                        results: data.items.map((item)=> ({
                                            ...item,
                                            id: item.value
        
                                        })   
                                        ),
                                        pagination: {
                                            more: data.pagination.has_next_page
                                        }
                                    }
                                }
                            } else {
                                if (Array.isArray(data)){
                                    return {
                                        results: data.map((item)=> ({
                                            ...item,
                                            id: item.name
                                        })    
                                        ),  
                                    }
                                } else {
                                    return {
                                        results: data.items.map((item)=> ({
                                            ...item,
                                            id: item.name
                                        })    
                                        ),  
                                    }
                                }
                                
                            }  
                        },
                    },
                    language: {
                        inputTooShort: () => headerSearch.data('message-add_more_text'),
                        noResults: () => headerSearch.data('message-no_results')
                    },
                    escapeMarkup: (markup) => markup,
                    templateResult: formatSearchData,
                    templateSelection: formatSelectedSearch,

                }).on('select2:select', function(e) {
                    $('#invitations-dashboard').addClass('is-requesting')

					let path = e.params.data.path ? e.params.data.path : null
                    if (!path){
                        let selectionKey = e.params.data.filter_key
                        let selectionValue = e.params.data.value
                        url = window.updateUrlParameter(url, `filter[${selectionKey}]`, selectionValue)
                    } else {
                        url = path
					}
					window.location.href = url
                    
                }).on('select2:unselecting', function(e) {
                    e.preventDefault()
                }).on('select2:opening', function(e){
                    if(searchState){
                        e.preventDefault();

                    }
                }).on('select2:open', function () {
                    setTimeout(() => {
                        $('#bag-user-dashboard .page-content:not(.highway-companies-page-content)').addClass('zIndexHack')
                    })
                }).on('select2:close', function () {
                    $('#bag-user-dashboard .page-content:not(.highway-companies-page-content)').removeClass('zIndexHack')
                })
                const searchInput = $('.header-search-select2 .select2-search__field')
          
                /**
                 * Set onload preselected value
                 */
                if (Object.keys(searchQuery).length){
                    // param that disables the tips functionality in case of a search query
                    window.disableStartupAutoplay = true
                    displaySearchQuery(searchQuery)
                    
                } else {
                    headerSearch.find('option').remove().trigger('change')
                }

            
                async function displaySearchQuery(queryObject){
                    let queryKeys = Object.keys(queryObject)
                    if (queryKeys.length && !searchState){
                        searchInput.addClass('hidden')
                        searchState = true
                        let query, queryName
                        let values = []
                        queryKeys.forEach(function(key) {
                            queryName = key
                            query = queryObject[key]
                            if (query){
                                let text = `${queryNaming[queryName]}: ${query}`
                                const option = new Option(text, query, true, true)
                                headerSearch.append(option).trigger('change')
                                values.push(query)
                                setTimeout(() => {
                                    const markup = `<span>${text}</span>`
                                    let selection = $(`.select2-selection__choice[title='${text}']`)
                                    selection.append(markup)
                                    selection.attr('data-filter', queryName )
                                
                                }, 1)
            
                            }
                        })
                    }
                }

                // Show icon after select2 init
                $('.search-bar .search-icon').removeClass('hidden')

            }
    
            function formatSearchData (data, item) {
                let markup = ''
                // Loading
                if (data.loading) {
                    markup = searchLoader
                } else {

                    // Option item
                    let { name, path, header, value, filter_key } = data
                    
                    let markupName = name ? name : value
					let markupHeader = header ? header :  queryNaming[filter_key]
					
                    if (!markupName){
                        $(item).addClass('hidden')
                    }
                    markup = `
                        <a class="search-result-link" href="javascript:;">
                            ${markupHeader? markupHeader.concat(':') : ''} ${markupName}
                        </a>
                    `
				}
				return markup
            }

            function formatSelectedSearch (data, item) {
                const { name } = data
                return name
            }
        }

        $('.users-page-search-container').on('click', '.select2-selection__choice', function() {
            const filterName = $(this).data('filter')
            url = window.updateUrlParameter(url, `filter[${filterName}]`, '')

            $('#invitations-dashboard').addClass('is-requesting')
            window.location.href = url
        })
	}
})