$(document).ready(function () {
    const dropdownArrow = $('.dropdown-arrow-svg').html()
    let url = window.location.href


    if($('#customer-users-page').length) {
        // Company list route
        let companyListRoute = window.location.href

        // Open popup for customer user
        const customerUsersPage = $('#customer-users-page')
        // const openRoleChangeButton = $('#customer-users-page .open-add-users-popup')
        const roleChangePopup = $('#customer-users-page .add-users-popup')
        const usersList = $('#customer-users-list')
        /**
         * Filter customer users by company
         */
        const companyFilter = $('#customer-company-filter')
        let companyId = companyFilter.val()
        companyFilter.select2({
            minimumResultsForSearch: -1,
            dropdownParent: $('.dashboard-company-filter')
        }).on('select2:select', function () {
            
            let selectedValue = $(this).val()
            companyId = selectedValue
            let url = {c: selectedValue}
            updateCustomerDashboard(url)
        })


        // Logic for auto opening popup if coming from calloff creation step
        const query = window.location.search
        const urlParams = new URLSearchParams(query)

        if (urlParams.get('open')) {
            window.showLoaderPopup()
            const requestURI = $('.open-add-users-popup').data('url')
            $('.add-users-popup').addClass('hidden')
            
            handleAjaxChange(requestURI)

            companyListRoute = window.updateUrlParameter(companyListRoute, 'open', '')
        }


        // Logic for removing an active filter
        $('.active-filter').on('click', function() {
            let filterKey = $(this).find('.remove-active-filter').data('filterKey')
            usersList.addClass('is-requesting')

            url = window.updateUrlParameter(url, filterKey, '')
            window.location.href = url
        })

        
        //companyFilter.val(5345345).trigger('change')
        customerUsersPage.find('.add-users').on('click', '.open-add-users-popup', function() {
            window.showLoaderPopup()
            const requestURI = $(this).data('url')
            $('.add-users-popup').addClass('hidden')
            // $('.add-users-popup .invite-company__loader').removeClass('hidden')
            // $('.add-users-popup .add-users').addClass('hidden')
            
            handleAjaxChange(requestURI)
        })

        customerUsersPage.on('click','.role-dropdown li', function() {
            const requestURI = $(this).data('url')
            handleAjaxRole(requestURI)
        })

        // Closer
        customerUsersPage.on('click', '.back-to-login, .popup-exit', () => {
            $('.add-users-popup').addClass("animated fadeOut faster")
            setTimeout(function(){
                $('.add-users-popup').addClass("hidden fadeOut").removeClass("animated fadeIn faster")
            }, 500)
        })

        // Check if user is active on page load
        const statusCheck = () => {
            let checkboxes = $('#company-users-table .change-users-status')
            checkboxes.map((i, el) => {
                const tr = $(el).parents('tr')
                if($(el).is(':checked')) {
                    tr.removeClass('disabled-user')
                } else {
                    tr.addClass('disabled-user')
                }
            })
        }
        
        // Active-Inactive user
        $('#customer-users-list').on('click', '#company-users-table .change-users-status', function() {
            if($(this).is(':checked')) {
                $(this).closest('tr').removeClass('disabled-user')
            } else {
                $(this).closest('tr').addClass('disabled-user')
            }
        })

        // Ajax request for change role
        async function handleAjaxChange (requestURI) {
            // Show popup
            roleChangePopup.removeClass("hidden fadeOut").addClass("animated fadeIn faster")

            try {
                // Make request & set inner HTML
                const response = await axios.get(requestURI, window.axiosConfig)
                const { data } = response

                roleChangePopup.html(data)
                window.closeLoaderPopup()
                handleDynamicContent()
            } catch (error) {
                window.showErrorPopup()
                console.error(error)
                throw error
            }
        }

        async function handleAjaxRole (requestURI) {
            try {
                await axios.get(requestURI, window.axiosConfig)

                const response = await axios.get(location.href, window.axiosConfig)

                const customerUserList = $(response.data).find('.customer-users-content')
                $('#customer-users-list').html(customerUserList)
                
                window.addCheckboxInput()
                // window.addToggleInput()
            } catch (error) {
                window.showErrorPopup()
                console.error(error)
                throw error
            }
        }

        /**
         * Function to create checkboxes
         */
        const handleDynamicContent = () => {
            const checkboxFormInput = $('.add-users__inputs .checkbox input')
            checkboxFormInput.after("<span class='checkbox-material'><span class='check'></span></span>")
        }

        // Logic for checkboxes to behave like radio buttons
        customerUsersPage.on('change', '.add-bag-user-check', function() {
            $('.add-bag-user-check').prop('checked', false)
            $(this).prop('checked', true)
        })

        // Form validation
        function validateForm() {
            const $form = $(this).closest('form')
            const validation = window.validateFormFields($form)

            if(validation && validation.isValid) {
                $('#customer-users-page #invite_bag_user__submit').attr('disabled', false)
            } else {
                $('#customer-users-page #invite_bag_user__submit').attr('disabled', true)
            }
        }

        customerUsersPage.on('change keyup', '.form-group input', validateForm)
        /**
         * check invite email domain
         */
        $(document).on('keyup', '#invite_customer_user_email', function() {
           
            let checkUrl = $('.open-add-users-popup').data('emailcheck') 
           
            window.checkDomain.call($(this),checkUrl, companyId)
        
        })
       
     
        // Form submit for adding bag user
        let formContainer = $('#customer-users-page')
        formContainer.on('click', '#invite_customer_user__submit', function(event) {
            event.stopPropagation()
            event.preventDefault()
            const $form = $(this).closest('form')
            const requestURI =  $(this).closest('form').attr('action')
            const validation = window.validateFormFields($form)

            if(validation && validation.isValid) {
                // Create "plain" formData
                // const formData = window.createFromData($form)
                const formData = new FormData($form[0])

                submitBagUserRoleChange(requestURI, formData)
            }
        })

        /**
         * Make submit request via ajax call
         */
       
        async function submitBagUserRoleChange(requestURI, payload) {
                window.showLoaderPopup()
                let submitButton = $('#bag-user-change-role')
                $('.add-users-popup .add-users').addClass('hidden')
               
                await axios.post(requestURI, payload, window.axiosConfig)
                .then((res)=> {
                    window.closeLoaderPopup()
                    
                    swal({
                        className: 'calloff-status-modal', 
                        title: submitButton.data('title'),
                        text:  submitButton.data('content'),
                        icon: 'success',
                        buttons:  {
                            submit: {
                                text: submitButton.data('submit'),
                                closeModal: true,
                            }
                        }
        
                    })
                  
                   
                }).catch(err => {
                    // inviteOwnerPopup.addClass('hidden fadeOut').removeClass("animated fadeIn faster")
                    console.log('custromer users error', err.response);
                    window.closeLoaderPopup()
                    window.showErrorPopup(err.response.data.message)
                    // formSubmitBtn.trigger('toggleState')
                    throw err
                })
                

                // if(data.status == 'success') {
                //     // Popup actions
                  

                //     const successMessage = data.message
                //     const messagePlaceholder = customerUsersPage.find('.success-message')

                //     messagePlaceholder.html(successMessage)

                // } else {
                //     roleChangePopup.html(data)
                //     handleDynamicContent()
                // }
                
        }

        /**
         * Filter customer users by activeness
         */
        const usersFilter = $('.company-filter-select')

        usersFilter.select2({
            minimumResultsForSearch: -1,
            dropdownParent: $('.dashboard-customer-filter')
        }).on('select2:select', function () {
            let selectedValue = $(this).val()
            let url = {status: selectedValue}

            updateCustomerDashboard(url)
        })
        $('.dashboard-customer-filter').find('.select2-selection__arrow b').html(dropdownArrow)

        const userRoleFilter = $('.user-role-select')

        userRoleFilter.select2({
            minimumResultsForSearch: -1,
            dropdownParent: $('.user-role-filter')
        }).on('select2:select', function () {
            let selectedValue = $(this).val()
            let url = {role: selectedValue}

            updateCustomerDashboard(url)
        })
        $('.user-role-filter').find('.select2-selection__arrow b').html(dropdownArrow)

        // ghost user checkbox/switch logic:
        const ghostSwitch = $('.change-ghost-status')

        ghostSwitch.on('change', async function() {
            let status = $(this).is(':checked') ? '1' : '0'
            let { path, queryParams } = $(this).data()
            let url = window.updateUrlParameter(path, 'status', status)

            for (const query in queryParams) {
                url = window.updateUrlParameter(url, query, queryParams[query])
            }

            try {
                await axios.post(url)
                    .then(response => {
                        if (response.data.can_trigger_request == false) {
                            $(this).parent().addClass('disabled')
                            $('.ghost-user-switch-error').removeClass('hidden')
                            
                            // revert the checkbox to previous state
                            $(this).prop('checked', !$(this).prop('checked'))
                        }
                    })
            } catch(error) {
                $(this).parent().addClass('disabled')
                $('.ghost-user-switch-error').removeClass('hidden')
                $(this).prop('checked', !$(this).prop('checked'))
                console.error(error)
            }
        })



        /**
        * Get contracts list
        * @param {Object} url
        */
        async function updateCustomerDashboard(url, removeParams = false) {
            if (!url.isEmpty) {
                Object.keys(url).forEach( function (key) {
                    companyListRoute = window.updateUrlParameter(companyListRoute, key, url[key])
                })
                if (removeParams) {
                    const params = window.getUrlParams(companyListRoute)
                    Object.keys(params).forEach( function (key) {
                        companyListRoute = window.updateUrlParameter(companyListRoute, key, '')
                    })
                    // companyListRoute = companyListRoute.split('?')
                    // companyListRoute = companyListRoute[0]
                }

                // Push to url feature
                // After page refresh, user will have everything all set
                // added page reload to reinit the select2 elements
                // if (typeof window.history.pushState != 'undefined') {
                    // window.history.pushState(null, null, companyListRoute)
                    usersList.addClass('is-requesting')

                    window.location.href = companyListRoute
                // }
            }
            //might need refactorr but currently doesnt happen cause we reload the page
            // try {
            //
            //     usersList.addClass('is-requesting')
            //     const response = await axios.get(companyListRoute, window.axiosConfig)
            //     const { data } = response

            //     const customerUserList = $(data).find('#customer-users-list').html()

            //     usersList.html(customerUserList)
            //     usersList.removeClass('is-requesting')
            //     // const customerUserNavigationTitle = $(data).find('.navigation-information')
            //     // $('.navigation-left').html(customerUserNavigationTitle)

            //     const customerUserNavigationButton = $(data).find('.open-add-users-popup')
            //     $('.add-users').html(customerUserNavigationButton)

            //     window.addCheckboxInput()
            //     window.addToggleInput()
            //     statusCheck()
            // } catch (err) {
            //     window.showErrorPopup()
            //     console.error(err)
            //     throw err
            // }
        }
    }
})