var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-loader" }, [
    _c(
      "svg",
      {
        staticClass: "bag-loader-icon",
        attrs: {
          width: "200",
          height: "198",
          viewBox: "0 0 200 198",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }
      },
      [
        _c("path", {
          attrs: {
            d:
              "M49 97.5C49 124.286 70.7142 146 97.5 146C124.286 146 146 124.286 146 97.5C146 70.7142 124.286 49 97.5 49C70.7142 49 49 70.7142 49 97.5Z",
            stroke: "#4A90CA",
            "stroke-width": "14"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M76 97.5C76 109.374 85.6259 119 97.5 119C109.374 119 119 109.374 119 97.5C119 85.6259 109.374 76 97.5 76C85.6259 76 76 85.6259 76 97.5Z",
            stroke: "#063973",
            "stroke-width": "6"
          }
        }),
        _vm._v(" "),
        _c("path", {
          staticClass: "path-1",
          attrs: {
            d:
              "M30.7585 75.405C25.4957 91.7184 26.1952 109.37 32.7323 125.216C39.2695 141.062 51.2198 154.073 66.4542 161.93",
            stroke: "#063973",
            "stroke-width": "9",
            "stroke-linecap": "round"
          }
        }),
        _vm._v(" "),
        _c("path", {
          staticClass: "path-2",
          attrs: {
            d:
              "M111.279 187.093C131.796 184.167 150.687 174.287 164.795 159.105C178.903 143.923 187.372 124.359 188.788 103.683C190.204 83.0068 184.481 62.4709 172.574 45.5077C160.668 28.5444 143.3 16.1816 123.374 10.4854",
            stroke: "#4A90CA",
            "stroke-width": "2",
            "stroke-linecap": "round"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }