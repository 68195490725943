$(document).ready(function() {

    if ($('.contact-form-parent-container').exists()) {
        const openingButton = $('.contact-form-opener')
        const formTypeSelect = $('.contact-form-type-select')
        const selectArrow = $('#select-arrow-container').html()
        const closeButton = $('.popup-top-container .close')


        // Logic for showing/hiding the form popup
        openingButton.add(closeButton).on('click', toggleContactFormPopup)


        // Logic for choosing contact form type
        formTypeSelect.select2({
            dropdownParent: formTypeSelect.parent(),
            minimumResultsForSearch: -1,
            placeholder: formTypeSelect.data('placeholder')

        }).on('select2:select', async function() {
            const requestURL = $(this).val()
            $('.contact-form-popup-container').addClass('is-requesting')

            try {
                const response = await axios.get(requestURL)
                const { data } = response
                $('.contact-form-parent-container').html(data)
                initContactForm()

                $('.contact-form-popup-container').removeClass('is-requesting')

            } catch {
                $('.contact-form-popup-container').removeClass('is-requesting')
                window.showErrorPopup()
            }
        })
        formTypeSelect.parent().find('.select2-selection__arrow b').html(selectArrow)


        // Logic for initializing the contact form
        function initContactForm() {

            // initialize selects
            $('.contact-form .select select').each(function() {
                $(this).select2({
                    dropdownParent: $(this).parent(),
                    minimumResultsForSearch: -1,
                    placeholder: $(this).data('placeholder')
                })
                $(this).parent().find('.select2-selection__arrow b').html(selectArrow)
            })

            // initialize datepickers
            $('.contact-form .date input').each(function() {
                flatpickr($(this), {
                    dateFormat: 'd.m.Y',
                    appendTo: $(this).parent()[0],
                    disableMobile: true
                })
            })
        }


        // Logic for submitting the contact form
        $('.contact-form-popup').on('click', '.contact-form-submit-button', async function() {
            const contactForm = $('form[name="contact-form"]')

            const formAction = contactForm.attr('action')
            const formData = new FormData(contactForm[0])

            try {
                if (validateForm(contactForm)) {
                    $('.contact-form-popup-container').addClass('is-requesting')

                    const response = await axios.post(formAction, formData)
                    const { data } = response

                    $('.contact-form-popup-container').removeClass('is-requesting')
                    toggleContactFormPopup()

                    const { titleSuccess, titleFail, buttonText } = $('.contact-form-submit-feedback-popup-data').data()

                    swal({
                        icon: data.status == 'success' ? 'success' : 'error',
                        className: 'contact-form-feedback-popup',
                        title: data.status == 'success' ? titleSuccess : titleFail,
                        text: data.message,
                        buttons: {
                            submit: {
                                text: buttonText,
                                value: true
                            }
                        }
                    })

                } else {
                    $('.contact-form-popup-container').animate({ scrollTop: 0 }, 'slow')
                }

            } catch {
                window.showErrorPopup()
            }
        })


        // Logic for removing red border from failed form fields
        $('.contact-form-popup').on('change keydown', 'select, input, textarea', function() {
            $(this).closest('.field').removeClass('error')
        })


        // Helper function for validating the form
        function validateForm(contactForm) {
            let formIsValid = true

            // validate inputs and datepickers that are required
            contactForm.find('.input.required, .date.required').each(function() {

                if ($(this).find('input').val() == '') {
                    $(this).addClass('error')
                    formIsValid = false
                }
            })

            // validate selects that are required
            contactForm.find('.select.required').each(function() {

                if ($(this).find('select').val() == '') {
                    $(this).addClass('error')
                    formIsValid = false
                }
            })

            // validate text areas that are required
            contactForm.find('.textarea.required').each(function() {

                if ($(this).find('textarea').val() == '') {
                    $(this).addClass('error')
                    formIsValid = false
                }
            })

            // validate email format
            const emailField = contactForm.find('input[name="email"]')
            const validationRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

            if (emailField.exists() && !validationRegex.test(emailField.val())) {
                emailField.closest('.field').addClass('error')
                formIsValid = false
            }

            return formIsValid
        }


        // Helper function for showing/hiding the form popup
        function toggleContactFormPopup() {
            $('.contact-form-popup-container').toggleClass('popup-hidden')
            $('body').toggleClass('no-scroll')
        }
    }
})