export function initDatepicker (datePicker, datePickerParent, queryParamNames, loadingContainer) {
    let { selectedFrom, selectedTo, locale, singleSelectOnly, preselect, preselectStart, preselectEnd, restrictDates, submitTranslation } = datePicker.data()
    let { startParamName, endParamName } = queryParamNames

    let startValue = selectedFrom.replaceAll('-','.')
    let endValue = selectedTo.replaceAll('-','.')
    let url = window.location.href
    let preselectedDate

    if (startValue && endValue) {
        // necessary check for the case when you come with range of dates to a page that supports only single mode
        if ((startValue != endValue && singleSelectOnly) || (startValue == endValue)) {
            preselectedDate = startValue
        } else {
            preselectedDate = [startValue, endValue]
        }
    }
    let options = {
        dateFormat: 'd.m.Y',
        locale: locale,
        appendTo: datePickerParent[0],
        mode: singleSelectOnly ? 'single' : 'range',
        closeOnSelect: false,
        defaultDate: preselectedDate,
        disableMobile: true,
        onReady: (selectedDates, dateString, instance) => {
            // append submit button to datepicker
            let { calendarContainer } = instance
            $(calendarContainer).append(`<button class="date-submit">${submitTranslation}</button>`)
        },
        onValueUpdate: date => {
            if (date.length) {
                startValue = flatpickr.formatDate(date[0], 'd-m-Y')
                endValue = flatpickr.formatDate(date[date.length - 1], 'd-m-Y')
            }
        }
    }
    if (restrictDates) {
        options = {
            ...options,
            minDate:  moment().subtract(3, 'days').format('DD.MM.YYYY'),
            maxDate: moment().format('DD.MM.YYYY')
        }
    }

    flatpickr(datePicker, options)

    // if a value is selected show it, otherwise set the today's date if preselect is activated
    if (selectedFrom && selectedTo ) {              
        if (startValue != endValue ) {
            datePicker.after(`<span class="clear-tag clear-tag--wide"><i class="material-icons">close</i></span>`)
        } else {
            datePicker.after(`<span class="clear-tag"><i class="material-icons">close</i></span>`)
        }
        datePicker.trigger('change')
    } else if (preselect) {
        if (preselectStart == preselectEnd) {
            datePicker[0]._flatpickr.setDate(preselectStart, true)
        } else {
            datePicker[0]._flatpickr.setDate([preselectStart, preselectEnd], true)
        }
    }
    // logic for date submit button
    $('.date-submit').on('click', () => {
        datePicker[0]._flatpickr.close()
        loadingContainer.addClass('is-requesting')
        
        url = window.updateUrlParameter(url, startParamName, startValue)
        url = window.updateUrlParameter(url, endParamName, endValue)
        window.location.href = url
    })
    // logic for date clear
    $('.clear-tag').on('click', function() {
        $(this).remove()
        datePicker.val('').trigger('change')
        loadingContainer.addClass('is-requesting')

        url = window.updateUrlParameter(url, startParamName, '')
        url = window.updateUrlParameter(url, endParamName, '')
        window.location.href = url
    })
}