var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShowing,
          expression: "isShowing"
        }
      ],
      staticClass: "cancellation-row"
    },
    [
      _c("td", { attrs: { colspan: _vm.colspan } }, [
        _c("div", { staticClass: "cancel-container" }, [
          _c("p", [
            _vm._v(
              _vm._s(
                _vm._f("trans")("disposition.calloffs.reason_of_rejection")
              ) + "*"
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "rejection-select-container" },
            [
              _c(
                "select2-component",
                {
                  attrs: {
                    placeholder: _vm.$root.$options.filters.trans(
                      "disposition.calloffs.choose_reason"
                    ),
                    options: _vm.cancelData
                  },
                  on: { change: _vm.selectionUpdate },
                  model: {
                    value: _vm.selectValue,
                    callback: function($$v) {
                      _vm.selectValue = $$v
                    },
                    expression: "selectValue"
                  }
                },
                [
                  _c("option", {
                    attrs: { value: "", disabled: "", selected: "" }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(
                _vm._f("trans")("disposition.calloffs.reason_of_rejection")
              ) + "*"
            )
          ]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.rejectionMessage,
                expression: "rejectionMessage"
              }
            ],
            attrs: {
              placeholder: _vm.$root.$options.filters.trans(
                "disposition.calloffs.comment_for_manufacturer"
              )
            },
            domProps: { value: _vm.rejectionMessage },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.rejectionMessage = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "* " +
                _vm._s(_vm._f("trans")("disposition.calloffs.mandatory_field"))
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "button-row" }, [
          _c(
            "button",
            {
              ref: "sendDeny",
              staticClass: "btn btn-primary",
              attrs: { disabled: _vm.disableDeny },
              on: { click: _vm.denyCalloff }
            },
            [_vm._v(_vm._s(_vm._f("trans")("calloff.form.send")))]
          ),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "btn btn-white", on: { click: _vm.clearFields } },
            [_vm._v(_vm._s(_vm._f("trans")("company-invitation.cancel")))]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }