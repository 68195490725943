$(document).ready(() => {
    if($('#customer-dashboard-page').exists()) {
        const toggler = $('.toggle-amounts')
        let togglerFlag = false
        let scrollSize = 0
        let scrollSizeOff = 0

        const impersonation = $('.bag-back-link')
        if ($('body').hasClass('highway') && impersonation.exists()){
            $('.page-layout').css('margin-top', '58px')
        }

        
        toggler.on('click', toggleAmounts)
        function toggleAmounts () {
            const hierarchyWrapper = $('#header-hierarchy')

            if (toggler.hasClass('hide-hierarchy')) {
                toggler.removeClass('hide-hierarchy')
                hierarchyWrapper.removeClass('hidden')

                togglerFlag = true
            } else {
                toggler.addClass('hide-hierarchy')
                hierarchyWrapper.addClass('hidden')

                togglerFlag = false
            }
        }
            /**
         * Scrolling logic
         */
        window.onscroll = function() {
        
            let topHeaderHeight = 130
            const toggler = $('.customer-navigation .toggle-amounts')
            const topNavigation = $('#customer-dashboard-page .customer-navigation')
            if ((document.body.scrollTop > topHeaderHeight || 
                document.documentElement.scrollTop > topHeaderHeight)) {
                
                if ( isBetween(scrollSize, document.documentElement.scrollTop) ){
                    return;     
                }
                topNavigation.addClass('shrink')
                scrollSize = document.documentElement.scrollTop
                
                if (impersonation.exists()){
                    topNavigation.css('top', '58px')
                }
                if(!togglerFlag) {
                    toggler.addClass('hide-hierarchy')
                    $('#header-hierarchy').addClass('hidden')
                } else {
                    setTimeout(() => {
                        togglerFlag = false
                    }, 1)
                }
            } else {
                
                if (isBetween(scrollSizeOff, document.documentElement.scrollTop) ){
                    return; 
                }

                scrollSizeOff = document.documentElement.scrollTop
                topNavigation.removeClass('shrink')

            }

            const scrollTop = $('#scroll-to-top')
            if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
                scrollTop.removeClass('hidden')
            
            } else {
                scrollTop.addClass('hidden')

            }
        }
        function isBetween(oldSize, currentSize) {
            let n = $('.bag-back-link').exists() ? 50 : 10
            return (currentSize >= (oldSize - n) && currentSize <=(oldSize + n)) ;

        }
        $('#scroll-to-top').on('click', function() {
            $("html, body").animate({ scrollTop: 0 }, 'slow')
        })

    }
})