$(document).ready(function () {

    if ($('.pimcore_area_zebra-tip.pimcore_area_content').length) {
     
       const tipLinks = $('.tip-link')
       let progressFlag = false
       /**
        * Sort tips ascending based on priority level
        */
       function compare(a, b) {
           let tipA = $(a).data('priority');
           let tipB = $(b).data('priority');
           tipA = parseInt(tipA) 
           tipB = parseInt(tipB) 
   
           let comparison = 0;
           if (tipA > tipB) {
             comparison = 1
           } else if (tipA < tipB) {
             comparison = -1
           }
           return comparison
         }
        
       tipLinks.sort(compare)
       /**
        * Set click events for handling tip single display triggered on button 
        * enable startup display for first tips 
        */
       let isRendering = false
       tipLinks.each(function(index) {
          
           const singleTip = $(this)
           singleTip.on('click', () => showTips($(this), index, '', false, true))
           //check if the user is logged in
           checkAnonymous(singleTip, index)
           /**
            * start autoplay if there are tips with autoplay enabled
            * to customize tip use, set window.disableStartupAutoplay to true to disable tip display on startup on certain places  
            *  */ 
           
           if (!singleTip.data('hide-on-startup') && !isRendering && !window.disableStartupAutoplay) {
               isRendering = true
               showTips(singleTip, index, '', true )
   
           }
       })
       function checkAnonymous(tip, index) {
           if (!window.anonymousUser) {
               return false
           }
               let tipID = tip.attr('id')
               tipID = tipID || index
               if (sessionStorage.getItem(`${window.location.href}-${tipID}`)) {
                   window.disableStartupAutoplay = true
               } else {
                   window.disableStartupAutoplay = false
               }
           
       }
    
       async function showTips(tipLink, index, url = '', autoPlay = false, ignoreList = false) {
           let tipID = tipLink.attr('id')
           tipID = tipID || index 
           let dataUrl =  tipLink.data('href')
           let {closeText, hidePrev, hideNext, autoplayMessage} = tipLink.data()
           // let hiddenOnStartup = tipLink.data('hide-on-startup')
           let requestUri = url
           requestUri = requestUri != '' ?  requestUri : dataUrl
           try {
               const response = await axios.get((requestUri))
               let { 
                   data: {
                       success, 
                       title,
                       content,
                       current_page,
                       prev_page,
                       next_page,
                       next_link_label,
                       prev_link_label,
                    //    ignore_list_add_label,
                    //    ignore_list_remove_label,
                       total_page_count,
                       is_on_ignore_list
                   },
               } = response
            //since slider is added using only one label defined as a data-attr in the tip button template as autoplayMessage
            //    let ignoreListRemove = tipLink.data('hide-ignorelist-remove') ? '' : ignore_list_remove_label
            //    let ignoreListAdd =  tipLink.data('hide-ignorelist-add') ? '' : ignore_list_add_label

               if(is_on_ignore_list == true && ignoreList === false) {
                   return
               }

               if (success) {
                   let hiddenOnStartup = is_on_ignore_list
                   if (window.anonymousUser){
                       hiddenOnStartup = sessionStorage.getItem(`${window.location.href}-${tipID}`) ? true : false
                   }
                   
                   let paginator = `<div class="paginator">`
                   for (let i = 1; i <= total_page_count; i++){
                       paginator += `<a class="paginator-page ${i == current_page ? 'current': ''}" 
                           href="javascript:;"
                           data-page="${i}"
                       >
                           <span class="page-number">${i}</span>
                       </a>`
                   }
                   paginator += `</div>`;
                 
                       
                   let checked = !hiddenOnStartup ? true : false 
                   let tipsControl = `<div class="customer-tips-control"> 
                                            <div class="togglebutton">
                                                <label> <span class="tip-label">${autoplayMessage}</span>
                                                    <input
                                                        type="checkbox"
                                                        class=""
                                                        ${checked ? 'checked' : ''}
                                                    >
                                                    <span class='toggle'></span>  
                                                </label> 
                                            </div>
                                        </div>`
                   
                   let showControls = `<div class="tip-action">  
                           ${tipsControl}
                         
                       </div>`
                       console.log('no next page', !next_page);
                   let prevButton = hidePrev  ? '' : prev_page ? `<button class="btn prev-button"><i class="material-icons">play_arrow</i>${prev_link_label} </button>`: ''
                   let nextCloseButton = hideNext ? '': next_page  ? `<button class="btn next-button">${next_link_label} <i class="material-icons">play_arrow</i></button>`: `<button class="btn close-button">${closeText}</button>`
                  
                   let markup = `
                       <div class="tip-popup-container ">
                           <div class="top-container">
                               <h2>${title}</h2>
                               <i class="material-icons close">close</i>
                           </div>
                           <div class="tip-bordered-content">
                                <div class="tip-search-loader"> 
                                    <img src="/bundles/zebratips/img/simple-loading-blue.svg" alt="search">  
                                </div>       
                                <div class="options">
                                    ${paginator}
                                    ${showControls} 
                                </div>
                                <div class="content page-content">
                                ${content}
                                </div>
                                <div class="control-buttons">
                                        <div class="left-controls">
                                        ${prevButton}  
                                        </div>                     
                                        <div class="right-controls">
                                        ${next_page ? '' : showControls}
                                        ${nextCloseButton}
                                        </div>    
                                </div>
                           </div>
                       </div>
                   `
       
                   let wrapper = document.createElement('div')
                   wrapper.innerHTML = markup
       
                   swal({
                       content: wrapper,
                       className: 'tip-modal',
                       buttons: {},
                       overlay: '',
                       
                   }).then(function() {
                       $('.swal-overlay').removeClass('tip-overlay')
                       // call next tip that has isn't hidden on startup
                       if (autoPlay){
                           // get index of current iteration in tipLinks
                          let i = tipLinks.index(tipLink[0])
                          
                           if (i < tipLinks.length){
                               let check = false
                               //get next iteration that isn't set to hidden and disabled by anonymous-user
                               tipLinks.slice(i+1).each(function(){
                                   if (!$(this).data('hide-on-startup')&& !check) {
                                       check = true
                                       checkAnonymous($(this), tipLinks.index(this))
                                       if (!window.disableStartupAutoplay){
                                           showTips($(this), tipLinks.index(this),  '', true )
                                       }
                                   } 
                               })
                           }
                         
                       }
                   }
   
                   )
       
                   $('.swal-overlay').addClass('tip-overlay')
                   const closeSign = $('.tip-modal .top-container .close')
                   const closeButton = $('.tip-modal .close-button')
                   const buttonBack = $('.tip-modal .tip-bordered-content .control-buttons .prev-button')
                   const buttonNext = $('.tip-modal .tip-bordered-content .control-buttons .next-button')
                   const paginatorLinks = $('.paginator-page').not('.disabled')
                   const tipsShowing = $('.customer-tips-control')
                   const pageContent = $(wrapper).find('.tip-bordered-content')
   
                   // close the popup on x 
                   closeSign.on('click', closePopup)
                   // close on button
                   closeButton.on('click', closePopup)
                   function closePopup() {
                    // $('.swal-overlay').toggleClass('swal-overlay--show-modal')
                    swal.close()
                   
                   
                    }
   
                   // render back/next page
                   buttonBack.on('click', function(){
                       url = window.updateUrlParameter(dataUrl, 'page', --current_page)
                       pageContent.addClass('is-loading')
                       showTips(tipLink, index, url)
                   })
                   buttonNext.on('click', function(){
                       url = window.updateUrlParameter(dataUrl, 'page', ++current_page)
                       pageContent.addClass('is-loading')
                       showTips(tipLink, index, url)
                   })
                   //enable links before current page in the paginator 
                   paginatorLinks.on('click', function(){
                       let i = $(this).data('page')  
                       let url = window.updateUrlParameter(dataUrl, 'page', i)
                       pageContent.addClass('is-loading')
                       showTips(tipLink, index, url)
                   })
                   //handle showing of the modal
                   tipsShowing.on('click', async function(e) {
                       e.preventDefault()
                        
                       if (progressFlag) {
                           return
                       }
                        progressFlag = true 
                        let $tipsControl = $('.customer-tips-control')
                        let inputValue = $(this).find('input').prop('checked')
                        $tipsControl.find('input').prop('checked', !inputValue)

                       console.log(window.anonymousUser)

                       if (window.anonymousUser){
                           if (!hiddenOnStartup) {
                               sessionStorage.setItem(`${window.location.href}-${tipID}`, true)
                               hiddenOnStartup = !hiddenOnStartup
                               
                           } else {
                               sessionStorage.removeItem(`${window.location.href}-${tipID}`) 
                               hiddenOnStartup = !hiddenOnStartup
                           }
                           progressFlag = false
                       
                       } else {
                           try {
                               pageContent.addClass('is-loading')
                               //check if the tip is on ignore list
                               let checkUrl = tipLink.data('ignorelist-check')
                               let responseA = await axios.get(checkUrl)
                               //change status of tip
                               hiddenOnStartup = responseA.data.success
                               let url = hiddenOnStartup ? tipLink.data('ignorelist-remove-link') : tipLink.data('ignorelist-add-link')
                               let response = await axios(url)

                               if (response.data.success == true) {
                                   pageContent.removeClass('is-loading')
                                   progressFlag = false

                               }
                           }
                           catch(err) {
                               console.error(err);
                               window.showErrorPopup()
                           }
                           
                           
                           
                       } 
                  
                   })
   
               }
              
           } catch (error) {
               window.showErrorPopup()
               console.error(error)
               throw error
           }
          
       }
      
     
    }
   })
   