/**
 * Quality control page
 */
$(document).ready(() => {
    if($('#quality-control').exists()) {
        // Contract list
        const QaList = $('#quality-control')
        const loader = $('.loader')

        // Masonry init
        initMasonry()
        function initMasonry() {
            const QaList = $('#quality-control')
            QaList.masonry({
                itemSelector: '.qa-item',
                isAnimated: true,
                animationOptions: {
                    duration: 750,
                    easing: 'linear',
                    queue: false
                },
                gutter: 0,
            }).removeClass('invisible')
            loader.addClass('hidden')
        }
        
        //check for documents and display them if they exist
        const linkContainer = QaList.find('.dl-link-container')
        linkContainer.each( async function(){
            let linkGenerators = $(this).find('.generate-dl-link')
            for (const linkGenerator of linkGenerators) {
                let $this= $(linkGenerator)
                let { checkRoute, downloadLink, downloadLinkLabel } = $this.data()
                try {
                    let response = await axios.get(checkRoute)
                    if (response.data.soap_available && response.data.total_count > 0){
                        let html = `<a href="${downloadLink}" class="hidden" target="_blank" download="">${downloadLinkLabel}</a>`
                        $this.html(html)
                    }
                   
                } catch(err) {
                    window.showErrorPopup()
                    throw err
                }
            }
            $(this).find('a').removeClass('hidden')      
        })

        //material select init 
        const materialCategorySelect = $('#material-category-select')
        if (materialCategorySelect.exists()) {
            let { materials, defaultAll, preselect } = materialCategorySelect.data()
            let data = Object.keys(materials).map(el=>{
                return { 
                    id: materials[el]['id'],
                    text: materials[el]['name'],
                }
            })
            //set default value
            data.unshift({ id: 'all', text: defaultAll })
            materialCategorySelect.select2({ 
                minimumResultsForSearch: -1,
                dropdownParent: $('.material-category-select-container'),
                escapeMarkup: markup => markup,
                data: data
            }).on('select2:select', async function(e) {
                let selection = e.params.data.element.value !== 'all'? e.params.data.element.value : '';
                await getMaterialResults(selection)
                // window.location.href = url
            })
           
            if (preselect) {
                let dataItem = data.find(el => el.id== preselect)
                if (dataItem) {
                    materialCategorySelect.val(preselect).trigger('change')
                } else {
                    materialCategorySelect.val('all').trigger('change')
                }   
               
            }
            async function getMaterialResults(selection) {
                $('.loader').removeClass('hidden')
                QaList.addClass('is-requesting')
                let url = decodeURIComponent(window.location.href)
                url = window.updateUrlParameter(url, "filter[material_hierarchy]", selection)
                
                if (typeof window.history.pushState != 'undefined') {
                    window.history.pushState(null, null, url)
                }
                try {
                    const response = await axios.get(url, window.axiosConfig)
                    let { data } = response
                    let qualityHtml = $(data).find('#quality-control').html()
                    QaList.html(qualityHtml);
                    initMasonry()

                } catch (error) {
                    window.showErrorPopup()
                    throw error
                }
                $('.loader').addClass('hidden')
                QaList.removeClass('is-requesting')
            }
            // custom arrow on select2
            $('.material-category-select-container').find('.select2-selection__arrow b').html('<i class="material-icons">expand_more</i>')
        }

         //factory select init 
         const factorySelect = $('#factory-select')
         if (factorySelect.exists()) {
             let { materials, defaultAll, preselect } = factorySelect.data()
             let data = Object.keys(materials).map(el=>{
                 return { 
                     id: materials[el]['id'],
                     text: materials[el]['name'],
                 }
             })
             //set default value
             data.unshift({ id: 'all', text: defaultAll })
             factorySelect.select2({ 
                 minimumResultsForSearch: -1,
                 dropdownParent: $('.factory-select-container'),
                 escapeMarkup: markup => markup,
                 data: data
             }).on('select2:select', async function(e) {
                 let selection = e.params.data.element.value !== 'all'? e.params.data.element.value : '';
                 await getMaterialResults(selection)
                 // window.location.href = url
             })
            
             if (preselect) {
                 let dataItem = data.find(el => el.id== preselect)
                 if (dataItem) {
                    factorySelect.val(preselect).trigger('change')
                 } else {
                    factorySelect.val('all').trigger('change')
                 }   
                
             }
             async function getMaterialResults(selection) {
                 $('.loader').removeClass('hidden')
                 QaList.addClass('is-requesting')
                 let url = decodeURIComponent(window.location.href)
                 url = window.updateUrlParameter(url, "filter[factory]", selection)
                 
                 if (typeof window.history.pushState != 'undefined') {
                     window.history.pushState(null, null, url)
                 }
                 try {
                     const response = await axios.get(url, window.axiosConfig)
                     let { data } = response
                     let qualityHtml = $(data).find('#quality-control').html()
                     QaList.html(qualityHtml);
                     initMasonry()
 
                 } catch (error) {
                     window.showErrorPopup()
                     throw error
                 }
                 $('.loader').addClass('hidden')
                 QaList.removeClass('is-requesting')
             }
             // custom arrow on select2
             $('.factory-select-container').find('.select2-selection__arrow b').html('<i class="material-icons">expand_more</i>')
         }

        const materialGroupSelect = $('#material-group-select')
        if (materialGroupSelect.exists()) {
            let { materials, defaultAll, preselect } = materialGroupSelect.data()
            let data = Object.keys(materials).map(el=>{
                return { 
                    id: materials[el]['id'],
                    text: materials[el]['name'],
                }
            })
            //set default value
            data.unshift({ id: 'all', text: defaultAll })
            materialGroupSelect.select2({ 
                minimumResultsForSearch: -1,
                dropdownParent: $('.material-group-select-container'),
                escapeMarkup: markup => markup,
                data: data
            }).on('select2:select', async function(e) {
                let selection = e.params.data.element.value !== 'all'? e.params.data.element.value : '';
                await getMaterialResults(selection)
                // window.location.href = url
            })
           
            if (preselect) {
                let dataItem = data.find(el => el.id== preselect)
                if (dataItem) {
                    materialGroupSelect.val(preselect).trigger('change')
                } else {
                    materialGroupSelect.val('all').trigger('change')
                }   
               
            }
            async function getMaterialResults(selection) {
                $('.loader').removeClass('hidden')
                QaList.addClass('is-requesting')
                let url = decodeURIComponent(window.location.href)
                url = window.updateUrlParameter(url, "filter[material_group]", selection)
                
                if (typeof window.history.pushState != 'undefined') {
                    window.history.pushState(null, null, url)
                }
                try {
                    const response = await axios.get(url, window.axiosConfig)
                    let { data } = response
                    let qualityHtml = $(data).find('#quality-control').html()
                    QaList.html(qualityHtml);
                    initMasonry()

                } catch (error) {
                    window.showErrorPopup()
                    throw error
                }
                $('.loader').addClass('hidden')
                QaList.removeClass('is-requesting')
            }
            // custom arrow on select2
            $('.material-group-select-container').find('.select2-selection__arrow b').html('<i class="material-icons">expand_more</i>')
        }
    }
})
