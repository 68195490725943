$(document).ready(()=> {
    if ($('#call-off-companies').exists()) {
       
        const datepickersContainer = $('.datepickers')
        let pagination = $('#pagination')
        const tableContainer = $('.disposition-table-container')
        let datepickersFlag = false
        let startValue, endValue, filterValues
        let currentDate = moment().format('DD.MM.YYYY')
        let loader = $('.loader')
        const searchLoader = $('.calloff-search-container #search-loader').html()
        let url = window.location.href
        let searchState = false
        tableContainer.removeClass('is-requesting')
        const searchContainer =$('.calloff-page-search')
        const queryNaming = {
            customer_number : $('.call-off-companies').data('customerNumber'),
            customer_name: $('.call-off-companies').data('customerName'),
            companyId: $('.call-off-companies').data('companyId'),
            construction_site: $('.call-off-companies').data('constructionSite'), 
            construction_site_number: $('.call-off-companies').data('constructionSiteNumber'), 
            material_designation: $('.call-off-companies').data('materialDesignation'),
            material_number: $('.call-off-companies').data('materialNumber'),
            shipping_spot: $('.call-off-companies').data('shippingSpot'),
            username: $('.call-off-companies').data('username'),
            contract_id: $('.call-off-companies').data('contractId'),
            factory_number: $('.call-off-companies').data('factoryNumber'),
            contract_number: $('.call-off-companies').data('contractNumber'),
        }
    
        initHeaderSearch()
        initHeaderFilters()
        datePickersInit()
        initPagination(pagination)

        function initHeaderSearch() {
            const headerSearch = $('body .calloff-company-search')
            let {filter, method, messagePlaceholder, messageApi_route, pathParams} = headerSearch.data()
            if (method == 'GET'){
                let keys =  Object.keys(pathParams)
                if (keys.length){
                    keys.forEach(key => {
                        if (pathParams[key]!==''){
                            messageApi_route = window.updateUrlParameter(messageApi_route, key, pathParams[key])
                        }
                    })
                    
                }
            }
            let searchQuery = filter
            searchState = false
            if (headerSearch.length > 0) {
                // GETselect
                headerSearch.select2({
                    allowClear: true,
                    minimumResultsForSearch: -1,
                    dataType: 'json',
                    width: '',
                    // allowClear: true,
                    multiple: true,
                    tags: true,
                    minimumInputLength: 1,
                    closeOnSelect: true,
                    containerCssClass: 'header-search-select2',
                    placeholder: messagePlaceholder,
                    dropdownParent: $('.search-bar'),
                    ajax: {
                        transport: window.customTransportFunction,
                        method: method,
                        url: messageApi_route,
                        dataType: 'json',

                        data: (params) => {
                            if (method == 'GET'){
                                return {
                                    term: $.trim(params.term),
                                    page: params.page || 1,
                                }
                            } else {
                                
                                return  {
                                    term: $.trim(params.term),
                                    page: params.page || 1,
                                    ... pathParams
                                }
                            }
                            
                        },
                        processResults: (data, { term }) => {
                            if (method == 'GET'){
                                if (Array.isArray(data)){
                                    return {
                                        results: data.map((item)=> ({
                                            ...item,
                                            id: item.value
                                        })    
                                        ),  
                                    }
                                } else {
                                    return {
                                        results: data.items.map((item)=> ({
                                            ...item,
                                            id: item.value
        
                                        })   
                                        ),
                                        pagination: {
                                            more: data.pagination.has_next_page
                                        }
                                    }
                                }
                            } else {
                                if (Array.isArray(data)){
                                    return {
                                        results: data.map((item)=> ({
                                            ...item,
                                            id: item.name
                                        })    
                                        ),  
                                    }
                                } else {
                                    return {
                                        results: data.items.map((item)=> ({
                                            ...item,
                                            id: item.name
                                        })    
                                        ),  
                                    }
                                }
                                
                            }  
                        },
                    },
                   
                    escapeMarkup: (markup) => markup,
                    templateResult: formatSearchData,
                    templateSelection: formatSelectedSearch,
                    language: {
                        inputTooShort: () => headerSearch.data('message-add_more_text'),
                        noResults: () => {headerSearch.data('message-no_results')},
                    },
                }).on('select2:select', function(e) {
                    let path = e.params.data.path ? e.params.data.path : null
                    if (!path){
                        let selectionKey = e.params.data.filter_key
                        let selectionValue = e.params.data.value
                        url = window.updateUrlParameter(url, `filter[${selectionKey}]`, selectionValue)
                    } else {
                        url = path
                    }
                    
                    getTableHtml(url)
                    
                }).on('select2:clear', function(e) {
                    let searchKeys = Object.keys(searchQuery) 
                    searchKeys.forEach(function(key) {
                        url = window.updateUrlParameter(url, `filter[${key}]`, '')
                    })
                    
                    getTableHtml(url)
                
                    searchInput.removeClass('hidden')
                }).on('select2:opening', function(e){
                    if(searchState){
                        e.preventDefault();

                    }
                })
                const searchInput = $('.header-search-select2 .select2-search__field')
    
          
                /**
                 * Set onload preselected value
                 */
                if (Object.keys(searchQuery).length){
                    // param that disables the tips functionality in case of a search query
                    window.disableStartupAutoplay = true
                    displaySearchQuery(searchQuery)
                    
                } else {
                    headerSearch.find('option').remove().trigger('change')
                }

            
                async function displaySearchQuery(queryObject){
                    let queryKeys = Object.keys(queryObject)
                    if (queryKeys.length && !searchState){
                        searchInput.addClass('hidden')
                        searchState = true            
                        let route = headerSearch.data('message-api_route')
                        let query, queryName
                        let values = []
                        queryKeys.forEach(function(key) {
                            queryName = key
                            query = queryObject[key]
                            if (query){
                                
                                let text = `${queryNaming[queryName]}: ${query}`
                                const option = new Option(text, query, true, true)
                                headerSearch.append(option).trigger('change')
                                values.push(query)
                                setTimeout(() => {
                                    const markup = `<span>${text}</span>`
                                    let selection = $(`.select2-selection__choice[title='${text}']`)
                                    selection.append(markup)
                                    selection.attr('data-filter', queryName )
                                
                                }, 1)
            
                            }
                        })
                    }
                }

                // Show icon after select2 init
                $('.search-bar .search-icon').removeClass('hidden')
            }
    
            function formatSearchData (data, item) {
                let markup = ''
                // Loading
                if (data.loading) {
                    markup = searchLoader
                } else {

                    // Option item
                    let { name, path, header, value, filter_key } = data
                    
                    let markupName = name ? name : value
                    let markupHeader = header ? header :  queryNaming[filter_key]

                    if (!markupName){
                        $(item).addClass('hidden')
                    }
                    markup = `
                        <a class="search-result-link" href="javascript:;">
                            ${markupHeader? markupHeader.concat(':') : ''} ${markupName}
                        </a>
                    `
                } 
                return markup
            }

            function formatSelectedSearch (data, item) {
                const { name } = data
                return name
            }
        }

        $(document).on('click', '.select2-selection__choice', function() {
            const filterName = $(this).data('filter')
            url = window.updateUrlParameter(url, `filter[${filterName}]`, '')

            getTableHtml(url)
        })
    
        function initHeaderFilters() {
            const headerFilters = $('.disposition-table.calloff-overview .header-filter') 
            const filterIcon = headerFilters.data('filter-icon')
            if (headerFilters.length) {
                headerFilters.each(function(){
                    const filter = $(this)
                    const parentContainer = filter.parents('.filter-heading')
                    let { searchUrl, placeholder, name, pathParams} = filter.data()
                    let keys =  Object.keys(pathParams)
                    if (keys.length){
                        keys.forEach(key => {
                            if (pathParams[key]!==''){
                                searchUrl = window.updateUrlParameter(searchUrl, key, pathParams[key])
                            }
                        })
                        
                    }
                    let selectOptions = {
                        width: '',
                        allowClear: false,
                        minimumResultsForSearch: -1,
                        placeholder: placeholder,
                        dropdownParent: parentContainer,
                        escapeMarkup: markup => markup,
                        ajax: {
                            transport: window.customTransportFunction,
                            url: searchUrl,
                            data: (params) => {
                                return {
                                    term: $.trim(params.value),
                                    page: params.page || 1,
                                }
                            },
                            dataType: 'json',

                            processResults: (data, { term }) => {
                                return {
                                    results: Object.values(data.items).map(el=> ({ 
                                            ...el,
                                            id: el.value 

                                    })),
                                    pagination: {
                                        more: data.pagination.has_next_page
                                    }
                                }
                            },
                        },
                        templateResult: templateOptions,
                        templateSelection: templateOptions,
                    }
                    // GETselect and POSTselect
                    filter.select2(selectOptions)
                    .on('select2:select', function(e) {
                        let exactTarget = $(e.params.originalEvent.originalEvent.target)
                        let searchKey = `filter[${name}]`
                        let searchValue = e.params.data.value
                        if (exactTarget.hasClass('table-filter-clear') || exactTarget.parent('.table-filter-clear').length){
                            url = window.updateUrlParameter(url, searchKey, '')
                           
                        } else {
                            url = window.updateUrlParameter(url, searchKey, searchValue)

                        }

                        getTableHtml(url)
                    })
                    parentContainer.find('.select2-selection__arrow').html('')

                    

                })
            }
            function templateOptions(data, container) {
               
                
                let markup = ''
                // Loading
                if (data.loading) {
                    markup = searchLoader
                } else {
                    
                    let $option = $(data.element)
                    let text = $option.text()  
                    let icon = $option.data('icon')
                    let {selection} =  $('.header-filter').data();
                    
                    if (icon){
                        text = `${text} <i class="material-icons">${icon}</i>`
                        return text;
                    }
                    if (data.id == ""){
                        text = `${data.text} <i class="material-icons">${filterIcon}</i>`
                        return text;
                    }
                    // Option item
                    let { name, path, value } = data
                   
                    markup = `
                        <a class="dropdown-item table-filter" href="${path ? path: 'javascript:;' }">
                            ${name} <span class="id-span"> ${value}</span>
                        </a>
                    `
                    if (selection != null && selection == value) {
                        markup = `
                        <a class="table-filter-clear"><i class="material-icons">close</i></a>
                        <a class="dropdown-item table-filter" href="${path ? path: 'javascript:;' }">
                            
                            ${name} <span class="id-span"> ${value}</span>
                        </a>
                    `
                      
                    $(container).addClass('position-relative')

                    }
                }
                return markup
            }
            
        }
       
        //handle datepicker clear 
        $(document).on('click', '.clear-tag', function() {
            const clearTag = $('.clear-tag')
            clearTag.remove()
            const params = window.getUrlParams(url)
            const paramKeys = Object.keys(params)
            const tagDatepickers = $('.flatpickr-with-tag .flatpickr-input')
            tagDatepickers.val('').trigger('change')
            startValue = ""
            endValue = ""
            if (paramKeys.includes('delivery_from_date') || paramKeys.includes('delivery_to_date')){
                url = window.updateUrlParameter(url, 'delivery_from_date', '')
                url = window.updateUrlParameter(url, 'delivery_to_date', '')
            }
            
            datepickersFlag = false
            tableContainer.addClass('is-requesting')
            window.location.href = url
        })
        //datepickers init
        function datePickersInit() {
            const datePicker = $('.flatpickr-date-input')
            
            let submitBtnTranslation = $('#call-off-companies').data('flatpickrButtonTranslation')
            let datePickerOptions = datePicker.data()
            filterValues = datePickerOptions.filterValues ? datePickerOptions.filterValues : null
            let keys = datePickerOptions.filterValues ? Object.keys(datePickerOptions.filterValues): []
            let dateStamps =keys ? keys.map( date => flatpickr.parseDate(date, 'd.m.Y')): null
            // sort (descending) dates with calloffs to get first and last date
            const sortedDescDates = dateStamps.length ? dateStamps.sort((a, b) => b - a) : []
            let from =  sortedDescDates.length ? moment(sortedDescDates[sortedDescDates.length -1]): null
            let to =  sortedDescDates.length  ? moment(sortedDescDates[0]): null
            // get range to show if calloffs range is to wide 
            let before42 = moment().subtract(6, 'weeks');
            let to6months = moment().add(6, 'months')
            let current = moment()
            // variables to pass to flatpickr instance 
            let fromDate, toDate
            if (from){
                fromDate = from > before42 ? from.format() : before42.format()
                //fromDate = fromDate > current ? current.format() : fromDate.format()
            } else {
                fromDate= null
            }
            if (to) {
                toDate = to > to6months ? to6months : to
                toDate = toDate < current ? current.format() : toDate.format()
            } else {
                toDate = null
            }
               
            let datePickerSelector = `#${datePicker.attr('id')}`
            let enable =  [
                { 
                    from:  fromDate,
                    to: toDate
                }
            ]
            let options = {
                enable,
                dateFormat: 'd.m.Y',
                locale: datePickerOptions.locale,
                mode: 'range',
                appendTo: $('.datepicker-container')[0],
                disableMobile: true,
                onReady: (selectedDates, dateString, instance) => {
                    // Append title to calendar
                    const { calendarContainer } = instance
                    $(calendarContainer).append(`<button class="calloff-date-submit">${submitBtnTranslation}</button>`)
                },
                onValueUpdate: function(date, dateString, instance) {
                    if (date.length > 0){
                        let len = date.length
                        startValue = flatpickr.formatDate(date[0],'d-m-Y' )
                        endValue = flatpickr.formatDate(date[len -1],'d-m-Y' )
                    }
                },
                closeOnSelect: false
            }

            flatpickr(datePickerSelector, options)

            if (datePickerOptions.selectedFrom && datePickerOptions.selectedTo ) {              
                startValue = datePickerOptions.selectedFrom.split('-').join('.')
                endValue = datePickerOptions.selectedTo.split('-').join('.')

                datePicker[0]._flatpickr.setDate([startValue, endValue], true)
                datePicker.after(`
                    <span class="clear-tag ${startValue != endValue ? 'clear-tag--wide' : ''}">
                        <i class="material-icons">close</i>
                    </span>
                `)
            }

            else if (datePickerOptions.preselect) {
                datePicker[0]._flatpickr.setDate([currentDate, currentDate], true)
            }
        }

        $(document).on('click', '.calloff-date-submit', async function() {
            datepickersFlag = true
          
            url = window.updateUrlParameter(url, 'delivery_from_date', startValue )
            url = window.updateUrlParameter(url, 'delivery_to_date', endValue)
            const datePicker = $('#calloffs-date')
            const datepickerInstance = datePicker[0]._flatpickr

            datepickerInstance.close()
            tableContainer.addClass('is-requesting')
            window.location.href = url
        })

        tableContainer.on('click', '.table-filter', function() {
            const { filterValue, filterKey } = $(this).data()

            if(filterKey !== '' && filterValue !== '' ) {
                url = window.updateUrlParameter(url, `filter[${filterKey}]`, filterValue.toString())

                // Make request
                getTableHtml(url)
            }
        })
        tableContainer.on('click', '.table-filter-clear', function(e) {
            e.preventDefault()
            const { filterKey } = $(this).data()

            if(filterKey !== '' ) {
                url = window.updateUrlParameter(url, `filter[${filterKey}]`, '')

                // Make request
                getTableHtml(url)
            }
        })
        tableContainer.on('click', '.table-sort', function() {
            const { sortOrder, sortKey } = $(this).data()

            url = window.updateUrlParameter(url, 'order', sortOrder)
            url = window.updateUrlParameter(url, 'order_by', sortKey)
            

            // Make request
            getTableHtml(url)
        })

        async function getTableHtml(url, datepickerReinit = false){
            
            try {
                tableContainer.addClass('is-requesting')
                const response = await axios.get(url)
                let container = $(response.data).find('.disposition-table-container').html()
                let searchHeader = $(response.data).find('.calloff-page-search').html()
                
                let newDatepickers =$(response.data).find('.datepickers').html()
                    
                tableContainer.html(container)
                searchContainer.html(searchHeader)
                pagination = tableContainer.find('#pagination')
                if (typeof window.history.pushState != 'undefined') {
                    window.history.pushState(null, null, url)
                }
                initHeaderFilters()
                initHeaderSearch()
                initPagination(pagination)
                
                if (!datepickerReinit) {
                    datepickersContainer.html(newDatepickers)
                    datePickersInit()

                }
                // window.initVue()
                if ($('#details-table').exists() && !$('.customer-block').exists()){
                    const customEvent = new CustomEvent('vue')
                
                    document.dispatchEvent(customEvent)
                }
                tableContainer.removeClass('is-requesting')

                

            } catch(err) {
                window.showErrorPopup()
                console.error(err)
                url = window.updateUrlParameter(url, 'order', '')
                url = window.updateUrlParameter(url, 'order_by', '')
                if (typeof window.history.pushState != 'undefined') {
                    window.history.pushState(null, null, url)
                }
                tableContainer.removeClass('is-requesting')


            }
        }

      
        // Initialize pagination
        function initPagination($pagination) {
            
            if ($pagination.length == 0) {
                return
                
            }
            // pagination.twbsPagination('destroy')
            let {
                first,
                last,
                next,
                prev,
                startPage,
                totalPages,
                visiblePages,
            } = $pagination.data()
            
            if (totalPages == 0) {
                totalPages = 1
            }
            // Pagination Init
            $pagination.twbsPagination({
                first,
                last,
                next,
                prev,
                startPage,
                totalPages,
                visiblePages,
                initiateStartPageClick: false,
                onPageClick: (event, page) => {
                    let uri = window.location.href
                    const requestURI = window.updateUrlParameter(uri, 'page', page)
                    tableContainer.addClass('is-requesting')
                    loader.removeClass('hidden')
                    window.location.href = requestURI
                }
                
            })
            
        }
    }
})