$(document).ready(() => {
    if($('#user-management-companies').exists()) {

        const pagination = $('.pagination')
        const tableContainer = $('#companies-list-container')
        const statusFilter = $('#bag-user-dashboard #dashboard-company-filter')

        if (window.location.pathname != '/dashboard/user-management-companies') {
            initPagination(pagination)
        }
        // Initialize pagination
        function initPagination($pagination) {
            if ($pagination.length == 0) {
                return
            }

            let {
                first,
                last,
                next,
                prev,
                startPage,
                totalPages,
                visiblePages,
            } = $pagination.data()

            if (totalPages == 0) {
                totalPages = 1
            }

            // Pagination Init
            pagination.twbsPagination({
                first,
                last,
                next,
                prev,
                startPage,
                totalPages,
                visiblePages,
                initiateStartPageClick: false,
                onPageClick: (event, page) => {
                    let uri = window.location.href
                    const requestURI = window.updateUrlParameter(uri, 'page', page)

                    if (typeof window.history.pushState != 'undefined') {
                        window.history.pushState(null, null, requestURI)
                    }

                    updateTableView(requestURI, false)
                }
            })
        }

        // Filter
        statusFilter.on('change', function () {
            let status = $(this).val()

            let uri = window.location.href

            let requestURI = window.updateUrlParameter(uri, 'status', status)
            requestURI = window.updateUrlParameter(requestURI, 'page', 1)

            if (typeof window.history.pushState != 'undefined') {
                window.history.pushState(null, null, requestURI)
            }

            updateTableView(requestURI, true)
            location.reload()
        })

        // Toggle status
        tableContainer.on('click', '.company-controls .togglebutton input', async function() {
            const formData = new FormData()
            const { url } = $(this).data()

            const currentCard = $(this).closest('.card-dashboard__company')

            $(this).parent().siblings('.user-active').toggleClass('is-active')
            $(this).parent().siblings('.user-inactive').toggleClass('is-active')

            let status
            if (this.checked) {
                status = 'active'
            } else {
                status = 'inactive'
            }

            currentCard.addClass('is-requesting')
            formData.append('status', status)

            try {
                // Make request
                await axios.post(url, formData, window.axiosConfig)

                const activeFilter = statusFilter.val()

                if (activeFilter != '0' && activeFilter !== status) {
                    currentCard.remove()
                }

                currentCard.removeClass('is-requesting')
            } catch(err) {
                window.showErrorPopup()
                currentCard.removeClass('is-requesting')
                throw err
            }
        })

        const deletePopup = $('#bag-user-dashboard .delete-warning-popup')
        const userPopupText = $('#bag-user-dashboard .delete-user-text')
        const companyPopupText = $('#bag-user-dashboard .delete-company-text')
        const userDeleteButton = $('#bag-user-dashboard .delete-button--users')
        const companyDeleteButton = $('#bag-user-dashboard .delete-button--company')

        // Delete popup
        tableContainer.on('click', '.company-controls .button-company-delete', function() {
            deletePopup.removeClass('hidden fadeOut').addClass("animated fadeIn faster")
            companyPopupText.removeClass('hidden')
            companyDeleteButton.removeClass('hidden')
            userPopupText.addClass('hidden')
            userDeleteButton.addClass('hidden')

            let companyName = $(this).parents('.company-upper-card').find('.company-info__company-name').text()
            companyPopupText.find('.company').replaceWith('<b class="company">' + companyName + '</b>')

            $(this).parents('.card-dashboard__company').addClass('delete-company')

            const { url, company_id } = $(this).data()

            $('.delete-button--company').data('url', url)
            $('.delete-button--company').data('company_id', company_id)
        })

        deletePopup.on('click', '.back-to-login, .login-exit--register i', () => {
            tableContainer.find('.card-dashboard__company').removeClass('delete-company')
        })

        // Remove event
        companyDeleteButton.on('click', async function(event) {
            event.preventDefault()
            event.stopPropagation()

            const { url, company_id } = $(this).data()
            const cardToRemove = tableContainer.find('.card-dashboard__company.delete-company')

            const formData = new FormData()
            formData.set('company_id', company_id)

            try {
                cardToRemove.addClass('is-requesting')

                // Make request
                await axios.post(url, formData, window.axiosConfig)

                cardToRemove.remove()
            } catch (err) {
                window.showErrorPopup()
                throw err
            }
        })

        // Update whole view
        async function updateTableView(requestURI, updatePagination = false) {
            pagination.addClass('is-requesting')
            tableContainer.addClass('is-requesting')

            try {
                const response = await axios.get(requestURI)
                const { data } = response

                const tableContent = $(data).find('#companies-list-container').html()
                tableContainer.html(tableContent)

                if (updatePagination) {
                    const updatedPagination = $(data).find('.pagination')
                    pagination.twbsPagination('destroy')
                    initPagination(updatedPagination)
                }

                window.addToggleInput()
                updateCheckBoxesFlags()

                tableContainer.removeClass('is-requesting')
                pagination.removeClass('is-requesting')
            } catch(err) {
                tableContainer.removeClass('is-requesting')
                pagination.removeClass('is-requesting')
                throw err
            }
        }

        function updateCheckBoxesFlags() {
            tableContainer.find('.user-activation').each(function() {
                const labelActive = $(this).parent().siblings('.user-active')
                const labelInactive = $(this).parent().siblings('.user-inactive')
                if(this.checked) {
                    labelActive.addClass('is-active')
                    labelInactive.removeClass('is-active')
                } else {
                    labelInactive.addClass('is-active')
                    labelActive.removeClass('is-active')
                }
            })
        }
    }
})
