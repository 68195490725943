$(document).ready(() => {
    // Validation on page load
    //validatePasswordChange()

    // Show or hide password input
    const changePasswordForm = $('.password-change-form')
    const showHidePassword = $('.change-password-style')

    const sendPassword = $('.password-change')
    const changePasswordSuccess = $('.password-change__success').parent()
    const changePasswordButton = $('#change_password__submit')
    const profilePageRequested = $('.user-profile').data('profilePage')
    const changePasswordPopup = $('.change-password-popup').parent()
    const changePasswordPopupMessage = changePasswordPopup.find('.user-registered-messages')
   

    changePasswordForm.on('keyup change', '.form-group input', () => {
        validatePasswordChange()
    })

    // Changing password input visibility
    function passwordChanger() {

        $(this).addClass('hidden')
        $(this).siblings('.change-password-style').removeClass('hidden')

        $(this).siblings('input').attr('type') == 'password' ? $(this).siblings('input').attr('type', 'text') : $(this).siblings('input').attr('type', 'password')
    }

    // Validation of password change form inputs
    function validatePasswordChange() {
        const passwordWarnings = $('.password-tips')
        const oldPassword = $('#change_password__old_password')
        const newPassword1 = $('#change_password__password1')
        const newPassword2 = $('#change_password__password2')
        const $form = changePasswordButton.closest('form')
        // const regEx = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
        changePasswordPopupMessage.addClass('hidden')

        if(!changePasswordButton.exists()) {
            return
        }

        const validation = window.validateFormFields($form)
        // SubmitButton logic
        if (
            validation &&
            validation.isValid &&
            oldPassword.val() != newPassword1.val() &&
            newPassword1.val() == newPassword2.val() &&
            // regEx.test(newPassword1.val()) &&
            newPassword1.val().length > 7
        ) {
            if (!newPassword1.parent('.form-group').hasClass('is-valid')){
                newPassword1.parent('.form-group').addClass('is-valid')

            }
            if (!newPassword2.parent('.form-group').hasClass('is-valid')){
                newPassword2.parent('.form-group').addClass('is-valid')

            }
            newPassword2.parent('.form-group').addClass('is-valid')
            changePasswordButton.prop("disabled", false)
            // passwordWarnings.addClass('hidden')

           
        } else {
          
            if (!validation.errors['change_password[_password1]'] && !newPassword1.parent('.form-group').hasClass('is-valid')){
                    newPassword1.parent('.form-group').addClass('is-valid')
    
                }            
            if (!validation.errors['change_password[_password2]'] && !newPassword2.parent('.form-group').hasClass('is-valid')){
                newPassword2.parent('.form-group').addClass('is-valid')
            }
            changePasswordButton.prop("disabled", true)
            passwordWarnings.removeClass('hidden')
           
            
        }
        regexPassword()
        // Show error messages
        // const inputsWithMessage = [
        //     'change_password[_password1]',
        //     'change_password[_password2]'
        // ]

        // window.handleValidationErrorMessages(inputsWithMessage, validation.errors, $form)
    }
    function regexPassword () {
        const passwordWarnings = $('.password-tips')
        const newPassword =  $('#change_password__password1').val()
        if (newPassword.length >= 8 ){
            if (!passwordWarnings.find(`[data-type='symbols']`).hasClass('is-valid')){
                passwordWarnings.find(`[data-type='symbols']`).addClass('is-valid')

            }
        } else {
            if (passwordWarnings.find(`[data-type='symbols']`).hasClass('is-valid')){
                passwordWarnings.find(`[data-type='symbols']`).removeClass('is-valid')

            }

        }
         const regexObj = {
            number: /[0-9]/,
            uppercase: /[A-Z]/,
            lowercase: /[a-z]/,
            special_char: /[#!@$%^+'.,:;&§[{}*=`°_\-\]\?]/,
        }
        let keys = Object.keys(regexObj)
        keys.forEach((key)=> {
            if (newPassword.match(regexObj[key])){
                if (!passwordWarnings.find(`[data-type='${key}']`).hasClass('is-valid')) {
                    passwordWarnings.find(`[data-type='${key}']`).addClass('is-valid')
                }
            } else {
                if (passwordWarnings.find(`[data-type='${key}']`).hasClass('is-valid')) {
                    passwordWarnings.find(`[data-type='${key}']`).removeClass('is-valid')
                }
            }

        })
    }
    // Ajax request for changing password
    const handleAjaxChangePassword = async (e) => {
        e.preventDefault()

        changePasswordPopupMessage.addClass('hidden')
        const requestURI = changePasswordButton.data('url')

        let formData = new FormData

        formData.set('_old_password', $('#change_password__old_password').val())
        formData.set('_password1', $('#change_password__password1').val())
        formData.set('_password2', $('#change_password__password2').val())

        changePasswordButton.trigger('toggleState')

        try {
            const response = await axios.post(requestURI, formData, window.axiosConfig)

            if(response.status == 200) {
                changePasswordPopup.addClass("hidden fadeOut").removeClass("animated fadeIn faster")
                changePasswordSuccess.find('.password-invitation-message').addClass('hidden')
                changePasswordSuccess.find('.password-changed-message').removeClass('hidden')
                changePasswordSuccess.removeClass("hidden fadeOut").addClass("animated fadeIn faster")
            }

            changePasswordButton.trigger('toggleState')

        } catch (err) {
            // Handle response on "proper way"
            const { response } = err

            // "Unprocessable Entity"
            if (response.status == 500) {
                // Server error
                window.showErrorPopup()
                console.error(err)
            } else {
                // Error handling
                changePasswordPopup.find('.user-registered-messages').removeClass('hidden')
            }

            changePasswordButton.trigger('toggleState')

            throw err
        }
    }

    showHidePassword.on('click', passwordChanger)

    // Closing popup
    const popupClosing = () => {
        changePasswordSuccess.addClass("animated fadeOut faster")
        setTimeout(function(){
            changePasswordSuccess.addClass("hidden fadeOut").removeClass("animated fadeIn faster")
        }, 500)
    }

    // Call ajax change password request
    changePasswordButton.on('click', handleAjaxChangePassword)

    //Call ajax send email request or show popup if user has been redirected
    sendPassword.on('click', function(e) {
        changePasswordPopup.removeClass('hidden fadeOut').addClass("animated fadeIn faster")
    })
})
