
/**
 * Helper for creating plain object from formFields [Plain Object for axios payload]
 * @param {*} $form [jQery form selector]
 */
window.createFormPayload = $form => {
    // Serialize all form fields
    // Since we need plain object, reduce array to object with paired key-values
    const fieldsData = $form.serializeArray().reduce((obj, item) => {
        obj[item.name] = item.value
        return obj
    }, {})

    return fieldsData
}

/**
 * Helper for creating "true" formData
 * Can't be used for files because of serializeArray
 * @param {*} $form [jQery form selector]
 */
window.createFromData = $form => {
    const formData = new FormData()
    const fieldsData = $form.serializeArray()

    fieldsData.forEach(field => {
        formData.append(field.name, field.value)
    })

    return formData
}

/**
 * Helper for validationg form via validatorJS
 * @param {*} $form [jQery form selector]
 * @param {*} handleInputs [flags for showing eror classes on inputs]
 * @param {*} showMessages [flag for showing error messages connected to input]
*/
window.validateFormFields = ($form, handleInputs = true, showMessages = null) => {
    if(!$form.exists()) {
        return
    }

    // Get all fields in form
    const fieldsArray = $form.serializeArray()
    const formFields = $form.find('[data-validate]')

    // Get form data
    const formData = fieldsArray.reduce((obj, item) => {
        obj[item.name] = item.value
        return obj
    }, {})

    // Get validation rules
    let rules = {}
    formFields.each((index, item) => {
        const field = $(item)
        const { validate } = field.data()

        if (validate !== ''){

            const fieldName = field.attr('name')
            const fieldRule = field.data('validate')

            rules[fieldName] = fieldRule
        }
    })


    // Validator instance [*Validate action*]
    const validation = new Validator(formData, rules)

    // Validate & get errors
    const isValid = validation.passes()
    const validationErrors = validation.errors.all()

    if (handleInputs) {
        formFields.each((index, item) => {
            const field = $(item)
            const fieldName = field.attr('name')
            let formGroup = field.closest('.form-group')

            // Since checkbox uses different markup
            if(field.is(':checkbox')) {
                formGroup = field.closest('.checkbox')
            }

            if(validationErrors.hasOwnProperty(fieldName)) {
                formGroup.addClass('has-error')
            } else {
                formGroup.removeClass('has-error')
            }
        })
    }

    // Just return flag
    return {
        isValid,
        errors: validationErrors
    }
}

/**
 * Helper for showing validation messages in form
 * Will not display message when input is empty..
 */
window.handleValidationErrorMessages = (inputsWithMessage, errors, $form) => {
    inputsWithMessage.forEach(inputName => {
        const input = $form.find(`[name="${inputName}"]`)
        const inputHelpBlock = input.next('span.help-block')

        if (errors.hasOwnProperty(inputName)) {
            if(input.val() !== '') {
                inputHelpBlock.removeClass('hidden')
            } else {
                inputHelpBlock.addClass('hidden')
            }
        } else {
            inputHelpBlock.addClass('hidden')
        }
    })
}

/**
 * Update floating labels on inputs, if HTML is rendered from ajax call
 */
window.updateInputsFloatingLabels = () => {
    // Update is-empty class on inputs
    $('.form-control').each(function() {
        const input = $(this)
        const formGroup = input.closest('.form-group')

        '' === input.val() ? formGroup.addClass('is-empty') : formGroup.removeClass('is-empty')
    })
}

/**
 * Handler/Helper for uri with params & hashes
 * *Note*: value: undefined, null and 0 will remove sent key from string
 */
window.updateUrlParameter = (uri, key, value) => {
    // Remove the hash part before operating on the uri
    let i = uri.indexOf('#')
    let hash = i === -1 ? ''  : uri.substr(i)
    uri = i === -1 ? uri : uri.substr(0, i)

    // Key is array-like
    // E.g "filter[name]"...
    let escapedKey = key
    if (/\[(.*?)\]/.test(key)) {
        // We need to escape "[ & ]" characters
        escapedKey = key.replace(/\[|\]/g, function(x) {
            return `\\${x}`
        })
    }

    // Regex for existing key
    let re = new RegExp("([?&])" + escapedKey + "=.*?(&|$)", "i")
    let removeReg = new RegExp("([?&]?)" + escapedKey + "=[^&]*", "i")

    // Separator logic
    let separator = uri.indexOf('?') !== -1 ? "&" : "?"

    // Remove key-value pair if value is empty
    if (!value) {
        uri = uri.replace(removeReg, '')

        if (uri.slice(-1) === '?') {
            uri = uri.slice(0, -1)
        }

        // Replace first occurrence of & by ? if no ? is present
        if (uri.indexOf('?') === -1) {
            uri = uri.replace(/&/, '?')
        }

    } else if (uri.match(re)) {
        // Key already exists - replace
        uri = uri.replace(re, '$1' + key + "=" + value + '$2')
    } else {
        // Simple "add" logic
        uri = uri + separator + key + "=" + value
    }

    return uri + hash
}

/**
 * Create query string from object [Structured like return from "window.getUrlParams()"]
 * @param {object} urlParamsObject
 */
 window.createUrlParamsFromObject = (urlParamsObject) => {

    let queryString = buildQueries(urlParamsObject)
    queryString = sanitizeQuery(queryString)

    return queryString

    // Create query-string like url with new params
    function buildQueries(urlParams) {
        let queries = ''

        Object.keys(urlParams).forEach(key => {
            const value = urlParams[key]

            // We have array in param
            if (Array.isArray(value)) {
                const paramsArray = value.map(param => `${key}[]=${param}`)

                if (paramsArray.length > 0) {
                    // Add array-like ones
                    queries = `${queries}&${paramsArray.join('&')}`
                }

            } else {
                // Simple, key-value pair
                // Take care that null or '' will clear it
                if (value) {
                    queries = `${queries}&${key}=${value}`
                }
            }
        })

        return queries
    }

    // Remove unwanted charachters
    function sanitizeQuery(queries) {
        // Remove duplicated [if they exist]
        queries = queries.replace('&&', '&')

        // Remove '&' from 1st place
        if (queries.charAt(0) === '&') {
            queries = queries.substr(1)
        }

        // Remove '&' from last place
        if (queries.charAt(queries.length - 1) === '&') {
            queries = queries.substr(0, queries.length - 1)
        }

        return queries
    }
}

// Logic for updating url with multiselects
window.updateUrlParameterMulti = (uri, key, value) => {
    // split the uri into url + hash if it exists
    const i = uri.indexOf('#')
    const hash = i == -1 ? '' : uri.substr(i)
    let url = decodeURI(i == -1 ? uri : uri.substr(0, i))

    // create key-value string pair
    const keyValue = key + '[]=' + value

    // if the value already exists remove it
    if (url.includes(keyValue)) {
        // escape '[' and ']' characters
        const keyValueEscaped = keyValue.replace(/\[|\]|\(|\)+/g, x => `\\${x}`)

        // create regex and remove it from url
        const keyValueRegex = new RegExp('([?&]?)' + keyValueEscaped, 'i')
        url = url.replace(keyValueRegex, '')

        // if the first param was removed, replace first '&' with '?'
        if (!url.includes('?')) {
            url = url.replace('&', '?')
        }

    // if the value doesnt exist append it with appropriate separator
    } else {
        let separator = url.includes('?') ? '&' : '?'
        url = url + separator + keyValue
    }

    return url + hash
}


/**
 * Helper which shows sweetalert's popup
 * Used only
 */
window.showLoaderPopup = (disableBody = true) => {
    const body = $('body')
    const popup = $('#bag-svg-loader-popup-global')

    if (disableBody) {
        body.addClass('is-Loading')
    }
    popup.addClass('animated fadeIn faster').removeClass('hidden fadeOut')
}

window.closeLoaderPopup = () => {
    const body = $('body')
    const popup = $('#bag-svg-loader-popup-global')

    body.removeClass('is-Loading')
    popup.addClass('animated fadeOut faster')
    setTimeout(() => {
        popup.addClass('hidden')
    }, 500)
}

/**
 * Helper which adds checkbox in dynamicaly createn forms
 *
 */
window.addCheckboxInput = () => {
    const checkboxInput = $('.page-content .checkbox input')

    checkboxInput.after("<span class='checkbox-material'><span class='check'></span></span>");
}

/**
 * Helper which adds toggle input in dynamicaly createn forms
 *
 */
window.addToggleInput = () => {
    const toggleInput = $('.page-content .togglebutton input')

    toggleInput.after("<span class='toggle'></span>");
}

/**
 * jQuerry extended function
 */

// Check wheter selected element exists in current DOM
$.fn.exists = function () {
    return this.length !== 0
}

// Extend select2, add search input placeholder option
let Defaults = $.fn.select2.amd.require('select2/defaults')

$.extend(Defaults.defaults, {
    searchInputPlaceholder: ''
})

let SearchDropdown = $.fn.select2.amd.require('select2/dropdown/search')

let _renderSearchDropdown = SearchDropdown.prototype.render

SearchDropdown.prototype.render = function(decorated) {
    // Get data from associated select element
    const { searchPlaceholder } = this.$element.data()

    let placeholder = searchPlaceholder || this.options.get('searchInputPlaceholder')

    // Invoke parent method
    let $rendered = _renderSearchDropdown.apply(this, Array.prototype.slice.apply(arguments))
    this.$search.attr('placeholder', placeholder)

    return $rendered
}

// Tippy default options
tippy.setDefaults({
    arrow: false,
    maxWidth: 'auto',
    size: 'large',
})

/**
 * Submit button logic
 */
window.updateSubmitButtons = () => {
    const submitButton = $('.submit-button')
    const loader = `
        <div class="loader-container">
            <img src="/static/img/assets/simple-loading.svg" />
        </div>
    `

    submitButton.wrapInner('<span class="text"></span>')
    submitButton.append(loader)

    submitButton.on('toggleState', function() {
        const $this = $(this)
        const isRequesting = $(this).hasClass('is-requesting')

        if (isRequesting) {
            $this.removeClass('is-requesting')
        } else {
            $this.addClass('is-requesting')
        }
    })
}
/**
 * check email domain if it matches the company
 * $(this) is the email input
 * */
window.checkDomain = async function checkDomain(checkUrl, companyId) {

    // email
    companyId = window.formatNumber(companyId)

        let arr = $(this).val().split('@')
        let email = (arr.length > 1) ? arr[1] : ''
        if (email){
            checkUrl = checkUrl.replace(/(@__[a-z]*)/, email)
            checkUrl = checkUrl.replace(/(__[a-z]*)/, companyId)
            if (!$('.email-message').length){
                $(this).after(`<span class="email-message"></span>`)
            }
            const messageSpan = $('.email-message')

            try {
                // Make invite request

                const response = await axios.get(checkUrl)

              if (!response.data.success){
                messageSpan.text(response.data.message)
              } else {
                messageSpan.text('')
              }

            } catch (err) {
                if (err.response.status == 304){
                    messageSpan.text(err.response.data.message)

                    return
                }
                messageSpan.text(err.response.data.message)

            }
        }
}
/***
 * Pad company number with zeros in front
 */
window.formatNumber = function (companyNumber){
    let formattedNumber = parseInt(companyNumber).toString();
    let numberLength = formattedNumber.length
    if (numberLength<10){
        let zerosNum= 10 - numberLength;
        formattedNumber= formattedNumber.padStart(zerosNum, '0');
    }
    return formattedNumber

}

$(document).ready(() => {
    window.updateSubmitButtons()
})

/**
 * Get all query params as object
 */
window.getUrlParams = (url) => {
    // get query string from url (optional) or window
    let queryString = url ? url.split('?')[1] : window.location.search.slice(1)
    // we'll store the parameters here
    let obj = {}
    // if query string exists
    if (queryString) {
        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0]
        // split our query string into its component parts
        let arr = queryString.split('&')
        for (let i = 0; i < arr.length; i++) {
            // separate the keys and the values
            let a = arr[i].split('=')
            // set parameter name and value (use 'true' if empty)
            let paramName = a[0]
            let paramValue = typeof (a[1]) === 'undefined' ? true : a[1]
            // (optional) keep case consistent
            paramName = paramName.toLowerCase()
            if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase()
            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {
                // create key if it doesn't exist
                let key = paramName.replace(/\[(\d+)?\]/, '')
                if (!obj[key]) obj[key] = []
                // if it's an indexed array e.g. colors[2]
                if (paramName.match(/\[\d+\]$/)) {
                    // get the index value and add the entry at the appropriate position
                    let index = /\[(\d+)\]/.exec(paramName)[1]
                    obj[key][index] = paramValue
                } else {
                    // otherwise add the value to the end of the array
                    obj[key].push(paramValue)
                }
            } else {
                // we're dealing with a string
                if (!obj[paramName]) {
                    // if it doesn't exist, create property
                    obj[paramName] = paramValue;
                } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                    // if property does exist and it's a string, convert it to an array
                    obj[paramName] = [obj[paramName]]
                    obj[paramName].push(paramValue)
                } else {
                    // otherwise add the property
                    obj[paramName].push(paramValue)
                }
            }
        }
    }
    return obj
}

if (!String.prototype.includes) {
    String.prototype.includes = function(search, start) {
        'use strict';

        if (search instanceof RegExp) {
        throw TypeError('first argument must not be a RegExp')
        }

        if (start === undefined) { start = 0 }

        return this.indexOf(search, start) !== -1
    }
}

/**
 * https://stackoverflow.com/questions/31221341/ie-does-not-support-includes-method
 */
 if (!Array.prototype.includes) {
    Array.prototype.includes = function (search, start) {
        'use strict'

        if (typeof start !== 'number') {
            start = 0
        }

        if (start + search.length > this.length) {
            return false
        } else {
            return this.indexOf(search, start) !== -1
        }
    }
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/isArray
 */
if (!Array.isArray) {
    Array.isArray = function (arg) {
        return Object.prototype.toString.call(arg) === '[object Array]'
    }
}

//add company parameter to home url
window.setCompanyToHome = function() {
    if ($('body').hasClass('cockpit')){
        let homeLink = $('#page-header').find('.home-path')
        let currentUrl = window.location.href
        let params = window.getUrlParams(currentUrl)
        let key = Object.keys(params).find((name)=> name == 'c')
        if (homeLink.exists()) {
            let url = homeLink.attr('href')
            if(key) {
                url = window.updateUrlParameter(homeLink.attr('href'), key, params[key])
            }

            // Add page number that was choosen on the dashboard to home url
            if(localStorage.getItem('dashboardPage')) {
                const pageNumber = localStorage.getItem('dashboardPage')

                url = window.updateUrlParameter(url, 'page', pageNumber)
            }
            homeLink.attr('href', url)
        }
    }
}

// Set page number to header navigation
$(document).ready(function() {
    let dashboardLink = $('.navigation-container').find('.nav-item a.active')

    if (dashboardLink.exists()) {
        let url = dashboardLink.attr('href')

        // Add page number that was choosen on the dashboard to header navigation
        if(localStorage.getItem('dashboardPage')) {
            const pageNumber = localStorage.getItem('dashboardPage')
            url = window.updateUrlParameter(url, 'page', pageNumber)
        }
        dashboardLink.attr('href', url)
    }
})
