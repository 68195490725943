var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "material-info" },
    [
      _c("table", { staticClass: "bordered-info-table" }, [
        _c("tbody", [
          _c("tr", [
            _c("td", { staticClass: "heading", attrs: { colspan: "3" } }, [
              _vm._v(
                _vm._s(
                  _vm._f("trans")("disposition.calloff_details.materialinfo")
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("tr", { staticClass: "info-table" }, [
            _c("td", [
              _vm._v(
                _vm._s(_vm._f("trans")("disposition.calloff_details.sort")) +
                  ":"
              )
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "last-edit" }, [
              _vm.materialCategoryEdit
                ? _c("span", [_vm._v(_vm._s(_vm.materialCategoryEdit))])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                _vm._s(
                  _vm.item.material && _vm.item.material.category
                    ? _vm.item.material.category
                    : "—"
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("tr", { staticClass: "info-table" }, [
            _c("td", [
              _vm._v(
                _vm._s(_vm._f("trans")("disposition.calloff_details.number")) +
                  ":"
              )
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "last-edit" }, [
              _vm.materialNumberEdit
                ? _c("span", [_vm._v(_vm._s(_vm.materialNumberEdit))])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                _vm._s(
                  _vm.item.material && _vm.item.material.number
                    ? parseInt(_vm.item.material.number)
                    : "—"
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("tr", { staticClass: "info-table" }, [
            _c("td", [
              _vm._v(
                _vm._s(_vm._f("trans")("disposition.calloff_details.name")) +
                  ":"
              )
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "last-edit" }, [
              _vm.materialNameEdit
                ? _c("span", [_vm._v(_vm._s(_vm.materialNameEdit))])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                _vm._s(
                  _vm.item.material && _vm.item.material.designation
                    ? _vm.item.material.designation
                    : "—"
                )
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.status == 2 || _vm.status == 0
          ? _c("tbody", [
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")(
                        "disposition.calloff_details.last_reminder"
                      )
                    ) + ":"
                  )
                ]),
                _vm._v(" "),
                _vm.status === 0
                  ? _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.item.last_reminder_date
                            ? _vm.item.last_reminder_date
                            : "—"
                        )
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.status === 2
                  ? _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.item.status_info &&
                            _vm.item.status_info.cancellation_requested_date
                            ? _vm.item.status_info.cancellation_requested_date
                            : "—"
                        )
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")(
                        "disposition.call-off.table.header.delivery_date"
                      )
                    ) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.item.delivery_date ? _vm.item.delivery_date : "—"
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")("disposition.calloff_details.time_left")
                    ) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.item.remaining_time ? _vm.item.remaining_time : "—"
                    )
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.status == 1
          ? _c("tbody", [
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")(
                        "disposition.call-off.table.header.delivery_start_date"
                      )
                    ) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "last-edit" }, [
                  _vm.deliveryDateEdit
                    ? _c("span", [_vm._v(_vm._s(_vm.deliveryDateEdit))])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.item.delivery_date ? _vm.item.delivery_date : "—"
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")(
                        "disposition.delivery-note.table.header.delivery_start_time"
                      )
                    ) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "last-edit" }, [
                  _vm.deliveryTimeEdit
                    ? _c("span", [_vm._v(_vm._s(_vm.deliveryTimeEdit))])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.item.delivery_time ? _vm.item.delivery_time : "—"
                    ) +
                      " " +
                      _vm._s(_vm.timeTrans)
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")(
                        "disposition.call-off.table.header.delivery_end_date"
                      )
                    ) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "last-edit" }, [
                  _vm.deliveryDateEndEdit
                    ? _c("span", [_vm._v(_vm._s(_vm.deliveryDateEndEdit))])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.item.delivery_end_date
                        ? _vm.item.delivery_end_date
                        : "—"
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")(
                        "disposition.delivery-note.table.header.delivery_end_time"
                      )
                    ) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "last-edit" }, [
                  _vm.deliveryTimeEndEdit
                    ? _c("span", [_vm._v(_vm._s(_vm.deliveryTimeEndEdit))])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.item.delivery_end_time
                        ? _vm.item.delivery_end_time
                        : "—"
                    ) +
                      " " +
                      _vm._s(_vm.timeTrans)
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")(
                        "disposition.call-off.table.header.quantity"
                      )
                    ) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "last-edit" }, [
                  _vm.quantityEdit
                    ? _c("span", [_vm._v(_vm._s(_vm.quantityEdit))])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(_vm.item.quantity ? _vm.item.quantity : "—"))
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")(
                        "disposition.call-off.table.header.quantity_hourly"
                      )
                    ) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "last-edit" }, [
                  _vm.hourlyQuantityEdit
                    ? _c("span", [_vm._v(_vm._s(_vm.hourlyQuantityEdit))])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.item.hourly_quantity ? _vm.item.hourly_quantity : "—"
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(_vm._f("trans")("disposition.call-offs.work")) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "last-edit" }, [
                  _vm.shipping_spotEdit
                    ? _c("span", [_vm._v(_vm._s(_vm.shipping_spotEdit))])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.item.shipping_spot && _vm.item.shipping_spot.name
                        ? _vm.item.shipping_spot.name
                        : "—"
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")("disposition.calloffs.vehicle_type")
                    ) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "last-edit" }, [
                  _vm.vehicle_typeEdit
                    ? _c("span", [_vm._v(_vm._s(_vm.vehicle_typeEdit))])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  !_vm.vehicle_typeEdit && _vm.item.vehicle_type_changed
                    ? _c("span", { staticClass: "tooltip-container" }, [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            attrs: {
                              "data-tooltip":
                                _vm.item.vehicle_type_changed_tooltip
                            }
                          },
                          [
                            _vm._v(
                              "\n                               error_outline\n                           "
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(
                    "\n                       " +
                      _vm._s(
                        _vm.item.vehicle_type ? _vm.item.vehicle_type : "—"
                      ) +
                      "\n                   "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(_vm._f("trans")("disposition.calloffs.incoterm")) +
                      ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "last-edit" }, [
                  _vm.incotermEdit
                    ? _c("span", [_vm._v(_vm._s(_vm.incotermEdit))])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  !_vm.incotermEdit && _vm.item.incoterm_changed
                    ? _c("span", { staticClass: "tooltip-container" }, [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            attrs: {
                              "data-tooltip": _vm.item.incoterm_changed_tooltip
                            }
                          },
                          [
                            _vm._v(
                              "\n                               error_outline\n                           "
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(
                    "\n                       " +
                      _vm._s(
                        _vm.item.incoterm_data
                          ? _vm.item.incoterm_data.title
                          : "—"
                      ) +
                      "\n                   "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")(
                        "disposition.call-off.table.header.number_of_vehicles"
                      )
                    ) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "last-edit" }, [
                  _vm.nr_vehiclesEdit
                    ? _c("span", [_vm._v(_vm._s(_vm.nr_vehiclesEdit))])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.item.nr_vehicles ? _vm.item.nr_vehicles : "—")
                  )
                ])
              ])
            ])
          : _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "heading", attrs: { colspan: "3" } }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")(
                        "disposition.calloff_details.manufacturer_info"
                      )
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")(
                        "disposition.calloffs.customerbox.customer"
                      )
                    ) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(_vm._s(_vm.item.customer.name))
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(_vm._f("trans")("disposition.customer.phone")) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(_vm._s(_vm.item.customer.phone))
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")("disposition.calloffs.customerbox.mail")
                    ) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(_vm._s(_vm.splitEmail))
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("trans")(
                        "disposition.calloffs.customerbox.delivery_date"
                      )
                    ) + ":"
                  )
                ]),
                _vm._v(" "),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(_vm._s(_vm.item.creation_date))
                ])
              ])
            ])
      ]),
      _vm._v(" "),
      _vm.status == 1
        ? _c("div", { staticClass: "button-row" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary show-edit-calloff-popup",
                attrs: { "data-calloff-update-path": _vm.calloffEditUrl }
              },
              [
                _vm._v(
                  "\n               " +
                    _vm._s(
                      _vm._f("trans")("disposition.calloffs.calloff_edit")
                    ) +
                    "\n           "
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.popupShow
        ? _c("popup-container", {
            attrs: {
              calloffTypesUrl: _vm.calloffTypesUrl,
              calloffEditUrl: _vm.calloffEditUrl
            },
            on: {
              close: function($event) {
                _vm.popupShow = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }