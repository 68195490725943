$(document).ready(() => {

    const popupClosing = () => {
        $('.dashboard-popup').addClass("animated fadeOut faster")
        setTimeout(function(){
            $('.dashboard-popup').addClass("hidden fadeOut").removeClass("animated fadeIn faster")
        }, 500)
    }

    const successClosing = () => {
        $('.dashboard-popup').addClass("animated fadeOut faster"),
        setTimeout(function(){
            $('.dashboard-popup').addClass("hidden fadeOut").removeClass("animated fadeIn faster"),
            $('.invite-company__success').addClass("hidden"),
            $('.invite-company__form').removeClass("hidden")
        }, 500)
    }

    const removeClass = () => {
        $('tr').removeClass('delete-row')
    }

    //Close popup for company invitation
    $('.login-exit--register, .popup-exit, .back-to-login').on('click', function() {
        popupClosing(),
        removeClass(),
        clearForm()
    })
    $('.login-exit--success, .invitation-success-close').on('click', function() {
        popupClosing(),
        successClosing()
    })

    $('.delete-button--users, .delete-button--company').on('click', popupClosing)

    // Select2 selects and logic for search
    if(
        $('.company-invite-search-ac').length > 0
    ) {
        // Autocomplete component for search company in invite form
        // GETselect
        const searchInput =  $('.company-invite-search-ac')
        let companyId = window.formatNumber(searchInput.val())
        searchInput.select2({
            width: '',
            multiple: true,
            allowClear: true,
            maximumSelectionLength: 1,
            minimumInputLength: 1,
            minimumResultsForSearch: -1,
            dropdownParent: $('.invite-company--name'),
            language: {
                inputTooShort: () => searchInput.data('messageAddMoreText'),
                noResults: () => searchInput.data('messageNoResults'),
                errorLoading: () => searchInput.data('messageNoResults'),
                maximumSelected: () => searchInput.data('messageMaxSelected'),
            },
            ajax: {
                transport: window.customTransportFunction,
                cache: false,
                url: searchInput.data('url'),
                dataType: 'json',
               
                data: (params) => {
                    return {
                        term: $.trim(params.term)
                    }
                },
                processResults: (data) => {
                    return {
                        results: data.map(el => ({
                            ...el,
                            id: parseInt(el.customer_nr),
                            text: el.name
                        }))
                    }
                },
            },
            escapeMarkup: (markup) => markup,
            templateResult: formatCompany,
            templateSelection: formatSelectedCompany
        }).on('select2:select', function(e) {
             companyId = window.formatNumber(e.params.data.customer_nr) 
        })

        $('.company-invite-search-ac').empty().trigger('change')

        function formatDisplay (str, splitter) {
            let isNumber =  isNaN(parseInt(str)) ? false : true;
            if (isNumber){
                str = parseInt(str).toString();  
            } 
            const trimmedSeparator = splitter.toString().trim()
            const replaceRegex = new RegExp(trimmedSeparator, "gi")
            
            const originalArray = str.split(replaceRegex)
            let result = ''
            let separators = []

            let iteratedString = str

            let currentIndex = iteratedString.search(replaceRegex)

           
            while (currentIndex !== -1) {
                separators.push(iteratedString.substring(currentIndex, currentIndex + trimmedSeparator.length))

                iteratedString = iteratedString.slice(currentIndex + trimmedSeparator.length)

                currentIndex = iteratedString.search(replaceRegex)
               
            }

            originalArray.map( (el, i) => {
                result += el

                if (i !== originalArray.length - 1) {
                    result += `<span class="search-term-splitter">${separators[i]}</span>`
                }
            })

            return result   
        }

        function formatCompany (company, item) {
            let searchFocused = $('.invite-company--name .select2-container').hasClass('select2-container--focus')
            let searchOpened = $('.invite-company--name .select2-container').hasClass('select2-container--open')
           
            const currentSearchTerm =  $('.invite-company--name .select2-search__field').val()
            let formatedName = ''
            let formatedNumber = ''
            if (company.name) {
                if (searchFocused && searchOpened){
                    formatedName = formatDisplay(company.name, currentSearchTerm)
                    formatedNumber = formatDisplay(company.customer_nr, currentSearchTerm)
                }
            }
            if (company.loading) return `   <div class="loader-container">
                <img src="/static/img/assets/simple-loading-blue.svg" />
                </div>`

            const markup = `
                <span data-customer_name="${company.name}" class="company-search-result">
                    ${formatedName}
                </span>
                <span data-customer_nr="${company.customer_nr}" class="company-customer-number company-search-result">
                    ${formatedNumber}
                </span>

            `

            return markup
        }

        function formatSelectedCompany (company, item) {
            const markup = `
            <span data-customer_name="${company.text}">
                ${company.text}
            </span>
            <span data-customer_nr="${company.customer_nr}" class="company-customer-number">
                ${parseInt(company.customer_nr)}
            </span>
            `

            return markup
        }

        // Add material dashboard class to form input
        $('#invite-company-form .select2-search__field').addClass('form-control')
        $(document).on('keyup', '#invite_company_email', function() {
            let checkUrl = $('.open-company-invitation').data('emailcheck')
            window.checkDomain.call($(this), checkUrl, companyId)
        })
    }
  
    /**
     * Show & close popup logic
     */
    const clearForm = () => {
        $('.invite-company__form .form-group').each(function() {
            $(this).find('.form-control').val('')
            $(this).find('.company-invite-search-ac').val(null).trigger('change')
            $(this).removeClass('has-error')
        })

        $('.invite-company__form .company-invite-button').prop('disabled', true)
        window.updateInputsFloatingLabels()
    }

    $('.open-company-invitation').on('click', function() {
        $('.company-invitation-popup').removeClass("hidden fadeOut").addClass("animated fadeIn faster")
    })

    $('.company-invitation-popup .login-exit, .company-invitation-popup .back-to-login').on('click', () => clearForm())

    /**
     * Validate & submit
     */
    let dashboardPopup = $('.dashboard-popup')
    dashboardPopup.on('keyup change', '.invite-company__form [data-validate]:not([data-validate=""])', () => {
        validateInviteForm()
    })  

    $('body').on('click', '.company-invite-button', function(event) {
        
        event.stopPropagation()
        event.preventDefault()

        const $form = $(this).closest('form')

        if(!$form.exists()) {
            return
        }

        const payload = $form.serialize()
        const requestURI = $form.attr('action')

        $('.company-invitation-popup .invite-company__loader').removeClass('hidden')
        $('.company-invitation-popup').addClass('hidden')

        handleAjaxInvitation(requestURI, payload)
    })

    function validateInviteForm() {
        const submitButton = $('.invite-company__form .company-invite-button')
        const $form = submitButton.closest('form')

        const validation = window.validateFormFields($form, false)

        // SubmitButton logic
        if (validation && validation.isValid) {
            submitButton.prop('disabled', false)
        } else {
            submitButton.prop('disabled', true)
        }
    }

    /**
     * Request handler
     */
    async function handleAjaxInvitation(requestURI, payload) {
        try {
            // Make invite request
            window.showLoaderPopup()
            $('.company-invitation-popup, .add-owner-popup').addClass('hidden')
            await axios.post(requestURI, payload, window.axiosConfig)

            // Update dashboard view
            if ($('#invitations-dashboard').exists()) {

                const dashboardUpdateRoute = window.bagRoutes.getUserDashboard
                const response = await axios.get(dashboardUpdateRoute, window.axiosConfig)

                const { data } = response
                const dashboardHtml = $(data).find('#invitations-dashboard')

                $('#invitations-dashboard').html(dashboardHtml)
            }

            // Popup actions
            window.closeLoaderPopup()
            swal({
                className: $('.company-invite-button').data('className'),
                title: $('.company-invite-button').data('title'),
                text: $('.company-invite-button').data('text'),
                icon: 'success',
                buttons: {
                    submit: {
                        text: $('.company-invite-button').data('submitText'),
                        closeModal: true,
                    }
                }
            }).then(() => {
                location.reload()
            })
        } catch (err) {
            window.closeLoaderPopup()
            $('.company-invitation-popup .invite-company__success').addClass('hidden')
            $('.company-invitation-popup .invite-company__loader').addClass('hidden')
            $('.company-invitation-popup .add-owner-popup').addClass('hidden')
            window.showErrorPopup(err.response.data.message)
            clearForm()
            throw err
        }
    }
})
