<template>
   
    <select class="rejection-select" :key="selection" >
        <slot></slot>
    </select>
   
</template>

<script>
export default {
    props: {
       
        placeholder: {
            type: String,
            default: 'Choose'
        },
        options: ()=>[]

       
    },
    mounted() {
        let component = this
        let selector = $(this.$el)
        
       selector.select2({
            minimumResultsForSearch: -1,
            dropdownParent: selector.parent('.rejection-select-container'),
            escapeMarkup: markup => markup,
            placeholder: this.placeholder,
            data: this.options,
            templateSelection: function (item, container) {
               
                let $container = $(container)
                $container.attr('key', item.id)
                let text = item.text
              
               
                return text
            }
        }).on('select2:select', function(e) {
                component.selection(e.params.data.id)
            })
    },
     watch: {
        value: function (value) {
        // update value
        $(this.$el).val(value).trigger('change');
        },
        options: function (options) {
        // update options
        $(this.$el).select2({ data: options })
        }
    },
  destroyed: function () {
    $(this.$el).off().select2('destroy')
  },

    methods: {
        selection(val) {
            this.selected= val;
            this.$emit('change', this.selected)
        }
    }
}
</script>

<style>

</style>