$(document).ready(() => {
    if($('.web-image-container').length > 0) {
        let imageZoom = $('.web-image-container .image-container.image-zoom-enabled')
        
        $('.web-image-container .trigger-edit-popup').on('click', function() {
            const editModePopup = $(this).closest('.web-image-container').find('.editmode-popup')

            editModePopup.addClass('open')
            $('html').css('overflow', 'hidden')
        })

        $('.web-image-container .save-popup').on('click', function() {
            const { documentId } = $(this).data()
            const editModePopup = $(this).closest('.editmode-popup')

            editModePopup.removeClass('open')
            $('html').css('overflow', 'auto')

            const payload = {
                detail: {
                    document_id: documentId
                } 
            }

            const myEvent = new CustomEvent('document_save_reload', payload)
            window.parent.dispatchEvent(myEvent)
        })

        $('.web-image-container .close-popup').on('click', function() {
            const editModePopup = $(this).closest('.editmode-popup')

            editModePopup.removeClass('open')
            $('html').css('overflow', 'auto')
        })
        
        imageZoom.on('click touch', function() {
            if($(this).hasClass('image-zoom-visible')) {
                $(this).removeClass('image-zoom-visible') 
            } else {
                $(this).addClass('image-zoom-visible')
            }
        })
    }
})